import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseAdminItemContainer } from "../purchase-admin-item/purchase-admin-item.container";
import { PurchaseAdminItemDataContainer } from "../purchase-admin-item-data/purchase-admin-item-data.container";
import { PurchaseAdminItemTabContainer } from "../purchase-admin-item-tab/purchase-admin-item-tab.container";

export function PurchaseAdminItemPage() {
  return (
    <Container maxWidth="sm">
      <PurchaseAdminItemContainer />
      <Grid spacing={3} container>
        <Grid item>
          <PurchaseAdminItemTabContainer />
        </Grid>
        <Grid item>
          <PurchaseAdminItemDataContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
