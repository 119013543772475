import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Alert from '@mui/material/Alert';

import { LoaderBarComponent } from '../../lib/common/loader-bar';
import { SkeletonListComponent } from '../../lib/common/skeleton-list/skeleton-list.component';
import { TextComponent } from '../../lib/common/text';

import { PromocodeAdminListViewComponent } from './frame/promocode-admin-list-view.component';

export function PromocodeAdminListComponent(props) {
  const {
    pageLoading,
    isPending,
    onSubmitForm,
    isError,
    isSuccess,
    data,
    errorMessage,
  } = props;

  return (
    <Box>
      <Paper sx={{ py: 6 }}>
        <Box>
          <Box sx={{ px: 6 }}>
            <Box sx={{ pb: 4 }}>
              <TextComponent
                variant="title"
                component="div"
                tid="PROMOCODE.ADMIN.LIST.TITLE"
              />
            </Box>
            <Divider />
          </Box>
          {isSuccess && <PromocodeAdminListViewComponent data={data} />}
          {isPending && (
            <Box sx={{ px: 6, pt: 4 }}>
              <SkeletonListComponent />
            </Box>
          )}
          {isError && (
            <Box sx={{ px: 6, pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
