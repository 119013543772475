import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_STORE_NAME } from '../purchase-admin-item-coaching-demo-list/purchase-admin-item-coaching-demo-list.constant';

import { purchaseAdminItemCoachingDemoCreateFormValidation } from './purchase-admin-item-coaching-demo-create.validation';
import { PurchaseAdminItemCoachingDemoCreateComponent } from './purchase-admin-item-coaching-demo-create.component';
import {
  uploadPurchaseAdminItemCoachingDemoCreateForm,
  resetPurchaseAdminItemCoachingDemoCreateFormState,
} from './purchase-admin-item-coaching-demo-create.action';
import {
  PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_STORE_NAME,
  PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_DATA_NAME,
} from './purchase-admin-item-coaching-demo-create.constant';
import { convertPurchaseAdminItemCoachingDemoCreateFormData } from './purchase-admin-item-coaching-demo-create.convert';

export function PurchaseAdminItemCoachingDemoCreateContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, coachingStore } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    coachingStore: state[PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemCoachingDemoCreateFormState());
  }, []);

  const purchaseAdminItemCoachingDemoCreateFormSendData = (
    values,
    { resetForm },
  ) => {
    const data = convertPurchaseAdminItemCoachingDemoCreateFormData(values);
    data.purchaseId = coachingStore.purchaseId;
    dispatch(
      uploadPurchaseAdminItemCoachingDemoCreateForm(data, () => {
        resetForm();
      }),
    );
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_DATA_NAME.INFO]: '',
      [PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_DATA_NAME.FILE]: [],
    };
  };

  return (
    <PurchaseAdminItemCoachingDemoCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(coachingStore.request)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemCoachingDemoCreateFormValidation}
      onSubmitForm={purchaseAdminItemCoachingDemoCreateFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
