import { validate } from '../../main/validate';

import { PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME } from './purchase-admin-item-chat-create.constant';

import { required } from '../../main/validate/validate.service';

const config = {
  [PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.DATA]: [required],
};

export const purchaseAdminItemChatCreateFormValidation = (values) =>
  validate(values, config);
