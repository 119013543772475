import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PurchaseAdminItemUpdateGdsItemComponent } from "./purchase-admin-item-update-gds-item.component";
import { purchaseAdminItemUpdateGdsItemFormValidation } from "./purchase-admin-item-update-gds-item.validation";
import { convertPurchaseAdminItemUpdateGdsItemFormData } from "./purchase-admin-item-update-gds-item.convert";
import {
  uploadPurchaseAdminItemUpdateGdsItemForm,
  resetPurchaseAdminItemUpdateGdsItemFormState,
} from "./purchase-admin-item-update-gds-item.action";
import {
  PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_STORE_NAME,
  PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_DATA_NAME,
} from "./purchase-admin-item-update-gds-item.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PurchaseAdminItemUpdateGdsItemContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemUpdateGdsItemFormState());
  }, []);

  const purchaseAdminItemUpdateGdsItemFormSendData = (values) => {
    const data = convertPurchaseAdminItemUpdateGdsItemFormData(values);

    data.purchaseId = purchaseId;

    dispatch(uploadPurchaseAdminItemUpdateGdsItemForm(data));
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_DATA_NAME.ID]: "",
    };
  };

  return (
    <PurchaseAdminItemUpdateGdsItemComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemUpdateGdsItemFormValidation}
      onSubmitForm={purchaseAdminItemUpdateGdsItemFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
