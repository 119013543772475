import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PurchaseAdminItemUpdateBoosterComponent } from "./purchase-admin-item-update-booster.component";
import { purchaseAdminItemUpdateBoosterFormValidation } from "./purchase-admin-item-update-booster.validation";
import { convertPurchaseAdminItemUpdateBoosterFormData } from "./purchase-admin-item-update-booster.convert";
import {
  uploadPurchaseAdminItemUpdateBoosterForm,
  resetPurchaseAdminItemUpdateBoosterFormState,
} from "./purchase-admin-item-update-booster.action";
import {
  PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_STORE_NAME,
  PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_DATA_NAME,
} from "./purchase-admin-item-update-booster.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PurchaseAdminItemUpdateBoosterContainer() {
  let { purchaseId } = useParams();

  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemUpdateBoosterFormState());
  }, []);

  const purchaseAdminItemUpdateBoosterFormSendData = (values) => {
    const data = convertPurchaseAdminItemUpdateBoosterFormData(values);

    data.purchaseId = purchaseId;

    dispatch(uploadPurchaseAdminItemUpdateBoosterForm(data));
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_DATA_NAME.ID]: "",
    };
  };

  return (
    <PurchaseAdminItemUpdateBoosterComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemUpdateBoosterFormValidation}
      onSubmitForm={purchaseAdminItemUpdateBoosterFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
