export const NEWS_ADMIN_ITEM_DELETE_STORE_NAME = 'NEWS_ADMIN_ITEM_DELETE';

export const NEWS_ADMIN_ITEM_DELETE_API = {
  DELETE_DATA: {
    ENDPOINT: (id) => `/news/${id}`,
    TYPE: 'DELETE',
  },
};

export const NEWS_ADMIN_ITEM_DELETE_ACTION_TYPE = {
  FORM_PENDING: 'NEWS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'NEWS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'NEWS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'NEWS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_RESET',
};
