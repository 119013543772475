import React from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { FieldTextComponent } from '../../lib/common/field-text/field-text.component';
import { TextComponent } from '../../lib/common/text';
import Button from '@mui/material/Button';

export function UserAdminFindItemComponent(props) {
  const { onSubmitForm } = props;

  const [login, setLogin] = React.useState('');

  const handleChangeLogin = (e) => {
    setLogin(e.target.value);
  };

  const handleOnSubmit = () => {
    onSubmitForm(login);
  };

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="USER.ADMIN.FIND_ITEM.TITLE"
            />
          </Box>
          <Divider />
          <Grid sx={{ pt: 4 }} spacing={3} container>
            <Grid item>
              <FieldTextComponent
                onChange={handleChangeLogin}
                label={<TextComponent tid="USER.ADMIN.FIND_ITEM.FORM.LOGIN" />}
              />
            </Grid>
            <Grid item>
              <Button onClick={handleOnSubmit} fullWidth>
                <TextComponent tid="USER.ADMIN.FIND_ITEM.FORM.SUBMIT" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
