import React from 'react';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { text } from '../text/text.core';

export const FieldCopyComponent = ({ value, alertTid }) => {
  const [copied, setCopied] = React.useState(false);

  const onCopyKey = () => {
    setCopied(true);
  };

  const handleClose = (event, reason) => {
    setCopied(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item>
          <OutlinedInput
            sx={{ pr: 8 }}
            value={value}
            multiline
            endAdornment={
              <InputAdornment position="end">
                <CopyToClipboard
                  sx={{
                    position: 'absolute',
                    top: '6px',
                    right: '18px',
                  }}
                  text={value}
                  onCopy={onCopyKey}
                >
                  <IconButton aria-label="copy data" edge="end">
                    <FileCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <Snackbar
        onClose={handleClose}
        message={text(alertTid)}
        open={copied}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </React.Fragment>
  );
};
