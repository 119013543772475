import { convertFileFieldValue } from '../../lib/common/field-file/field-file.convert';
import { convertSelectFieldValue } from '../../lib/common/field-select/field-select.convert';
import { GDS_ITEM_ADMIN_CREATE_DATA_NAME } from './gds-item-admin-create.constant';

export const convertGdsItemAdminCreateFormData = (data) => ({
  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_RU]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_RU],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_EN]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_EN],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.ORDER]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.ORDER],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_RU]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_RU],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_EN]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_EN],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_EN]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_EN],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_RU]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_RU],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU]:
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU],

  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.DATA]: String(
    data[GDS_ITEM_ADMIN_CREATE_DATA_NAME.DATA],
  ),
});
