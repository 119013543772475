import { validate } from '../../main/validate';

import { PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_DATA_NAME } from './purchase-admin-item-update-customer.constant';

import { required } from '../../main/validate/validate.service';

const config = {
  [PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_DATA_NAME.ID]: [required],
};

export const purchaseAdminItemUpdateCustomerFormValidation = (values) =>
  validate(values, config);
