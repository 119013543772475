import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { ErrorContainer } from "./error.container";
import { NavigationContainer } from "../navigation/navigation.container";

export function ErrorPage(props) {
  return (
    <NavigationContainer>
      <Container maxWidth="sm">
        <Grid container>
          <Grid item>
            <ErrorContainer {...props} />
          </Grid>
        </Grid>
      </Container>
    </NavigationContainer>
  );
}
