import React from 'react';

import { AccountSettingsUpdateTimezoneComponent } from './account-settings-update-timezone.component';

import { useDispatch, useSelector } from 'react-redux';

import { accountSettingsUpdateTimezoneFormValidation } from './account-settings-update-timezone.validation';

import { convertAccountSettingsUpdateTimezoneFormData } from './account-settings-update-timezone.convert';

import {
  uploadAccountSettingsUpdateTimezoneForm,
  resetAccountSettingsUpdateTimezoneFormState,
} from './account-settings-update-timezone.action';

import {
  ACCOUNT_SETTINGS_UPDATE_TIMEZONE_STORE_NAME,
  ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME,
} from './account-settings-update-timezone.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';
import { AUTH_STORE_NAME } from '../../lib/common/auth';

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';

import { convertTimezone } from '../../lib/common/user/user.convert';

export function AccountSettingsUpdateTimezoneContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, auth } = useSelector((state) => ({
    state: state[ACCOUNT_SETTINGS_UPDATE_TIMEZONE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    auth: state[AUTH_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetAccountSettingsUpdateTimezoneFormState());
  }, []);

  const accountSettingsUpdateTimezoneFormSendData = (values) => {
    const data = convertAccountSettingsUpdateTimezoneFormData(values);

    console.log(data, '####');

    dispatch(uploadAccountSettingsUpdateTimezoneForm(data));
  };

  const getInitialValue = () => {
    return {
      [ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME.TIMEZONE]: auth.logged
        ? convertTimezone(auth.user.timezone)
        : null,
    };
  };

  return (
    <AccountSettingsUpdateTimezoneComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={accountSettingsUpdateTimezoneFormValidation}
      onSubmitForm={accountSettingsUpdateTimezoneFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
      userTimezone={auth.logged ? auth.user.timezone : null}
    />
  );
}
