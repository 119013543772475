import React from "react";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Pagination from "@mui/material/Pagination";

import Skeleton from "@mui/material/Skeleton";

import { USER_ADMIN_LIST_DATA_NAME } from "../user-admin-list/user-admin-list.constant";

export function UserAdminListPaginationComponent(props) {
  const { onChangeFilter, data, isLoading } = props;

  const handleChangePagination = (e, value) => {
    onChangeFilter({
      [USER_ADMIN_LIST_DATA_NAME.SKIP]: data.take * value - data.take,
    });
  };

  const paginationCount = Math.ceil(data.amount / data.take);
  const paginationPage = Math.ceil(data.skip / data.take + 1);

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        {isLoading ? (
          <Skeleton variant="rectangular" width="100%" height={40} />
        ) : (
          <Pagination
            count={paginationCount}
            page={paginationPage}
            onChange={handleChangePagination}
            size="large"
            variant="outlined"
            shape="rounded"
          />
        )}
      </Paper>
    </Box>
  );
}
