import { httpRequest } from '../../main/http';
import {
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DELETE_DATA_API,
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE,
} from './purchase-admin-item-boost-account-delete-data.constant';
import { reloadPurchaseAdminItemBoostAccountData } from '../purchase-admin-item-boost-account-data/purchase-admin-item-boost-account-data.action';
import { resetPurchaseAdminItemBoostAccountUpdateDataFormState } from '../purchase-admin-item-boost-account-update-data/purchase-admin-item-boost-account-update-data.action';

export function uploadPurchaseAdminItemBoostAccountDeleteDataForm(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method:
          PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DELETE_DATA_API.DELETE_DATA.TYPE,
        url: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DELETE_DATA_API.DELETE_DATA.ENDPOINT(
          data.purchaseId,
          data.boostAccountId,
        ),
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE.FORM_SUCCESS,
      });

      await dispatch(resetPurchaseAdminItemBoostAccountUpdateDataFormState());
      await dispatch(reloadPurchaseAdminItemBoostAccountData());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseAdminItemBoostAccountDeleteDataFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE.FORM_RESET,
    });
  };
}
