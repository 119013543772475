import Grid from '@mui/material/Grid';

import { TextComponent } from '../../../lib/common/text/text.component';
import { FileListComponent } from '../../../lib/common/file-list/file-list.component';
import { POPUP_ADMIN_ITEM_DATA_DATA_NAME } from '../popup-admin-item-data.constant';

export function PopupAdminItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="POPUP.ADMIN.ITEM_DATA.ID"
        />
        <TextComponent variant="dataViewContent">
          {data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.ID]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="POPUP.ADMIN.ITEM_DATA.TEXT"
        />
        <TextComponent variant="dataViewContent">
          {data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.TEXT]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="POPUP.ADMIN.ITEM_DATA.TYPE"
        />
        <TextComponent variant="dataViewContent">
          {data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.TYPE].text}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="POPUP.ADMIN.ITEM_DATA.LANG"
        />
        <TextComponent variant="dataViewContent">
          {data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.LANG].text}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="POPUP.ADMIN.ITEM_DATA.CREATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="POPUP.ADMIN.ITEM_DATA.FILE"
        />
        {data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.FILE] ? (
          <FileListComponent
            item={data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.FILE]}
          />
        ) : (
          <TextComponent variant="dataViewContent">
            <TextComponent tid="POPUP.ADMIN.ITEM_DATA.FILE_NULL" />
          </TextComponent>
        )}
      </Grid>
    </Grid>
  );
}
