import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Grid from '@mui/material/Grid';

import { TextComponent } from '../../../lib/common/text/text.component';
import { FileListComponent } from '../../../lib/common/file-list/file-list.component';
import { GDS_ADMIN_ITEM_DATA_DATA_NAME } from '../gds-admin-item-data.constant';

export function GdsAdminItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.ID"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ID]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.ORDER"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ORDER]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.CATEGORY"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY].text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.TYPE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TYPE].text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.STATUS"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STATUS].text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.TAG"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TAG]
            .map((i) => i.text)
            .join(', ')}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.HAS_VERIFY"
        />
        <TextComponent variant="dataViewContent">
          {String(data[GDS_ADMIN_ITEM_DATA_DATA_NAME.HAS_VERIFY])}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.CREATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.UPDATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]}
        </TextComponent>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.PRICE_EN"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE_EN]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.STOCK_EN"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STOCK_EN] || (
            <TextComponent tid="GDS.ADMIN.ITEM_DATA.STOCK_EN_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.TITLE_EN"
        />
        <TextComponent sx={{ lineHeight: '1.3em' }} variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE_EN]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.DESCRIPTION_EN"
        />
        <TextComponent sx={{ lineHeight: '1.3em' }} variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_EN]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.BODY_EN"
        />
        <TextComponent sx={{ lineHeight: '1.3em' }} variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY_EN]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.URL_EN"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.URL_EN]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.FILE_LIST_EN"
        />
        <FileListComponent
          list={data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST_EN]}
        />
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.FILE_PREVIEW_EN"
        />
        <FileListComponent
          item={data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW_EN]}
        />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.PRICE_RU"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE_RU]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.STOCK_RU"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STOCK_RU] || (
            <TextComponent tid="GDS.ADMIN.ITEM_DATA.STOCK_RU_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.TITLE_RU"
        />
        <TextComponent sx={{ lineHeight: '1.3em' }} variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE_RU]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.DESCRIPTION_RU"
        />
        <TextComponent sx={{ lineHeight: '1.3em' }} variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_RU]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.BODY_RU"
        />
        <TextComponent sx={{ lineHeight: '1.3em' }} variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY_RU]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.URL_RU"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ADMIN_ITEM_DATA_DATA_NAME.URL_RU]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.FILE_LIST_RU"
        />
        <FileListComponent
          list={data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST_RU]}
        />
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.FILE_PREVIEW_RU"
        />
        <FileListComponent
          item={data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW_RU]}
        />
      </Grid>
    </Grid>
  );
}
