import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_STORE_NAME } from '../purchase-customer-item-boost-time-list/purchase-customer-item-boost-time-list.constant';

import { purchaseCustomerItemBoostTimeCreateFormValidation } from './purchase-customer-item-boost-time-create.validation';
import { PurchaseCustomerItemBoostTimeCreateComponent } from './purchase-customer-item-boost-time-create.component';
import {
  uploadPurchaseCustomerItemBoostTimeCreateForm,
  resetPurchaseCustomerItemBoostTimeCreateFormState,
} from './purchase-customer-item-boost-time-create.action';
import {
  PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_STORE_NAME,
  PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME,
} from './purchase-customer-item-boost-time-create.constant';
import { convertPurchaseCustomerItemBoostTimeCreateFormData } from './purchase-customer-item-boost-time.convert.convert';

export function PurchaseCustomerItemBoostTimeCreateContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, boostTimeStore } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    boostTimeStore: state[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseCustomerItemBoostTimeCreateFormState());
  }, []);

  const purchaseCustomerItemBoostTimeCreateFormSendData = (values) => {
    const data = convertPurchaseCustomerItemBoostTimeCreateFormData(values);
    data.purchaseId = boostTimeStore.purchaseId;
    dispatch(uploadPurchaseCustomerItemBoostTimeCreateForm(data));
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME]: null,
      [PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME]: null,
    };
  };

  return (
    <PurchaseCustomerItemBoostTimeCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(boostTimeStore.request)}
      initialValue={getInitialValue()}
      validation={purchaseCustomerItemBoostTimeCreateFormValidation}
      onSubmitForm={purchaseCustomerItemBoostTimeCreateFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
