import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { FileListComponent } from '../../../lib/common/file-list/file-list.component';

import { TextComponent } from '../../../lib/common/text/text.component';
import { FILE_ADMIN_LIST_DATA_NAME } from '../file-admin-list.constant';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { FieldCopyButtonComponent } from '../../../lib/common/field-copy-button/field-copy-button.component';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { text } from '../../../lib/common/text';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Link from '@mui/material/Link';

export function FileAdminListViewComponent(props) {
  const [copied, setCopied] = React.useState(false);

  const onCopyKey = () => {
    setCopied(true);
  };

  const handleClose = (event, reason) => {
    setCopied(false);
  };

  const { data } = props;

  if (!data.list?.length) {
    return (
      <Box sx={{ pt: 4 }}>
        <TextComponent variant="body1" tid="FILE.ADMIN.LIST.LIST_IS_EMPTY" />
      </Box>
    );
  }

  return (
    <List>
      <Snackbar
        onClose={handleClose}
        message={text('FILE.ADMIN.LIST.COPY_ALERT')}
        open={copied}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {data.list.map((item, index) => (
        <React.Fragment key={item.id}>
          {index !== 0 && (
            <ListItem sx={{ p: 0 }}>
              <Divider sx={{ width: '100%', opacity: 0.4 }} />
            </ListItem>
          )}
          <ListItem
            sx={{ px: 0 }}
            variant="list"
            secondaryAction={
              <CopyToClipboard
                text={item[FILE_ADMIN_LIST_DATA_NAME.URL]}
                onCopy={onCopyKey}
              >
                <IconButton aria-label="copy data" edge="end">
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              </CopyToClipboard>
            }
          >
            <Grid container spacing={2} sx={{ pr: 12 }}>
              {/*<Grid item>*/}
              {/*  <TextComponent variant="body1">*/}
              {/*    {item[FILE_ADMIN_LIST_DATA_NAME.ID]}*/}
              {/*  </TextComponent>*/}
              {/*</Grid>*/}
              <Grid item>
                <Link
                  variant="body1"
                  underline="always"
                  sx={{ color: '#fff' }}
                  target="_blank"
                  href={item[FILE_ADMIN_LIST_DATA_NAME.URL]}
                >
                  <TextComponent variant="body1">
                    {item[FILE_ADMIN_LIST_DATA_NAME.NAME]}
                  </TextComponent>
                </Link>
              </Grid>
            </Grid>
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
}
