import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { UserAdminListContainer } from "./user-admin-list.container";
import { UserAdminCreateInfoContainer } from "../user-admin-create-info/user-admin-create-info.container";
import { UserAdminFindItemContainer } from "../user-admin-find-item/user-admin-find-item.container";
import { UserAdminListPaginationContainer } from "../user-admin-list-pagination/user-admin-list-pagination.container";

export function UserAdminListPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <UserAdminCreateInfoContainer />
        </Grid>
        <Grid item>
          <UserAdminFindItemContainer />
        </Grid>
        <Grid item>
          <UserAdminListContainer />
        </Grid>
        <Grid item>
          <UserAdminListPaginationContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
