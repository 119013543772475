export const GDS_ITEM_ADMIN_ITEM_DATA_STORE_NAME = 'GDS_ITEM_ADMIN_ITEM_DATA';

export const GDS_ITEM_ADMIN_ITEM_DATA_API = {
  GDS_ITEM_ADMIN_ITEM_DATA: {
    ENDPOINT: (gdsId, gdsItemId) => `/gds/${gdsId}/item/${gdsItemId}/admin`,
    TYPE: 'GET',
  },
};

export const GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE = {
  REQUEST_PENDING: 'GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME = {
  ORDER: 'order',
  PRICE_RU: 'priceRu',
  STOCK_RU: 'stockRu',
  PRICE_EN: 'priceEn',
  STOCK_EN: 'stockEn',
  TITLE_EN: 'titleEn',
  TITLE_RU: 'titleRu',
  DESCRIPTION_EN: 'descriptionEn',
  DESCRIPTION_RU: 'descriptionRu',
  DATA: 'data',
  ID: 'id',
  STATUS: 'status',
  BOUGHT: 'bought',
  CREATE_DATE: 'createDate',
  UPDATE_DATE: 'updateDate',
};
