import { PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME } from './promocode-admin-item-data.constant';

import { convertPromocodeFactor } from '../../lib/common/promocode/promocode.convert';

import { convertDatetime } from '../../lib/common/convert/convert.core';

export const convertPromocodeAdminItemData = (data) => ({
  [PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.ID]:
    data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.ID],
  [PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.NAME]:
    data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.NAME],
  [PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]: convertDatetime(
    data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE],
  ),
  [PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.FACTOR]: convertPromocodeFactor(
    data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.FACTOR],
  ),
});
