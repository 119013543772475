import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { NoticeAccountListContainer } from "./notice-account-list.container";

export function NoticeAccountListPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={3} container>
        <Grid item>
          <NoticeAccountListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
