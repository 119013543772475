export const PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_STORE_NAME =
  'PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE';

export const PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_API = {
  DELETE_DATA: {
    ENDPOINT: (purchaseId, coachingReportId) =>
      `/purchase/${purchaseId}/admin/coaching/report/${coachingReportId}`,
    TYPE: 'DELETE',
  },
};

export const PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE = {
  FORM_PENDING:
    'PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS:
    'PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR:
    'PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET:
    'PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE.FORM_RESET',
};
