import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC } from "../../purchase-booster-item/purchase-booster-item.constant";
import { PURCHASE_TYPE_TYPE } from "../../../lib/common/purchase/purchase.type";

export function PurchaseBoosterListViewComponent(props) {
  const { data } = props;
  if (data.list.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="PURCHASE.BOOSTER.LIST.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (purchaseId) => (e) => {
    e.preventDefault();
    return navigate(PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }));
  };

  const getCurent = (item) => {
    switch (item.boost.type.value) {
      case "APEX_BOOST_TYPE_BADGE":
        return (
          <>
            <Grid item>
              <TextComponent
                sx={{
                  wordBreak: "break-all",
                  whiteSpace: "break-spaces",
                }}
                variant="body1"
              >
                {item.boost.data.badge.map((badge) => (
                  <p>{badge}</p>
                ))}
              </TextComponent>
            </Grid>
          </>
        );
      default:
        return (
          <>
            <TextComponent
              tid="PURCHASE.BOOSTER.LIST.BOOST"
              variant="body1"
              tvalue={{
                type: item.boost.type.text,
                current: item.boost.current.text,
                desired: item.boost.desired.text,
                actual: item.boost.actual.text,
              }}
            />
          </>
        );
    }
  };

  return (
    <List sx={{ pt: 4 }}>
      {data.list.map((item, itemIndex) => (
        <React.Fragment key={item.id}>
          <ListItem
            onClick={handleClickItem(item.id)}
            button
            variant="list"
            component="a"
            href={PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: item.id,
            })}
          >
            <Grid
              sx={{
                flexDirection: "row",
                justifyContent: "flex-start !important",
              }}
              container
            >
              <Grid item xs={13} md={1}>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextComponent
                      tid="PURCHASE.BOOSTER.LIST.ID"
                      variant="body1"
                      component="div"
                      tvalue={{ id: item.id }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={2}>
                <Grid item>
                  <TextComponent
                    tid="PURCHASE.BOOSTER.LIST.CREATE_DATE"
                    variant="body1"
                    component="div"
                    tvalue={{ date: item.createDate }}
                  />
                </Grid>
              </Grid>

              {item.type.id === PURCHASE_TYPE_TYPE.COACHING && (
                <React.Fragment>
                  <Grid item xs={12} md={5}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.BOOSTER.LIST.COACHING"
                          variant="body1"
                          tvalue={{
                            type: item.coaching.type.text,
                            sessionAmount: item.coaching.sessionAmount,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                      <Grid item>
                        {item.coaching.additionals ? (
                          <TextComponent variant="body1">
                            {item.coaching.additionals
                              .map((i) => i.text)
                              .join(", ")}
                          </TextComponent>
                        ) : (
                          <TextComponent
                            variant="body1"
                            tid="PURCHASE.BOOSTER.LIST.COACHING_ADDITIONALS_NULL"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.BOOSTER.LIST.COACHING_ACTUAL_SESSION"
                          variant="body1"
                          tvalue={{
                            actualSession: Number(item.coaching.actualSession),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              {item.type.id === PURCHASE_TYPE_TYPE.BOOST && (
                <React.Fragment>
                  <Grid item xs={12} md={2}>
                    <Grid container spacing={2}>
                      <Grid item>
                        {getCurent(item)}
                        {/* <TextComponent
                          tid="PURCHASE.BOOSTER.LIST.BOOST"
                          variant="body1"
                          tvalue={{
                            type: item.boost.type.text,
                            current: item.boost.current.text,
                            desired: item.boost.desired.text,
                            actual: item.boost.actual.text,
                          }}
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Grid container spacing={2}>
                      <Grid item>
                        {item.boost.additionals ? (
                          <TextComponent variant="body1">
                            {item.boost.additionals
                              .map((i) => i.text)
                              .join(", ")}
                          </TextComponent>
                        ) : (
                          <TextComponent
                            variant="body1"
                            tid="PURCHASE.BOOSTER.LIST.BOOST_ADDITIONALS_NULL"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.BOOSTER.LIST.ACTUAL"
                          variant="body1"
                          tvalue={{
                            actual: item.boost.actual
                              ? item.boost.actual.text
                              : "none",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.BOOSTER.LIST.NOTES"
                        variant="body1"
                        component="div"
                        tvalue={{ notes: item.notes }}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </ListItem>
          {itemIndex !== data.list.length - 1 && (
            <ListItem>
              <Divider sx={{ width: "100%", opacity: 0.35 }} />
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
