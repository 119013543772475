import { validate } from "../../main/validate";

import { PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_DATA_NAME } from "./purchase-admin-item-update-booster.constant";

import { required } from "../../main/validate/validate.service";

const config = {
  [PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_DATA_NAME.ID]: [],
};

export const purchaseAdminItemUpdateBoosterFormValidation = (values) =>
  validate(values, config);
