import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { NavigationOnlineComponent } from "./navigation-online-item.component";
import { AUTH_STORE_NAME } from "../../lib/common/auth";
import {
  NAVIGATION_FORM,
  NAVIGATION_ONLINE_BOOSTER_STORE_NAME,
} from "./navigation-online-item.constant";
import {
  getBoosterStatus,
  uploadBoosterStatusForm,
} from "./navigation-online-item.action";

export function NavigationOnlineItemContainer(props) {
  let location = useLocation();

  const [onlineStatus, setStatus] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const result = await getBoosterStatus();
      setStatus(result?.data?.online);
    }
    fetchData();
  }, []);

  const setOnlineStatus = (data) => {
    localStorage.setItem("isOnline", data);
    dispatch(uploadBoosterStatusForm(data));
  };

  const getInitialValue = () => {
    return {
      [NAVIGATION_FORM.ONLINE]: onlineStatus,
    };
  };

  const { activePath } = useSelector((state) => ({
    activePath: state[NAVIGATION_ONLINE_BOOSTER_STORE_NAME]?.activePath,
    role: state[AUTH_STORE_NAME].user?.role,
  }));

  useEffect(() => {
    const currentPath = location.pathname;
    const pathsArray = JSON.parse(localStorage.getItem("pathsArray")) || [];

    if (currentPath === activePath) {
      pathsArray.push(activePath);
    }

    localStorage.setItem("pathsArray", JSON.stringify(pathsArray));

    function isPathsArrayEmpty() {
      return pathsArray.length === 0;
    }

    const handler = () => {
      const firstIndex = pathsArray.indexOf(currentPath);

      if (firstIndex !== -1) {
        pathsArray.splice(firstIndex, 1);
      }

      localStorage.setItem("pathsArray", JSON.stringify(pathsArray));

      if (isPathsArrayEmpty()) {
        setOnlineStatus(false);
      }
    };

    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  return (
    <NavigationOnlineComponent
      setOnlineStatus={setOnlineStatus}
      getInitialValue={getInitialValue}
      {...props}
    />
  );
}
