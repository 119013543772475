export const PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_STORE_NAME =
  'PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE';

export const PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_API = {
  UPDATE_DATA: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/booster/coaching/report`,
    TYPE: 'POST',
  },
};

export const PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_ACTION_TYPE = {
  FORM_PENDING:
    'PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS:
    'PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR:
    'PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET:
    'PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_DATA_NAME = {
  INFO: 'info',
  FILE: 'fileId',
};
