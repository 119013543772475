import React from "react";
import { useNavigate } from "react-router-dom";

import { PromocodeAdminCreateInfoComponent } from "./promocode-admin-create-info.component";
import { PROMOCODE_ADMIN_CREATE_ROUTE_PATH } from "../promocode-admin-create/promocode-admin-create.constant";

export function PromocodeAdminCreateInfoContainer() {
  const navigate = useNavigate();

  const onSubmitForm = () => {
    return navigate(PROMOCODE_ADMIN_CREATE_ROUTE_PATH);
  };

  return <PromocodeAdminCreateInfoComponent onSubmitForm={onSubmitForm} />;
}
