export const PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_STORE_NAME =
  'PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA';

export const PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_API = {
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/admin/boost/account`,
    TYPE: 'GET',
  },
};

export const PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE = {
  REQUEST_PENDING:
    'PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS:
    'PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR:
    'PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME = {
  ID: 'id',
  PURCHASE_ID: 'purchaseId',
  DATA: 'data',
};
