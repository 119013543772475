import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PopupAdminItemDataContainer } from "../popup-admin-item-data/popup-admin-item-data.container";
import { PopupAdminItemUpdateDataContainer } from "../popup-admin-item-update-data/popup-admin-item-update-data.container";
import { PopupAdminItemDeleteContainer } from "../popup-admin-item-delete/popup-admin-item-delete.container";

export function PopupAdminItemPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <PopupAdminItemDataContainer />
        </Grid>
        <Grid item>
          <PopupAdminItemUpdateDataContainer />
        </Grid>
        <Grid item>
          <PopupAdminItemDeleteContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
