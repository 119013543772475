import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_STORE_NAME } from '../purchase-admin-item-boost-time-list/purchase-admin-item-boost-time-list.constant';

import { PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE_STORE_NAME } from './purchase-admin-item-boost-time-delete.constant';
import { PurchaseAdminItemBoostTimeDeleteComponent } from './purchase-admin-item-boost-time-delete.component';
import {
  uploadPurchaseAdminItemBoostTimeDeleteForm,
  resetPurchaseAdminItemBoostTimeDeleteFormState,
} from './purchase-admin-item-boost-time-delete.action';

export function PurchaseAdminItemBoostTimeDeleteContainer(props) {
  const { boostTimeId } = props;
  const dispatch = useDispatch();
  const { state, pageLoading, boostTimeStore } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    boostTimeStore: state[PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemBoostTimeDeleteFormState());
  }, []);

  const purchaseAdminItemBoostTimeDeleteFormSendData = () => {
    dispatch(
      uploadPurchaseAdminItemBoostTimeDeleteForm(
        boostTimeStore.purchaseId,
        boostTimeId,
      ),
    );
  };

  return (
    <PurchaseAdminItemBoostTimeDeleteComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isPendingThis={state.boostTimeId === boostTimeId}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(boostTimeStore.request)}
      onSubmitForm={purchaseAdminItemBoostTimeDeleteFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
