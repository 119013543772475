export const PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_STORE_NAME =
  "PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER";

export const PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: (purchaseId, userId) =>
      `/purchase/${purchaseId}/admin/booster/${userId}`,
    TYPE: "POST",
  },
  REMOVE_BOOSTER: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/admin/remove/booster`,
    TYPE: "POST",
  },
};

export const PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE = {
  FORM_PENDING: "PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_RESET",
};

export const PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_DATA_NAME = {
  ID: "id",
};
