import {
  PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-boost-account/purchase-booster-item-boost-account.constant";
import {
  PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-boost-time/purchase-booster-item-boost-time.constant";

import {
  PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-chat/purchase-booster-item-chat.constant";

export const PURCHASE_BOOSTER_ITEM_STORE_NAME = "PURCHASE_BOOSTER_ITEM";

export const PURCHASE_BOOSTER_ITEM_ROUTE_PATH = "/booster/work/:purchaseId";
export const PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC = (
  { purchaseId } = {
    purchaseId: "[purchaseId]",
  }
) => `/booster/work/${purchaseId}`;

export const PURCHASE_BOOSTER_ITEM_API = {
  PURCHASE_BOOSTER_ITEM: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/booster`,
    TYPE: "GET",
  },
};

export const PURCHASE_BOOSTER_ITEM_ACTION_TYPE = {
  REQUEST_PENDING: "PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const PURCHASE_BOOSTER_ITEM_DATA_NAME = {
  ID: "id",
  METADATA: "metadata",
  COMMENTS: "comments",
  TYPE: "type",
  CREATE_DATE: "createDate",
  STATUS: "status",
  TIMEZONE: "timezone",
  LANG: "lang",
  NOTES: "notes",

  BOOST: "boost",
  BOOST_ID: "id",
  BOOST_DESIRED: "desired",
  BOOST_ACTUAL: "actual",
  BOOST_CURRENT: "current",
  BOOST_TYPE: "type",
  BOOST_PRICE: "price",
  BOOST_ADDITIONALS: "additionals",
  BOOST_DATA: "data",

  IS_CUSTOMER: "isCustomer",
  CUSTOMER: "customer",
  CUSTOMER_ID: "id",
  CUSTOMER_TIMEZONE: "timezone",

  COACHING: "coaching",
  COACHING_ADDITIONALS: "additionals",
  COACHING_ID: "id",
  COACHING_DATA: "data",
  COACHING_TYPE: "type",
  COACHING_SESSION_AMOUNT: "sessionAmount",
  COACHING_ACTUAL_SESSION: "actualSession",
};

//НЕ ИСПОЛЬЗУЕТСЯ???
export const PURCHASE_BOOSTER_ITEM_TAB = (redirect, purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM",
    path: PURCHASE_BOOSTER_ITEM_ROUTE_PATH,
    action: () => {
      redirect(PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC, {
        params: { purchaseId },
      });
    },
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_BOOST_ACCOUNT",
    path: PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
    action: () => {
      redirect(PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC, {
        params: { purchaseId },
      });
    },
  },
  {
    id: 2,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_BOOST_TIME",
    path: PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH,
    action: () => {
      redirect(PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC, {
        params: { purchaseId },
      });
    },
  },
  {
    id: 3,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_CHAT",
    path: PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH,
    action: () => {
      redirect(PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC, {
        params: { purchaseId },
      });
    },
  },
];
