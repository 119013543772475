import { text } from '../text/text.core';

import { POPUP_TYPE_VALUE, POPUP_LANG_VALUE } from './popup.type';

export const convertPopupType = (popupTypeId) => {
  if (!popupTypeId) {
    return null;
  }

  return {
    id: popupTypeId,
    value: POPUP_TYPE_VALUE[popupTypeId],
    text: text(`POPUP.DATA.TYPE.${POPUP_TYPE_VALUE[popupTypeId]}`),
  };
};

export const convertPopupLang = (popupLangId) => {
  if (!popupLangId) {
    return null;
  }

  return {
    id: popupLangId,
    value: POPUP_LANG_VALUE[popupLangId],
    text: text(`POPUP.DATA.LANG.${POPUP_LANG_VALUE[popupLangId]}`),
  };
};
