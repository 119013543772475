import React from "react";

import Box from "@mui/material/Box";

import { TextComponent } from "../../../lib/common/text";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export const AccountTelegramConnectFormComponent = (props) => {
  const { onSubmit, pageLoading, isSuccess, isError, isPending } = props;

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <Box sx={{ py: 4 }}>
      <Grid spacing={3} container>
        <Grid item>
          <Button onClick={onSubmit} disabled={isSubmitDisabled()} fullWidth>
            <TextComponent tid="ACCOUNT.TELEGRAM.CONNECT.SUBMIT" />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
