import React from "react";

import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "../../purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_TYPE_TYPE } from "../../../lib/common/purchase/purchase.type";

export function PurchaseCustomerItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.ID"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.ID]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.CREATE_DATE"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CREATE_DATE]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.STATUS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.STATUS].text}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.CONTACTS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CONTACTS] || (
                <TextComponent tid="PURCHASE.CUSTOMER.ITEM_DATA.CONTACTS_NULL" />
              )}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_DATA.COMMENTS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COMMENTS] || (
                <TextComponent tid="PURCHASE.CUSTOMER.ITEM_DATA.COMMENTS_NULL" />
              )}
            </TextComponent>
          </Grid>

          {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.TYPE].id ===
            PURCHASE_TYPE_TYPE.BOOST && (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_DATA.IS_BOOSTER"
              />
              <TextComponent variant="dataViewContent">
                {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.IS_BOOSTER] ? (
                  <TextComponent tid="PURCHASE.CUSTOMER.ITEM_DATA.IS_BOOSTER_TRUE" />
                ) : (
                  <TextComponent tid="PURCHASE.CUSTOMER.ITEM_DATA.IS_BOOSTER_FALSE" />
                )}
              </TextComponent>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
