import React from 'react';

import { PromocodeAdminItemUpdateDataComponent } from './promocode-admin-item-update-data.component';

import { useDispatch, useSelector } from 'react-redux';

import { promocodeAdminItemUpdateDataFormValidation } from './promocode-admin-item-update-data.validation';

import { convertPromocodeAdminItemUpdateDataFormData } from './promocode-admin-item-update-data.convert';

import {
  uploadPromocodeAdminItemUpdateDataForm,
  resetPromocodeAdminItemUpdateDataFormState,
} from './promocode-admin-item-update-data.action';

import {
  PROMOCODE_ADMIN_ITEM_UPDATE_DATA_STORE_NAME,
  PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME,
} from './promocode-admin-item-update-data.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';
import {
  PROMOCODE_ADMIN_ITEM_DATA_STORE_NAME,
  PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME,
} from '../promocode-admin-item-data/promocode-admin-item-data.constant';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';

export function PromocodeAdminItemUpdateDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, promocodeItemStore } = useSelector((state) => ({
    state: state[PROMOCODE_ADMIN_ITEM_UPDATE_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    promocodeItemStore: state[PROMOCODE_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPromocodeAdminItemUpdateDataFormState());
  }, []);

  const promocodeAdminItemUpdateDataFormSendData = (values) => {
    const data = convertPromocodeAdminItemUpdateDataFormData(values);
    dispatch(
      uploadPromocodeAdminItemUpdateDataForm(
        data,
        promocodeItemStore.promocodeId,
      ),
    );
  };

  const getInitialValue = () => {
    if (isRequestSuccess(promocodeItemStore.request)) {
      const data = getRequestData(promocodeItemStore.request);
      return {
        [PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NAME]:
          data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.NAME],
        [PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FACTOR]:
          data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.FACTOR].value,
      };
    }

    return {};
  };

  return (
    <PromocodeAdminItemUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(promocodeItemStore.request)}
      initialValue={getInitialValue()}
      validation={promocodeAdminItemUpdateDataFormValidation}
      onSubmitForm={promocodeAdminItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
