export const GDS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME =
  'GDS_ADMIN_ITEM_UPDATE_DATA';

export const GDS_ADMIN_ITEM_UPDATE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (gdsId) => `/gds/${gdsId}`,
    TYPE: 'PATCH',
  },
};

export const GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING: 'GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET',
};

export const GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME = {
  ORDER: 'order',
  PRICE_RU: 'priceRu',
  STOCK_RU: 'stockRu',
  PRICE_EN: 'priceEn',
  STOCK_EN: 'stockEn',
  TITLE_EN: 'titleEn',
  TITLE_RU: 'titleRu',
  DESCRIPTION_EN: 'descriptionEn',
  DESCRIPTION_RU: 'descriptionRu',
  BODY_EN: 'bodyEn',
  BODY_RU: 'bodyRu',
  URL_RU: 'urlRu',
  URL_EN: 'urlEn',
  FILE_PREVIEW_RU: 'filePreviewImageRuId',
  FILE_PREVIEW_EN: 'filePreviewImageEnId',
  FILE_LIST_EN: 'fileImageListEnId',
  FILE_LIST_RU: 'fileImageListRuId',
  CATEGORY: 'category',
  TYPE: 'type',
  TAG: 'tag',
  STATUS: 'status',
  HAS_VERIFY: 'hasVerify',
};
