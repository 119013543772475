export const ACCOUNT_UPDATE_AUTH_ROUTE_PATH = '/account/update-auth';

export const ACCOUNT_UPDATE_AUTH_STORE_NAME = 'ACCOUNT_UPDATE_AUTH';

export const ACCOUNT_UPDATE_AUTH_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: '/user-recovery/change-password',
    TYPE: 'POST',
  },
};

export const ACCOUNT_UPDATE_AUTH_ACTION_TYPE = {
  FORM_PENDING: 'ACCOUNT_UPDATE_AUTH_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'ACCOUNT_UPDATE_AUTH_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'ACCOUNT_UPDATE_AUTH_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'ACCOUNT_UPDATE_AUTH_ACTION_TYPE.FORM_RESET',
};

export const ACCOUNT_UPDATE_AUTH_DATA_NAME = {
  PASSWORD: 'password',
  REPEAT_PASSWORD: 'repeatPassword',
  CODE: 'code',
};
