import { PURCHASE_CUSTOMER_LIST_DATA_NAME } from './purchase-customer-list.constant';

import {
  convertPurchaseType,
  convertPurchaseStatus,
} from '../../lib/common/purchase/purchase.convert';
import {
  convertBoostRank,
  convertBoostType,
} from '../../lib/common/boost/boost.convert';
import { convertPromocode } from '../../lib/common/promocode/promocode.convert';
import { PURCHASE_TYPE_TYPE } from '../../lib/common/purchase/purchase.type';
import { convertDate } from '../../lib/common/convert/convert.core';
import {
  convertPaymentSystemType,
  convertPaymentValuteType,
} from '../../lib/common/payment/payment.convert';
import { convertDatetime } from '../../lib/common/convert/convert.core';
import { PURCHASE_BOOSTER_LIST_DATA_NAME } from '../purchase-booster-list/purchase-booster-list.constant';
import {
  convertCoachingAdditionalList,
  convertCoachingType,
} from '../../lib/common/coaching/coaching.convert';

export const convertPurchaseCustomerList = (d) => ({
  [PURCHASE_CUSTOMER_LIST_DATA_NAME.LIST]: d[
    PURCHASE_CUSTOMER_LIST_DATA_NAME.LIST
  ].map((data) => {
    const purchaseType = convertPurchaseType(
      data[PURCHASE_CUSTOMER_LIST_DATA_NAME.TYPE],
    );

    const gdsItemData = data[PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_ITEM];

    const gdsItem =
      purchaseType.id === PURCHASE_TYPE_TYPE.GDS && gdsItemData
        ? {
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_ITEM_ID]:
              gdsItemData[PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_ITEM_ID],
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_ITEM_TITLE]:
              gdsItemData[PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_ITEM_TITLE],
          }
        : null;

    const gdsData = data[PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS];

    const gds =
      purchaseType.id === PURCHASE_TYPE_TYPE.GDS && gdsData
        ? {
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_ID]:
              gdsData[PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_ID],
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_TITLE]:
              gdsData[PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_TITLE],
          }
        : null;

    const boostData = data[PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST];

    const boost =
      purchaseType.id === PURCHASE_TYPE_TYPE.BOOST
        ? {
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_CURRENT]: convertBoostRank(
              boostData[PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_CURRENT],
            ),
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_DESIRED]: convertBoostRank(
              boostData[PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_DESIRED],
            ),
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_DESIRED]: convertBoostRank(
              boostData[PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_DESIRED],
            ),
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_TYPE]: convertBoostType(
              boostData[PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_TYPE],
            ),
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_DATA]:
              boostData[PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST_DATA],
          }
        : null;

    const coachingData = data[PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING];

    const coaching =
      purchaseType.id === PURCHASE_TYPE_TYPE.COACHING
        ? {
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_ADDITIONALS]:
              convertCoachingAdditionalList(
                coachingData[
                  PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_ADDITIONALS
                ],
              ),
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_ID]:
              coachingData[PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_ID],
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_DATA]:
              coachingData[PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_DATA],
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_TYPE]:
              convertCoachingType(
                coachingData[PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_TYPE],
              ),
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_SESSION_AMOUNT]:
              coachingData[
                PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_SESSION_AMOUNT
              ],
            [PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_ACTUAL_SESSION]:
              coachingData[
                PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING_ACTUAL_SESSION
              ],
          }
        : null;

    let promocode = !!data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PROMOCODE]
      ? true
      : null;

    if (promocode) {
      promocode = convertPromocode(
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PROMOCODE],
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PRICE],
      );
    }

    const paymentData = data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PAYMENT];

    const payment = paymentData
      ? {
          [PURCHASE_CUSTOMER_LIST_DATA_NAME.CONFIRM]:
            paymentData[PURCHASE_CUSTOMER_LIST_DATA_NAME.CONFIRM],
          [PURCHASE_CUSTOMER_LIST_DATA_NAME.SYSTEM]: convertPaymentSystemType(
            paymentData[PURCHASE_CUSTOMER_LIST_DATA_NAME.SYSTEM],
          ),
        }
      : null;

    return {
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.ID]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.ID],
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.PRICE]:
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PRICE],
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.VALUTE]: convertPaymentValuteType(
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.VALUTE],
      ),

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.STATUS]: convertPurchaseStatus(
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.STATUS],
      ),

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.TYPE]: purchaseType,

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.CREATE_DATE]: convertDate(
        data[PURCHASE_CUSTOMER_LIST_DATA_NAME.CREATE_DATE],
      ),

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.PAID]:
        !!data[PURCHASE_CUSTOMER_LIST_DATA_NAME.PAYMENT],

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS_ITEM]: gdsItem,
      [PURCHASE_CUSTOMER_LIST_DATA_NAME.GDS]: gds,

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.BOOST]: boost,

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.COACHING]: coaching,

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.PAYMENT]: payment,

      [PURCHASE_CUSTOMER_LIST_DATA_NAME.PROMOCODE]: promocode,
    };
  }),
});
