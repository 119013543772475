import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Alert from '@mui/material/Alert';

import { LoaderBarComponent } from '../../lib/common/loader-bar';
import { SkeletonDataViewComponent } from '../../lib/common/skeleton-data-view/skeleton-data-view.component';
import { TextComponent } from '../../lib/common/text';

import { PurchaseCustomerItemDataPaymentViewComponent } from './frame/purchase-customer-item-data-payment-view.component';

export function PurchaseCustomerItemDataPaymentComponent(props) {
  const {
    pageLoading,
    isPending,
    onSubmitForm,
    isError,
    isSuccess,
    data,
    errorMessage,
  } = props;

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          {isSuccess && (
            <Box>
              <Box sx={{ pb: 4 }}>
                <TextComponent
                  variant="title"
                  component="div"
                  tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.TITLE"
                />
              </Box>
              <Divider />
              <PurchaseCustomerItemDataPaymentViewComponent data={data} />
            </Box>
          )}
          {isPending && (
            <Box>
              <SkeletonDataViewComponent />
            </Box>
          )}
          {isError && (
            <Box>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
