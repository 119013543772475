import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

import { FileAdminListComponent } from "./file-admin-list.component";
import { getFileAdminList } from "./file-admin-list.action";
import { FILE_ADMIN_LIST_STORE_NAME } from "./file-admin-list.constant";

export function FileAdminListContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[FILE_ADMIN_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(getFileAdminList());
  }, []);

  return (
    <FileAdminListComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
