import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PurchaseAdminItemPaymentCreateComponent } from "./purchase-admin-item-payment-create.component";
import { purchaseAdminItemPaymentCreateFormValidation } from "./purchase-admin-item-payment-create.validation";
import { convertPurchaseAdminItemPaymentCreateFormData } from "./purchase-admin-item-payment-create.convert";
import {
  uploadPurchaseAdminItemPaymentCreateForm,
  resetPurchaseAdminItemPaymentCreateFormState,
} from "./purchase-admin-item-payment-create.action";
import {
  PURCHASE_ADMIN_ITEM_PAYMENT_CREATE_STORE_NAME,
  PURCHASE_ADMIN_ITEM_PAYMENT_CREATE_DATA_NAME,
} from "./purchase-admin-item-payment-create.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PurchaseAdminItemPaymentCreateContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_PAYMENT_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemPaymentCreateFormState());
  }, []);

  const purchaseAdminItemPaymentCreateFormSendData = (values) => {
    const data = convertPurchaseAdminItemPaymentCreateFormData(values);

    data.purchaseId = purchaseId;

    dispatch(uploadPurchaseAdminItemPaymentCreateForm(data));
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_ADMIN_ITEM_PAYMENT_CREATE_DATA_NAME.ID]: "",
    };
  };

  return (
    <PurchaseAdminItemPaymentCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemPaymentCreateFormValidation}
      onSubmitForm={purchaseAdminItemPaymentCreateFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
