import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  uploadPurchaseBoosterItemUpdateDataForm,
  resetPurchaseBoosterItemUpdateDataFormState,
} from "./purchase-booster-item-update-data.action";
import {
  PURCHASE_BOOSTER_ITEM_UPDATE_DATA_STORE_NAME,
  PURCHASE_BOOSTER_ITEM_UPDATE_DATA_DATA_NAME,
} from "./purchase-booster-item-update-data.constant";
import { convertPurchaseBoosterItemUpdateDataFormData } from "./purchase-booster-item-update-data.convert";
import { purchaseBoosterItemUpdateDataFormValidation } from "./purchase-booster-item-update-data.validation";
import { PurchaseBoosterItemUpdateDataComponent } from "./purchase-booster-item-update-data.component";
import { PURCHASE_BOOSTER_ITEM_STORE_NAME } from "../purchase-booster-item/purchase-booster-item.constant";

export function PurchaseBoosterItemUpdateDataContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading, purchaseState } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ITEM_UPDATE_DATA_STORE_NAME],
    purchaseState: state[PURCHASE_BOOSTER_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseBoosterItemUpdateDataFormState());
  }, []);

  const purchaseBoosterItemUpdateDataFormSendData = (values) => {
    const data = convertPurchaseBoosterItemUpdateDataFormData(values);

    data.purchaseId = purchaseId;

    dispatch(uploadPurchaseBoosterItemUpdateDataForm(data));
  };

  const getInitialValue = () => {
    if (isRequestSuccess(purchaseState.request)) {
      const data = getRequestData(purchaseState.request);
      return {
        [PURCHASE_BOOSTER_ITEM_UPDATE_DATA_DATA_NAME.NOTES]:
          data[PURCHASE_BOOSTER_ITEM_UPDATE_DATA_DATA_NAME.NOTES] || "",
      };
    }

    return {};
  };

  return (
    <PurchaseBoosterItemUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseBoosterItemUpdateDataFormValidation}
      onSubmitForm={purchaseBoosterItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
