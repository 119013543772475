import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserAdminCreateComponent } from "./user-admin-create.component";
import { userAdminCreateFormValidation } from "./user-admin-create.validation";
import { convertUserAdminCreateFormData } from "./user-admin-create.convert";
import {
  uploadUserAdminCreateForm,
  resetUserAdminCreateForm,
} from "./user-admin-create.action";
import {
  USER_ADMIN_CREATE_STORE_NAME,
  USER_ADMIN_CREATE_DATA_NAME,
} from "./user-admin-create.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function UserAdminCreateContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[USER_ADMIN_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const signupFormSendData = (values) => {
    const data = convertUserAdminCreateFormData(values);
    dispatch(uploadUserAdminCreateForm(data, navigate));
  };

  React.useEffect(() => {
    dispatch(resetUserAdminCreateForm());
  }, []);

  const getInitialValue = () => {
    return {
      [USER_ADMIN_CREATE_DATA_NAME.LOGIN]: "",
      [USER_ADMIN_CREATE_DATA_NAME.TIMEZONE]: "",
      [USER_ADMIN_CREATE_DATA_NAME.EMAIL]: "",
      [USER_ADMIN_CREATE_DATA_NAME.ROLE]: "",
      [USER_ADMIN_CREATE_DATA_NAME.BOOSTER_ROLE]: "",
    };
  };

  return (
    <UserAdminCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={userAdminCreateFormValidation}
      onSubmitForm={signupFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
