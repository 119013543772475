import { httpRequest } from '../../main/http';

import {
  PROMOCODE_ADMIN_ITEM_DATA_API,
  PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE,
  PROMOCODE_ADMIN_ITEM_DATA_STORE_NAME,
} from './promocode-admin-item-data.constant';

import { convertPromocodeAdminItemData } from './promocode-admin-item-data.convert';

export function getPromocodeAdminItemData(data) {
  return async (dispatch) => {
    dispatch({
      type: PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PROMOCODE_ADMIN_ITEM_DATA_API.PROMOCODE_ADMIN_ITEM_DATA.TYPE,
        url: PROMOCODE_ADMIN_ITEM_DATA_API.PROMOCODE_ADMIN_ITEM_DATA.ENDPOINT(
          data.promocodeId,
        ),
      });

      dispatch({
        type: PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPromocodeAdminItemData(res.data),
        promocodeId: data.promocodeId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadPromocodeAdminItemData() {
  return async (dispatch, getState) => {
    const { promocodeId } = getState()[PROMOCODE_ADMIN_ITEM_DATA_STORE_NAME];

    dispatch({
      type: PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PROMOCODE_ADMIN_ITEM_DATA_API.PROMOCODE_ADMIN_ITEM_DATA.TYPE,
        url: PROMOCODE_ADMIN_ITEM_DATA_API.PROMOCODE_ADMIN_ITEM_DATA.ENDPOINT(
          promocodeId,
        ),
      });

      dispatch({
        type: PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPromocodeAdminItemData(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
