import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PopupAdminCreateComponent } from "./popup-admin-create.component";
import { popupAdminCreateFormValidation } from "./popup-admin-create.validation";
import { convertPopupAdminCreateFormData } from "./popup-admin-create.convert";
import {
  uploadPopupAdminCreateForm,
  resetPopupAdminCreateForm,
} from "./popup-admin-create.action";
import { POPUP_ADMIN_CREATE_STORE_NAME } from "./popup-admin-create.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PopupAdminCreateContainer() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[POPUP_ADMIN_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const signupFormSendData = (values) => {
    const data = convertPopupAdminCreateFormData(values);
    dispatch(uploadPopupAdminCreateForm(data, navigate));
  };

  React.useEffect(() => {
    dispatch(resetPopupAdminCreateForm());
  }, []);

  const getInitialValue = () => {
    return {};
  };

  return (
    <PopupAdminCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={popupAdminCreateFormValidation}
      onSubmitForm={signupFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
