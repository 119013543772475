import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseBoosterItemCoachingReportListContainer } from "../purchase-booster-item-coaching-report-list/purchase-booster-item-coaching-report-list.container";
import { PurchaseBoosterItemTabContainer } from "../purchase-booster-item-tab/purchase-booster-item-tab.container";
import { PurchaseBoosterItemContainer } from "../purchase-booster-item/purchase-booster-item.container";
import { PurchaseBoosterItemCoachingReportCreateContainer } from "../purchase-booster-item-coaching-report-create/purchase-booster-item-coaching-report-create.container";

export function PurchaseBoosterItemCoachingReportPage() {
  return (
    <Container maxWidth="sm">
      <PurchaseBoosterItemContainer />
      <Grid spacing={3} container>
        <Grid item>
          <PurchaseBoosterItemTabContainer />
        </Grid>
        <Grid item>
          <PurchaseBoosterItemCoachingReportListContainer />
        </Grid>
        <Grid item>
          <PurchaseBoosterItemCoachingReportCreateContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
