import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";

import { INDEX_ADMIN_LINK_OPTION } from "./index-admin.constant";

import { TextComponent } from "../../lib/common/text/text.component";

export function IndexAdminComponent(props) {
  const navigate = useNavigate();

  const handleRedirect = (path) => () => {
    return navigate(path);
  };
  return (
    <Paper>
      <Box sx={{ p: 6 }}>
        <Box sx={{ pb: 4 }}>
          <TextComponent
            variant="title"
            component="div"
            tid="NAVIGATION.INDEX.ADMIN.TITLE"
          />
        </Box>
        <Divider />
        <Box sx={{ pt: 4 }}>
          <Grid
            container
            sx={{ flexDirection: "row", justifyContent: "flex-start" }}
            spacing={2}
          >
            {INDEX_ADMIN_LINK_OPTION.map((route) => (
              <Grid key={route.id} item xs={12} md={6}>
                <Link
                  component="button"
                  variant="body1"
                  underline="always"
                  sx={{ color: "#fff" }}
                  onClick={handleRedirect(route.path)}
                >
                  <TextComponent tid={route.tid} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
