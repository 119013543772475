export const USER_ADMIN_ITEM_DATA_STORE_NAME = "USER_ADMIN_ITEM_DATA";

export const USER_ADMIN_ITEM_DATA_API = {
  USER_ADMIN_ITEM_DATA: {
    ENDPOINT: (userId) => `/user/admin/user/${userId}`,
    TYPE: "GET",
  },
};

export const USER_ADMIN_ITEM_DATA_ACTION_TYPE = {
  REQUEST_PENDING: "USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const USER_ADMIN_ITEM_DATA_DATA_NAME = {
  LOGIN: "login",
  EMAIL: "email",
  ID: "id",
  ROLE: "role",
  TIMEZONE: "timezone",
  TELEGRAM: "telegramId",
  BOOSTER_ROLE: "boosterRole",
  BONUS: "bonus",
  ONLINE: "online",
};
