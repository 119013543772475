import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { purchaseBoosterItemCoachingReportCreateFormValidation } from './purchase-booster-item-coaching-report-create.validation';
import { PurchaseBoosterItemCoachingReportCreateComponent } from './purchase-booster-item-coaching-report-create.component';
import {
  uploadPurchaseBoosterItemCoachingReportCreateForm,
  resetPurchaseBoosterItemCoachingReportCreateFormState,
} from './purchase-booster-item-coaching-report-create.action';
import {
  PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_STORE_NAME,
  PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_DATA_NAME,
} from './purchase-booster-item-coaching-report-create.constant';
import { convertPurchaseBoosterItemCoachingReportCreateFormData } from './purchase-booster-item-coaching-report-create.convert';
import { PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_STORE_NAME } from '../purchase-booster-item-coaching-report-list/purchase-booster-item-coaching-report-list.constant';

export function PurchaseBoosterItemCoachingReportCreateContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, coachingStore } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    coachingStore: state[PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseBoosterItemCoachingReportCreateFormState());
  }, []);

  const purchaseBoosterItemCoachingReportCreateFormSendData = (
    values,
    { resetForm },
  ) => {
    const data = convertPurchaseBoosterItemCoachingReportCreateFormData(values);
    data.purchaseId = coachingStore.purchaseId;
    dispatch(
      uploadPurchaseBoosterItemCoachingReportCreateForm(data, () => {
        resetForm();
      }),
    );
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_DATA_NAME.INFO]: '',
      [PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_DATA_NAME.FILE]: [],
    };
  };

  return (
    <PurchaseBoosterItemCoachingReportCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(coachingStore.request)}
      initialValue={getInitialValue()}
      validation={purchaseBoosterItemCoachingReportCreateFormValidation}
      onSubmitForm={purchaseBoosterItemCoachingReportCreateFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
