export const PURCHASE_CUSTOMER_ITEM_STORE_NAME = "PURCHASE_CUSTOMER_ITEM";

export const PURCHASE_CUSTOMER_ITEM_ROUTE_PATH =
  "/customer/purchase/:purchaseId";

export const PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC = (
  { purchaseId } = {
    purchaseId: "[purchaseId]",
  }
) => `/customer/purchase/${purchaseId}`;

export const PURCHASE_CUSTOMER_ITEM_API = {
  PURCHASE_CUSTOMER_ITEM: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/customer`,
    TYPE: "GET",
  },
};

export const PURCHASE_CUSTOMER_ITEM_ACTION_TYPE = {
  REQUEST_PENDING: "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const PURCHASE_CUSTOMER_ITEM_DATA_NAME = {
  ID: "id",
  NOTES: "notes",
  METADATA: "metadata",
  COMMENTS: "comments",
  CONTACTS: "contacts",
  TYPE: "type",
  CREATE_DATE: "createDate",
  STATUS: "status",
  PRICE: "price",
  VALUTE: "valute",
  TIMEZONE: "timezone",
  LANG: "lang",

  PAYMENT: "payment",
  PAID: "isPaid",
  PAYMENT_ID: "id",
  CONFIRM: "confirm",
  SYSTEM: "system",

  IS_CUSTOMER: "isBooster",

  GDS_ITEM: "gdsItem",
  GDS_ITEM_ID: "id",
  GDS_ITEM_TITLE: "titleEn",
  GDS_ITEM_TITLE_EN: "titleEn",
  GDS_ITEM_TITLE_RU: "titleRu",
  GDS_ITEM_DESCRIPTION_EN: "descriptionEn",
  GDS_ITEM_DESCRIPTION_RU: "descriptionRu",
  GDS_ITEM_DATA: "data",

  GDS: "gds",
  GDS_ID: "id",
  GDS_TITLE: "titleEn",
  GDS_TITLE_EN: "titleEn",
  GDS_TITLE_RU: "titleRu",
  GDS_DESCRIPTION_EN: "descriptionEn",
  GDS_DESCRIPTION_RU: "descriptionRu",

  IS_PROMOCODE: "isPromocode",
  PROMOCODE: "promocode",
  PROMOCODE_ID: "id",
  PROMOCODE_NAME: "name",
  PROMOCODE_FACTOR: "factor",

  BOOST: "boost",
  BOOST_ID: "id",
  BOOST_DESIRED: "desired",
  BOOST_ACTUAL: "actual",
  BOOST_CURRENT: "current",
  BOOST_TYPE: "type",
  BOOST_PRICE: "price",
  BOOST_ADDITIONALS: "additionals",
  BOOST_DATA: "data",

  BOOSTER: "booster",
  IS_BOOSTER: "isBooster",
  BOOSTER_ID: "id",
  BOOSTER_LOGIN: "login",
  BOOSTER_ONLINE: "online",
  BOOSTER_TIMEZONE: "timezone",

  COACHING: "coaching",
  COACHING_ADDITIONALS: "additionals",
  COACHING_ID: "id",
  COACHING_DATA: "data",
  COACHING_TYPE: "type",
  COACHING_SESSION_AMOUNT: "sessionAmount",
  COACHING_ACTUAL_SESSION: "actualSession",
};
