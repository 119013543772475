import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AuthSignupComponent } from "./auth-signup.component";
import { authSignupFormValidation } from "./auth-signup.validation";
import { convertAuthSignupFormData } from "./auth-signup.convert";
import {
  resetAuthSignupForm,
  uploadAuthSignupForm,
} from "./auth-signup.action";
import {
  AUTH_SIGNUP_STORE_NAME,
  AUTH_SIGNUP_DATA_NAME,
} from "./auth-signup.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function AuthSignupContainer() {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { state, pageLoading } = useSelector((state) => ({
    state: state[AUTH_SIGNUP_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const signupFormSendData = (values) => {
    const data = convertAuthSignupFormData(values);
    dispatch(uploadAuthSignupForm(data, navigation));
  };

  const getInitialValue = () => {
    return {
      [AUTH_SIGNUP_DATA_NAME.LOGIN]: "",
      [AUTH_SIGNUP_DATA_NAME.PASSWORD]: "",
      [AUTH_SIGNUP_DATA_NAME.EMAIL]: "",
      [AUTH_SIGNUP_DATA_NAME.PASSWORD_REPEAT]: "",
    };
  };

  React.useEffect(() => {
    dispatch(resetAuthSignupForm());
  }, []);

  return (
    <AuthSignupComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={authSignupFormValidation}
      onSubmitForm={signupFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
