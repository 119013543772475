import { validate } from '../../main/validate';
import {
  required,
  requiredArray,
  arrayLengthMax,
} from '../../main/validate/validate.service';

import { PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME } from './purchase-customer-item-coaching-demo-create.constant';

const config = {
  [PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME.INFO]: [required],
  [PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME.FILE]: [
    requiredArray,
    arrayLengthMax(1),
  ],
};

export const purchaseCustomerItemCoachingDemoCreateFormValidation = (values) =>
  validate(values, config);
