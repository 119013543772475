import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { TextComponent } from '../../../lib/common/text';
import { FieldTextComponent } from '../../../lib/common/field-text/field-text.component';
import { FieldDatePickerComponent } from '../../../lib/common/field-date-picker/field-date-picker.component';

import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME } from '../purchase-customer-item-boost-time-create.constant';

export const PurchaseCustomerItemBoostTimeCreateFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    setFieldValue,
  } = props;
  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (JSON.stringify(touched) === '{}') {
      return true;
    }

    if (isPending) {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldDatePickerComponent
              label={
                <TextComponent tid="PURCHASE.CUSTOMER.ITEM_BOOST_TIME.CREATE.FORM.FIELD.FROM_TIME" />
              }
              name={
                PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME
              }
              // setValue={setFieldValue}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values[
                  PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME
                ]
              }
              error={isFieldError(
                PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME,
              )}
              errorText={getFieldError(
                PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME,
              )}
            />
          </Grid>
          <Grid item>
            <FieldDatePickerComponent
              label={
                <TextComponent tid="PURCHASE.CUSTOMER.ITEM_BOOST_TIME.CREATE.FORM.FIELD.TO_TIME" />
              }
              name={PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME}
              // setValue={setFieldValue}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values[
                  PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME
                ]
              }
              error={isFieldError(
                PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME,
              )}
              errorText={getFieldError(
                PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME,
              )}
            />
          </Grid>
          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="PURCHASE.CUSTOMER.ITEM_BOOST_TIME.CREATE.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
