import { POPUP_ADMIN_CREATE_DATA_NAME } from './popup-admin-create.constant';
import { convertFileFieldValue } from '../../lib/common/field-file/field-file.convert';
import { convertSelectFieldValue } from '../../lib/common/field-select/field-select.convert';

export const convertPopupAdminCreateFormData = (data) => ({
  [POPUP_ADMIN_CREATE_DATA_NAME.TEXT]: String(
    data[POPUP_ADMIN_CREATE_DATA_NAME.TEXT],
  ),
  [POPUP_ADMIN_CREATE_DATA_NAME.TYPE]: convertSelectFieldValue(
    data[POPUP_ADMIN_CREATE_DATA_NAME.TYPE],
  ),
  [POPUP_ADMIN_CREATE_DATA_NAME.LANG]: convertSelectFieldValue(
    data[POPUP_ADMIN_CREATE_DATA_NAME.LANG],
  ),
  [POPUP_ADMIN_CREATE_DATA_NAME.FILE]: convertFileFieldValue(
    data[POPUP_ADMIN_CREATE_DATA_NAME.FILE],
  )
    ? convertFileFieldValue(data[POPUP_ADMIN_CREATE_DATA_NAME.FILE])[0]
    : null,
});
