import Box from '@mui/material/Box';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ModalConfirmComponent } from '../../../lib/common/modal-confirm/modal-confirm.component';

export const PromocodeAdminItemDeleteFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    setFieldValue,
    onSubmitForm,
  } = props;

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }
  };

  return (
    <Box sx={{ pt: 4 }}>
      <Grid spacing={3} container>
        <Grid item>
          <ModalConfirmComponent
            action={onSubmitForm}
            disabled={isSubmitDisabled()}
          >
            <Button fullWidth>
              <TextComponent tid="PROMOCODE.ADMIN.DELETE.FORM.SUBMIT" />
            </Button>
          </ModalConfirmComponent>
        </Grid>
      </Grid>
    </Box>
  );
};
