export const PROMOCODE_ADMIN_CREATE_ROUTE_PATH = '/admin/promocode/create';

export const PROMOCODE_ADMIN_CREATE_STORE_NAME = 'PROMOCODE_ADMIN_CREATE';

export const PROMOCODE_ADMIN_CREATE_API = {
  PROMOCODE_ADMIN_CREATE: {
    ENDPOINT: '/promocode/',
    TYPE: 'POST',
  },
};

export const PROMOCODE_ADMIN_CREATE_ACTION_TYPE = {
  FORM_PENDING: 'PROMOCODE_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PROMOCODE_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PROMOCODE_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PROMOCODE_ADMIN_CREATE_ACTION_TYPE.FORM_RESET',
};

export const PROMOCODE_ADMIN_CREATE_DATA_NAME = {
  FACTOR: 'factor',
  NAME: 'name',
};
