export const PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_STORE_NAME =
  'PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER';

export const PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/booster/`,
    TYPE: 'POST',
  },
};

export const PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE = {
  FORM_PENDING: 'PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_DATA_NAME = {
  ID: 'id',
};
