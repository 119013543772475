import { text } from '../text/text.core';

import {
  NEWS_STATUS_VALUE,
  NEWS_CATEGORY_VALUE,
  NEWS_LANG_VALUE,
} from './news.type';

export const convertNewsStatus = (newsStatusId) => {
  if (!newsStatusId) {
    return null;
  }

  return {
    id: newsStatusId,
    value: NEWS_STATUS_VALUE[newsStatusId],
    text: text(`NEWS.DATA.STATUS.${NEWS_STATUS_VALUE[newsStatusId]}`),
  };
};

export const convertNewsCategory = (newsCategoryId) => {
  if (!newsCategoryId) {
    return null;
  }

  return {
    id: newsCategoryId,
    value: NEWS_CATEGORY_VALUE[newsCategoryId],
    text: text(`NEWS.DATA.CATEGORY.${NEWS_CATEGORY_VALUE[newsCategoryId]}`),
  };
};

export const convertNewsLang = (newsLangId) => {
  if (!newsLangId) {
    return null;
  }

  return {
    id: newsLangId,
    value: NEWS_LANG_VALUE[newsLangId],
    text: text(`NEWS.DATA.LANG.${NEWS_LANG_VALUE[newsLangId]}`),
  };
};
