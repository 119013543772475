import React from "react";
import styled from "styled-components";

import { TextComponent } from "../../../lib/common/text/text.component";

export function BoosterOnlineIndicatorElement({ purchaseData }) {
  const boosterStatus = purchaseData?.booster?.online;
  console.log("boosterStatus", boosterStatus);
  return (
    <Container>
      <Indicator active={boosterStatus} />
      <TextComponent
        variant="title"
        component="div"
        tid="PURCHASE.CHAT.BOOSTER_STATUS"
        tvalue={{
          status: boosterStatus ? "online" : "offline",
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Indicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background-color: ${({ active }) => (active ? "green" : "red")};
`;
