import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { NavigationContainer } from "../navigation/navigation.container";

import { PurchaseAdminItemContainer } from "../purchase-admin-item/purchase-admin-item.container";
import { PurchaseAdminItemBoostAccountDataContainer } from "../purchase-admin-item-boost-account-data/purchase-admin-item-boost-account-data.container";
import { PurchaseAdminItemBoostAccountUpdateDataContainer } from "../purchase-admin-item-boost-account-update-data/purchase-admin-item-boost-account-update-data.container";
import { PurchaseAdminItemBoostAccountDeleteDataContainer } from "../purchase-admin-item-boost-account-delete-data/purchase-admin-item-boost-account-delete-data.container";
import { PurchaseAdminItemTabContainer } from "../purchase-admin-item-tab/purchase-admin-item-tab.container";

export function PurchaseAdminItemBoostAccountPage() {
  return (
    <>
      <PurchaseAdminItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseAdminItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemBoostAccountDataContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemBoostAccountUpdateDataContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemBoostAccountDeleteDataContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
