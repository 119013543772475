import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PURCHASE_CUSTOMER_ITEM_STORE_NAME } from "../purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_TYPE_TYPE } from "../../lib/common/purchase/purchase.type";
import { TabContainer } from "../../lib/common/tab/tab.container";
import { TabSkeletonComponent } from "../../lib/common/tab/tab-skeleton.component";
import {
  getPurchaseCustomerItemBoost,
  getPurchaseCustomerItemTabGds,
  getPurchaseCustomerItemTabCoaching,
} from "./purchase-customer-item-tab.constant";

import {
  getRequestData,
  isRequestPending,
} from "../../main/store/store.service";

export function PurchaseCustomerItemTabContainer() {
  let { purchaseId } = useParams();
  const { state } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_ITEM_STORE_NAME],
  }));

  const stateData = getRequestData(state.request);
  const isLoading = isRequestPending(state.request);

  const PURCHASE_CUSTOMER_ITEM_TAB_BOOST =
    getPurchaseCustomerItemBoost(purchaseId);

  const PURCHASE_CUSTOMER_ITEM_TAB_GDS =
    getPurchaseCustomerItemTabGds(purchaseId);
  const PURCHASE_CUSTOMER_ITEM_TAB_COACHING =
    getPurchaseCustomerItemTabCoaching(purchaseId);

  if (isLoading) {
    return <TabSkeletonComponent />;
  }

  if (stateData?.type?.id === PURCHASE_TYPE_TYPE.BOOST) {
    return <TabContainer tab={PURCHASE_CUSTOMER_ITEM_TAB_BOOST} />;
  }

  if (stateData?.type?.id === PURCHASE_TYPE_TYPE.GDS) {
    return <TabContainer tab={PURCHASE_CUSTOMER_ITEM_TAB_GDS} />;
  }

  if (stateData?.type?.id === PURCHASE_TYPE_TYPE.COACHING) {
    return <TabContainer tab={PURCHASE_CUSTOMER_ITEM_TAB_COACHING} />;
  }

  return <TabSkeletonComponent />;
}
