import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseCustomerItemBoostAccountDataContainer } from "../purchase-customer-item-boost-account-data/purchase-customer-item-boost-account-data.container";
import { PurchaseCustomerItemBoostAccountUpdateDataContainer } from "../purchase-customer-item-boost-account-update-data/purchase-customer-item-boost-account-update-data.container";
import { PurchaseCustomerItemBoostAccountDeleteDataContainer } from "../purchase-customer-item-boost-account-delete-data/purchase-customer-item-boost-account-delete-data.container";
import { PurchaseCustomerItemContainer } from "../purchase-customer-item/purchase-customer-item.container";
import { PurchaseCustomerItemTabContainer } from "../purchase-customer-item-tab/purchase-customer-item-tab.container";

export function PurchaseCustomerItemBoostAccountPage() {
  return (
    <>
      <PurchaseCustomerItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseCustomerItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemBoostAccountDataContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemBoostAccountUpdateDataContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemBoostAccountDeleteDataContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
