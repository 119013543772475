export const POPUP_ADMIN_ITEM_DATA_STORE_NAME = 'POPUP_ADMIN_ITEM_DATA';

export const POPUP_ADMIN_ITEM_DATA_API = {
  POPUP_ADMIN_ITEM_DATA: {
    ENDPOINT: (popupId) => `/popup/${popupId}`,
    TYPE: 'GET',
  },
};

export const POPUP_ADMIN_ITEM_DATA_ACTION_TYPE = {
  REQUEST_PENDING: 'POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const POPUP_ADMIN_ITEM_DATA_DATA_NAME = {
  ID: 'id',
  TEXT: 'text',
  TYPE: 'type',
  LANG: 'lang',
  FILE: 'fileImage',
  CREATE_DATE: 'createDate',
};
