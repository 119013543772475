import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseBoosterItemDataContainer } from "../purchase-booster-item-data/purchase-booster-item-data.container";
import { PurchaseBoosterItemTabContainer } from "../purchase-booster-item-tab/purchase-booster-item-tab.container";
import { PurchaseBoosterItemContainer } from "../purchase-booster-item/purchase-booster-item.container";

export function PurchaseBoosterItemPage() {
  return (
    <Container maxWidth="sm">
      <PurchaseBoosterItemContainer />

      <Grid spacing={3} container>
        <Grid item>
          <PurchaseBoosterItemTabContainer />
        </Grid>

        <Grid item>
          <PurchaseBoosterItemDataContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
