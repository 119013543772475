import { validate } from "../../main/validate";
import { required } from "../../main/validate/validate.service";

import { PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_DATA_NAME } from "./purchase-customer-item-boost-account-update-data.constant";

const config = {};

export const purchaseCustomerItemBoostAccountUpdateDataFormValidation = (
  values
) => validate(values, config);
