export const PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_STORE_NAME =
  'PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA';

export const PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (purchaseId, coachingId) =>
      `/purchase/${purchaseId}/booster/coaching/${coachingId}`,
    TYPE: 'PATCH',
  },
};

export const PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE = {
  FORM_PENDING:
    'PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS:
    'PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR:
    'PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET:
    'PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME = {
  ACTUAL_SESSION: 'actualSession',
};
