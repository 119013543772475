import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "./purchase-customer-item.constant";

import {
  convertPurchaseType,
  convertPurchaseStatus,
  convertPurchaseLang,
} from "../../lib/common/purchase/purchase.convert";
import {
  convertBoostRank,
  convertBoostType,
  convertBoostAdditionalList,
} from "../../lib/common/boost/boost.convert";
import { convertPromocode } from "../../lib/common/promocode/promocode.convert";
import { convertTimezone } from "../../lib/common/user/user.convert";
import { PURCHASE_TYPE_TYPE } from "../../lib/common/purchase/purchase.type";
import { convertDatetimePm } from "../../lib/common/convert/convert.core";
import {
  convertPaymentSystemType,
  convertPaymentValuteType,
} from "../../lib/common/payment/payment.convert";
import { PURCHASE_BOOSTER_ITEM_DATA_NAME } from "../purchase-booster-item/purchase-booster-item.constant";
import {
  convertCoachingAdditionalList,
  convertCoachingType,
} from "../../lib/common/coaching/coaching.convert";

export const convertPurchaseCustomerItem = (data) => {
  const purchaseType = convertPurchaseType(
    data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.TYPE]
  );

  const gdsItemData = data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM];

  const gdsItem =
    purchaseType.id === PURCHASE_TYPE_TYPE.GDS && gdsItemData
      ? {
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_TITLE_EN]:
            gdsItemData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_TITLE_EN],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_TITLE_RU]:
            gdsItemData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_TITLE_RU],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_DESCRIPTION_EN]:
            gdsItemData[
              PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_DESCRIPTION_EN
            ],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_DESCRIPTION_RU]:
            gdsItemData[
              PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_DESCRIPTION_RU
            ],

          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_ID]:
            gdsItemData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_ID],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_DATA]:
            gdsItemData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM_DATA],
        }
      : null;

  const gdsData =
    purchaseType.id === PURCHASE_TYPE_TYPE.GDS
      ? data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS]
      : null;

  const gds =
    purchaseType.id === PURCHASE_TYPE_TYPE.GDS && gdsData
      ? {
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ID]:
            gdsData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ID],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_TITLE_EN]:
            gdsData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_TITLE_EN],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_TITLE_RU]:
            gdsData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_TITLE_RU],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_DESCRIPTION_EN]:
            gdsData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_DESCRIPTION_EN],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_DESCRIPTION_RU]:
            gdsData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_DESCRIPTION_RU],
        }
      : null;

  const boostData = data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST];

  const boost =
    purchaseType.id === PURCHASE_TYPE_TYPE.BOOST
      ? {
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_CURRENT]: convertBoostRank(
            boostData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_CURRENT]
          ),
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_DESIRED]: convertBoostRank(
            boostData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_DESIRED]
          ),
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_ACTUAL]: convertBoostRank(
            boostData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_ACTUAL]
          ),
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_ADDITIONALS]:
            convertBoostAdditionalList(
              boostData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_ADDITIONALS]
            ),
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_ID]:
            boostData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_ID],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_DATA]:
            boostData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_DATA],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_TYPE]: convertBoostType(
            boostData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_TYPE]
          ),
        }
      : null;

  const coachingData = data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING];

  const coaching =
    purchaseType.id === PURCHASE_TYPE_TYPE.COACHING
      ? {
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ADDITIONALS]:
            convertCoachingAdditionalList(
              coachingData[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ADDITIONALS]
            ),
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ID]:
            coachingData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ID],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_DATA]:
            coachingData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_DATA],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_TYPE]: convertCoachingType(
            coachingData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_TYPE]
          ),
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_SESSION_AMOUNT]:
            coachingData[
              PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_SESSION_AMOUNT
            ],
          [PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION]:
            coachingData[
              PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION
            ],
        }
      : null;

  const isPromocode = !!data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PROMOCODE];

  let promocode = null;

  if (isPromocode) {
    promocode = convertPromocode(
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PROMOCODE],
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PRICE]
    );
  }

  const paid = !!data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT];

  const paymentData = data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT];

  const payment = paid
    ? {
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT_ID]:
          paymentData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT_ID],
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.CONFIRM]:
          paymentData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CONFIRM],
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.SYSTEM]: convertPaymentSystemType(
          paymentData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.SYSTEM]
        ),
      }
    : null;

  const isBooster = !!data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER];
  const boosterData = data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER];

  const booster = isBooster
    ? {
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER_ID]:
          boosterData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER_ID],
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER_ONLINE]:
          boosterData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER_ONLINE],
        [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER_TIMEZONE]: convertTimezone(
          boosterData[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER_TIMEZONE]
        ),
      }
    : null;

  return {
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.ID]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.ID],
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.METADATA]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.METADATA],
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.TIMEZONE]: convertTimezone(
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.TIMEZONE]
    ),
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.LANG]: convertPurchaseLang(
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.LANG]
    ),
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.COMMENTS]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COMMENTS],
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.CONTACTS]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CONTACTS],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PRICE]:
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PRICE],

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.VALUTE]: convertPaymentValuteType(
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.VALUTE]
    ),

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.STATUS]: convertPurchaseStatus(
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.STATUS]
    ),

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.TYPE]: purchaseType,

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.CREATE_DATE]: convertDatetimePm(
      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.CREATE_DATE]
    ),

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAID]: paid,
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT]: payment,

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM]: gdsItem,
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS]: gds,
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST]: boost,

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.IS_PROMOCODE]: isPromocode,
    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.PROMOCODE]: promocode,

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.IS_BOOSTER]: isBooster,

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER]: booster,

    [PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING]: coaching,
  };
};
