import React from "react";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { SkeletonListComponent } from "../../lib/common/skeleton-list/skeleton-list.component";
import { TextComponent } from "../../lib/common/text";

import { NoticeAccountListShortViewComponent } from "./frame/notice-account-list-short-view.component";

import ListItem from "@mui/material/ListItem";

export function NoticeAccountListShortComponent(props) {
  const {
    isPending,
    isError,
    isSuccess,
    data,
    errorMessage,
    viewUpdateId,
    isViewUpdate,
    handleUpdateView,
    handleUpdateViewAll,
  } = props;

  return (
    <Box>
      <Box sx={{ px: 6, pt: 2, pr: 2 }}>
        <Header sx={{ pb: 2 }}>
          <Box>
            <TextComponent
              variant="title"
              component="div"
              tid="NOTICE.ACCOUNT.LIST_SHORT.TITLE"
            />
          </Box>
          <ListItem
            variant="list"
            button
            disabled={!data.amount}
            onClick={handleUpdateViewAll}
            sx={{ width: "120px", py: 2, px: 3 }}
          >
            <TextComponent
              variant="body1"
              tid="NOTICE.ACCOUNT.LIST_SHORT.SET_VIEW_ALL"
            />
          </ListItem>
        </Header>
        <Box sx={{ pr: 4 }}>
          <Divider />
        </Box>
      </Box>
      <Box sx={{ py: 4 }}>
        {isSuccess && (
          <NoticeAccountListShortViewComponent
            handleUpdateView={handleUpdateView}
            viewUpdateId={viewUpdateId}
            isViewUpdate={isViewUpdate}
            data={data}
          />
        )}
        {isPending && (
          <Box sx={{ px: 6 }}>
            <SkeletonListComponent />
          </Box>
        )}
        {isError && (
          <Box sx={{ px: 6 }}>
            <Alert severity="error">
              <TextComponent tid={`ERROR.${errorMessage}`} />
            </Alert>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
