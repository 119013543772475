import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { AccountTelegramConnectComponent } from "./account-telegram-connect.component";
import { convertAccountTelegramConnectFormData } from "./account-telegram-connect.convert";
import { uploadAccountTelegramConnectForm } from "./account-telegram-connect.action";
import { ACCOUNT_TELEGRAM_CONNECT_STORE_NAME } from "./account-telegram-connect.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function AccountTelegramConnectContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[ACCOUNT_TELEGRAM_CONNECT_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  let [searchParams, setSearchParams] = useSearchParams();

  const params = {};

  for (let [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  const accountTelegramConnectFormSendData = () => {
    const data = convertAccountTelegramConnectFormData(params);

    dispatch(uploadAccountTelegramConnectForm(data));
  };

  return (
    <AccountTelegramConnectComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      onSubmitForm={accountTelegramConnectFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
