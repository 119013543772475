import { POPUP_ADMIN_LIST_DATA_NAME } from './popup-admin-list.constant';

import {
  convertPopupLang,
  convertPopupType,
} from '../../lib/common/popup/popup.convert';

export const convertPopupAdminList = (d) =>
  d.list.map((data) => {
    return {
      [POPUP_ADMIN_LIST_DATA_NAME.ID]: data[POPUP_ADMIN_LIST_DATA_NAME.ID],
      [POPUP_ADMIN_LIST_DATA_NAME.TYPE]: convertPopupType(
        data[POPUP_ADMIN_LIST_DATA_NAME.TYPE],
      ),
      [POPUP_ADMIN_LIST_DATA_NAME.LANG]: convertPopupLang(
        data[POPUP_ADMIN_LIST_DATA_NAME.LANG],
      ),
    };
  });
