import { validate } from '../../main/validate';
import { required } from '../../main/validate/validate.service';
import { PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME } from './purchase-admin-item-update-boost-data.constant';

const config = {
  [PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]: [required],
};

export const purchaseAdminItemUpdateBoostDataFormValidation = (values) =>
  validate(values, config);
