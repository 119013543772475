import { validate } from '../../main/validate';

import { ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME } from './account-settings-update-password.constant';

import {
  password,
  required,
  passwordRepeat,
  fieldMatch,
} from '../../main/validate/validate.service';

const config = {
  [ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME.PASSWORD]: [required],
  [ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME.NEW_PASSWORD]: [
    required,
    password,
    fieldMatch(
      ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME.PASSWORD,
      'VALIDATION.NEW_PASSWORD_MATCH_OLD',
    ),
  ],
  [ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME.REPEAT_NEW_PASSWORD]: [
    required,
    passwordRepeat(ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME.NEW_PASSWORD),
  ],
};

export const accountSettingsUpdatePasswordFormValidation = (values) =>
  validate(values, config);
