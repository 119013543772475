import * as React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import { NoticeAccountListShortHandler } from "../notice-account-list-short/notice-account-list-short.handler";
import { NavigationOnlineItemContainer } from "../navigation-online-item/navigation-online-item.container";
import { USER_ROLE_TYPE } from "../../lib/common/user/user.type";

export function NavigationMobileAppComponent(props) {
  const navigate = useNavigate();

  const { routeList, activePath, children, noticeAmount, role } = props;

  const [value, setValue] = React.useState(activePath);

  return (
    <Box>
      <NoticeAccountListShortHandler />
      <Box
        component="main"
        sx={{
          width: "100vw",
          px: 4,
          py: 24,
          pt: 8,
          minHeight: "calc(100vh - 65px)",
        }}
      >
        {children}
      </Box>
      <Box sx={{ position: "fixed", left: 0, bottom: 0, width: "100%" }}>
        <BottomNavigation showLabels value={value}>
          {routeList.map((route, index) => {
            if (route.id === 13) {
              return (
                <BottomNavigationAction
                  value={route.path}
                  // label={<TextComponent variant="navigationItem" tid={'Notification'} />}
                  key={route.id + index}
                  onClick={() => navigate(route.route)}
                  icon={
                    noticeAmount ? (
                      <Badge
                        max={30}
                        badgeContent={noticeAmount}
                        color="primary"
                      >
                        <NotificationsIcon sx={{ fontSize: 28 }} />
                      </Badge>
                    ) : (
                      <NotificationsNoneIcon sx={{ fontSize: 28 }} />
                    )
                  }
                />
              );
            }

            return (
              <BottomNavigationAction
                value={route.path}
                key={route.id + index}
                // label={<TextComponent variant="navigationItem" tid={route.tid} />}
                onClick={() => navigate(route.route)}
                icon={route.icon}
              />
            );
          })}
          {role === USER_ROLE_TYPE.BOOSTER && (
            <AbsoluteContainer>
              <NavigationOnlineItemContainer />
            </AbsoluteContainer>
          )}
        </BottomNavigation>
      </Box>
    </Box>
  );
}

const AbsoluteContainer = styled.div`
  position: absolute;
  left: 28px;
  bottom: 80px;
`;
