import { text } from '../text/text.core';

import {
  BOOST_RANK_VALUE,
  BOOST_ADDITIONAL_VALUE,
  BOOST_TYPE_VALUE,
} from './boost.type';

export const convertBoostType = (boostTypeId) => {
  if (!boostTypeId) {
    return null;
  }

  return {
    id: boostTypeId,
    value: BOOST_TYPE_VALUE[boostTypeId],
    text: text(`BOOST.DATA.TYPE.${BOOST_TYPE_VALUE[boostTypeId]}`),
  };
};

export const convertBoostAdditional = (boostAdditionalId) => {
  if (!boostAdditionalId) {
    return null;
  }

  return {
    id: boostAdditionalId,
    value: BOOST_ADDITIONAL_VALUE[boostAdditionalId],
    text: text(
      `BOOST.DATA.ADDITIONAL.${BOOST_ADDITIONAL_VALUE[boostAdditionalId]}`,
    ),
  };
};

export const convertBoostAdditionalList = (arr) =>
  arr ? arr.map(convertBoostAdditional) : null;

export const convertBoostRank = (boostRankId) => {
  if (!boostRankId) {
    return {
      text: text(`BOOST.DATA.RANK.NULL`),
      value: null,
      id: null,
    };
  }

  return {
    id: boostRankId,
    value: boostRankId,
    text: text(`BOOST.DATA.RANK.${boostRankId}`),
  };
};
