import { PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item-boost-account/purchase-customer-item-boost-account.constant";
import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item-boost-time/purchase-customer-item-boost-time.constant";
import { PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item-chat/purchase-customer-item-chat.constant";
import { PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item-coaching-demo/purchase-customer-item-coaching-demo.constant";
import { PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item-coaching-report/purchase-customer-item-coaching-report.constant";

export const getPurchaseCustomerItemBoost = (purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM",
    path: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM_BOOST_ACCOUNT",
    path: PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 2,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM_BOOST_TIME",
    path: PURCHASE_CUSTOMER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 3,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM_CHAT",
    path: PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
];

export const getPurchaseCustomerItemTabGds = (purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM",
    path: PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
];

export const getPurchaseCustomerItemTabCoaching = (purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM",
    path: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM_COACHING_DEMO",
    path: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 2,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM_COACHING_REPORT",
    path: PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 3,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM_BOOST_TIME",
    path: PURCHASE_CUSTOMER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 4,
    tid: "NAVIGATION.TAB.PURCHASE.CUSTOMER.ITEM_CHAT",
    path: PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
];
