export const ACCOUNT_TELEGRAM_CONNECT_ROUTE_PATH = '/account/telegram/connect';

export const ACCOUNT_TELEGRAM_CONNECT_STORE_NAME = 'ACCOUNT_TELEGRAM_CONNECT';

export const ACCOUNT_TELEGRAM_CONNECT_API = {
  GET_EMAIL: {
    ENDPOINT: '/telegram/connect',
    TYPE: 'POST',
  },
};

export const ACCOUNT_TELEGRAM_CONNECT_ACTION_TYPE = {
  FORM_PENDING: 'ACCOUNT_TELEGRAM_CONNECT_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'ACCOUNT_TELEGRAM_CONNECT_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'ACCOUNT_TELEGRAM_CONNECT_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'ACCOUNT_TELEGRAM_CONNECT_ACTION_TYPE.FORM_RESET',
};

export const ACCOUNT_TELEGRAM_CONNECT_DATA_NAME = {
  TELEGRAM_DATA: 'telegramData',
};
