import { Dispatch } from "redux";
import { AuthStoreAction } from "./auth.type";

import { authDecode, setAutorization } from "../../../main/auth";
import { parseUserAuthData } from "./auth.convert";
// import { getCookie } from 'src/main/cookie';
// import { AUTH_STORAGE } from "src/main/auth/auth.constant";
// import { INDEX_ROUTE_PATH } from '../../../core/index/index.constant';
import { AUTH_ACTION_TYPE, AUTH_API } from ".";
import { httpRequest } from "../../../main/http";
import { INDEX_ROUTE_PATH } from "../../../core/index/index.constant";

export function authLogout() {
  setAutorization(null);

  const data: AuthStoreAction = {
    type: AUTH_ACTION_TYPE.SET_DATA,
    token: null,
    logged: false,
    user: null,
  };

  return (dispatch: Dispatch) => {
    dispatch(data);
  };
}

export function authSetData(token: string | null = null) {
  const user = token ? parseUserAuthData(authDecode(token)) : null;

  setAutorization(token);

  const data: AuthStoreAction = {
    type: AUTH_ACTION_TYPE.SET_DATA,
    token,
    logged: !!token,
    user,
  };

  return (dispatch: Dispatch) => dispatch(data);
}

export function authGetCookieToken(ctx: any) {
  // return getCookie(AUTH_STORAGE, ctx);
}

// export function authLogout() {
//   setAutorization(null);

//   const data: AuthStoreAction = {
//     type: AUTH_ACTION_TYPE.SET_DATA,
//     token: null,
//     logged: false,
//     user: null,
//   };

//   return (dispatch: Dispatch) => {
//     dispatch(data);
//     return redirect(INDEX_ROUTE_PATH);
//   };
// }

export function authUpdateUserData() {
  return async (dispatch: any) => {
    try {
      const res = await httpRequest({
        method: AUTH_API.UPDATE_TOKEN.TYPE,
        url: AUTH_API.UPDATE_TOKEN.ENDPOINT,
      });

      dispatch(authSetData(res.data.accessToken));
    } catch (error) {
      console.log(error);
    }
  };
}
