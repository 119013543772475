import React from 'react';

import { FileAdminCreateComponent } from './file-admin-create.component';
import { useDispatch } from 'react-redux';
import { reloadFileAdminList } from '../file-admin-list/file-admin-list.action';

export function FileAdminCreateContainer() {
  const dispatch = useDispatch();

  const onSubmitForm = () => {
    dispatch(reloadFileAdminList());

    // return redirect(PROMOCODE_ADMIN_CREATE_ROUTE_PATH);
  };

  return <FileAdminCreateComponent onSubmitForm={onSubmitForm} />;
}
