export const NEWS_ADMIN_LIST_ROUTE_PATH = '/admin/news';

export const NEWS_ADMIN_LIST_STORE_NAME = 'NEWS_ADMIN_LIST';

export const NEWS_ADMIN_LIST_API = {
  NEWS_ADMIN_LIST: {
    ENDPOINT: `/news/`,
    TYPE: 'GET',
  },
};

export const NEWS_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'NEWS_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'NEWS_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'NEWS_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR',
};

export const NEWS_ADMIN_LIST_DATA_NAME = {
  ID: 'id',
  TITLE: 'title',
  STATUS: 'status',
  CREATE_DATE: 'createDate',
  URL: 'url',
  ORDER: 'order',
  CATEGORY: 'category',

  LIST: 'list',
  SKIP: 'skip',
  TAKE: 'take',
  AMOUNT: 'amount',
  STATUS: 'status',
  CATEGORY: 'category',
  LANG: 'lang',
  SORT_ORDER: 'sortOrder',
  SORT_ID: 'sortId',
};
