import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddTaskIcon from "@mui/icons-material/AddTask";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { AlertMessageElement } from "../../lib/common/alert-message";
import { ModalConfirmComponent } from "../../lib/common/modal-confirm/modal-confirm.component";

export function PurchaseBoosterItemUpdateBoosterComponent(props) {
  const { updateBooster, isPending, isError, isSuccess, errorMessage } = props;
  return (
    <Box>
      <ModalConfirmComponent
        action={updateBooster}
        disabled={isPending || isError}
      >
        <IconButton disabled={isPending || isError}>
          <AddTaskIcon />
        </IconButton>
      </ModalConfirmComponent>

      {isError && (
        <AlertMessageElement severity="error" tid={`ERROR.${errorMessage}`} />
      )}
      {isSuccess && (
        <AlertMessageElement
          severity="success"
          tid="PURCHASE.ADMIN.UPDATE.BOOSTER.SUCCESS_MESSAGE"
        />
      )}
      {isPending && <LoaderBarComponent />}
    </Box>
  );
}
