import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseBoosterItemTabContainer } from "../purchase-booster-item-tab/purchase-booster-item-tab.container";
import { PurchaseBoosterItemUpdateDataContainer } from "../purchase-booster-item-update-data/purchase-booster-item-update-data.container";
import { PurchaseBoosterItemUpdateBoostDataContainer } from "../purchase-booster-item-update-boost-data/purchase-booster-item-update-boost-data.container";
import { PurchaseBoosterItemUpdateCoachingDataContainer } from "../purchase-booster-item-update-coaching-data/purchase-booster-item-update-coaching-data.container";

export function PurchaseBoosterItemUpdatePage() {
  return (
    <>
      {/* <PurchaseAdminItemContainer /> */}
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseBoosterItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseBoosterItemUpdateDataContainer />
          </Grid>
          <Grid item>
            <PurchaseBoosterItemUpdateBoostDataContainer />
          </Grid>
          <Grid item>
            <PurchaseBoosterItemUpdateCoachingDataContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
