import { httpRequest } from '../../main/http';

import {
  NOTICE_ADMIN_LIST_API,
  NOTICE_ADMIN_LIST_ACTION_TYPE,
  NOTICE_ADMIN_LIST_DATA_NAME,
} from './notice-admin-list.constant';

import { convertNoticeAdminList } from './notice-admin-list.convert';

export function getNoticeAdminList(data) {
  return async (dispatch) => {
    dispatch({
      type: NOTICE_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: NOTICE_ADMIN_LIST_API.NOTICE_ADMIN_LIST.TYPE,
        url: NOTICE_ADMIN_LIST_API.NOTICE_ADMIN_LIST.ENDPOINT,
        params: {
          [NOTICE_ADMIN_LIST_DATA_NAME.SKIP]:
            data[NOTICE_ADMIN_LIST_DATA_NAME.SKIP],
          [NOTICE_ADMIN_LIST_DATA_NAME.TAKE]:
            data[NOTICE_ADMIN_LIST_DATA_NAME.TAKE],
        },
      });

      dispatch({
        type: NOTICE_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertNoticeAdminList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NOTICE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
