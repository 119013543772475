import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PromocodeAdminItemDeleteComponent } from "./promocode-admin-item-delete.component";
import {
  uploadPromocodeAdminItemDeleteForm,
  resetPromocodeAdminItemDeleteFormState,
} from "./promocode-admin-item-delete.action";
import { PROMOCODE_ADMIN_ITEM_DELETE_STORE_NAME } from "./promocode-admin-item-delete.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { PROMOCODE_ADMIN_ITEM_DATA_STORE_NAME } from "../promocode-admin-item-data/promocode-admin-item-data.constant";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PromocodeAdminItemDeleteContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, pageLoading, promocodeItemStore } = useSelector((state) => ({
    state: state[PROMOCODE_ADMIN_ITEM_DELETE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    promocodeItemStore: state[PROMOCODE_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPromocodeAdminItemDeleteFormState());
  }, []);

  const promocodeAdminItemDeleteFormSendData = () => {
    dispatch(
      uploadPromocodeAdminItemDeleteForm(
        promocodeItemStore.promocodeId,
        navigate
      )
    );
  };

  return (
    <PromocodeAdminItemDeleteComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(promocodeItemStore.request)}
      onSubmitForm={promocodeAdminItemDeleteFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
