export const ACCOUNT_SETTINGS_UPDATE_PASSWORD_STORE_NAME =
  'ACCOUNT_SETTINGS_UPDATE_PASSWORD';

export const ACCOUNT_SETTINGS_UPDATE_PASSWORD_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: '/user-settings/password',
    TYPE: 'PATCH',
  },
};

export const ACCOUNT_SETTINGS_UPDATE_PASSWORD_ACTION_TYPE = {
  FORM_PENDING: 'ACCOUNT_SETTINGS_UPDATE_PASSWORD_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'ACCOUNT_SETTINGS_UPDATE_PASSWORD_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'ACCOUNT_SETTINGS_UPDATE_PASSWORD_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'ACCOUNT_SETTINGS_UPDATE_PASSWORD_ACTION_TYPE.FORM_RESET',
};

export const ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME = {
  PASSWORD: 'password',
  NEW_PASSWORD: 'newPassword',
  REPEAT_NEW_PASSWORD: 'repeatNewPassword',
};
