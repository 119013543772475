import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { GdsAdminListPaginationComponent } from "./gds-admin-list-pagination.component";
import { GDS_ADMIN_LIST_STORE_NAME } from "../gds-admin-list/gds-admin-list.constant";
import { getGdsAdminList } from "../gds-admin-list/gds-admin-list.action";
import { getRequestData } from "../../main/store/store.service";

export function GdsAdminListPaginationContainer() {
  const dispatch = useDispatch();

  const { state } = useSelector((state) => ({
    state: getRequestData(state[GDS_ADMIN_LIST_STORE_NAME].request),
  }));

  const onChangeFilter = (data) => {
    dispatch(getGdsAdminList({ ...state, ...data }));
  };

  return (
    <GdsAdminListPaginationComponent
      data={state}
      onChangeFilter={onChangeFilter}
    />
  );
}
