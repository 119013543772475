import React from 'react';

import { AccountSettingsUpdatePasswordComponent } from './account-settings-update-password.component';

import { useDispatch, useSelector } from 'react-redux';

import { accountSettingsUpdatePasswordFormValidation } from './account-settings-update-password.validation';

import { convertAccountSettingsUpdatePasswordFormData } from './account-settings-update-password.convert';

import {
  uploadAccountSettingsUpdatePasswordForm,
  resetAccountSettingsUpdatePasswordFormState,
} from './account-settings-update-password.action';

import {
  ACCOUNT_SETTINGS_UPDATE_PASSWORD_STORE_NAME,
  ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME,
} from './account-settings-update-password.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';

export function AccountSettingsUpdatePasswordContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[ACCOUNT_SETTINGS_UPDATE_PASSWORD_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(resetAccountSettingsUpdatePasswordFormState());
  }, []);

  const accountSettingsUpdatePasswordFormSendData = (values) => {
    const data = convertAccountSettingsUpdatePasswordFormData(values);
    dispatch(uploadAccountSettingsUpdatePasswordForm(data));
  };

  const getInitialValue = () => {
    return {
      [ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME.PASSWORD]: '',
      [ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME.NEW_PASSWORD]: '',
      [ACCOUNT_SETTINGS_UPDATE_PASSWORD_DATA_NAME.REPEAT_NEW_PASSWORD]: '',
    };
  };

  return (
    <AccountSettingsUpdatePasswordComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={accountSettingsUpdatePasswordFormValidation}
      onSubmitForm={accountSettingsUpdatePasswordFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
