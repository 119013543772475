import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_STORE_NAME } from '../purchase-admin-item-boost-time-list/purchase-admin-item-boost-time-list.constant';

import { purchaseAdminItemBoostTimeCreateFormValidation } from './purchase-admin-item-boost-time-create.validation';
import { PurchaseAdminItemBoostTimeCreateComponent } from './purchase-admin-item-boost-time-create.component';
import {
  uploadPurchaseAdminItemBoostTimeCreateForm,
  resetPurchaseAdminItemBoostTimeCreateFormState,
} from './purchase-admin-item-boost-time-create.action';
import {
  PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_STORE_NAME,
  PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_DATA_NAME,
} from './purchase-admin-item-boost-time-create.constant';
import { convertPurchaseAdminItemBoostTimeCreateFormData } from './purchase-admin-item-boost-time.convert.convert';

export function PurchaseAdminItemBoostTimeCreateContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, boostTimeStore } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    boostTimeStore: state[PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemBoostTimeCreateFormState());
  }, []);

  const purchaseAdminItemBoostTimeCreateFormSendData = (values) => {
    const data = convertPurchaseAdminItemBoostTimeCreateFormData(values);
    data.purchaseId = boostTimeStore.purchaseId;
    dispatch(uploadPurchaseAdminItemBoostTimeCreateForm(data));
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME]: null,
      [PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME]: null,
    };
  };

  return (
    <PurchaseAdminItemBoostTimeCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(boostTimeStore.request)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemBoostTimeCreateFormValidation}
      onSubmitForm={purchaseAdminItemBoostTimeCreateFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
