import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import { LoaderBarComponent } from '../../lib/common/loader-bar';
import { SkeletonDataViewComponent } from '../../lib/common/skeleton-data-view/skeleton-data-view.component';
import { TextComponent } from '../../lib/common/text';

import { PurchaseBoosterItemCoachingReportListViewComponent } from './frame/purchase-booster-item-coaching-report-list-view.component';

export function PurchaseBoosterItemCoachingReportListComponent(props) {
  const { isPending, isError, isSuccess, pageLoading, data, errorMessage } =
    props;
  return (
    <Box>
      <Paper sx={{ p: 6, pb: 4 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="PURCHASE.ADMIN.ITEM_COACHING_REPORT.LIST.TITLE"
            />
          </Box>
          <Divider />
          {isSuccess && (
            <PurchaseBoosterItemCoachingReportListViewComponent data={data} />
          )}
          {isPending && (
            <Box sx={{ pt: 4, pb: 2 }}>
              <SkeletonDataViewComponent />
            </Box>
          )}
          {isError && (
            <Box sx={{ pt: 4, pb: 2 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
