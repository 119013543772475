import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getPurchaseAdminItem } from "./purchase-admin-item.action";
import { PURCHASE_ADMIN_ITEM_STORE_NAME } from "./purchase-admin-item.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

export function PurchaseAdminItemContainer() {
  const dispatch = useDispatch();
  let { purchaseId } = useParams();

  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    const data = { purchaseId };

    dispatch(getPurchaseAdminItem(data));
  }, []);

  return null;
}
