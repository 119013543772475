import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseCustomerListContainer } from "./purchase-customer-list.container";

export function PurchaseCustomerListPage() {
  return (
    <Container maxWidth="md">
      <Grid container>
        <Grid item>
          <PurchaseCustomerListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
