import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseBoosterListContainer } from "./purchase-booster-list.container";

export function PurchaseBoosterListPage() {
  return (
    <Container maxWidth="md">
      <Grid container>
        <Grid item>
          <PurchaseBoosterListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
