import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { GdsAdminItemDeleteComponent } from "./gds-admin-item-delete.component";
import {
  uploadGdsAdminItemDeleteForm,
  resetGdsAdminItemDeleteFormState,
} from "./gds-admin-item-delete.action";
import { GDS_ADMIN_ITEM_DELETE_STORE_NAME } from "./gds-admin-item-delete.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { GDS_ADMIN_ITEM_DATA_STORE_NAME } from "../gds-admin-item-data/gds-admin-item-data.constant";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function GdsAdminItemDeleteContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, pageLoading, itemStore } = useSelector((state) => ({
    state: state[GDS_ADMIN_ITEM_DELETE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    itemStore: state[GDS_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetGdsAdminItemDeleteFormState());
  }, []);

  const gdsAdminItemDeleteFormSendData = () => {
    dispatch(uploadGdsAdminItemDeleteForm(itemStore.gdsId, navigate));
  };

  return (
    <GdsAdminItemDeleteComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(itemStore.request)}
      onSubmitForm={gdsAdminItemDeleteFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
