import { PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME } from './purchase-customer-item-chat-create.constant';

import { convertOneFileFieldValue } from '../../lib/common/field-file/field-file.convert';

export const convertPurchaseCustomerItemChatCreateFormData = (data) => ({
  [PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.DATA]:
    data[PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.DATA].trim(),
  [PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.FILE]: convertOneFileFieldValue(
    data[PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.FILE],
  ),
});
