export const PURCHASE_CUSTOMER_LIST_ROUTE_PATH = '/customer/purchase';

export const PURCHASE_CUSTOMER_LIST_STORE_NAME = 'PURCHASE_CUSTOMER_LIST';

export const PURCHASE_CUSTOMER_LIST_API = {
  PURCHASE_CUSTOMER_LIST: {
    ENDPOINT: `/purchase/customer`,
    TYPE: 'GET',
  },
};

export const PURCHASE_CUSTOMER_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_UPDATE_PENDING:
    'PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING',
  REQUEST_SUCCESS: 'PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_UPDATE_SUCCESS:
    'PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_ERROR: 'PURCHASE_CUSTOMER_LIST_ACTION_TYPE.REQUEST_ERROR',
};

export const PURCHASE_CUSTOMER_LIST_DATA_NAME = {
  LIST: 'list',

  ID: 'id',
  TYPE: 'type',
  VALUTE: 'valute',
  CREATE_DATE: 'createDate',
  STATUS: 'status',

  PAYMENT: 'payment',
  PAYMENT_ID: 'id',
  PAID: 'paid',
  CONFIRM: 'confirm',
  SYSTEM: 'system',

  GDS: 'gds',
  GDS_ID: 'id',
  GDS_PRICE: 'price',
  GDS_STOCK: 'stock',
  GDS_TITLE: 'titleEn',

  GDS_ITEM: 'gdsItem',
  GDS_ITEM_ID: 'id',
  GDS_ITEM_PRICE: 'price',
  GDS_ITEM_STOCK: 'stock',
  GDS_ITEM_TITLE: 'titleEn',

  PROMOCODE: 'promocode',
  PROMOCODE_ID: 'id',
  PROMOCODE_NAME: 'name',
  PROMOCODE_FACTOR: 'factor',

  BOOST: 'boost',
  BOOST_ID: 'id',
  BOOST_DESIRED: 'desired',
  BOOST_CURRENT: 'current',
  BOOST_TYPE: 'type',
  BOOST_PRICE: 'price',

  PRICE: 'price',

  COACHING: 'coaching',
  COACHING_ADDITIONALS: 'additionals',
  COACHING_ID: 'id',
  COACHING_DATA: 'data',
  COACHING_TYPE: 'type',
  COACHING_SESSION_AMOUNT: 'sessionAmount',
  COACHING_ACTUAL_SESSION: 'actualSession',
  BOOST_DATA: 'data',
};

export const PURCHASE_CUSTOMER_LIST_PAGINATION_CRON_UPDATE = 60000;
