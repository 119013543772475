import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseCustomerItemTabContainer } from "../purchase-customer-item-tab/purchase-customer-item-tab.container";
import { PurchaseCustomerItemContainer } from "../purchase-customer-item/purchase-customer-item.container";
import { PurchaseCustomerItemCoachingReportListContainer } from "../purchase-customer-item-coaching-report-list/purchase-customer-item-coaching-report-list.container";

export function PurchaseCustomerItemCoachingReportPage() {
  return (
    <Container maxWidth="sm">
      <PurchaseCustomerItemContainer />
      <Grid spacing={3} container>
        <Grid item>
          <PurchaseCustomerItemTabContainer />
        </Grid>
        <Grid item>
          <PurchaseCustomerItemCoachingReportListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
