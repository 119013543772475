export const ACCOUNT_VERIFICATION_EMAIL_ROUTE_PATH =
  '/account/verification/email';

export const ACCOUNT_VERIFICATION_EMAIL_STORE_NAME =
  'ACCOUNT_VERIFICATION_EMAIL';

export const ACCOUNT_VERIFICATION_EMAIL_API = {
  GET_CODE: {
    ENDPOINT: '/user-verification/email',
    TYPE: 'GET',
  },
  CONFIRM_EMAIL: {
    ENDPOINT: '/user-verification/email',
    TYPE: 'POST',
  },
};
//
export const ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE = {
  FORM_PENDING: 'ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.FORM_ERROR',
  REQUEST_PENDING: 'ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.REQUEST_ERROR',
  RESET: 'ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.RESET',
};

export const ACCOUNT_VERIFICATION_EMAIL_DATA_NAME = {
  CODE: 'code',
};
