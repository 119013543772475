import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { NewsAdminItemDataContainer } from "../news-admin-item-data/news-admin-item-data.container";
import { NewsAdminItemUpdateDataContainer } from "../news-admin-item-update-data/news-admin-item-update-data.container";
import { NewsAdminItemDeleteContainer } from "../news-admin-item-delete/news-admin-item-delete.container";

export function NewsAdminItemPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <NewsAdminItemDataContainer />
        </Grid>
        <Grid item>
          <NewsAdminItemUpdateDataContainer />
        </Grid>
        <Grid item>
          <NewsAdminItemDeleteContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
