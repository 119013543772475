export const PROMOCODE_ADMIN_LIST_ROUTE_PATH = '/admin/promocode';

export const PROMOCODE_ADMIN_LIST_STORE_NAME = 'PROMOCODE_ADMIN_LIST';

export const PROMOCODE_ADMIN_LIST_API = {
  PROMOCODE_ADMIN_LIST: {
    ENDPOINT: `/promocode/short`,
    TYPE: 'GET',
  },
};

export const PROMOCODE_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'PROMOCODE_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'PROMOCODE_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'PROMOCODE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR',
};

export const PROMOCODE_ADMIN_LIST_DATA_NAME = {
  ID: 'id',
  NAME: 'name',
  FACTOR: 'factor',
};
