export const PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_STORE_NAME =
  'PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA';

export const PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (purchaseId, boostId) =>
      `/purchase/${purchaseId}/booster/boost/${boostId}`,
    TYPE: 'PATCH',
  },
};

export const PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_ACTION_TYPE = {
  FORM_PENDING:
    'PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS:
    'PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME = {
  BOOST_ACTUAL: 'actual',
  BOOST_ACTUAL_STATIC_VALUE: 'staticActual',
  BOOST_ACTUAL_STATIC_IMAGE: 'staticActualImage',
};
