export const PROMOCODE_ADMIN_ITEM_UPDATE_DATA_STORE_NAME =
  'PROMOCODE_ADMIN_ITEM_UPDATE_DATA';

export const PROMOCODE_ADMIN_ITEM_UPDATE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (newsId) => `/promocode/${newsId}`,
    TYPE: 'PATCH',
  },
};

export const PROMOCODE_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING: 'PROMOCODE_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PROMOCODE_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PROMOCODE_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PROMOCODE_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET',
};

export const PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME = {
  NAME: 'name',
  FACTOR: 'factor',
};
