import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from "../../main/store/store.service";
import { PurchaseBoosterItemChatListComponent } from "./purchase-booster-item-chat-list.component";
import {
  getPurchaseBoosterItemChatList,
  reloadPurchaseBoosterItemChatList,
} from "./purchase-booster-item-chat-list.action";
import { PURCHASE_BOOSTER_ITEM_CHAT_LIST_STORE_NAME, RELOAD_PURCHASE_BOOSTER_ITEM_CHAT_LIST } from "./purchase-booster-item-chat-list.constant";

export function PurchaseBoosterItemChatListContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ITEM_CHAT_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const reloadStore = () => {
    const data = { purchaseId };

    dispatch(reloadPurchaseBoosterItemChatList(data));
  };

  React.useEffect(() => {
    const data = { purchaseId };

    dispatch(getPurchaseBoosterItemChatList(data));

    const intervalId = setInterval(reloadStore, RELOAD_PURCHASE_BOOSTER_ITEM_CHAT_LIST);

    return () => {
      clearInterval(intervalId);
    };
  }, [purchaseId]);

  return (
    <PurchaseBoosterItemChatListComponent
      isPending={isRequestPending(state.request)}
      isUpdatePending={isRequestUpdatePending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
