import {
  PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-update/purchase-admin-item-update.constant";
import {
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-boost-account/purchase-admin-item-boost-account.constant";
import {
  PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-boost-time/purchase-admin-item-boost-time.constant";
import {
  PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-chat/purchase-admin-item-chat.constant";

export const PURCHASE_ADMIN_ITEM_STORE_NAME = "PURCHASE_ADMIN_ITEM";

export const PURCHASE_ADMIN_ITEM_ROUTE_PATH = "/admin/purchase/:purchaseId";

export const PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC = (
  { purchaseId } = {
    purchaseId: "[purchaseId]",
  }
) => `/admin/purchase/${purchaseId}`;

export const PURCHASE_ADMIN_ITEM_API = {
  PURCHASE_ADMIN_ITEM: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/admin`,
    TYPE: "GET",
  },
};

export const PURCHASE_ADMIN_ITEM_ACTION_TYPE = {
  REQUEST_PENDING: "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const PURCHASE_ADMIN_ITEM_DATA_NAME = {
  ID: "id",
  NOTES: "notes",
  CONTACTS: "contacts",
  METADATA: "metadata",
  COMMENTS: "comments",
  TYPE: "type",
  CREATE_DATE: "createDate",
  ONLINE: "online",
  PRICE: "price",
  VALUTE: "valute",
  TIMEZONE: "timezone",

  PAYMENT: "payment",
  PAYMENT_ID: "id",
  PAID: "paid",
  AMOUNT: "amount",
  STRIPE_PAID: "stripePaid",
  PAYOP_PAID: "payopPaid",
  STRIPE_CHECKED: "stripeChecked",
  ELOBOSS_GG: "elobossGg",
  COINBASE_PAID: "coinbasePaid",
  LAVA_PAID: "lavaPaid",
  LAVA_CHECKED: "lavaChecked",
  PAYPALYCH_PAID: "paypalychPaid",
  PAYPALYCH_CHECKED: "paypalychChecked",
  UNITPAY_PAID: "unitpayPaid",
  CONFIRM: "confirm",
  MANUAL_PAID: "manualPaid",
  SYSTEM: "system",
  LANG: "lang",

  IS_BOOSTER: "isBooster",

  GDS_ITEM: "gdsItem",
  GDS_ITEM_ID: "id",
  GDS_ITEM_PRICE_EN: "priceEn",
  GDS_ITEM_STOCK_EN: "stockEn",
  GDS_ITEM_PRICE_RU: "priceRu",
  GDS_ITEM_STOCK_RU: "stockRu",
  GDS_ITEM_TITLE: "titleEn",

  GDS: "gds",
  GDS_ID: "id",
  GDS_PRICE_EN: "priceEn",
  GDS_STOCK_EN: "stockEn",
  GDS_PRICE_RU: "priceRu",
  GDS_STOCK_RU: "stockRu",
  GDS_TITLE: "titleEn",

  IS_PROMOCODE: "isPromocode",
  PROMOCODE: "promocode",
  PROMOCODE_ID: "id",
  PROMOCODE_NAME: "name",
  PROMOCODE_FACTOR: "factor",

  BOOST: "boost",
  BOOST_ID: "id",
  BOOST_DESIRED: "desired",
  BOOST_ACTUAL: "actual",
  BOOST_CURRENT: "current",
  BOOST_TYPE: "type",
  BOOST_PRICE: "price",
  BOOST_ADDITIONALS: "additionals",
  BOOST_DATA: "data",

  IS_CUSTOMER: "isCustomer",
  CUSTOMER: "customer",
  CUSTOMER_ID: "id",
  CUSTOMER_EMAIL: "email",
  CUSTOMER_LOGIN: "login",
  CUSTOMER_TIMEZONE: "timezone",
  CUSTOMER_CONFIRM_EMAIL: "confirmEmail",

  BOOSTER: "booster",
  BOOSTER_ID: "id",
  BOOSTER_LOGIN: "login",
  BOOSTER_TIMEZONE: "timezone",
  BOOSTER_EMAIL: "email",
  STATUS: "status",

  COACHING: "coaching",
  COACHING_ADDITIONALS: "additionals",
  COACHING_ID: "id",
  COACHING_DATA: "data",
  COACHING_TYPE: "type",
  COACHING_SESSION_AMOUNT: "sessionAmount",
  COACHING_ACTUAL_SESSION: "actualSession",
};

// НИГДЕ НЕ ИСПОЛЬЗУЕТСЯ ???
export const PURCHASE_ADMIN_ITEM_TAB = (purchaseId, redirect) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM",
    path: PURCHASE_ADMIN_ITEM_ROUTE_PATH,
    action: () => {
      redirect(PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }));
    },
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_UPDATE",
    path: PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH,
    action: () => {
      redirect(PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC({ purchaseId }));
    },
  },
  {
    id: 2,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_BOOST_ACCOUNT",
    path: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
    action: () => {
      redirect(
        PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({ purchaseId })
      );
    },
  },
  {
    id: 3,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_BOOST_TIME",
    path: PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH,
    action: () => {
      redirect(
        PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({ purchaseId })
      );
    },
  },
  {
    id: 4,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_CHAT",
    path: PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH,
    action: () => {
      redirect(PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC({ purchaseId }));
    },
  },
];
