import React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";

import { NoticeItemDefaultComponent } from "./notice-item-default.component";
import { NoticeItemButtonComponent } from "./notice-item-button.component";
import { NoticeItemAdminComponent } from "./notice-item-admin.component";

export function NoticeItemComponent({
  index,
  admin,
  onDelete,
  action,
  ...props
}) {
  const isButton = !!action;
  const handleOnDelete = onDelete
    ? () => {
        return onDelete(props.id);
      }
    : null;

  const handleAction = action
    ? (e) => {
        try {
          // action();
          onDelete(props.id);
        } catch {
          console.error(props.id, "deletion error");
        }
      }
    : null;

  return (
    <Box key={index}>
      {index !== 0 && (
        <ListItem variant="list" sx={{ py: 2 }}>
          <Divider sx={{ width: "100%", opacity: 0.35 }} />
        </ListItem>
      )}
      {admin ? (
        <NoticeItemAdminComponent onDelete={handleOnDelete} {...props} />
      ) : isButton ? (
        <NoticeItemButtonComponent
          action={handleAction}
          onDelete={handleOnDelete}
          {...props}
        />
      ) : (
        <NoticeItemDefaultComponent onDelete={handleOnDelete} {...props} />
      )}
    </Box>
  );
}
