export const ACCOUNT_SETTINGS_UPDATE_EMAIL_STORE_NAME =
  'ACCOUNT_SETTINGS_UPDATE_EMAIL';

export const ACCOUNT_SETTINGS_UPDATE_EMAIL_API = {
  CHANGE_EMAIL: {
    ENDPOINT: '/user-settings/email',
    TYPE: 'PATCH',
  },
};

export const ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE = {
  FORM_PENDING: 'ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE.FORM_RESET',
};

export const ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME = {
  PASSWORD: 'password',
  EMAIL: 'oldEmail',
  NEW_EMAIL: 'email',
};
