import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import useMediaQuery from "@mui/material/useMediaQuery";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "../../purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_TYPE_TYPE } from "../../../lib/common/purchase/purchase.type";

import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

import { getBoostRankImageUrl } from "../../../lib/common/boost/boost.core";
import { PURCHASE_BOOSTER_ITEM_DATA_NAME } from "../../purchase-booster-item/purchase-booster-item.constant";

export function PurchaseCustomerItemDataCoachingViewComponent(props) {
  const { data } = props;

  const matches = useMediaQuery("(max-width:1000px)");

  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_COACHING.TYPE"
            />
            <TextComponent
              tid="PURCHASE.CUSTOMER.ITEM_COACHING.TYPE_DATA"
              tvalue={{
                type: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING].type.text,
              }}
              variant="dataViewContent"
            />
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_COACHING.ADDITIONALS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ADDITIONALS
              ] ? (
                data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                  PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ADDITIONALS
                ]
                  .map((item) => item.text)
                  .join(", ")
              ) : (
                <TextComponent tid="PURCHASE.CUSTOMER.ITEM_COACHING.ADDITIONALS_NULL" />
              )}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_COACHING.COACHING_DATA"
            />

            {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING].data ? (
              <Grid>
                {Object.entries(
                  data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING].data
                ).map((item) => (
                  <Grid item>
                    <TextComponent
                      variant="dataViewContent"
                      tid={`PURCHASE.CUSTOMER.ITEM_COACHING.COACHING_DATA_DATA.${item[0].toUpperCase()}`}
                      tvalue={{ value: item[1] }}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.CUSTOMER.ITEM_COACHING.COACHING_DATA_NULL"
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      {matches ? (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_AMOUNT"
              />
              <TextComponent
                tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_AMOUNT_VALUE"
                tvalue={{
                  value:
                    data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING]
                      .sessionAmount,
                }}
                variant="dataViewContent"
              />
            </Grid>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_COACHING.ACTUAL_SESSION"
              />
              <TextComponent
                tid="PURCHASE.CUSTOMER.ITEM_COACHING.ACTUAL_SESSION_VALUE"
                tvalue={{
                  value:
                    data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING]
                      .actualSession,
                }}
                variant="dataViewContent"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS"
              />
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION
              ] === 0 && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `12% 1fr 16%`,
                  }}
                  container
                  spacing={3}
                >
                  <Box
                    sx={{ borderBottom: "2px solid rgba(91, 37, 236, 0.4)" }}
                  >
                    <TextComponent
                      gutterBottom
                      variant="dataViewContent"
                      tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS_START"
                    />
                  </Box>

                  <Box
                    sx={{ borderBottom: "2px solid rgba(91, 37, 236, 0.4)" }}
                  />
                  <Box
                    sx={{
                      textAlign: "right",
                      borderBottom: "2px solid rgba(91, 37, 236, 0.4)",
                    }}
                  >
                    <TextComponent
                      gutterBottom
                      variant="dataViewContent"
                      tvalue={{
                        value:
                          data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                            PURCHASE_CUSTOMER_ITEM_DATA_NAME
                              .COACHING_SESSION_AMOUNT
                          ],
                      }}
                      tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS_END"
                    />
                  </Box>
                </Box>
              )}

              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION
              ] === 1 &&
                data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                  PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION
                ] <
                  data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                    PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_SESSION_AMOUNT
                  ] && (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: `12% 1fr 16%`,
                    }}
                    container
                    spacing={3}
                  >
                    <Box
                      sx={{ borderBottom: "2px solid rgba(91, 37, 236, 1)" }}
                    >
                      <TextComponent
                        gutterBottom
                        variant="dataViewContent"
                        tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS_START"
                      />
                    </Box>
                    <Box
                      sx={{ borderBottom: "2px solid rgba(91, 37, 236, 0.4)" }}
                    />
                    <Box
                      sx={{
                        textAlign: "right",
                        borderBottom: "2px solid rgba(91, 37, 236, 0.4)",
                      }}
                    >
                      <TextComponent
                        gutterBottom
                        variant="dataViewContent"
                        tvalue={{
                          value:
                            data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                              PURCHASE_CUSTOMER_ITEM_DATA_NAME
                                .COACHING_SESSION_AMOUNT
                            ],
                        }}
                        tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS_END"
                      />
                    </Box>
                  </Box>
                )}

              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION
              ] ===
                data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                  PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_SESSION_AMOUNT
                ] && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `12% 1fr 16%`,
                  }}
                  container
                  spacing={3}
                >
                  <Box sx={{ borderBottom: "2px solid rgba(91, 37, 236, 1)" }}>
                    <TextComponent
                      gutterBottom
                      variant="dataViewContent"
                      tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS_START"
                    />
                  </Box>
                  <Box
                    sx={{ borderBottom: "2px solid rgba(91, 37, 236, 1)" }}
                  />
                  <Box
                    sx={{
                      textAlign: "right",
                      borderBottom: "2px solid rgba(91, 37, 236, 1)",
                    }}
                  >
                    <TextComponent
                      gutterBottom
                      variant="dataViewContent"
                      tvalue={{
                        value:
                          data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                            PURCHASE_CUSTOMER_ITEM_DATA_NAME
                              .COACHING_SESSION_AMOUNT
                          ],
                      }}
                      tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS_END"
                    />
                  </Box>
                </Box>
              )}

              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION
              ] > 1 &&
                data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                  PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION
                ] <
                  data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                    PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING_SESSION_AMOUNT
                  ] && (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: `12% ${
                        (100 /
                          (data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                            PURCHASE_CUSTOMER_ITEM_DATA_NAME
                              .COACHING_SESSION_AMOUNT
                          ] -
                            1)) *
                        (data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                          PURCHASE_CUSTOMER_ITEM_DATA_NAME
                            .COACHING_ACTUAL_SESSION
                        ] -
                          1)
                      }% 1fr 16%`,
                    }}
                    container
                    spacing={3}
                  >
                    <Box
                      sx={{ borderBottom: "2px solid rgba(91, 37, 236, 1)" }}
                    >
                      <TextComponent
                        gutterBottom
                        variant="dataViewContent"
                        tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS_START"
                      />
                    </Box>
                    <Box
                      sx={{
                        textAlign: "right",
                        borderBottom: "2px solid rgba(91, 37, 236, 1)",
                      }}
                    >
                      <TextComponent
                        gutterBottom
                        variant="dataViewContent"
                        tvalue={{
                          value:
                            data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                              PURCHASE_CUSTOMER_ITEM_DATA_NAME
                                .COACHING_ACTUAL_SESSION
                            ],
                        }}
                        tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS_ACTUAL"
                      />
                    </Box>
                    <Box
                      sx={{ borderBottom: "2px solid rgba(91, 37, 236, 0.4)" }}
                    />
                    <Box
                      sx={{
                        textAlign: "right",
                        borderBottom: "2px solid rgba(91, 37, 236, 0.4)",
                      }}
                    >
                      <TextComponent
                        gutterBottom
                        variant="dataViewContent"
                        tvalue={{
                          value:
                            data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.COACHING][
                              PURCHASE_CUSTOMER_ITEM_DATA_NAME
                                .COACHING_SESSION_AMOUNT
                            ],
                        }}
                        tid="PURCHASE.CUSTOMER.ITEM_COACHING.SESSION_STATUS_END"
                      />
                    </Box>
                  </Box>
                )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
