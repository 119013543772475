import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AccountUpdateAuthComponent } from "./account-update-auth.component";
import { accountUpdateAuthFormValidation } from "./account-update-auth.validation";
import { convertAccountUpdateAuthFormData } from "./account-update-auth.convert";
import {
  resetAccountUpdateAuthForm,
  uploadAccountUpdateAuthForm,
} from "./account-update-auth.action";
import {
  ACCOUNT_UPDATE_AUTH_STORE_NAME,
  ACCOUNT_UPDATE_AUTH_DATA_NAME,
} from "./account-update-auth.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function AccountUpdateAuthContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[ACCOUNT_UPDATE_AUTH_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const accountUpdateAuthFormSendData = (values) => {
    const data = convertAccountUpdateAuthFormData(values);
    dispatch(uploadAccountUpdateAuthForm(data));
  };

  const getInitialValue = () => {
    return {
      [ACCOUNT_UPDATE_AUTH_DATA_NAME.CODE]: "",
      [ACCOUNT_UPDATE_AUTH_DATA_NAME.PASSWORD]: "",
      [ACCOUNT_UPDATE_AUTH_DATA_NAME.REPEAT_PASSWORD]: "",
    };
  };

  React.useEffect(() => {
    dispatch(resetAccountUpdateAuthForm());
  }, []);

  return (
    <AccountUpdateAuthComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={accountUpdateAuthFormValidation}
      onSubmitForm={accountUpdateAuthFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
