import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { THEME_CONFIG } from "./lib/theme";
import { Theme } from "./main/theme";
import { initStore } from "./main/store/store.core";
import { router } from "./main/router";
import { getLocalData } from "./main/store/store.service";
import { AUTH_STORAGE } from "./main/auth/auth.constant";
import { authSetData } from "./lib/common/auth";
import "./css/main.css";

const App = () => {
  const store = initStore();
  const theme = Theme(THEME_CONFIG);

  const token = getLocalData(AUTH_STORAGE);
  store.dispatch(authSetData(token));

  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </ReduxProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
