import { httpRequest } from '../../main/http';

import {
  GDS_ITEM_ADMIN_ITEM_DATA_API,
  GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE,
  GDS_ITEM_ADMIN_ITEM_DATA_STORE_NAME,
} from './gds-item-admin-item-data.constant';

import { convertGdsItemAdminItemData } from './gds-item-admin-item-data.convert';

export function getGdsItemAdminItemData(data) {
  return async (dispatch) => {
    dispatch({
      type: GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: GDS_ITEM_ADMIN_ITEM_DATA_API.GDS_ITEM_ADMIN_ITEM_DATA.TYPE,
        url: GDS_ITEM_ADMIN_ITEM_DATA_API.GDS_ITEM_ADMIN_ITEM_DATA.ENDPOINT(
          data.gdsId,
          data.gdsItemId,
        ),
      });

      dispatch({
        type: GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertGdsItemAdminItemData(res.data),
        gdsId: data.gdsId,
        gdsItemId: data.gdsItemId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadGdsItemAdminItemData() {
  return async (dispatch, getState) => {
    const { gdsId, gdsItemId } =
      getState()[GDS_ITEM_ADMIN_ITEM_DATA_STORE_NAME];

    dispatch({
      type: GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: GDS_ITEM_ADMIN_ITEM_DATA_API.GDS_ITEM_ADMIN_ITEM_DATA.TYPE,
        url: GDS_ITEM_ADMIN_ITEM_DATA_API.GDS_ITEM_ADMIN_ITEM_DATA.ENDPOINT(
          gdsId,
          gdsItemId,
        ),
      });

      dispatch({
        type: GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertGdsItemAdminItemData(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ITEM_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
