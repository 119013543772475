import { TextComponent } from "../../../lib/common/text";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import { AUTH_SIGNUP_ROUTE_PATH } from "../../auth-signup/auth-signup.constant";
import { AUTH_LOGIN_ROUTE_PATH } from "../../auth-login/auth-login.constant";

export const AccountRecoveryFooterComponent = (props) => {
  const redirect = useNavigate();

  return (
    <Box>
      <Box>
        <Box>
          <TextComponent
            variant="body1"
            component="span"
            tid="ACCOUNT.RECOVERY.FOOTER.SIGNUP_LINK_TEXT"
          />{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              redirect(AUTH_SIGNUP_ROUTE_PATH);
            }}
          >
            <TextComponent tid="ACCOUNT.RECOVERY.FOOTER.SIGNUP_LINK" />
          </Link>
        </Box>
        <Box>
          <TextComponent
            variant="body1"
            component="span"
            tid="ACCOUNT.RECOVERY.FOOTER.LOGIN_LINK_TEXT"
          />{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              redirect(AUTH_LOGIN_ROUTE_PATH);
            }}
          >
            <TextComponent tid="ACCOUNT.RECOVERY.FOOTER.LOGIN_LINK" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
