import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { TextComponent } from "../../../lib/common/text/text.component";
import { POPUP_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../popup-admin-item/popup-admin-item.constant";
import { POPUP_ADMIN_LIST_DATA_NAME } from "../popup-admin-list.constant";

export function PopupAdminListViewComponent(props) {
  const { data } = props;

  if (data.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent variant="body1" tid="POPUP.ADMIN.LIST.LIST_IS_EMPTY" />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (popupId) => () =>
    navigate(POPUP_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ popupId }));

  return (
    <List sx={{ pt: 4 }}>
      {data.map((item) => (
        <ListItem
          onClick={handleClickItem(item[POPUP_ADMIN_LIST_DATA_NAME.ID])}
          button
          variant="list"
        >
          <ListItemText
            primary={
              <TextComponent gutterBottom variant="body1">
                <TextComponent
                  tid="POPUP.ADMIN.LIST.ID"
                  tvalue={{ id: item[POPUP_ADMIN_LIST_DATA_NAME.ID] }}
                />
              </TextComponent>
            }
            secondary={
              <TextComponent
                tid="POPUP.ADMIN.LIST.DATA"
                tvalue={{
                  type: item[POPUP_ADMIN_LIST_DATA_NAME.TYPE].text,
                  lang: item[POPUP_ADMIN_LIST_DATA_NAME.LANG].text,
                }}
                variant="body1"
              />
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
