import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GdsItemAdminListComponent } from "./gds-item-admin-list.component";
import { getGdsItemAdminList } from "./gds-item-admin-list.action";
import { GDS_ITEM_ADMIN_LIST_STORE_NAME } from "./gds-item-admin-list.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function GdsItemAdminListContainer() {
  let { gdsId } = useParams();

  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[GDS_ITEM_ADMIN_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    const data = { gdsId };

    dispatch(getGdsItemAdminList(data));
  }, []);

  return (
    <GdsItemAdminListComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      gdsId={gdsId}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
