export const GDS_ITEM_ADMIN_ITEM_DELETE_STORE_NAME =
  'GDS_ITEM_ADMIN_ITEM_DELETE';

export const GDS_ITEM_ADMIN_ITEM_DELETE_API = {
  DELETE_DATA: {
    ENDPOINT: (gdsId, gdsItemId) => `/gds/${gdsId}/item/${gdsItemId}`,
    TYPE: 'DELETE',
  },
};

export const GDS_ITEM_ADMIN_ITEM_DELETE_ACTION_TYPE = {
  FORM_PENDING: 'GDS_ITEM_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'GDS_ITEM_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'GDS_ITEM_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'GDS_ITEM_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_RESET',
};
