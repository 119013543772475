import { validate } from '../../main/validate';
import {
  required,
  numberPositiveMin,
  numberPositiveMax,
} from '../../main/validate/validate.service';
import { PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_DATA_NAME } from './purchase-admin-item-update-coaching-data.constant';

const config = {
  [PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION]: [
    required,
    numberPositiveMin(0),
    numberPositiveMax(50),
  ],
};

export const purchaseAdminItemUpdateCoachingDataFormValidation = (values) =>
  validate(values, config);
