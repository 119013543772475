import { httpRequest } from "../../main/http";

import {
  PURCHASE_BOOSTER_ORDER_LIST_API,
  PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE,
  PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME,
  PURCHASE_BOOSTER_ORDER_LIST_STORE_NAME,
} from "./purchase-booster-order-list.constant";

import { convertPurchaseBoosterOrderList } from "./purchase-booster-order-list.convert";
import {
  getRequestData,
  isRequestPending,
  isRequestUpdatePending,
} from "../../main/store/store.service";

export function getPurchaseBoosterOrderList(data) {
  return async (dispatch, getState) => {
    const stateData = getRequestData(
      getState()[PURCHASE_BOOSTER_ORDER_LIST_STORE_NAME].request
    );

    const params = data
      ? {
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.STATUS]:
            data[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.STATUS],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.PAID]:
            data[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.PAID],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.SKIP]:
            data[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.SKIP],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.TYPE]:
            data[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.TYPE],
        }
      : {
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.STATUS]:
            stateData[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.STATUS],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.PAID]:
            stateData[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.PAID],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.SKIP]:
            stateData[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.SKIP],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.TYPE]:
            stateData[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.TYPE],
        };

    dispatch({
      type: PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method:
          PURCHASE_BOOSTER_ORDER_LIST_API.PURCHASE_BOOSTER_ORDER_LIST.TYPE,
        url: PURCHASE_BOOSTER_ORDER_LIST_API.PURCHASE_BOOSTER_ORDER_LIST
          .ENDPOINT,
        params,
      });

      dispatch({
        type: PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseBoosterOrderList(res.data),
      });
    } catch (error) {
      console.log(error);
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function updatePurchaseBoosterOrderList(data) {
  return async (dispatch, getStateF) => {
    const getState = getStateF();

    const stateIsPending = isRequestPending(
      getState[PURCHASE_BOOSTER_ORDER_LIST_STORE_NAME].request
    );

    const stateIsUpdatePending = isRequestUpdatePending(
      getState[PURCHASE_BOOSTER_ORDER_LIST_STORE_NAME].request
    );

    if (stateIsPending || stateIsUpdatePending) return null;

    const stateData = getRequestData(
      getState[PURCHASE_BOOSTER_ORDER_LIST_STORE_NAME].request
    );

    const params = data
      ? {
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.STATUS]:
            data[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.STATUS],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.PAID]:
            data[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.PAID],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.SKIP]:
            data[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.SKIP],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.TYPE]:
            data[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.TYPE],
        }
      : {
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.STATUS]:
            stateData[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.STATUS],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.PAID]:
            stateData[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.PAID],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.SKIP]:
            stateData[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.SKIP],
          [PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.TYPE]:
            stateData[PURCHASE_BOOSTER_ORDER_LIST_DATA_NAME.TYPE],
        };

    dispatch({
      type: PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method:
          PURCHASE_BOOSTER_ORDER_LIST_API.PURCHASE_BOOSTER_ORDER_LIST.TYPE,
        url: PURCHASE_BOOSTER_ORDER_LIST_API.PURCHASE_BOOSTER_ORDER_LIST
          .ENDPOINT,
        params,
      });

      dispatch({
        type: PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseBoosterOrderList(res?.data),
      });
    } catch (error) {
      console.log(error);
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_ORDER_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
