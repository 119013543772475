import React from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { TextComponent } from '../../lib/common/text';
import Grid from '@mui/material/Grid';

import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';

import {
  NEWS_CATEGORY_OPTION,
  NEWS_LANG_OPTION,
  NEWS_STATUS_OPTION,
  NEWS_SORT_OPTION,
  NEWS_SORT_TYPE,
} from '../../lib/common/news/news.type';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

import { FieldSelectComponent } from '../../lib/common/field-select/field-select.component';

import { NEWS_ADMIN_LIST_DATA_NAME } from '../news-admin-list/news-admin-list.constant';

export function NewsAdminListPaginationComponent(props) {
  const { onChangeFilter, data } = props;

  const [openFilter, setOpenFilter] = React.useState();

  const handleOpenFilter = () => {
    if (openFilter === true) {
      onChangeFilter({
        [NEWS_ADMIN_LIST_DATA_NAME.SORT_ID]: false,
        [NEWS_ADMIN_LIST_DATA_NAME.SORT_ORDER]: false,
        [NEWS_ADMIN_LIST_DATA_NAME.CATEGORY]: false,
        [NEWS_ADMIN_LIST_DATA_NAME.LANG]: null,
        [NEWS_ADMIN_LIST_DATA_NAME.STATUS]: null,
      });
    }

    setOpenFilter(!openFilter);
  };

  const handleChangeFilterSelect = (key) => (e) => {
    onChangeFilter({ [key]: e.target.value.id });
  };

  const handleChangeSortType = (e) => {
    const v = e.target.value.value;

    if (v === NEWS_SORT_TYPE.ID) {
      onChangeFilter({
        [NEWS_ADMIN_LIST_DATA_NAME.SORT_ID]: true,
        [NEWS_ADMIN_LIST_DATA_NAME.SORT_ORDER]: false,
      });
    }

    if (v === NEWS_SORT_TYPE.ORDER) {
      onChangeFilter({
        [NEWS_ADMIN_LIST_DATA_NAME.SORT_ID]: false,
        [NEWS_ADMIN_LIST_DATA_NAME.SORT_ORDER]: true,
      });
    }
  };

  const handleChangePagination = (e, value) => {
    onChangeFilter({
      [NEWS_ADMIN_LIST_DATA_NAME.SKIP]: data.take * value - data.take,
    });
  };

  const paginationCount = Math.ceil(data.amount / data.take);
  const paginationPage = Math.ceil(data.skip / data.take + 1);

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Grid container spacing={4}>
          <Grid item>
            <Paper>
              <FormGroup>
                <FormControlLabel
                  sx={{ py: 1, px: 2, m: 0 }}
                  control={<Switch />}
                  onChange={handleOpenFilter}
                  label={<TextComponent tid="NEWS.ADMIN.PAGINATION.FILTER" />}
                />
              </FormGroup>
            </Paper>
          </Grid>
          {openFilter && (
            <React.Fragment>
              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeFilterSelect(
                    NEWS_ADMIN_LIST_DATA_NAME.LANG,
                  )}
                  option={NEWS_LANG_OPTION}
                  label={<TextComponent tid="NEWS.ADMIN.PAGINATION.LANG" />}
                />
              </Grid>
              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeFilterSelect(
                    NEWS_ADMIN_LIST_DATA_NAME.CATEGORY,
                  )}
                  option={NEWS_CATEGORY_OPTION}
                  label={<TextComponent tid="NEWS.ADMIN.PAGINATION.CATEGORY" />}
                />
              </Grid>
              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeFilterSelect(
                    NEWS_ADMIN_LIST_DATA_NAME.STATUS,
                  )}
                  option={NEWS_STATUS_OPTION}
                  label={<TextComponent tid="NEWS.ADMIN.PAGINATION.STATUS" />}
                />
              </Grid>
              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeSortType}
                  option={NEWS_SORT_OPTION}
                  defaultValue={NEWS_SORT_OPTION[1]}
                  label={<TextComponent tid="NEWS.ADMIN.PAGINATION.SORT" />}
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Pagination
              count={paginationCount}
              page={paginationPage}
              onChange={handleChangePagination}
              size="large"
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
