import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import { AUTH_LOGIN_ROUTE_PATH } from "../../auth-login/auth-login.constant";
import { ACCOUNT_RECOVERY_ROUTE_PATH } from "../../account-recovery/account-recovery.constant";
import { text, TextComponent } from "../../../lib/common/text";

export const AuthSignupFooterComponent = (props) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box>
        <Box>
          <TextComponent
            variant="body1"
            component="span"
            tid="AUTH.SIGNUP.FOOTER.LOGIN_LINK_TEXT"
          />{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(AUTH_LOGIN_ROUTE_PATH);
            }}
          >
            <TextComponent tid="AUTH.SIGNUP.FOOTER.LOGIN_LINK" />
          </Link>
        </Box>
        <Box>
          <TextComponent
            variant="body1"
            component="span"
            tid="AUTH.SIGNUP.FOOTER.RECOVERY_LINK_TEXT"
          />{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(ACCOUNT_RECOVERY_ROUTE_PATH);
            }}
          >
            <TextComponent tid="AUTH.SIGNUP.FOOTER.RECOVERY_LINK" />
          </Link>
        </Box>
      </Box>
      <Box sx={{ pt: 4 }}>
        <TextComponent
          variant="body1"
          component="span"
          tid="AUTH.SIGNUP.FOOTER.TERMS_LINK_TEXT"
        />{" "}
        <LinkStyled
          target="_blank"
          href={text("AUTH.SIGNUP.FOOTER.PRIVACY_LINK_VALUE")}
        >
          <TextComponent tid="AUTH.SIGNUP.FOOTER.TERMS_LINK" />
        </LinkStyled>{" "}
        <TextComponent
          variant="body1"
          component="span"
          tid="AUTH.SIGNUP.FOOTER.PRIVACY_LINK_TEXT"
        />{" "}
        <LinkStyled
          target="_blank"
          href={text("AUTH.SIGNUP.FOOTER.PRIVACY_LINK_VALUE")}
        >
          <TextComponent tid="AUTH.SIGNUP.FOOTER.PRIVACY_LINK" />
        </LinkStyled>{" "}
      </Box>
    </Box>
  );
};

const LinkStyled = styled.a`
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  letter-spacing: 0;
  font-weight: 400;
  color: #5b25ec;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-decoration-color: rgba(91, 37, 236, 0.4);
`;
