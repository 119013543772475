import React from "react";
import { createBrowserRouter } from "react-router-dom";

import { Layout } from "../../Layout";
import {
  AuthRouter,
  IndexRouter,
  PrivateRouter,
  PrivateRouterDynamic,
} from "../../lib/common/router/router.redirect";

import { AUTH_LOGIN_ROUTE_PATH } from "../../core/auth-login/auth-login.constant";
import { AuthLoginPage } from "../../core/auth-login/auth-login.page";

import { AUTH_SIGNUP_ROUTE_PATH } from "../../core/auth-signup/auth-signup.constant";
import { AuthSignupPage } from "../../core/auth-signup/auth-signup.page";

import { ACCOUNT_VERIFICATION_EMAIL_ROUTE_PATH } from "../../core/account-verification-email/account-verification-email.constant";
import { AccountVerificationEmailPage } from "../../core/account-verification-email/account-verification-email.page";

import { ACCOUNT_UPDATE_AUTH_ROUTE_PATH } from "../../core/account-update-auth/account-update-auth.constant";
import { AccountUpdateAuthPage } from "../../core/account-update-auth/account-update-auth.page";

import { ACCOUNT_RECOVERY_ROUTE_PATH } from "../../core/account-recovery/account-recovery.constant";
import { AccountRecoveryPage } from "../../core/account-recovery/account-recovery.page";

import { ERROR_ROUTE_PATH } from "../../core/error/error.constant";
import { ErrorPage } from "../../core/error/error.page";

import { INDEX_ROUTE_PATH } from "../../core/index/index.constant";

import { ACCOUNT_SETTINGS_ROUTE_PATH } from "../../core/account-settings/account-settings.constant";
import { AccountSettingsPage } from "../../core/account-settings/account-settings.page";

import { FILE_ADMIN_ROUTE_PATH } from "../../core/file-admin/file-admin.constant";
import { FileAdminPage } from "../../core/file-admin/file-admin.page";

import { GDS_ADMIN_CREATE_ROUTE_PATH } from "../../core/gds-admin-create/gds-admin-create.constant";
import { GdsAdminCreatePage } from "../../core/gds-admin-create/gds-admin-create.page";

import { GdsAdminItemPage } from "../../core/gds-admin-item/gds-admin-item.page";
import { GDS_ADMIN_ITEM_ROUTE_PATH } from "../../core/gds-admin-item/gds-admin-item.constant";

import { GDS_ADMIN_LIST_ROUTE_PATH } from "../../core/gds-admin-list/gds-admin-list.constant";
import { GdsAdminListPage } from "../../core/gds-admin-list/gds-admin-list.page";

import { INDEX_ADMIN_ROUTE_PATH } from "../../core/index-admin/index-admin.constant";
import { IndexAdminPage } from "../../core/index-admin/index-admin.page";

import { NEWS_ADMIN_CREATE_ROUTE_PATH } from "../../core/news-admin-create/news-admin-create.constant";
import { NewsAdminCreatePage } from "../../core/news-admin-create/news-admin-create.page";

import { NEWS_ADMIN_LIST_ROUTE_PATH } from "../../core/news-admin-list/news-admin-list.constant";
import { NewsAdminListPage } from "../../core/news-admin-list/news-admin-list.page";

import { NOTICE_ACCOUNT_LIST_ROUTE_PATH } from "../../core/notice-account-list/notice-account-list.constant";
import { NoticeAccountListPage } from "../../core/notice-account-list/notice-account-list.page";

import { NOTICE_ADMIN_LIST_ROUTE_PATH } from "../../core/notice-admin-list/notice-admin-list.constant";
import { NoticeAdminListPage } from "../../core/notice-admin-list/notice-admin-list.page";

import { POPUP_ADMIN_CREATE_ROUTE_PATH } from "../../core/popup-admin-create/popup-admin-create.constant";
import { PopupAdminCreatePage } from "../../core/popup-admin-create/popup-admin-create.page";

import { POPUP_ADMIN_ITEM_ROUTE_PATH } from "../../core/popup-admin-item/popup-admin-item.constant";
import { PopupAdminItemPage } from "../../core/popup-admin-item/popup-admin-item.page";

import { POPUP_ADMIN_LIST_ROUTE_PATH } from "../../core/popup-admin-list/popup-admin-list.constant";
import { PopupAdminListPage } from "../../core/popup-admin-list/popup-admin-list.page";

import { PROMOCODE_ADMIN_CREATE_ROUTE_PATH } from "../../core/promocode-admin-create/promocode-admin-create.constant";
import { PromocodeAdminCreatePage } from "../../core/promocode-admin-create/promocode-admin-create.page";

import { PROMOCODE_ADMIN_LIST_ROUTE_PATH } from "../../core/promocode-admin-list/promocode-admin-list.constant";
import { PromocodeAdminListPage } from "../../core/promocode-admin-list/promocode-admin-list.page";

import { PROMOCODE_ADMIN_ITEM_ROUTE_PATH } from "../../core/promocode-admin-item/promocode-admin-item.constant";
import { PromocodeAdminItemPage } from "../../core/promocode-admin-item/promocode-admin-item.page";

import { PURCHASE_ADMIN_LIST_ROUTE_PATH } from "../../core/purchase-admin-list/purchase-admin-list.constant";
import { PurchaseAdminListPage } from "../../core/purchase-admin-list/purchase-admin-list.page";

import { PURCHASE_BOOSTER_LIST_ROUTE_PATH } from "../../core/purchase-booster-list/purchase-booster-list.constant";
import { PurchaseBoosterListPage } from "../../core/purchase-booster-list/purchase-booster-list.page";

import { PURCHASE_CUSTOMER_LIST_ROUTE_PATH } from "../../core/purchase-customer-list/purchase-customer-list.constant";
import { PurchaseCustomerListPage } from "../../core/purchase-customer-list/purchase-customer-list.page";

import { USER_ADMIN_CREATE_ROUTE_PATH } from "../../core/user-admin-create/user-admin-create.constant";
import { UserAdminCreatePage } from "../../core/user-admin-create/user-admin-create.page";

import { USER_ADMIN_LIST_ROUTE_PATH } from "../../core/user-admin-list/user-admin-list.constant";
import { UserAdminListPage } from "../../core/user-admin-list/user-admin-list.page";

import { PurchaseCustomerItemPage } from "../../core/purchase-customer-item/purchase-customer-item.page";
import {
  PURCHASE_CUSTOMER_ITEM_ROUTE_PATH,
  PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-customer-item/purchase-customer-item.constant";

import {
  PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
  PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-customer-item-boost-account/purchase-customer-item-boost-account.constant";
import { PurchaseCustomerItemBoostAccountPage } from "../../core/purchase-customer-item-boost-account/purchase-customer-item-boost-account.page";

import {
  PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-customer-item-chat/purchase-customer-item-chat.constant";
import { PurchaseCustomerItemChatPage } from "../../core/purchase-customer-item-chat/purchase-customer-item-chat.page";

import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_ROUTE_PATH } from "../../core/purchase-customer-item-boost-time/purchase-customer-item-boost-time.constant";
import { PurchaseCustomerItemBoostTimePage } from "../../core/purchase-customer-item-boost-time/purchase-customer-item-boost-time.page";

import {
  PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_ROUTE_PATH,
  PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-customer-item-coaching-demo/purchase-customer-item-coaching-demo.constant";
import { PurchaseCustomerItemCoachingDemoPage } from "../../core/purchase-customer-item-coaching-demo/purchase-customer-item-coaching-demo.page";

import {
  PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH,
  PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-customer-item-coaching-report/purchase-customer-item-coaching-report.constant";
import { PurchaseCustomerItemCoachingReportPage } from "../../core/purchase-customer-item-coaching-report/purchase-customer-item-coaching-report.page";

import { PURCHASE_BOOSTER_ORDER_LIST_ROUTE_PATH } from "../../core/purchase-booster-order-list/purchase-booster-order-list.constant";
import { PurchaseBoosterOrderListPage } from "../../core/purchase-booster-order-list/purchase-booster-order-list.page";

import { AUTH_LOGOUT_ROUTE_PATH } from "../../core/auth-logout/auth-logout.constant";
import { AuthLogoutPage } from "../../core/auth-logout/auth-logout.page";

import { GDS_ITEM_ADMIN_LIST_ROUTE_PATH } from "../../core/gds-item-admin-list/gds-item-admin-list.constant";
import { GdsItemAdminListPage } from "../../core/gds-item-admin-list/gds-item-admin-list.page";

import { GdsItemAdminCreatePage } from "../../core/gds-item-admin-create/gds-item-admin-create.page";
import { GDS_ITEM_ADMIN_CREATE_ROUTE_PATH } from "../../core/gds-item-admin-create/gds-item-admin-create.constant";

import { GDS_ITEM_ADMIN_ITEM_ROUTE_PATH } from "../../core/gds-item-admin-item/gds-item-admin-item.constant";
import { GdsItemAdminItemPage } from "../../core/gds-item-admin-item/gds-item-admin-item.page";

import { NEWS_ADMIN_ITEM_ROUTE_PATH } from "../../core/news-admin-item/news-admin-item.constant";
import { NewsAdminItemPage } from "../../core/news-admin-item/news-admin-item.page";

import {
  PURCHASE_ADMIN_ITEM_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-admin-item/purchase-admin-item.constant";
import { PurchaseAdminItemPage } from "../../core/purchase-admin-item/purchase-admin-item.page";

import {
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-admin-item-boost-account/purchase-admin-item-boost-account.constant";
import { PurchaseAdminItemBoostAccountPage } from "../../core/purchase-admin-item-boost-account/purchase-admin-item-boost-account.page";

import {
  PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-admin-item-chat/purchase-admin-item-chat.constant";
import { PurchaseAdminItemChatPage } from "../../core/purchase-admin-item-chat/purchase-admin-item-chat.page";

import {
  PURCHASE_ADMIN_ITEM_COACHING_DEMO_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-admin-item-coaching-demo/purchase-admin-item-coaching-demo.constant";
import { PurchaseAdminItemCoachingDemoPage } from "../../core/purchase-admin-item-coaching-demo/purchase-admin-item-coaching-demo.page";

import {
  PURCHASE_ADMIN_ITEM_COACHING_REPORT_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-admin-item-coaching-report/purchase-admin-item-coaching-report.constant";
import { PurchaseAdminItemCoachingReportPage } from "../../core/purchase-admin-item-coaching-report/purchase-admin-item-coaching-report.page";

import {
  PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-admin-item-boost-time/purchase-admin-item-boost-time.constant";
import { PurchaseAdminItemBoostTimePage } from "../../core/purchase-admin-item-boost-time/purchase-admin-item-boost-time.page";

import {
  PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-admin-item-update/purchase-admin-item-update.constant";
import { PurchaseAdminItemUpdatePage } from "../../core/purchase-admin-item-update/purchase-admin-item-update.page";

import { USER_ADMIN_ITEM_ROUTE_PATH } from "../../core/user-admin-item/user-admin-item.constant";
import { UserAdminItemPage } from "../../core/user-admin-item/user-admin-item.page";

import { ACCOUNT_TELEGRAM_CONNECT_ROUTE_PATH } from "../../core/account-telegram-connect/account-telegram-connect.constant";
import { AccountTelegramConnectPage } from "../../core/account-telegram-connect/account-telegram-connect.page";

import {
  PURCHASE_BOOSTER_ITEM_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-booster-item/purchase-booster-item.constant";
import { PurchaseBoosterItemPage } from "../../core/purchase-booster-item/purchase-booster-item.page";

import {
  PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-booster-item-boost-account/purchase-booster-item-boost-account.constant";
import { PurchaseBoosterItemBoostAccountPage } from "../../core/purchase-booster-item-boost-account/purchase-booster-item-boost-account.page";

import {
  PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-booster-item-chat/purchase-booster-item-chat.constant";
import { PurchaseBoosterItemChatPage } from "../../core/purchase-booster-item-chat/purchase-booster-item-chat.page";

import {
  PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-booster-item-coaching-demo/purchase-booster-item-coaching-demo.constant";
import { PurchaseBoosterItemCoachingDemoPage } from "../../core/purchase-booster-item-coaching-demo/purchase-booster-item-coaching-demo.page";

import {
  PURCHASE_BOOSTER_ITEM_COACHING_REPORT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-booster-item-coaching-report/purchase-booster-item-coaching-report.constant";
import { PurchaseBoosterItemCoachingReportPage } from "../../core/purchase-booster-item-coaching-report/purchase-booster-item-coaching-report.page";

import {
  PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
} from "../../core/purchase-booster-item-boost-time/purchase-booster-item-boost-time.constant";
import { PurchaseBoosterItemBoostTimePage } from "../../core/purchase-booster-item-boost-time/purchase-booster-item-boost-time.page";

import { PURCHASE_BOOSTER_ITEM_UPDATE_ROUTE_PATH } from "../../core/purchase-booster-item-update/purchase-admin-item-update.constant";
import { PurchaseBoosterItemUpdatePage } from "../../core/purchase-booster-item-update/purchase-admin-item-update.page";

import { USER_ROLE_TYPE } from "../../lib/common/user/user.type";

export const router = createBrowserRouter([
  {
    path: AUTH_LOGOUT_ROUTE_PATH,
    element: <AuthRouter component={<AuthLogoutPage />} />,
  },
  {
    path: ACCOUNT_TELEGRAM_CONNECT_ROUTE_PATH,
    element: <AuthRouter component={<AccountTelegramConnectPage />} />,
  },
  {
    path: ACCOUNT_VERIFICATION_EMAIL_ROUTE_PATH,
    element: <AccountVerificationEmailPage />,
  },
  {
    element: <AuthRouter component={<Layout />} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ACCOUNT_UPDATE_AUTH_ROUTE_PATH,
        element: <AccountUpdateAuthPage />,
      },
      {
        path: INDEX_ROUTE_PATH,
        element: <IndexRouter />,
      },
      {
        path: AUTH_LOGIN_ROUTE_PATH,
        element: <AuthLoginPage />,
      },
      {
        path: AUTH_SIGNUP_ROUTE_PATH,
        element: <AuthSignupPage />,
      },
      {
        path: ACCOUNT_RECOVERY_ROUTE_PATH,
        element: <AccountRecoveryPage />,
      },
      {
        path: ERROR_ROUTE_PATH,
        element: <ErrorPage />,
      },
      {
        path: ACCOUNT_SETTINGS_ROUTE_PATH,
        element: <AccountSettingsPage />,
      },
      {
        path: FILE_ADMIN_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<FileAdminPage />}
          />
        ),
      },
      {
        path: GDS_ADMIN_CREATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsAdminCreatePage />}
          />
        ),
      },
      {
        path: GDS_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsAdminListPage />}
          />
        ),
      },
      {
        path: INDEX_ADMIN_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<IndexAdminPage />}
          />
        ),
      },
      {
        path: NEWS_ADMIN_CREATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<NewsAdminCreatePage />}
          />
        ),
      },
      {
        path: NEWS_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<NewsAdminListPage />}
          />
        ),
      },
      {
        path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
        element: <NoticeAccountListPage />,
      },
      {
        path: NOTICE_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<NoticeAdminListPage />}
          />
        ),
      },
      {
        path: POPUP_ADMIN_CREATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PopupAdminCreatePage />}
          />
        ),
      },
      {
        path: POPUP_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PopupAdminListPage />}
          />
        ),
      },
      {
        path: PROMOCODE_ADMIN_CREATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PromocodeAdminCreatePage />}
          />
        ),
      },
      {
        path: PROMOCODE_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PromocodeAdminListPage />}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminListPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
              [USER_ROLE_TYPE.BOOSTER]: PURCHASE_BOOSTER_LIST_ROUTE_PATH,
            }}
          />
        ),
      },
      {
        path: PURCHASE_BOOSTER_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.BOOSTER]}
            component={<PurchaseBoosterListPage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]: PURCHASE_ADMIN_LIST_ROUTE_PATH,
              [USER_ROLE_TYPE.USER]: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
            }}
          />
        ),
      },
      {
        path: PURCHASE_BOOSTER_ORDER_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.BOOSTER]}
            component={<PurchaseBoosterOrderListPage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]: PURCHASE_ADMIN_LIST_ROUTE_PATH,
              [USER_ROLE_TYPE.USER]: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
            }}
          />
        ),
      },
      {
        path: PURCHASE_BOOSTER_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.BOOSTER]}
            component={<PurchaseBoosterItemPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.ADMIN]: PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.BOOSTER]}
            component={<PurchaseBoosterItemBoostAccountPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]:
                PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_BOOSTER_ITEM_UPDATE_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.BOOSTER]}
            component={<PurchaseBoosterItemUpdatePage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },

      {
        path: PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.BOOSTER]}
            component={<PurchaseBoosterItemChatPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]:
                PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.BOOSTER]}
            component={<PurchaseBoosterItemCoachingDemoPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]:
                PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.BOOSTER]}
            component={<PurchaseBoosterItemCoachingReportPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]:
                PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.BOOSTER]}
            component={<PurchaseBoosterItemBoostTimePage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerListPage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]: PURCHASE_ADMIN_LIST_ROUTE_PATH,
              [USER_ROLE_TYPE.BOOSTER]: PURCHASE_BOOSTER_LIST_ROUTE_PATH,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerItemPage />}
            anotherPath={{
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.ADMIN]: PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerItemBoostAccountPage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerItemChatPage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_ITEM_BOOST_TIME_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerItemBoostTimePage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerItemCoachingDemoPage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.USER]}
            component={<PurchaseCustomerItemCoachingReportPage />}
            anotherPath={{
              [USER_ROLE_TYPE.ADMIN]:
                PURCHASE_ADMIN_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminItemPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminItemBoostAccountPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]:
                PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },

      {
        path: PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminItemChatPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]:
                PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_ITEM_COACHING_DEMO_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminItemCoachingDemoPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]:
                PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_ITEM_COACHING_REPORT_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminItemCoachingReportPage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]:
                PURCHASE_CUSTOMER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH,
        element: (
          <PrivateRouterDynamic
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminItemBoostTimePage />}
            anotherPath={{
              [USER_ROLE_TYPE.USER]: PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC,
              [USER_ROLE_TYPE.BOOSTER]:
                PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
            }}
          />
        ),
      },
      {
        path: PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PurchaseAdminItemUpdatePage />}
          />
        ),
      },
      {
        path: USER_ADMIN_CREATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<UserAdminCreatePage />}
          />
        ),
      },
      {
        path: USER_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<UserAdminListPage />}
          />
        ),
      },
      {
        path: GDS_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsAdminItemPage />}
          />
        ),
      },
      {
        path: GDS_ITEM_ADMIN_LIST_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsItemAdminListPage />}
          />
        ),
      },
      {
        path: GDS_ITEM_ADMIN_CREATE_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsItemAdminCreatePage />}
          />
        ),
      },
      {
        path: GDS_ITEM_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN, USER_ROLE_TYPE.EDITOR]}
            component={<GdsItemAdminItemPage />}
          />
        ),
      },
      {
        path: NEWS_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<NewsAdminItemPage />}
          />
        ),
      },
      {
        path: POPUP_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PopupAdminItemPage />}
          />
        ),
      },
      {
        path: PROMOCODE_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<PromocodeAdminItemPage />}
          />
        ),
      },
      {
        path: USER_ADMIN_ITEM_ROUTE_PATH,
        element: (
          <PrivateRouter
            roles={[USER_ROLE_TYPE.ADMIN]}
            component={<UserAdminItemPage />}
          />
        ),
      },
    ],
  },
]);
