import { PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './promocode-admin-item-update-data.constant';

export const convertPromocodeAdminItemUpdateDataFormData = (data) => ({
  [PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FACTOR]: Number(
    data[PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FACTOR],
  ),
  [PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NAME]: String(
    data[PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NAME],
  ),
});
