import { httpRequest } from '../../main/http';

import {
  PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_API,
  PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE,
} from './purchase-customer-item-chat-create.constant';

import { reloadPurchaseCustomerItemChatList } from '../purchase-customer-item-chat-list/purchase-customer-item-chat-list.action';

export function uploadPurchaseCustomerItemChatCreateForm(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_API.CHANGE_PASSWORD.TYPE,
        url: PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_API.CHANGE_PASSWORD.ENDPOINT(
          data.purchaseId,
          data.id,
        ),
        data,
      });

      await dispatch(reloadPurchaseCustomerItemChatList(data));

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseCustomerItemChatCreateFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_RESET,
    });
  };
}
