import { httpRequest } from '../../main/http';

import {
  PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_API,
  PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE,
} from './purchase-customer-item-boost-account-update-data.constant';

import { reloadPurchaseCustomerItemBoostAccountData } from '../purchase-customer-item-boost-account-data/purchase-customer-item-boost-account-data.action';

export function uploadPurchaseCustomerItemBoostAccountUpdateDataForm(
  data,
  purchaseId,
) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method:
          PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_API.UPDATE_DATA.TYPE,
        url: PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_API.UPDATE_DATA.ENDPOINT(
          purchaseId,
        ),
        data,
      });

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS,
      });

      await dispatch(reloadPurchaseCustomerItemBoostAccountData());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseCustomerItemBoostAccountUpdateDataFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE.FORM_RESET,
    });
  };
}
