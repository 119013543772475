export const GDS_STATUS_TYPE = {
  ACTIVE: 1,
  DISABLED: 2,
};

export const GDS_TYPE_TYPE = {
  CLASSIC: 1,
  ITEM: 2,
  MANUAL: 3,
};

export const GDS_TAG_TYPE = {
  SALE: 1,
  NEW: 2,
  BEST_SELLER: 3,
  INSTANT_DELIVERY: 4,
  MANUAL_DELIVARY: 5,
};

export const GDS_CATEGORY_TYPE = {
  FACEIT_10_LVL_PLUS: 1,
  FACEIT_4_9_LVL: 2,
  FACEIT_READY: 3,
  CSGO_RANKED: 4,
  STEAM_WITH_HOURS: 5,
  ESEA_RANKED: 6,
  FACEIT_1_LVL: 7,
  CS2: 8,
};

export const GDS_SORT_TYPE = {
  ORDER: 1,
  ID: 2,
};

export const GDS_TAG_VALUE = {
  1: 'SALE',
  2: 'NEW',
  3: 'BEST_SELLER',
  4: 'INSTANT_DELIVERY',
  5: 'MANUAL_DELIVARY',
};

export const GDS_STATUS_VALUE = {
  1: 'ACTIVE',
  2: 'DISABLED',
};

export const GDS_TYPE_VALUE = {
  1: 'CLASSIC',
  2: 'ITEM',
  3: 'MANUAL',
};

export const GDS_CATEGORY_VALUE = {
  1: 'FACEIT_10_LVL_PLUS',
  2: 'FACEIT_4_9_LVL',
  3: 'FACEIT_READY',
  4: 'CSGO_RANKED',
  5: 'STEAM_WITH_HOURS',
  6: 'ESEA_RANKED',
  7: 'FACEIT_1_LVL',
  8: 'CS2',
};

export const GDS_SORT_VALUE = {
  1: 'ORDER',
  2: 'ID',
};

export const GDS_STATUS_OPTION = [
  { id: 1, value: 1, tid: 'GDS.DATA.STATUS.ACTIVE' },
  { id: 2, value: 2, tid: 'GDS.DATA.STATUS.DISABLED' },
];

export const GDS_TYPE_OPTION = [
  { id: 1, value: 1, tid: 'GDS.DATA.TYPE.CLASSIC' },
  { id: 2, value: 2, tid: 'GDS.DATA.TYPE.ITEM' },
  { id: 3, value: 3, tid: 'GDS.DATA.TYPE.MANUAL' },
];

export const GDS_TAG_OPTION = [
  { id: 1, value: 1, tid: 'GDS.DATA.TAG.SALE' },
  { id: 2, value: 2, tid: 'GDS.DATA.TAG.NEW' },
  { id: 3, value: 3, tid: 'GDS.DATA.TAG.BEST_SELLER' },
  { id: 4, value: 4, tid: 'GDS.DATA.TAG.INSTANT_DELIVERY' },
  { id: 5, value: 5, tid: 'GDS.DATA.TAG.MANUAL_DELIVARY' },
];

export const GDS_CATEGORY_OPTION = [
  { id: 1, value: 1, tid: 'GDS.DATA.CATEGORY.FACEIT_10_LVL_PLUS' },
  { id: 2, value: 2, tid: 'GDS.DATA.CATEGORY.FACEIT_4_9_LVL' },
  { id: 3, value: 3, tid: 'GDS.DATA.CATEGORY.FACEIT_READY' },
  { id: 4, value: 4, tid: 'GDS.DATA.CATEGORY.CSGO_RANKED' },
  { id: 5, value: 5, tid: 'GDS.DATA.CATEGORY.STEAM_WITH_HOURS' },
  { id: 6, value: 6, tid: 'GDS.DATA.CATEGORY.ESEA_RANKED' },
  { id: 7, value: 7, tid: 'GDS.DATA.CATEGORY.FACEIT_1_LVL' },
  { id: 8, value: 8, tid: 'GDS.DATA.CATEGORY.CS2' },
];

export const GDS_SORT_OPTION = [
  { id: 1, value: 1, tid: 'GDS.DATA.SORT.ORDER' },
  { id: 2, value: 2, tid: 'GDS.DATA.SORT.ID' },
];
