import VALIDATION from './validation.json';
import AUTH from './auth.json';
import ACCOUNT from './account.json';
import ERROR from './error.json';
import NAVIGATION from './navigation.json';
import USER from './user.json';
import PURCHASE from './purchase.json';
import BOOST from './boost.json';
import NEWS from './news.json';
import FILE from './file.json';
import POPUP from './popup.json';
import PROMOCODE from './promocode.json';
import GDS from './gds.json';
import GDS_ITEM from './gds-item.json';
import PAYMENT from './payment.json';
import MODAL from './modal.json';
import NOTICE from './notice.json';
import CONVERT from './convert.json';
import COACHING from './coaching.json';

export const RU = {
  translation: {
    VALIDATION,
    AUTH,
    ACCOUNT,
    ERROR,
    NAVIGATION,
    USER,
    PURCHASE,
    BOOST,
    NEWS,
    FILE,
    POPUP,
    PROMOCODE,
    GDS,
    GDS_ITEM,
    PAYMENT,
    MODAL,
    NOTICE,
    CONVERT,
    COACHING,
  },
};
