import { httpRequest } from "../../main/http";
import {
  USER_ADMIN_CREATE_API,
  USER_ADMIN_CREATE_ACTION_TYPE,
} from "./user-admin-create.constant";
import { USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../user-admin-item/user-admin-item.constant";

export function uploadUserAdminCreateForm(data, redirect) {
  return async (dispatch) => {
    dispatch({
      type: USER_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING,
    });

    try {
      const res = await httpRequest({
        method: USER_ADMIN_CREATE_API.USER_ADMIN_CREATE.TYPE,
        url: USER_ADMIN_CREATE_API.USER_ADMIN_CREATE.ENDPOINT,
        data,
      });

      await redirect(
        USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ userId: res.data.id })
      );

      dispatch({
        type: USER_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: USER_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetUserAdminCreateForm(data) {
  return async (dispatch) => {
    dispatch({
      type: USER_ADMIN_CREATE_ACTION_TYPE.FORM_RESET,
    });
  };
}
