export const PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_STORE_NAME =
  'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE';

export const PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_API = {
  DELETE_DATA: {
    ENDPOINT: (purchaseId, coachingDemoId) =>
      `/purchase/${purchaseId}/customer/coaching/demo/${coachingDemoId}`,
    TYPE: 'DELETE',
  },
};

export const PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE = {
  FORM_PENDING:
    'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS:
    'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR:
    'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET:
    'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE.FORM_RESET',
};
