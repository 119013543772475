import Paper from "@mui/material/Paper";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { TextComponent } from "../../lib/common/text";

import { PurchaseBoosterItemUpdateDataFormComponent } from "./frame/purchase-booster-item-update-data-form.component";

export function PurchaseBoosterItemUpdateDataComponent(props) {
  const {
    initialValue,
    pageLoading,
    isPending,
    validation,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
  } = props;
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="PURCHASE.BOOSTER.UPDATE.DATA.TITLE"
            />
          </Box>
          <Divider />
          <Formik
            enableReinitialize
            initialValues={initialValue}
            validate={validation}
            onSubmit={onSubmitForm}
          >
            {(props) => (
              <PurchaseBoosterItemUpdateDataFormComponent
                {...props}
                isPending={isPending}
                isError={isError}
                errorMessage={errorMessage}
                pageLoading={pageLoading}
              />
            )}
          </Formik>
          {isError && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isSuccess && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="success">
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.SUCCESS_MESSAGE" />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
