import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PROMOCODE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../promocode-admin-item/promocode-admin-item.constant";
import { PROMOCODE_ADMIN_LIST_DATA_NAME } from "../promocode-admin-list.constant";

export function PromocodeAdminListViewComponent(props) {
  const { data } = props;

  if (data.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="PROMOCODE.ADMIN.LIST.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (promocodeId) => () =>
    navigate(PROMOCODE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ promocodeId }));

  return (
    <List sx={{ pt: 4 }}>
      {data.map((item) => (
        <ListItem
          onClick={handleClickItem(item[PROMOCODE_ADMIN_LIST_DATA_NAME.ID])}
          button
          variant="list"
        >
          <ListItemText
            primary={
              <TextComponent gutterBottom variant="body1">
                <TextComponent
                  tid="PROMOCODE.ADMIN.LIST.ID"
                  tvalue={{ id: item[PROMOCODE_ADMIN_LIST_DATA_NAME.ID] }}
                />
              </TextComponent>
            }
            secondary={
              <TextComponent
                tid="PROMOCODE.ADMIN.LIST.DATA"
                tvalue={{
                  name: item[PROMOCODE_ADMIN_LIST_DATA_NAME.NAME],
                  factor: item[PROMOCODE_ADMIN_LIST_DATA_NAME.FACTOR].text,
                }}
                variant="body1"
              />
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
