import { AUTH_USER_DATA, AuthUserDto } from './auth.type';

export const parseUserAuthData = (raw: any): AuthUserDto => ({
  id: raw[AUTH_USER_DATA.ID],
  role: raw[AUTH_USER_DATA.ROLE],
  login: raw[AUTH_USER_DATA.LOGIN],
  email: raw[AUTH_USER_DATA.EMAIL],
  timezone: raw[AUTH_USER_DATA.TIMEZONE],
  confirmEmail: raw[AUTH_USER_DATA.CONFIRM_EMAIL],
});
