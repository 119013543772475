import React from "react";
import { useNavigate } from "react-router-dom";

import { NewsAdminCreateInfoComponent } from "./news-admin-create-info.component";
import { NEWS_ADMIN_CREATE_ROUTE_PATH } from "../news-admin-create/news-admin-create.constant";

export function NewsAdminCreateInfoContainer() {
  const navigate = useNavigate();

  const onSubmitForm = () => {
    return navigate(NEWS_ADMIN_CREATE_ROUTE_PATH);
  };

  return <NewsAdminCreateInfoComponent onSubmitForm={onSubmitForm} />;
}
