import { httpRequest } from '../../main/http';

import {
  GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_API,
  GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE,
} from './gds-item-admin-item-update-data.constant';

import { reloadGdsItemAdminItemData } from '../gds-item-admin-item-data/gds-item-admin-item-data.action';

export function uploadGdsItemAdminItemUpdateDataForm(data, gdsItemId, gdsId) {
  return async (dispatch) => {
    dispatch({
      type: GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_API.CHANGE_DATA.TYPE,
        url: GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_API.CHANGE_DATA.ENDPOINT(
          gdsItemId,
          gdsId,
        ),
        data,
      });

      dispatch({
        type: GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS,
      });

      await dispatch(reloadGdsItemAdminItemData());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetGdsItemAdminItemUpdateDataFormState() {
  return async (dispatch) => {
    dispatch({
      type: GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET,
    });
  };
}
