import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import { LoaderBarComponent } from '../../lib/common/loader-bar';
import { SkeletonDataViewComponent } from '../../lib/common/skeleton-data-view/skeleton-data-view.component';
import { TextComponent } from '../../lib/common/text';

import { PurchaseBoosterItemChatListViewComponent } from './frame/purchase-booster-item-chat-list-view.component';

export function PurchaseBoosterItemChatListComponent(props) {
  const {
    isPending,
    isError,
    isSuccess,
    pageLoading,
    data,
    errorMessage,
    isUpdatePending,
  } = props;
  return (
    <Box>
      <Paper sx={{ p: 6, pb: 0 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="PURCHASE.BOOSTER.CHAT.LIST.TITLE"
            />
          </Box>
          <Divider />
          {isSuccess && (
            <PurchaseBoosterItemChatListViewComponent data={data} />
          )}
          {isPending && (
            <Box sx={{ pt: 4, pb: 6 }}>
              <SkeletonDataViewComponent />
            </Box>
          )}

          {isError && (
            <Box sx={{ pt: 4, pb: 6 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}

          {/* {isUpdatePending && <LoaderBarComponent />} */}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
