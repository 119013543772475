import { PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE } from './purchase-booster-item-update-booster.constant';
import {
  initRequestState,
  setRequestPending,
  setRequestSuccess,
  setRequestError,
  resetRequestStatus,
} from '../../main/store/store.service';

const initialState = {
  form: initRequestState(),
};

export function purchaseBoosterItemUpdateBoosterStore(
  state = initialState,
  action,
) {
  switch (action.type) {
    case PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_PENDING:
      return {
        ...state,
        form: setRequestPending(state.form),
      };

    case PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_SUCCESS:
      return {
        ...state,
        form: setRequestSuccess(state.form),
      };

    case PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_ERROR:
      return {
        ...state,
        form: setRequestError(state.form, action.errorMessage),
      };

    case PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_RESET:
      return {
        ...state,
        form: resetRequestStatus(state.form),
      };

    default:
      return state;
  }
}
