import { httpRequest } from "../../main/http";

import {
  ACCOUNT_VERIFICATION_EMAIL_API,
  ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE,
} from "./account-verification-email.constant";
import { authUpdateUserData } from "../../lib/common/auth/auth.action";
import { INDEX_ROUTE_PATH } from "../index/index.constant";

export function getAccountVerificationEmailForm(data) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: ACCOUNT_VERIFICATION_EMAIL_API.GET_CODE.TYPE,
        url: ACCOUNT_VERIFICATION_EMAIL_API.GET_CODE.ENDPOINT,
      });

      dispatch({
        type: ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function uploadAccountVerificationEmailForm(data, navigate) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: ACCOUNT_VERIFICATION_EMAIL_API.CONFIRM_EMAIL.TYPE,
        url: ACCOUNT_VERIFICATION_EMAIL_API.CONFIRM_EMAIL.ENDPOINT,
        data,
      });
      await dispatch(authUpdateUserData());

      await navigate(INDEX_ROUTE_PATH);

      dispatch({
        type: ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.REQUEST_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetAccountVerificationEmailForm(data) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.RESET,
    });
  };
}
