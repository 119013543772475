export const convertFileFieldValue = (fileFieldValue) => {
  if (!fileFieldValue) return null;
  if (!fileFieldValue[0]) return null;

  if (fileFieldValue.id) return fileFieldValue.id;

  return fileFieldValue.map((item) => item.id);
};

export const convertOneFileFieldValue = (fileFieldValue) => {
  if (!fileFieldValue) return null;

  if (fileFieldValue.id) return fileFieldValue.id;

  if (fileFieldValue.length === 0) return null;

  return fileFieldValue.map((item) => item.id)[0];
};
