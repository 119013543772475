export const GDS_ADMIN_ITEM_DELETE_STORE_NAME = 'GDS_ADMIN_ITEM_DELETE';

export const GDS_ADMIN_ITEM_DELETE_API = {
  DELETE_DATA: {
    ENDPOINT: (id) => `/gds/${id}`,
    TYPE: 'DELETE',
  },
};

export const GDS_ADMIN_ITEM_DELETE_ACTION_TYPE = {
  FORM_PENDING: 'GDS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'GDS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'GDS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'GDS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_RESET',
};
