import { PROMOCODE_ADMIN_CREATE_DATA_NAME } from './promocode-admin-create.constant';

export const convertPromocodeAdminCreateFormData = (data) => ({
  [PROMOCODE_ADMIN_CREATE_DATA_NAME.FACTOR]: Number(
    data[PROMOCODE_ADMIN_CREATE_DATA_NAME.FACTOR],
  ),
  [PROMOCODE_ADMIN_CREATE_DATA_NAME.NAME]: String(
    data[PROMOCODE_ADMIN_CREATE_DATA_NAME.NAME],
  ),
});
