import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_STORE_NAME } from '../purchase-admin-item-coaching-demo-list/purchase-admin-item-coaching-demo-list.constant';

import { PURCHASE_ADMIN_ITEM_COACHING_DEMO_DELETE_STORE_NAME } from './purchase-admin-item-coaching-demo-delete.constant';
import { PurchaseAdminItemCoachingDemoDeleteComponent } from './purchase-admin-item-coaching-demo-delete.component';
import {
  uploadPurchaseAdminItemCoachingDemoDeleteForm,
  resetPurchaseAdminItemCoachingDemoDeleteFormState,
} from './purchase-admin-item-coaching-demo-delete.action';

export function PurchaseAdminItemCoachingDemoDeleteContainer(props) {
  const { coachingDemoId } = props;
  const dispatch = useDispatch();
  const { state, pageLoading, coachingStore } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_COACHING_DEMO_DELETE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    coachingStore: state[PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemCoachingDemoDeleteFormState());
  }, []);

  const purchaseAdminItemCoachingDemoDeleteFormSendData = () => {
    dispatch(
      uploadPurchaseAdminItemCoachingDemoDeleteForm(
        coachingStore?.purchaseId,
        coachingDemoId,
      ),
    );
  };

  return (
    <PurchaseAdminItemCoachingDemoDeleteComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      stateCoachingDemoId={state.coachingDemoId}
      coachingDemoId={coachingDemoId}
      isDependentPending={isRequestPending(coachingStore.request)}
      onSubmitForm={purchaseAdminItemCoachingDemoDeleteFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
