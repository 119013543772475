export const FILE_ADMIN_LIST_STORE_NAME = 'FILE_ADMIN_LIST';

export const FILE_ADMIN_LIST_API = {
  FILE_ADMIN_LIST: {
    ENDPOINT: () => `/file`,
    TYPE: 'GET',
  },
};

export const FILE_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS: 'FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING: 'FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const FILE_ADMIN_LIST_DATA_NAME = {
  ID: 'id',
  URL: 'url',
  NAME: 'name',
};
