import { PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME } from './purchase-admin-item-update-boost-data.constant';
import { getBoostRankImageUrl } from '../../lib/common/boost/boost.core';
import { i18n } from '../../main/lang';

const STATIC_URL = process.env.STATIC;

export const convertPurchaseAdminItemUpdateBoostDataFormData = (data) => {
  return {
    [PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]:
      data[PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL].value,

    [PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL_STATIC_VALUE]:
      i18n.t(
        data[PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL].tid,
      ),

    [PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL_STATIC_IMAGE]:
      getBoostRankImageUrl(
        data[PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]
          .value,
        STATIC_URL,
      ),
  };
};
