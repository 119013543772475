import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GdsItemAdminCreateInfoComponent } from "./gds-item-admin-create-info.component";
import { GDS_ITEM_ADMIN_CREATE_ROUTE_PATH_DYNAMIC } from "../gds-item-admin-create/gds-item-admin-create.constant";

export function GdsItemAdminCreateInfoContainer() {
  const { gdsId } = useParams();
  const navigate = useNavigate();

  const onSubmitForm = () => {
    return navigate(GDS_ITEM_ADMIN_CREATE_ROUTE_PATH_DYNAMIC({ gdsId }));
  };

  return <GdsItemAdminCreateInfoComponent onSubmitForm={onSubmitForm} />;
}
