import { PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './purchase-admin-item-update-data.constant';

export const convertPurchaseAdminItemUpdateDataFormData = (data) => ({
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS] === "" ? data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS] : data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS] || undefined,
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES] === "" ? data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES] : data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NOTES] || undefined,
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]:
    data[PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS].id,
});
