import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME } from './purchase-customer-item-boost-time-list.constant';

import { convertDateSchedulePm } from '../../lib/common/convert/convert.core';

export const convertPurchaseCustomerItemBoostTimeList = (d) => {
  return {
    list: d.list.map((data) => {
      return {
        [PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME.ID]:
          data[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME.ID],
        [PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME.FROM_TIME]:
          convertDateSchedulePm(
            data[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME.FROM_TIME],
          ),
        [PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME.TO_TIME]:
          convertDateSchedulePm(
            data[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME.TO_TIME],
          ),
      };
    }),
  };
};
