import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  uploadPurchaseAdminItemUpdateBoostDataForm,
  resetPurchaseAdminItemUpdateBoostDataFormState,
} from "./purchase-admin-item-update-boost-data.action";
import {
  PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_STORE_NAME,
  PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME,
} from "./purchase-admin-item-update-boost-data.constant";
import { convertPurchaseAdminItemUpdateBoostDataFormData } from "./purchase-admin-item-update-boost-data.convert";
import { purchaseAdminItemUpdateBoostDataFormValidation } from "./purchase-admin-item-update-boost-data.validation";
import { PurchaseAdminItemUpdateBoostDataComponent } from "./purchase-admin-item-update-boost-data.component";
import {
  PURCHASE_ADMIN_ITEM_DATA_NAME,
  PURCHASE_ADMIN_ITEM_STORE_NAME,
} from "../purchase-admin-item/purchase-admin-item.constant";

export function PurchaseAdminItemUpdateBoostDataContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading, boostItemState } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_STORE_NAME],
    boostItemState: state[PURCHASE_ADMIN_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const boostData = getRequestData(boostItemState.request)?.[
    PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST
  ];

  const boostDataSuccess =
    isRequestSuccess(boostItemState.request) && !!boostData;

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemUpdateBoostDataFormState());
  }, []);

  const purchaseAdminItemUpdateBoostDataFormSendData = (values) => {
    const data = convertPurchaseAdminItemUpdateBoostDataFormData(values);

    data.purchaseId = purchaseId;
    data.boostId = boostData[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST_ID];
    dispatch(uploadPurchaseAdminItemUpdateBoostDataForm(data));
  };

  const getInitialValue = () => {
    if (boostDataSuccess) {
      return {
        [PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]:
          boostData?.[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOST_ACTUAL],
      };
    } else {
      return {
        [PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]: null,
      };
    }
  };

  return (
    <PurchaseAdminItemUpdateBoostDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemUpdateBoostDataFormValidation}
      onSubmitForm={purchaseAdminItemUpdateBoostDataFormSendData}
      pageLoading={pageLoading}
      isBoost={boostDataSuccess}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
