import { httpRequest } from "../../main/http";

import {
  USER_ADMIN_ITEM_UPDATE_BONUS_API,
  USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE,
} from "./user-admin-item-update-bonus.constant";

import { reloadUserAdminItemData } from "../user-admin-item-data/user-admin-item-data.action";

export function uploadUserAdminItemUpdateBonusForm(data, userId) {
  return async (dispatch) => {
    dispatch({
      type: USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: USER_ADMIN_ITEM_UPDATE_BONUS_API.CHANGE_DATA.TYPE,
        url: USER_ADMIN_ITEM_UPDATE_BONUS_API.CHANGE_DATA.ENDPOINT(userId),
        data,
      });

      dispatch({
        type: USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE.FORM_SUCCESS,
      });

      await dispatch(reloadUserAdminItemData());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetUserAdminItemUpdateBonusFormState() {
  return async (dispatch) => {
    dispatch({
      type: USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE.FORM_RESET,
    });
  };
}
