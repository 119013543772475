import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../purchase-admin-item/purchase-admin-item.constant";

export function PurchaseAdminListViewComponent(props) {
  const { data } = props;

  if (data.list.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="PURCHASE.ADMIN.LIST.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (purchaseId) => (e) => {
    e.preventDefault();
    return navigate(PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }));
  };

  const getCurent = (item) => {
    switch (item.boost.type.value) {
      case "APEX_BOOST_TYPE_BADGE":
        return (
          <>
            <Grid item>
              <TextComponent
                sx={{
                  wordBreak: "break-all",
                  whiteSpace: "break-spaces",
                }}
                variant="body1"
              >
                {item.boost.data.badge.map((badge) => (
                  <p>{badge}</p>
                ))}
              </TextComponent>
            </Grid>
          </>
        );
      default:
        return (
          <>
            <Grid item>
              <TextComponent
                sx={{
                  wordBreak: "break-all",
                  whiteSpace: "break-spaces",
                }}
                tid="PURCHASE.ADMIN.LIST.BOOST"
                variant="body1"
                tvalue={{
                  current: item.boost.current.text,
                  desired: item.boost.desired.text,
                }}
              />
            </Grid>
            <Grid item>
              <TextComponent
                sx={{
                  wordBreak: "break-all",
                  whiteSpace: "break-spaces",
                }}
                tid="PURCHASE.ADMIN.LIST.BOOST_ACTUAL"
                variant="body1"
                tvalue={{
                  actual: item.boost.actual.text,
                }}
              />
            </Grid>
          </>
        );
    }
  };
  return (
    <List sx={{ pt: 4 }}>
      {data.list.map((item, itemIndex) => (
        <React.Fragment key={itemIndex}>
          <ListItem
            sx={{ px: 6 }}
            onClick={handleClickItem(item.id)}
            button
            variant="list"
            component="a"
            href={PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
              purchaseId: item.id,
            })}
          >
            <Grid
              sx={{
                flexDirection: "row",
                justifyContent: "flex-start !important",
              }}
              spacing={2}
              container
            >
              <Grid item xs={12} md={2.3}>
                <Grid container spacing={1}>
                  <Grid item>
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.ID"
                      variant="body1"
                      component="div"
                      tvalue={{ id: item.id }}
                    />
                  </Grid>
                  <Grid item>
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.CREATE_DATE"
                      variant="body1"
                      component="div"
                      tvalue={{ date: item.createDate }}
                    />
                  </Grid>
                  <Grid item>
                    <TextComponent
                      tid="PURCHASE.ADMIN.LIST.STATUS"
                      variant="body1"
                      component="div"
                      tvalue={{ status: item.status.text }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container spacing={1}>
                  {item.boost && (
                    <React.Fragment>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.TYPE"
                          variant="body1"
                          tvalue={{ type: item.boost.type.text }}
                        />
                      </Grid>
                      {getCurent(item)}
                      {item.boost.additionals && (
                        <Grid item>
                          <TextComponent variant="body1">
                            {item.boost.additionals
                              .map((i) => i.text)
                              .join(", ")}
                          </TextComponent>
                        </Grid>
                      )}
                    </React.Fragment>
                  )}
                  {item.gds && (
                    <React.Fragment>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.GDS"
                          variant="body1"
                          tvalue={{
                            title: item.gds.titleEn,
                            id: item.gds.id,
                          }}
                        />
                      </Grid>
                      {item.gdsItem && (
                        <Grid item>
                          <TextComponent
                            tid="PURCHASE.ADMIN.LIST.GDS_ITEM"
                            variant="body1"
                            tvalue={{
                              title: item.gdsItem.titleEn,
                              id: item.gdsItem.id,
                            }}
                          />
                        </Grid>
                      )}
                    </React.Fragment>
                  )}
                  {item.type.value === "GDS" && (
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.NAME_ITEM"
                        variant="body1"
                        tvalue={{
                          name: item.metadata.static.title,
                        }}
                      />
                    </Grid>
                  )}
                  {item.coaching && (
                    <React.Fragment>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.TYPE"
                          variant="body1"
                          tvalue={{ type: item.coaching.type.text }}
                        />
                      </Grid>
                      <Grid item>
                        <TextComponent
                          sx={{
                            wordBreak: "break-all",
                            whiteSpace: "break-spaces",
                          }}
                          tid="PURCHASE.ADMIN.LIST.COACHING"
                          variant="body1"
                          tvalue={{
                            sessionAmount: item.coaching.sessionAmount,
                            actualSession: item.coaching.actualSession,
                          }}
                        />
                      </Grid>
                      {item.coaching.additionals && (
                        <Grid item>
                          <TextComponent variant="body1">
                            {item.coaching.additionals
                              .map((i) => i.text)
                              .join(", ")}
                          </TextComponent>
                        </Grid>
                      )}
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <Grid container spacing={1}>
                  {item.promocode ? (
                    <React.Fragment>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.PRICE"
                          variant="body1"
                          tvalue={{
                            price: item.price,
                            valute: item.valute.text,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.PROMOCODE"
                          variant="body1"
                          tvalue={{
                            factor: item.promocode.factor.text,
                            name: item.promocode.name,
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.PRICE"
                          variant="body1"
                          tvalue={{
                            price: item.price,
                            valute: item.valute.text,
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  {item.metadata?.purchaseByGiftCard && (
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.GIFT_CARD"
                        variant="body1"
                        tvalue={{
                          card: item.metadata?.purchaseByGiftCard,
                        }}
                      />
                    </Grid>
                  )}
                  {item.metadata?.isDirectlyPay && (
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.DIRECTLY_PAY"
                        variant="body1"
                        tvalue={{
                          card: item.metadata?.isDirectlyPay,
                        }}
                      />
                    </Grid>
                  )}
                  {item.metadata?.static?.popupBonus && (
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.POPUP"
                        variant="body1"
                      />
                    </Grid>
                  )}

                  {item.metadata?.static?.bonus && (
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.BONUS"
                        variant="body1"
                        tvalue={{
                          price: item.metadata?.static?.bonus || "0",
                          valute: item.valute.text,
                        }}
                      />
                    </Grid>
                  )}

                  {item.paid ? (
                    <React.Fragment>
                      <Grid item>
                        <TextComponent
                          tid="PURCHASE.ADMIN.LIST.PAYMENT_SYSTEM"
                          variant="body1"
                          sx={{ color: "green" }}
                          tvalue={{ system: item.payment.system.text }}
                        />
                      </Grid>
                      <Grid item>
                        {item.payment.confirm ? (
                          <TextComponent
                            tid="PURCHASE.ADMIN.LIST.PAYMENT_CONFIRM_TRUE"
                            variant="body1"
                            sx={{ color: "green" }}
                          />
                        ) : (
                          <TextComponent
                            tid="PURCHASE.ADMIN.LIST.PAYMENT_CONFIRM_FALSE"
                            variant="body1"
                            sx={{ color: "red" }}
                          />
                        )}
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.ADMIN.LIST.PAID_FALSE"
                        variant="body1"
                        sx={{ color: "red" }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container spacing={1}>
                  {item.isCustomer && (
                    <React.Fragment>
                      <Grid item>
                        <TextComponent
                          sx={{
                            wordBreak: "break-all",
                            whiteSpace: "break-spaces",
                          }}
                          tid="PURCHASE.ADMIN.LIST.CUSTOMER"
                          variant="body1"
                          tvalue={{
                            email: item.customer.email,
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  {item.isBooster && (
                    <React.Fragment>
                      <Grid item>
                        <TextComponent
                          sx={{
                            wordBreak: "break-all",
                            whiteSpace: "break-spaces",
                          }}
                          tid="PURCHASE.ADMIN.LIST.BOOSTER"
                          variant="body1"
                          tvalue={{
                            email: item.booster.email,
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          {itemIndex !== data.list.length - 1 && (
            <ListItem>
              <Divider sx={{ width: "100%", opacity: 0.35 }} />
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
