import { validate } from '../../main/validate';

import { ACCOUNT_VERIFICATION_EMAIL_DATA_NAME } from './account-verification-email.constant';

import { required } from '../../main/validate/validate.service';

const config = {
  [ACCOUNT_VERIFICATION_EMAIL_DATA_NAME.CODE]: [required],
};

export const accountVerificationEmailFormValidation = (values) =>
  validate(values, config);
