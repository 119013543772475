import { httpRequest } from '../../main/http';
import { reloadPurchaseAdminItem } from '../purchase-admin-item/purchase-admin-item.action';

import {
  PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_API,
  PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE,
} from './purchase-admin-item-update-coaching-data.constant';

export function uploadPurchaseAdminItemUpdateCoachingDataForm(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_API.CHANGE_DATA.TYPE,
        url: PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_API.CHANGE_DATA.ENDPOINT(
          data.purchaseId,
          data.coachingId,
        ),
        data,
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE.FORM_SUCCESS,
      });

      await dispatch(reloadPurchaseAdminItem());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseAdminItemUpdateCoachingDataFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_ACTION_TYPE.FORM_RESET,
    });
  };
}
