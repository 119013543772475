import { validate } from '../../main/validate';

import { ACCOUNT_UPDATE_AUTH_DATA_NAME } from './account-update-auth.constant';

import {
  maxLength,
  required,
  password,
  passwordRepeat,
} from '../../main/validate/validate.service';

const config = {
  [ACCOUNT_UPDATE_AUTH_DATA_NAME.CODE]: [required, maxLength(10)],
  [ACCOUNT_UPDATE_AUTH_DATA_NAME.PASSWORD]: [required, password],
  [ACCOUNT_UPDATE_AUTH_DATA_NAME.REPEAT_PASSWORD]: [
    required,
    passwordRepeat(ACCOUNT_UPDATE_AUTH_DATA_NAME.PASSWORD),
  ],
};

export const accountUpdateAuthFormValidation = (values) =>
  validate(values, config);
