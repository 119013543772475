import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { TextComponent } from "../../../lib/common/text/text.component";
import { NEWS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../news-admin-item/news-admin-item.constant";

export function NewsAdminListViewComponent(props) {
  const {
    data: { list: data },
  } = props;

  if (data.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent variant="body1" tid="NEWS.ADMIN.LIST.LIST_IS_EMPTY" />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (newsId) => () =>
    navigate(NEWS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(newsId));

  return (
    <List sx={{ pt: 4 }}>
      {data.map((item) => (
        <ListItem onClick={handleClickItem(item.id)} button variant="list">
          <ListItemText
            primary={
              <TextComponent gutterBottom variant="body1">
                <TextComponent
                  tid="NEWS.ADMIN.LIST.LIST.ID"
                  tvalue={{ id: item.id }}
                />
                <br />
                <TextComponent
                  tid="NEWS.ADMIN.LIST.LIST.ORDER"
                  tvalue={{ order: item.order }}
                />
                <br />
                <TextComponent
                  tid="NEWS.ADMIN.LIST.LIST.STATUS"
                  tvalue={{ status: item.status.text }}
                />
              </TextComponent>
            }
            secondary={
              <TextComponent variant="body1">
                <TextComponent
                  tid="NEWS.ADMIN.LIST.LIST.TITLE"
                  tvalue={{ title: item.title }}
                />
                <br />
                <TextComponent
                  tid="NEWS.ADMIN.LIST.LIST.URL"
                  tvalue={{ url: item.url }}
                />
                <br />
                <TextComponent
                  tid="NEWS.ADMIN.LIST.LIST.CREATE_DATE"
                  tvalue={{ createDate: item.createDate }}
                />
              </TextComponent>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
