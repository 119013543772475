import {
  initRequestState,
  setRequestPending,
  setRequestSuccess,
  setRequestError,
  setRequestUpdatePending,
  setRequestUpdateSuccess,
} from '../../main/store/store.service';
import { PURCHASE_BOOSTER_ITEM_CHAT_LIST_ACTION_TYPE } from './purchase-booster-item-chat-list.constant';

const initialState = {
  request: initRequestState(),
  purchaseId: null,
};

export function purchaseBoosterItemChatListStore(state = initialState, action) {
  switch (action.type) {
    case PURCHASE_BOOSTER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_PENDING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case PURCHASE_BOOSTER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.data),
        purchaseId: action.purchaseId,
      };

    case PURCHASE_BOOSTER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.errorMessage),
      };

    case PURCHASE_BOOSTER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING:
      return {
        ...state,
        request: setRequestUpdatePending(state.request),
      };

    case PURCHASE_BOOSTER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        request: setRequestUpdateSuccess(state.request),
      };

    default:
      return state;
  }
}
