import { PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME } from './purchase-admin-item-chat-create.constant';

import { convertOneFileFieldValue } from '../../lib/common/field-file/field-file.convert';

export const convertPurchaseAdminItemChatCreateFormData = (data) => ({
  [PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.DATA]:
    data[PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.DATA],
  [PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.FILE]: convertOneFileFieldValue(
    data[PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.FILE],
  ),
});
