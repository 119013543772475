import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PurchaseBoosterListComponent } from './purchase-booster-list.component';

import {
  getPurchaseBoosterList,
  updatePurchaseBoosterList,
} from './purchase-booster-list.action';

import {
  PURCHASE_BOOSTER_LIST_STORE_NAME,
  PURCHASE_BOOSTER_LIST_PAGINATION_CRON_UPDATE,
} from './purchase-booster-list.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from '../../main/store/store.service';

export function PurchaseBoosterListContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const [intervalId, setIntervalId] = React.useState(null);

  React.useEffect(() => {
    clearInterval(intervalId);

    dispatch(getPurchaseBoosterList());

    const intId = setInterval(() => {
      dispatch(updatePurchaseBoosterList());
    }, PURCHASE_BOOSTER_LIST_PAGINATION_CRON_UPDATE);

    setIntervalId(intId);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return (
    <PurchaseBoosterListComponent
      isPending={isRequestPending(state.request)}
      isUpdating={isRequestUpdatePending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
