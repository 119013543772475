export const calcCurrentTimeByTimezone = (timezone, local) => {
  let time_now = Date.now();
  time_now = time_now - 3600000 * local + 3600000 * timezone;
  let new_date = new Date(time_now);
  return new_date;
};

export const calcTimezone = () => {
  const date = new Date();
  const timezone = date.getTimezoneOffset() / -60;

  return timezone;
};
