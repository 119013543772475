import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { GdsItemAdminListPaginationComponent } from './gds-item-admin-list-pagination.component';

import {
  GDS_ITEM_ADMIN_LIST_STORE_NAME,
  GDS_ITEM_ADMIN_LIST_DATA_NAME,
} from '../gds-item-admin-list/gds-item-admin-list.constant';

import { getGdsItemAdminList } from '../gds-item-admin-list/gds-item-admin-list.action';
import { getRequestData } from '../../main/store/store.service';

export function GdsItemAdminListPaginationContainer() {
  const dispatch = useDispatch();

  const { state, gdsId } = useSelector((state) => ({
    state: getRequestData(state[GDS_ITEM_ADMIN_LIST_STORE_NAME].request),
    gdsId: state[GDS_ITEM_ADMIN_LIST_STORE_NAME].gdsId,
  }));

  const onChangeFilter = (data) => {
    dispatch(getGdsItemAdminList({ ...state, ...data, gdsId }));
  };

  return (
    <GdsItemAdminListPaginationComponent
      data={state}
      onChangeFilter={onChangeFilter}
    />
  );
}
