export const PURCHASE_ADMIN_ITEM_CHAT_LIST_STORE_NAME =
  "PURCHASE_ADMIN_ITEM_CHAT_LIST";

export const PURCHASE_ADMIN_ITEM_CHAT_LIST_API = {
  PURCHASE_ADMIN_ITEM_CHAT_LIST: {
    ENDPOINT: (purchaseId) => `/chat-message/purchase/${purchaseId}/admin/`,
    TYPE: "GET",
  },
};

export const RELOAD_PURCHASE_ADMIN_ITEM_CHAT_LIST = 60000;

export const PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE = {
  REQUEST_PENDING: "PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const PURCHASE_ADMIN_ITEM_CHAT_LIST_DATA_NAME = {
  ID: "id",
  VIEW: "view",
  DATA: "data",
  TYPE: "type",
  CREATE_DATE: "createDate",

  USER: "user",
  USER_LOGIN: "login",

  FILE: "file",
  FILE_NAME: "name",
  FILE_URL: "url",
};
