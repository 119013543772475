import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  uploadPurchaseAdminItemUpdateCoachingDataForm,
  resetPurchaseAdminItemUpdateCoachingDataFormState,
} from "./purchase-admin-item-update-coaching-data.action";
import {
  PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_STORE_NAME,
  PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_DATA_NAME,
} from "./purchase-admin-item-update-coaching-data.constant";
import { convertPurchaseAdminItemUpdateCoachingDataFormData } from "./purchase-admin-item-update-coaching-data.convert";
import { purchaseAdminItemUpdateCoachingDataFormValidation } from "./purchase-admin-item-update-coaching-data.validation";
import { PurchaseAdminItemUpdateCoachingDataComponent } from "./purchase-admin-item-update-coaching-data.component";
import {
  PURCHASE_ADMIN_ITEM_DATA_NAME,
  PURCHASE_ADMIN_ITEM_STORE_NAME,
} from "../purchase-admin-item/purchase-admin-item.constant";

export function PurchaseAdminItemUpdateCoachingDataContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading, coachingItemState } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_STORE_NAME],
    coachingItemState: state[PURCHASE_ADMIN_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const coachingData = getRequestData(coachingItemState.request)?.[
    PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING
  ];

  const coachingDataSuccess =
    isRequestSuccess(coachingItemState.request) && !!coachingData;

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemUpdateCoachingDataFormState());
  }, []);

  const purchaseAdminItemUpdateCoachingDataFormSendData = (values) => {
    const data = convertPurchaseAdminItemUpdateCoachingDataFormData(values);

    data.purchaseId = purchaseId;
    data.coachingId = coachingData[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING_ID];

    dispatch(uploadPurchaseAdminItemUpdateCoachingDataForm(data));
  };

  const getInitialValue = () => {
    if (coachingDataSuccess) {
      return {
        [PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION]:
          coachingData?.[PURCHASE_ADMIN_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION],
      };
    } else {
      return {
        [PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION]:
          null,
      };
    }
  };

  return (
    <PurchaseAdminItemUpdateCoachingDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemUpdateCoachingDataFormValidation}
      onSubmitForm={purchaseAdminItemUpdateCoachingDataFormSendData}
      pageLoading={pageLoading}
      isCoaching={coachingDataSuccess}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
