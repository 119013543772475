import { validate } from '../../main/validate';

import { PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './promocode-admin-item-update-data.constant';

import {
  required,
  numberPositive,
  numberPositiveMax,
  maxLength,
} from '../../main/validate/validate.service';

const config = {
  [PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.NAME]: [maxLength(20)],
  [PROMOCODE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FACTOR]: [
    numberPositive,
    numberPositiveMax(1),
  ],
};

export const promocodeAdminItemUpdateDataFormValidation = (values) =>
  validate(values, config);
