import { validate } from '../../main/validate';

import { PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_DATA_NAME } from './purchase-admin-item-payment-confirm.constant';

import { required } from '../../main/validate/validate.service';

const config = {
  // [PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_DATA_NAME.ID]: [required],
};

export const purchaseAdminItemPaymentConfirmFormValidation = (values) =>
  validate(values, config);
