import React from "react";
import { useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { NOTICE_ACCOUNT_LIST_ROUTE_PATH } from "../notice-account-list/notice-account-list.constant";
import { NoticeAccountListShortHandler } from "../notice-account-list-short/notice-account-list-short.handler";

export function NavigationNoticeMobileComponent({ noticeAmount }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(NOTICE_ACCOUNT_LIST_ROUTE_PATH);
  };

  return (
    <React.Fragment>
      <NoticeAccountListShortHandler />
      <IconButton
        variant="contained"
        onClick={handleClick}
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        {noticeAmount ? (
          <Badge max={30} badgeContent={noticeAmount} color="primary">
            <NotificationsIcon />
          </Badge>
        ) : (
          <NotificationsNoneIcon />
        )}
      </IconButton>
    </React.Fragment>
  );
}
