import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Grid from '@mui/material/Grid';

import { TextComponent } from '../../../lib/common/text/text.component';
import { FileListComponent } from '../../../lib/common/file-list/file-list.component';
import { GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME } from '../gds-item-admin-item-data.constant';

export function GdsItemAdminItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.ID"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ID]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.ORDER"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ORDER]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.STATUS"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STATUS].text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.BOUGHT"
        />
        <TextComponent variant="dataViewContent">
          {String(data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.BOUGHT])}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.CREATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.UPDATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.DATA"
        />
        <TextComponent sx={{ whiteSpace: 'break-spaces' }} variant="body1">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DATA]}
        </TextComponent>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.PRICE_EN"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.PRICE_EN] || (
            <TextComponent tid="GDS_ITEM.ADMIN.ITEM_DATA.PRICE_EN_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.STOCK_EN"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STOCK_EN] || (
            <TextComponent tid="GDS_ITEM.ADMIN.ITEM_DATA.STOCK_EN_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.TITLE_EN"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.TITLE_EN] || (
            <TextComponent tid="GDS_ITEM.ADMIN.ITEM_DATA.TITLE_EN_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.DESCRIPTION_EN"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_EN] || (
            <TextComponent tid="GDS_ITEM.ADMIN.ITEM_DATA.DESCRIPTION_EN_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.PRICE_RU"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.PRICE_RU] || (
            <TextComponent tid="GDS_ITEM.ADMIN.ITEM_DATA.PRICE_RU_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS.ADMIN.ITEM_DATA.STOCK_RU"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STOCK_RU] || (
            <TextComponent tid="GDS.ADMIN.ITEM_DATA.STOCK_RU_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.TITLE_RU"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.TITLE_RU] || (
            <TextComponent tid="GDS_ITEM.ADMIN.ITEM_DATA.TITLE_RU_NULL" />
          )}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="GDS_ITEM.ADMIN.ITEM_DATA.DESCRIPTION_RU"
        />
        <TextComponent variant="dataViewContent">
          {data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_RU] || (
            <TextComponent tid="GDS_ITEM.ADMIN.ITEM_DATA.DESCRIPTION_RU_NULL" />
          )}
        </TextComponent>
      </Grid>
    </Grid>
  );
}
