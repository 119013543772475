import { text } from '../text/text.core';

import {
  COACHING_ADDITIONAL_VALUE,
  COACHING_TYPE_VALUE,
} from './coaching.type';

export const convertCoachingType = (coachingTypeId) => {
  if (!coachingTypeId) {
    return null;
  }

  return {
    id: coachingTypeId,
    value: COACHING_TYPE_VALUE[coachingTypeId],
    text: text(`COACHING.DATA.TYPE.${COACHING_TYPE_VALUE[coachingTypeId]}`),
  };
};

export const convertCoachingAdditional = (coachingAdditionalId) => {
  if (!coachingAdditionalId) {
    return null;
  }

  return {
    id: coachingAdditionalId,
    value: COACHING_ADDITIONAL_VALUE[coachingAdditionalId],
    text: text(
      `COACHING.DATA.ADDITIONAL.${COACHING_ADDITIONAL_VALUE[coachingAdditionalId]}`,
    ),
  };
};

export const convertCoachingAdditionalList = (arr) =>
  arr ? arr.map(convertCoachingAdditional) : null;
