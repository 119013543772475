export const BOOST_RANK_TYPE = {
  SILVER_1: 'SILVER_1',
  SILVER_2: 'SILVER_2',
  SILVER_3: 'SILVER_3',
  SILVER_4: 'SILVER_4',
  SILVER_ELITE: 'SILVER_ELITE',
  SILVER_ELITE_MASTER: 'SILVER_ELITE_MASTER',
  GOLD_NOVA_1: 'GOLD_NOVA_1',
  GOLD_NOVA_2: 'GOLD_NOVA_2',
  GOLD_NOVA_3: 'GOLD_NOVA_3',
  GOLD_NOVA_MASTER: 'GOLD_NOVA_MASTER',
  MASTER_GUADIAN_1: 'MASTER_GUADIAN_1',
  MASTER_GUADIAN_2: 'MASTER_GUADIAN_2',
  MASTER_GUADIAN_ELITE: 'MASTER_GUADIAN_ELITE',
  BIG_STAR: 'BIG_STAR',
  LE: 'LE',
  LEM: 'LEM',
  SUPRIME: 'SUPRIME',
  GLOBAL_ELITE: 'GLOBAL_ELITE',

  D_PLUS: 'D_PLUS',
  D: 'D',
  D_MINUS: 'D_MINUS',
  C_PLUS: 'C_PLUS',
  C: 'C',
  C_MINUS: 'C_MINUS',
  B_PLUS: 'B_PLUS',
  B: 'B',
  B_MINUS: 'B_MINUS',
  A_PLUS: 'A_PLUS',
  A: 'A',
  A_MINUS: 'A_MINUS',
  G: 'G',
  S: 'S',

  ELO_1: 'ELO_1',
  ELO_2: 'ELO_2',
  ELO_3: 'ELO_3',
  ELO_4: 'ELO_4',
  ELO_5: 'ELO_5',
  ELO_6: 'ELO_6',
  ELO_7: 'ELO_7',
  ELO_8: 'ELO_8',
  ELO_9: 'ELO_9',
  ELO_10: 'ELO_10',

  WIN_1: 'WIN_1',
  WIN_2: 'WIN_2',
  WIN_3: 'WIN_3',
  WIN_4: 'WIN_4',
  WIN_5: 'WIN_5',

  UNRANKED: 'UNRANKED',

  MMR_1: 'MMR_1',
  MMR_2: 'MMR_2',
  MMR_3: 'MMR_3',
  MMR_4: 'MMR_4',
  MMR_5: 'MMR_5',
  MMR_6: 'MMR_6',
  MMR_7: 'MMR_7',
  MMR_8: 'MMR_8',
  MMR_9: 'MMR_9',
  MMR_10: 'MMR_10',
  MMR_11: 'MMR_11',
  MMR_12: 'MMR_12',
  MMR_13: 'MMR_13',
  MMR_14: 'MMR_14',

  LOL_IRON: 'LOL_IRON',
  LOL_BRONZE: 'LOL_BRONZE',
  LOL_SILVER: 'LOL_SILVER',
  LOL_GOLD: 'LOL_GOLD',
  LOL_PLATINUM: 'LOL_PLATINUM',
  LOL_DIAMOND: 'LOL_DIAMOND',
  LOL_MASTER: 'LOL_MASTER',
  LOL_CHALLENGER: 'LOL_CHALLENGER',
  LOL_DIVISION_1: 'LOL_DIVISION_1',
  LOL_DIVISION_2: 'LOL_DIVISION_2',
  LOL_DIVISION_3: 'LOL_DIVISION_3',
  LOL_DIVISION_4: 'LOL_DIVISION_4',
  APEX_UNRANKED: 'APEX_UNRANKED',
  APEX_ROOKIE: 'APEX_ROOKIE',
  APEX_BRONZE: 'APEX_BRONZE',
  APEX_SILVER: 'APEX_SILVER',
  APEX_GOLD: 'APEX_GOLD',
  APEX_PLATINUM: 'APEX_PLATINUM',
  APEX_DIAMOND: 'APEX_DIAMOND',
  APEX_MASTER: 'APEX_MASTER',
  APEX_PREDATOR: 'APEX_PREDATOR',
  VALORANT_DIVISION_1: 'VALORANT_DIVISION_1',
  VALORANT_DIVISION_2: 'VALORANT_DIVISION_2',
  VALORANT_DIVISION_3: 'VALORANT_DIVISION_3',
  VALORANT_UNRANKED: 'VALORANT_UNRANKED',
  VALORANT_IRON_1: 'VALORANT_IRON_1',
  VALORANT_IRON_2: 'VALORANT_IRON_2',
  VALORANT_IRON_3: 'VALORANT_IRON_3',
  VALORANT_BRONZE_1: 'VALORANT_BRONZE_1',
  VALORANT_BRONZE_2: 'VALORANT_BRONZE_2',
  VALORANT_BRONZE_3: 'VALORANT_BRONZE_3',
  VALORANT_SILVER_1: 'VALORANT_SILVER_1',
  VALORANT_SILVER_2: 'VALORANT_SILVER_2',
  VALORANT_SILVER_3: 'VALORANT_SILVER_3',
  VALORANT_GOLD_1: 'VALORANT_GOLD_1',
  VALORANT_GOLD_2: 'VALORANT_GOLD_2',
  VALORANT_GOLD_3: 'VALORANT_GOLD_3',
  VALORANT_PLATINUM_1: 'VALORANT_PLATINUM_1',
  VALORANT_PLATINUM_2: 'VALORANT_PLATINUM_2',
  VALORANT_PLATINUM_3: 'VALORANT_PLATINUM_3',
  VALORANT_DIAMOND_1: 'VALORANT_DIAMOND_1',
  VALORANT_DIAMOND_2: 'VALORANT_DIAMOND_2',
  VALORANT_DIAMOND_3: 'VALORANT_DIAMOND_3',
  VALORANT_ASCENDANT_1: 'VALORANT_ASCENDANT_1',
  VALORANT_ASCENDANT_2: 'VALORANT_ASCENDANT_2',
  VALORANT_ASCENDANT_3: 'VALORANT_ASCENDANT_3',
  VALORANT_IMMORTAL_1: 'VALORANT_IMMORTAL_1',
  VALORANT_IMMORTAL_2: 'VALORANT_IMMORTAL_2',
  VALORANT_IMMORTAL_3: 'VALORANT_IMMORTAL_3',
  VALORANT_RADIANT: 'VALORANT_RADIANT',
  TFT_UNRANKED: 'TFT_UNRANKED',
  TFT_IRON_1: 'TFT_IRON_1',
  TFT_IRON_2: 'TFT_IRON_2',
  TFT_IRON_3: 'TFT_IRON_3',
  TFT_IRON_4: 'TFT_IRON_4',
  TFT_BRONZE_1: 'TFT_BRONZE_1',
  TFT_BRONZE_2: 'TFT_BRONZE_2',
  TFT_BRONZE_3: 'TFT_BRONZE_3',
  TFT_BRONZE_4: 'TFT_BRONZE_4',
  TFT_SILVER_1: 'TFT_SILVER_1',
  TFT_SILVER_2: 'TFT_SILVER_2',
  TFT_SILVER_3: 'TFT_SILVER_3',
  TFT_SILVER_4: 'TFT_SILVER_4',
  TFT_GOLD_1: 'TFT_GOLD_1',
  TFT_GOLD_2: 'TFT_GOLD_2',
  TFT_GOLD_3: 'TFT_GOLD_3',
  TFT_GOLD_4: 'TFT_GOLD_4',
  TFT_PLATINUM_1: 'TFT_PLATINUM_1',
  TFT_PLATINUM_2: 'TFT_PLATINUM_2',
  TFT_PLATINUM_3: 'TFT_PLATINUM_3',
  TFT_PLATINUM_4: 'TFT_PLATINUM_4',
  TFT_EMERALD_1: 'TFT_EMERALD_1',
  TFT_EMERALD_2: 'TFT_EMERALD_2',
  TFT_EMERALD_3: 'TFT_EMERALD_3',
  TFT_EMERALD_4: 'TFT_EMERALD_4',
  TFT_DIAMOND_1: 'TFT_DIAMOND_1',
  TFT_DIAMOND_2: 'TFT_DIAMOND_2',
  TFT_DIAMOND_3: 'TFT_DIAMOND_3',
  TFT_DIAMOND_4: 'TFT_DIAMOND_4',
  TFT_MASTER: 'TFT_MASTER',
  TFT_GRANDMASTER: 'TFT_GRANDMASTER',
  TFT_CHALLENGER: 'TFT_CHALLENGER',
  WILD_RIFT_UNRANKED: 'WILD_RIFT_UNRANKED',
  WILD_RIFT_IRON_1: 'WILD_RIFT_IRON_1',
  WILD_RIFT_IRON_2: 'WILD_RIFT_IRON_2',
  WILD_RIFT_IRON_3: 'WILD_RIFT_IRON_3',
  WILD_RIFT_IRON_4: 'WILD_RIFT_IRON_4',
  WILD_RIFT_BRONZE_1: 'WILD_RIFT_BRONZE_1',
  WILD_RIFT_BRONZE_2: 'WILD_RIFT_BRONZE_2',
  WILD_RIFT_BRONZE_3: 'WILD_RIFT_BRONZE_3',
  WILD_RIFT_BRONZE_4: 'WILD_RIFT_BRONZE_4',
  WILD_RIFT_SILVER_1: 'WILD_RIFT_SILVER_1',
  WILD_RIFT_SILVER_2: 'WILD_RIFT_SILVER_2',
  WILD_RIFT_SILVER_3: 'WILD_RIFT_SILVER_3',
  WILD_RIFT_SILVER_4: 'WILD_RIFT_SILVER_4',
  WILD_RIFT_GOLD_1: 'WILD_RIFT_GOLD_1',
  WILD_RIFT_GOLD_2: 'WILD_RIFT_GOLD_2',
  WILD_RIFT_GOLD_3: 'WILD_RIFT_GOLD_3',
  WILD_RIFT_GOLD_4: 'WILD_RIFT_GOLD_4',
  WILD_RIFT_PLATINUM_1: 'WILD_RIFT_PLATINUM_1',
  WILD_RIFT_PLATINUM_2: 'WILD_RIFT_PLATINUM_2',
  WILD_RIFT_PLATINUM_3: 'WILD_RIFT_PLATINUM_3',
  WILD_RIFT_PLATINUM_4: 'WILD_RIFT_PLATINUM_4',
  WILD_RIFT_EMERALD_1: 'WILD_RIFT_EMERALD_1',
  WILD_RIFT_EMERALD_2: 'WILD_RIFT_EMERALD_2',
  WILD_RIFT_EMERALD_3: 'WILD_RIFT_EMERALD_3',
  WILD_RIFT_EMERALD_4: 'WILD_RIFT_EMERALD_4',
  WILD_RIFT_DIAMOND_1: 'WILD_RIFT_DIAMOND_1',
  WILD_RIFT_DIAMOND_2: 'WILD_RIFT_DIAMOND_2',
  WILD_RIFT_DIAMOND_3: 'WILD_RIFT_DIAMOND_3',
  WILD_RIFT_DIAMOND_4: 'WILD_RIFT_DIAMOND_4',
  WILD_RIFT_MASTER: 'WILD_RIFT_MASTER',
  WILD_RIFT_GRANDMASTER: 'WILD_RIFT_GRANDMASTER',
  WILD_RIFT_CHALLENGER: 'WILD_RIFT_CHALLENGER',
  LOL_UNRANKED: 'LOL_UNRANKED',
  LOL_IRON_1: 'LOL_IRON_1',
  LOL_IRON_2: 'LOL_IRON_2',
  LOL_IRON_3: 'LOL_IRON_3',
  LOL_IRON_4: 'LOL_IRON_4',
  LOL_BRONZE_1: 'LOL_BRONZE_1',
  LOL_BRONZE_2: 'LOL_BRONZE_2',
  LOL_BRONZE_3: 'LOL_BRONZE_3',
  LOL_BRONZE_4: 'LOL_BRONZE_4',
  LOL_SILVER_1: 'LOL_SILVER_1',
  LOL_SILVER_2: 'LOL_SILVER_2',
  LOL_SILVER_3: 'LOL_SILVER_3',
  LOL_SILVER_4: 'LOL_SILVER_4',
  LOL_GOLD_1: 'LOL_GOLD_1',
  LOL_GOLD_2: 'LOL_GOLD_2',
  LOL_GOLD_3: 'LOL_GOLD_3',
  LOL_GOLD_4: 'LOL_GOLD_4',
  LOL_PLATINUM_1: 'LOL_PLATINUM_1',
  LOL_PLATINUM_2: 'LOL_PLATINUM_2',
  LOL_PLATINUM_3: 'LOL_PLATINUM_3',
  LOL_PLATINUM_4: 'LOL_PLATINUM_4',
  LOL_DIAMOND_1: 'LOL_DIAMOND_1',
  LOL_DIAMOND_2: 'LOL_DIAMOND_2',
  LOL_DIAMOND_3: 'LOL_DIAMOND_3',
  LOL_DIAMOND_4: 'LOL_DIAMOND_4',
  LOL_MASTER_1: 'LOL_MASTER_1',
  LOL_MASTER_2: 'LOL_MASTER_2',
  LOL_MASTER_3: 'LOL_MASTER_3',
  LOL_MASTER_4: 'LOL_MASTER_4',
  LOL_GRANDMASTER: 'LOL_GRANDMASTER',
  TIER_1: 'TIER_1',
  TIER_2: 'TIER_2',
  TIER_3: 'TIER_3',
  TIER_4: 'TIER_4',
  TIER_5: 'TIER_5',
  TIER_6_1: 'TIER_6_1',
  TIER_6_2: 'TIER_6_2',
  TIER_7_1: 'TIER_7_1',
  TIER_7_2: 'TIER_7_2',
  TIER_7_3: 'TIER_7_3',
  MASTERY_POINT: 'MASTERY_POINT',
  MASTERY_POINT_1: 'MASTERY_POINT_1',
  MASTERY_POINT_2: '2K',
  MASTERY_POINT_5: '5K',
  MASTERY_POINT_10: '10K',
  MASTERY_POINT_20: '20K',
  MASTERY_POINT_50: '50K',

  DOTA_UNRANKED: 'Unranked',

  DOTA_HERALD: 'Herald',
  DOTA_GUARDIAN: 'Guardian',
  DOTA_CRUSADER: 'Crusader',
  DOTA_ARCHOR: 'Archer',
  DOTA_LEGEND: 'Legend',
  DOTA_ANCIENT: 'Ancient',
  DOTA_DIVINE: 'Divine',
  DOTA_IMMORTAL: 'Immortal',
  SILVER: 'Silver',
  GOLD_1: 'Gold I',
  GOLD_2: 'Gold II',
  VETERAN_1: 'Veteran I',
  VETERAN_2: 'Veteran II',
  MASTER_1: 'Master I',
  MASTER_2: 'Master II',
  ELITE_1: 'Elite I',
  ELITE_2: 'Elite II',
  PRO_1: 'Pro I',
  PRO_2: 'Pro II',
  LEGEND: 'Legend',
};

export const BOOST_RANK_VALUE = {
  SILVER_1: 'SILVER_1',
  SILVER_2: 'SILVER_2',
  SILVER_3: 'SILVER_3',
  SILVER_4: 'SILVER_4',
  SILVER_ELITE: 'SILVER_ELITE',
  SILVER_ELITE_MASTER: 'SILVER_ELITE_MASTER',
  GOLD_NOVA_1: 'GOLD_NOVA_1',
  GOLD_NOVA_2: 'GOLD_NOVA_2',
  GOLD_NOVA_3: 'GOLD_NOVA_3',
  GOLD_NOVA_MASTER: 'GOLD_NOVA_MASTER',
  MASTER_GUADIAN_1: 'MASTER_GUADIAN_1',
  MASTER_GUADIAN_2: 'MASTER_GUADIAN_2',
  MASTER_GUADIAN_ELITE: 'MASTER_GUADIAN_ELITE',
  BIG_STAR: 'BIG_STAR',
  LE: 'LE',
  LEM: 'LEM',
  SUPRIME: 'SUPRIME',
  GLOBAL_ELITE: 'GLOBAL_ELITE',

  D_PLUS: 'D_PLUS',
  D: 'D',
  D_MINUS: 'D_MINUS',
  C_PLUS: 'C_PLUS',
  C: 'C',
  C_MINUS: 'C_MINUS',
  B_PLUS: 'B_PLUS',
  B: 'B',
  B_MINUS: 'B_MINUS',
  A_PLUS: 'A_PLUS',
  A: 'A',
  A_MINUS: 'A_MINUS',

  ELO_1: 'ELO_1',
  ELO_2: 'ELO_2',
  ELO_3: 'ELO_3',
  ELO_4: 'ELO_4',
  ELO_5: 'ELO_5',
  ELO_6: 'ELO_6',
  ELO_7: 'ELO_7',
  ELO_8: 'ELO_8',
  ELO_9: 'ELO_9',
  ELO_10: 'ELO_10',

  WIN_1: 'WIN_1',
  WIN_2: 'WIN_2',
  WIN_3: 'WIN_3',
  WIN_4: 'WIN_4',
  WIN_5: 'WIN_5',

  UNRANKED: 'UNRANKED',

  MMR_1: 'MMR_1',
  MMR_2: 'MMR_2',
  MMR_3: 'MMR_3',
  MMR_4: 'MMR_4',
  MMR_5: 'MMR_5',
  MMR_6: 'MMR_6',
  MMR_7: 'MMR_7',
  MMR_8: 'MMR_8',
  MMR_9: 'MMR_9',
  MMR_10: 'MMR_10',
  MMR_11: 'MMR_11',
  MMR_12: 'MMR_12',
  MMR_13: 'MMR_13',
  MMR_14: 'MMR_14',

  LOL_IRON: 'LOL_IRON',
  LOL_BRONZE: 'LOL_BRONZE',
  LOL_SILVER: 'LOL_SILVER',
  LOL_GOLD: 'LOL_GOLD',
  LOL_PLATINUM: 'LOL_PLATINUM',
  LOL_DIAMOND: 'LOL_DIAMOND',
  LOL_MASTER: 'LOL_MASTER',
  LOL_CHALLENGER: 'LOL_CHALLENGER',
  LOL_DIVISION_1: 'LOL_DIVISION_1',
  LOL_DIVISION_2: 'LOL_DIVISION_2',
  LOL_DIVISION_3: 'LOL_DIVISION_3',
  LOL_DIVISION_4: 'LOL_DIVISION_4',
  APEX_UNRANKED: 'APEX_UNRANKED',
  APEX_ROOKIE: 'APEX_ROOKIE',
  APEX_BRONZE: 'APEX_BRONZE',
  APEX_SILVER: 'APEX_SILVER',
  APEX_GOLD: 'APEX_GOLD',
  APEX_PLATINUM: 'APEX_PLATINUM',
  APEX_DIAMOND: 'APEX_DIAMOND',
  APEX_MASTER: 'APEX_MASTER',
  APEX_PREDATOR: 'APEX_PREDATOR',
  VALORANT_DIVISION_1: 'VALORANT_DIVISION_1',
  VALORANT_DIVISION_2: 'VALORANT_DIVISION_2',
  VALORANT_DIVISION_3: 'VALORANT_DIVISION_3',
  VALORANT_UNRANKED: 'VALORANT_UNRANKED',
  VALORANT_IRON_1: 'VALORANT_IRON_1',
  VALORANT_IRON_2: 'VALORANT_IRON_2',
  VALORANT_IRON_3: 'VALORANT_IRON_3',
  VALORANT_BRONZE_1: 'VALORANT_BRONZE_1',
  VALORANT_BRONZE_2: 'VALORANT_BRONZE_2',
  VALORANT_BRONZE_3: 'VALORANT_BRONZE_3',
  VALORANT_SILVER_1: 'VALORANT_SILVER_1',
  VALORANT_SILVER_2: 'VALORANT_SILVER_2',
  VALORANT_SILVER_3: 'VALORANT_SILVER_3',
  VALORANT_GOLD_1: 'VALORANT_GOLD_1',
  VALORANT_GOLD_2: 'VALORANT_GOLD_2',
  VALORANT_GOLD_3: 'VALORANT_GOLD_3',
  VALORANT_PLATINUM_1: 'VALORANT_PLATINUM_1',
  VALORANT_PLATINUM_2: 'VALORANT_PLATINUM_2',
  VALORANT_PLATINUM_3: 'VALORANT_PLATINUM_3',
  VALORANT_DIAMOND_1: 'VALORANT_DIAMOND_1',
  VALORANT_DIAMOND_2: 'VALORANT_DIAMOND_2',
  VALORANT_DIAMOND_3: 'VALORANT_DIAMOND_3',
  VALORANT_ASCENDANT_1: 'VALORANT_ASCENDANT_1',
  VALORANT_ASCENDANT_2: 'VALORANT_ASCENDANT_2',
  VALORANT_ASCENDANT_3: 'VALORANT_ASCENDANT_3',
  VALORANT_IMMORTAL_1: 'VALORANT_IMMORTAL_1',
  VALORANT_IMMORTAL_2: 'VALORANT_IMMORTAL_2',
  VALORANT_IMMORTAL_3: 'VALORANT_IMMORTAL_3',
  VALORANT_RADIANT: 'VALORANT_RADIANT',
  TFT_UNRANKED: 'TFT_UNRANKED',
  TFT_IRON_1: 'TFT_IRON_1',
  TFT_IRON_2: 'TFT_IRON_2',
  TFT_IRON_3: 'TFT_IRON_3',
  TFT_IRON_4: 'TFT_IRON_4',
  TFT_BRONZE_1: 'TFT_BRONZE_1',
  TFT_BRONZE_2: 'TFT_BRONZE_2',
  TFT_BRONZE_3: 'TFT_BRONZE_3',
  TFT_BRONZE_4: 'TFT_BRONZE_4',
  TFT_SILVER_1: 'TFT_SILVER_1',
  TFT_SILVER_2: 'TFT_SILVER_2',
  TFT_SILVER_3: 'TFT_SILVER_3',
  TFT_SILVER_4: 'TFT_SILVER_4',
  TFT_GOLD_1: 'TFT_GOLD_1',
  TFT_GOLD_2: 'TFT_GOLD_2',
  TFT_GOLD_3: 'TFT_GOLD_3',
  TFT_GOLD_4: 'TFT_GOLD_4',
  TFT_PLATINUM_1: 'TFT_PLATINUM_1',
  TFT_PLATINUM_2: 'TFT_PLATINUM_2',
  TFT_PLATINUM_3: 'TFT_PLATINUM_3',
  TFT_PLATINUM_4: 'TFT_PLATINUM_4',
  TFT_EMERALD_1: 'TFT_EMERALD_1',
  TFT_EMERALD_2: 'TFT_EMERALD_2',
  TFT_EMERALD_3: 'TFT_EMERALD_3',
  TFT_EMERALD_4: 'TFT_EMERALD_4',
  TFT_DIAMOND_1: 'TFT_DIAMOND_1',
  TFT_DIAMOND_2: 'TFT_DIAMOND_2',
  TFT_DIAMOND_3: 'TFT_DIAMOND_3',
  TFT_DIAMOND_4: 'TFT_DIAMOND_4',
  TFT_MASTER: 'TFT_MASTER',
  TFT_GRANDMASTER: 'TFT_GRANDMASTER',
  TFT_CHALLENGER: 'TFT_CHALLENGER',
  WILD_RIFT_UNRANKED: 'WILD_RIFT_UNRANKED',
  WILD_RIFT_IRON_1: 'WILD_RIFT_IRON_1',
  WILD_RIFT_IRON_2: 'WILD_RIFT_IRON_2',
  WILD_RIFT_IRON_3: 'WILD_RIFT_IRON_3',
  WILD_RIFT_IRON_4: 'WILD_RIFT_IRON_4',
  WILD_RIFT_BRONZE_1: 'WILD_RIFT_BRONZE_1',
  WILD_RIFT_BRONZE_2: 'WILD_RIFT_BRONZE_2',
  WILD_RIFT_BRONZE_3: 'WILD_RIFT_BRONZE_3',
  WILD_RIFT_BRONZE_4: 'WILD_RIFT_BRONZE_4',
  WILD_RIFT_SILVER_1: 'WILD_RIFT_SILVER_1',
  WILD_RIFT_SILVER_2: 'WILD_RIFT_SILVER_2',
  WILD_RIFT_SILVER_3: 'WILD_RIFT_SILVER_3',
  WILD_RIFT_SILVER_4: 'WILD_RIFT_SILVER_4',
  WILD_RIFT_GOLD_1: 'WILD_RIFT_GOLD_1',
  WILD_RIFT_GOLD_2: 'WILD_RIFT_GOLD_2',
  WILD_RIFT_GOLD_3: 'WILD_RIFT_GOLD_3',
  WILD_RIFT_GOLD_4: 'WILD_RIFT_GOLD_4',
  WILD_RIFT_PLATINUM_1: 'WILD_RIFT_PLATINUM_1',
  WILD_RIFT_PLATINUM_2: 'WILD_RIFT_PLATINUM_2',
  WILD_RIFT_PLATINUM_3: 'WILD_RIFT_PLATINUM_3',
  WILD_RIFT_PLATINUM_4: 'WILD_RIFT_PLATINUM_4',
  WILD_RIFT_EMERALD_1: 'WILD_RIFT_EMERALD_1',
  WILD_RIFT_EMERALD_2: 'WILD_RIFT_EMERALD_2',
  WILD_RIFT_EMERALD_3: 'WILD_RIFT_EMERALD_3',
  WILD_RIFT_EMERALD_4: 'WILD_RIFT_EMERALD_4',
  WILD_RIFT_DIAMOND_1: 'WILD_RIFT_DIAMOND_1',
  WILD_RIFT_DIAMOND_2: 'WILD_RIFT_DIAMOND_2',
  WILD_RIFT_DIAMOND_3: 'WILD_RIFT_DIAMOND_3',
  WILD_RIFT_DIAMOND_4: 'WILD_RIFT_DIAMOND_4',
  WILD_RIFT_MASTER: 'WILD_RIFT_MASTER',
  WILD_RIFT_GRANDMASTER: 'WILD_RIFT_GRANDMASTER',
  WILD_RIFT_CHALLENGER: 'WILD_RIFT_CHALLENGER',
  LOL_UNRANKED: 'LOL_UNRANKED',
  LOL_IRON_1: 'LOL_IRON_1',
  LOL_IRON_2: 'LOL_IRON_2',
  LOL_IRON_3: 'LOL_IRON_3',
  LOL_IRON_4: 'LOL_IRON_4',
  LOL_BRONZE_1: 'LOL_BRONZE_1',
  LOL_BRONZE_2: 'LOL_BRONZE_2',
  LOL_BRONZE_3: 'LOL_BRONZE_3',
  LOL_BRONZE_4: 'LOL_BRONZE_4',
  LOL_SILVER_1: 'LOL_SILVER_1',
  LOL_SILVER_2: 'LOL_SILVER_2',
  LOL_SILVER_3: 'LOL_SILVER_3',
  LOL_SILVER_4: 'LOL_SILVER_4',
  LOL_GOLD_1: 'LOL_GOLD_1',
  LOL_GOLD_2: 'LOL_GOLD_2',
  LOL_GOLD_3: 'LOL_GOLD_3',
  LOL_GOLD_4: 'LOL_GOLD_4',
  LOL_PLATINUM_1: 'LOL_PLATINUM_1',
  LOL_PLATINUM_2: 'LOL_PLATINUM_2',
  LOL_PLATINUM_3: 'LOL_PLATINUM_3',
  LOL_PLATINUM_4: 'LOL_PLATINUM_4',
  LOL_DIAMOND_1: 'LOL_DIAMOND_1',
  LOL_DIAMOND_2: 'LOL_DIAMOND_2',
  LOL_DIAMOND_3: 'LOL_DIAMOND_3',
  LOL_DIAMOND_4: 'LOL_DIAMOND_4',
  LOL_MASTER_1: 'LOL_MASTER_1',
  LOL_MASTER_2: 'LOL_MASTER_2',
  LOL_MASTER_3: 'LOL_MASTER_3',
  LOL_MASTER_4: 'LOL_MASTER_4',
  LOL_GRANDMASTER: 'LOL_GRANDMASTER',
  TIER_1: 'TIER_1',
  TIER_2: 'TIER_2',
  TIER_3: 'TIER_3',
  TIER_4: 'TIER_4',
  TIER_5: 'TIER_5',
  TIER_6_1: 'TIER_6_1',
  TIER_6_2: 'TIER_6_2',
  TIER_7_1: 'TIER_7_1',
  TIER_7_2: 'TIER_7_2',
  TIER_7_3: 'TIER_7_3',
  MASTERY_POINT: 'MASTERY_POINT',
  MASTERY_POINT_1: 'MASTERY_POINT_1',
  MASTERY_POINT_2: '2K',
  MASTERY_POINT_5: '5K',
  MASTERY_POINT_10: '10K',
  MASTERY_POINT_20: '20K',
  MASTERY_POINT_50: '50K',

  DOTA_UNRANKED: 'Unranked',
  PREMIER_UNRANKED: 'Unranked',

  DOTA_HERALD: 'Herald',
  DOTA_GUARDIAN: 'Guardian',
  DOTA_CRUSADER: 'Crusader',
  DOTA_ARCHOR: 'Archer',
  DOTA_LEGEND: 'Legend',
  DOTA_ANCIENT: 'Ancient',
  DOTA_DIVINE: 'Divine',
  DOTA_IMMORTAL: 'Immortal',
  SILVER: 'Silver',
  GOLD_1: 'Gold I',
  GOLD_2: 'Gold II',
  VETERAN_1: 'Veteran I',
  VETERAN_2: 'Veteran II',
  MASTER_1: 'Master I',
  MASTER_2: 'Master II',
  ELITE_1: 'Elite I',
  ELITE_2: 'Elite II',
  PRO_1: 'Pro I',
  PRO_2: 'Pro II',
  LEGEND: 'Legend',
};

export const BOOST_ADDITIONAL_VALUE = {
  NEED_HOURS: 'NEED_HOURS',
  STREAMING: 'STREAMING',
  PRIORITY: 'PRIORITY',
  LOBBY_BOOST: 'LOBBY_BOOST',
  PREMIUM: 'PREMIUM',
  NEED_LEVEL: 'NEED_LEVEL',
  STEAM_OFFLINE_MODE: 'STEAM_OFFLINE_MODE',
  PLUS_WIN: 'PLUS_WIN',
  SPECIFIC_CHAMP: 'SPECIFIC_CHAMP',
  SPECIFIC_ROLE: 'SPECIFIC_ROLE',
  SPECIFIC_AGENT: 'SPECIFIC_AGENT',
  DUO: 'DUO',
  OFFLINE_CHAT: 'OFFLINE_CHAT',
  AGENT: 'AGENT',
  PLAY_BOOSTER: 'PLAY_BOOSTER',
  DUO_WITH_BOOSTER: 'DUO_WITH_BOOSTER',
  SOLO: 'SOLO',
  SPECIFIC_CHAMP_ROLE: 'SPECIFIC_CHAMP_ROLE',
  PREMIUM_COACHING: 'PREMIUM_COACHING',
  PLUS_BOOSTER: 'PLUS_BOOSTER',
  SHARE_SCREEN: 'SHARE_SCREEN',
  SPECIFIC_LEGEND: 'SPECIFIC_LEGEND',
  SPECIFIC_HERO_ROLE: 'SPECIFIC_HERO_ROLE',
  OFFLINE_MODE: 'OFFLINE_MODE',
  EXPRESS: 'EXPRESS',
  DOUBLE: 'DOUBLE',
};

export const BOOST_TYPE_VALUE = {
  CSGO_BOOST_TYPE_RANK: 'CSGO_BOOST_TYPE_RANK',
  CSGO_BOOST_TYPE_WIN: 'CSGO_BOOST_TYPE_WIN',
  CSGO_BOOST_TYPE_PLACEMENT: 'CSGO_BOOST_TYPE_PLACEMENT',
  CSGO_BOOST_TYPE_PREMIER_PLACEMENT: 'CSGO_BOOST_TYPE_PREMIER_PLACEMENT',
  CSGO_BOOST_TYPE_PREMIER: 'CSGO_BOOST_TYPE_PREMIER',
  FACEIT_BOOST_TYPE_ELO: 'FACEIT_BOOST_TYPE_ELO',
  FACEIT_BOOST_TYPE_WIN: 'FACEIT_BOOST_TYPE_WIN',
  FACEIT_BOOST_TYPE_PLACEMENT: 'FACEIT_BOOST_TYPE_PLACEMENT',
  FACEIT_BOOST_TYPE_LEVEL: 'FACEIT_BOOST_TYPE_LEVEL',
  ESEA_BOOST_TYPE_RANK: 'ESEA_BOOST_TYPE_RANK',
  ESEA_BOOST_TYPE_WIN: 'ESEA_BOOST_TYPE_WIN',
  ESEA_BOOST_TYPE_PLACEMENT: 'ESEA_BOOST_TYPE_PLACEMENT',
  WINGAME_BOOST_TYPE_RANK: 'WINGAME_BOOST_TYPE_RANK',
  WINGAME_BOOST_TYPE_WIN: 'WINGAME_BOOST_TYPE_WIN',
  ESEA_BOOST_TYPE_MMR: 'ESEA_BOOST_TYPE_MMR',
  WINGAME_BOOST_TYPE_PLACEMENT: 'WINGAME_BOOST_TYPE_PLACEMENT',
  LOL_BOOST_TYPE_RANK: 'LOL_BOOST_TYPE_RANK',
  LOL_BOOST_TYPE_WIN: 'LOL_BOOST_TYPE_WIN',
  LOL_BOOST_TYPE_PLACEMENT: 'LOL_BOOST_TYPE_PLACEMENT',
  LOL_BOOST_TYPE_NORMALS: 'LOL_BOOST_TYPE_NORMALS',
  LOL_BOOST_TYPE_CHAMPION_MASTERY: 'LOL_BOOST_TYPE_CHAMPION_MASTERY',
  LOL_BOOST_TYPE_CLASH_BOOST: 'LOL_BOOST_TYPE_CLASH_BOOST',

  VALORANT_BOOST_TYPE_RANK: 'VALORANT_BOOST_TYPE_RANK',
  VALORANT_BOOST_TYPE_WIN: 'VALORANT_BOOST_TYPE_WIN',
  VALORANT_BOOST_TYPE_PLACEMENT: 'VALORANT_BOOST_TYPE_PLACEMENT',
  VALORANT_BOOST_TYPE_NORMAL_WIN: 'VALORANT_BOOST_TYPE_NORMAL_WIN',
  VALORANT_BOOST_TYPE_CHALLENGES: 'VALORANT_BOOST_TYPE_CHALLENGES',

  TFT_BOOST_TYPE_RANK: 'TFT_BOOST_TYPE_RANK',
  TFT_BOOST_TYPE_WIN: 'TFT_BOOST_TYPE_WIN',
  TFT_BOOST_TYPE_HYPER_ROLL: 'TFT_BOOST_TYPE_HYPER_ROLL',
  TFT_BOOST_TYPE_DOUBLE_UP: 'TFT_BOOST_TYPE_DOUBLE_UP',
  TFT_BOOST_TYPE_PLACEMENT: 'TFT_BOOST_TYPE_PLACEMENT',

  WILD_RIFT_BOOST_TYPE_RANK: 'WILD_RIFT_BOOST_TYPE_RANK',
  WILD_RIFT_BOOST_TYPE_WIN: 'WILD_RIFT_BOOST_TYPE_WIN',
  WILD_RIFT_BOOST_TYPE_PLACEMENT: 'WILD_RIFT_BOOST_TYPE_PLACEMENT',
  WILD_RIFT_BOOST_TYPE_NORMAL_WIN: 'WILD_RIFT_BOOST_TYPE_NORMAL_WIN',
  WILD_RIFT_BOOST_TYPE_LEGENDARY_RANK: 'WILD_RIFT_BOOST_TYPE_LEGENDARY_RANK',

  DOTA_BOOST_TYPE_MMR: 'DOTA_BOOST_TYPE_MMR',
  DOTA_BOOST_TYPE_WIN: 'DOTA_BOOST_TYPE_WIN',
  DOTA_BOOST_TYPE_PLACEMENT: 'DOTA_BOOST_TYPE_PLACEMENT',
  DOTA_BOOST_TYPE_NORMAL_BEHAVIOUR: 'DOTA_BOOST_TYPE_NORMAL_BEHAVIOUR',
  DOTA_BOOST_TYPE_LOW_PRIORITY: 'DOTA_BOOST_TYPE_LOW_PRIORITY',

  ESPORTAL_BOOST_TYPE_RANK: 'ESPORTAL_BOOST_TYPE_RANK',
  ESPORTAL_BOOST_TYPE_WIN: 'ESPORTAL_BOOST_TYPE_WIN',
  ESPORTAL_BOOST_TYPE_PLACEMENT: 'ESPORTAL_BOOST_TYPE_PLACEMENT',
  APEX_BOOST_TYPE_RANK: 'APEX_BOOST_TYPE_RANK',
  APEX_BOOST_TYPE_WIN: 'APEX_BOOST_TYPE_WIN',
  APEX_BOOST_TYPE_PLACEMENT: 'APEX_BOOST_TYPE_PLACEMENT',
  APEX_BOOST_TYPE_NORMAL_KILL: 'APEX_BOOST_TYPE_NORMAL_KILL',
  APEX_BOOST_TYPE_BADGE: 'APEX_BOOST_TYPE_BADGE',

  FORTNITE_BOOST_TYPE_RANK: 'FORTNITE_BOOST_TYPE_RANK',
  FORTNITE_BOOST_TYPE_WIN: 'FORTNITE_BOOST_TYPE_WIN',

  OVERWATCH_BOOST_TYPE_RANK: 'OVERWATCH_BOOST_TYPE_RANK',
  OVERWATCH_BOOST_TYPE_WIN: 'OVERWATCH_BOOST_TYPE_WIN',
  OVERWATCH_BOOST_TYPE_PLACEMENT: 'OVERWATCH_BOOST_TYPE_PLACEMENT',
  OVERWATCH_BOOST_TYPE_GAMES: 'OVERWATCH_BOOST_TYPE_GAMES',
};

export const BOOST_ADDITIONAL_TYPE = {
  NEED_HOURS: 'NEED_HOURS',
  STREAMING: 'STREAMING',
  PRIORITY: 'PRIORITY',
  LOBBY_BOOST: 'LOBBY_BOOST',
  PREMIUM: 'PREMIUM',
  NEED_LEVEL: 'NEED_LEVEL',
  STEAM_OFFLINE_MODE: 'STEAM_OFFLINE_MODE',
  PLUS_WIN: 'PLUS_WIN',
  SPECIFIC_CHAMP: 'SPECIFIC_CHAMP',
  SPECIFIC_ROLE: 'SPECIFIC_ROLE',
  SPECIFIC_AGENT: 'SPECIFIC_AGENT',
  DUO: 'DUO',
  OFFLINE_CHAT: 'OFFLINE_CHAT',
  AGENT: 'AGENT',
  PLAY_BOOSTER: 'PLAY_BOOSTER',
  DUO_WITH_BOOSTER: 'DUO_WITH_BOOSTER',
  SOLO: 'SOLO',
  SPECIFIC_CHAMP_ROLE: 'SPECIFIC_CHAMP_ROLE',
  PREMIUM_COACHING: 'PREMIUM_COACHING',
  PLUS_BOOSTER: 'PLUS_BOOSTER',
  SHARE_SCREEN: 'SHARE_SCREEN',
  SPECIFIC_LEGEND: 'SPECIFIC_LEGEND',
  SPECIFIC_HERO_ROLE: 'SPECIFIC_HERO_ROLE',
  OFFLINE_MODE: 'OFFLINE_MODE',
  EXPRESS: 'EXPRESS',
  DOUBLE: 'DOUBLE',
};

export const BOOST_RANK_OPTION = [];

export const BOOST_TYPE_TYPE = {
  CSGO_BOOST_TYPE_RANK: 'CSGO_BOOST_TYPE_RANK',
  CSGO_BOOST_TYPE_WIN: 'CSGO_BOOST_TYPE_WIN',
  CSGO_BOOST_TYPE_PLACEMENT: 'CSGO_BOOST_TYPE_PLACEMENT',
  CSGO_BOOST_TYPE_PREMIER_PLACEMENT: 'CSGO_BOOST_TYPE_PREMIER_PLACEMENT',
  CSGO_BOOST_TYPE_PREMIER: 'CSGO_BOOST_TYPE_PREMIER',
  FACEIT_BOOST_TYPE_ELO: 'FACEIT_BOOST_TYPE_ELO',
  FACEIT_BOOST_TYPE_WIN: 'FACEIT_BOOST_TYPE_WIN',
  FACEIT_BOOST_TYPE_PLACEMENT: 'FACEIT_BOOST_TYPE_PLACEMENT',
  FACEIT_BOOST_TYPE_LEVEL: 'FACEIT_BOOST_TYPE_LEVEL',
  ESEA_BOOST_TYPE_RANK: 'ESEA_BOOST_TYPE_RANK',
  ESEA_BOOST_TYPE_WIN: 'ESEA_BOOST_TYPE_WIN',
  ESEA_BOOST_TYPE_PLACEMENT: 'ESEA_BOOST_TYPE_PLACEMENT',
  ESEA_BOOST_TYPE_MMR: 'ESEA_BOOST_TYPE_MMR',
  WINGAME_BOOST_TYPE_RANK: 'WINGAME_BOOST_TYPE_RANK',
  WINGAME_BOOST_TYPE_WIN: 'WINGAME_BOOST_TYPE_WIN',
  WINGAME_BOOST_TYPE_PLACEMENT: 'WINGAME_BOOST_TYPE_PLACEMENT',

  LOL_BOOST_TYPE_RANK: 'LOL_BOOST_TYPE_RANK',
  LOL_BOOST_TYPE_WIN: 'LOL_BOOST_TYPE_WIN',
  LOL_BOOST_TYPE_PLACEMENT: 'LOL_BOOST_TYPE_PLACEMENT',
  LOL_BOOST_TYPE_NORMALS: 'LOL_BOOST_TYPE_NORMALS',
  LOL_BOOST_TYPE_CHAMPION_MASTERY: 'LOL_BOOST_TYPE_CHAMPION_MASTERY',
  LOL_BOOST_TYPE_CLASH_BOOST: 'LOL_BOOST_TYPE_CLASH_BOOST',

  VALORANT_BOOST_TYPE_RANK: 'VALORANT_BOOST_TYPE_RANK',
  VALORANT_BOOST_TYPE_WIN: 'VALORANT_BOOST_TYPE_WIN',
  VALORANT_BOOST_TYPE_PLACEMENT: 'VALORANT_BOOST_TYPE_PLACEMENT',
  VALORANT_BOOST_TYPE_NORMAL_WIN: 'VALORANT_BOOST_TYPE_NORMAL_WIN',
  VALORANT_BOOST_TYPE_CHALLENGES: 'VALORANT_BOOST_TYPE_CHALLENGES',

  TFT_BOOST_TYPE_RANK: 'TFT_BOOST_TYPE_RANK',
  TFT_BOOST_TYPE_WIN: 'TFT_BOOST_TYPE_WIN',
  TFT_BOOST_TYPE_HYPER_ROLL: 'TFT_BOOST_TYPE_HYPER_ROLL',
  TFT_BOOST_TYPE_DOUBLE_UP: 'TFT_BOOST_TYPE_DOUBLE_UP',
  TFT_BOOST_TYPE_PLACEMENT: 'TFT_BOOST_TYPE_PLACEMENT',

  WILD_RIFT_BOOST_TYPE_RANK: 'WILD_RIFT_BOOST_TYPE_RANK',
  WILD_RIFT_BOOST_TYPE_WIN: 'WILD_RIFT_BOOST_TYPE_WIN',
  WILD_RIFT_BOOST_TYPE_PLACEMENT: 'WILD_RIFT_BOOST_TYPE_PLACEMENT',
  WILD_RIFT_BOOST_TYPE_NORMAL_WIN: 'WILD_RIFT_BOOST_TYPE_NORMAL_WIN',
  WILD_RIFT_BOOST_TYPE_LEGENDARY_RANK: 'WILD_RIFT_BOOST_TYPE_LEGENDARY_RANK',

  DOTA_BOOST_TYPE_MMR: 'DOTA_BOOST_TYPE_MMR',
  DOTA_BOOST_TYPE_WIN: 'DOTA_BOOST_TYPE_WIN',
  DOTA_BOOST_TYPE_PLACEMENT: 'DOTA_BOOST_TYPE_PLACEMENT',
  DOTA_BOOST_TYPE_NORMAL_BEHAVIOUR: 'DOTA_BOOST_TYPE_NORMAL_BEHAVIOUR',
  DOTA_BOOST_TYPE_LOW_PRIORITY: 'DOTA_BOOST_TYPE_LOW_PRIORITY',

  ESPORTAL_BOOST_TYPE_RANK: 'ESPORTAL_BOOST_TYPE_RANK',
  ESPORTAL_BOOST_TYPE_WIN: 'ESPORTAL_BOOST_TYPE_WIN',
  ESPORTAL_BOOST_TYPE_PLACEMENT: 'ESPORTAL_BOOST_TYPE_PLACEMENT',

  APEX_BOOST_TYPE_RANK: 'APEX_BOOST_TYPE_RANK',
  APEX_BOOST_TYPE_WIN: 'APEX_BOOST_TYPE_WIN',
  APEX_BOOST_TYPE_PLACEMENT: 'APEX_BOOST_TYPE_PLACEMENT',
  APEX_BOOST_TYPE_NORMAL_KILL: 'APEX_BOOST_TYPE_NORMAL_KILL',
  APEX_BOOST_TYPE_BADGE: 'APEX_BOOST_TYPE_BADGE',
};
