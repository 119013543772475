import { httpRequest } from "../../main/http";

import { AUTH_LOGIN_API, AUTH_LOGIN_ACTION_TYPE } from "./auth-login.constant";
import { authSetData } from "../../lib/common/auth/auth.action";
import { INDEX_ROUTE_PATH } from "../index/index.constant";

export function uploadAuthLoginForm(data, navigate) {
  return async (dispatch) => {
    dispatch({
      type: AUTH_LOGIN_ACTION_TYPE.FORM_PENDING,
    });

    try {
      const res = await httpRequest({
        method: AUTH_LOGIN_API.AUTH_LOGIN.TYPE,
        url: AUTH_LOGIN_API.AUTH_LOGIN.ENDPOINT,
        data,
      });
      dispatch(authSetData(res.data.accessToken));

      await navigate(INDEX_ROUTE_PATH);

      dispatch({
        type: AUTH_LOGIN_ACTION_TYPE.FORM_SUCCESS,
      });
      dispatch({
        type: AUTH_LOGIN_ACTION_TYPE.FORM_RESET,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        console.log("error.response", error.response);
        dispatch({
          type: AUTH_LOGIN_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetAuthLoginForm() {
  return async (dispatch) => {
    dispatch({
      type: AUTH_LOGIN_ACTION_TYPE.FORM_RESET,
    });
  };
}
