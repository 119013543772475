import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { GdsItemAdminItemDeleteComponent } from "./gds-item-admin-item-delete.component";
import {
  uploadGdsItemAdminItemDeleteForm,
  resetGdsItemAdminItemDeleteFormState,
} from "./gds-item-admin-item-delete.action";
import { GDS_ITEM_ADMIN_ITEM_DELETE_STORE_NAME } from "./gds-item-admin-item-delete.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { GDS_ITEM_ADMIN_ITEM_DATA_STORE_NAME } from "../gds-item-admin-item-data/gds-item-admin-item-data.constant";

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function GdsItemAdminItemDeleteContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { gdsId } = useParams();

  const { state, pageLoading, itemStore } = useSelector((state) => ({
    state: state[GDS_ITEM_ADMIN_ITEM_DELETE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    itemStore: state[GDS_ITEM_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetGdsItemAdminItemDeleteFormState());
  }, []);

  const gdsItemAdminItemDeleteFormSendData = () => {
    dispatch(
      uploadGdsItemAdminItemDeleteForm(gdsId, itemStore.gdsItemId, navigate)
    );
  };

  return (
    <GdsItemAdminItemDeleteComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(itemStore.request)}
      onSubmitForm={gdsItemAdminItemDeleteFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
