import { httpRequest } from '../../main/http';

import {
  GDS_ADMIN_ITEM_UPDATE_DATA_API,
  GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE,
} from './gds-admin-item-update-data.constant';

import { reloadGdsAdminItemData } from '../gds-admin-item-data/gds-admin-item-data.action';

export function uploadGdsAdminItemUpdateDataForm(data, gdsId) {
  return async (dispatch) => {
    dispatch({
      type: GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: GDS_ADMIN_ITEM_UPDATE_DATA_API.CHANGE_DATA.TYPE,
        url: GDS_ADMIN_ITEM_UPDATE_DATA_API.CHANGE_DATA.ENDPOINT(gdsId),
        data,
      });

      dispatch({
        type: GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS,
      });

      await dispatch(reloadGdsAdminItemData());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetGdsAdminItemUpdateDataFormState() {
  return async (dispatch) => {
    dispatch({
      type: GDS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET,
    });
  };
}
