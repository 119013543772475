import { PromocodeAdminCreateContainer } from "./promocode-admin-create.container";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

export function PromocodeAdminCreatePage() {
  return (
    <Container maxWidth="sm">
      <Grid container>
        <Grid item>
          <PromocodeAdminCreateContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
