export const POPUP_ADMIN_CREATE_ROUTE_PATH = '/admin/popup/create';

export const POPUP_ADMIN_CREATE_STORE_NAME = 'POPUP_ADMIN_CREATE';

export const POPUP_ADMIN_CREATE_API = {
  POPUP_ADMIN_CREATE: {
    ENDPOINT: '/popup/',
    TYPE: 'POST',
  },
};

export const POPUP_ADMIN_CREATE_ACTION_TYPE = {
  FORM_PENDING: 'POPUP_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'POPUP_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'POPUP_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'POPUP_ADMIN_CREATE_ACTION_TYPE.FORM_RESET',
};

export const POPUP_ADMIN_CREATE_DATA_NAME = {
  TEXT: 'text',
  TYPE: 'type',
  LANG: 'lang',
  FILE: 'fileImageId',
};
