import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { PurchaseAdminListPaginationComponent } from './purchase-admin-list-pagination.component';

import { PURCHASE_ADMIN_LIST_STORE_NAME } from '../purchase-admin-list/purchase-admin-list.constant';

import {
  getPurchaseAdminList,
  updatePurchaseAdminList,
} from '../purchase-admin-list/purchase-admin-list.action';
import { getRequestData } from '../../main/store/store.service';
import { PURCHASE_ADMIN_LIST_PAGINATION_CRON_UPDATE } from './purchase-admin-list-pagination.constant';

export function PurchaseAdminListPaginationContainer() {
  const dispatch = useDispatch();

  const { state } = useSelector((state) => ({
    state: getRequestData(state[PURCHASE_ADMIN_LIST_STORE_NAME].request),
  }));

  const [intervalId, setIntervalId] = React.useState(null);

  const onChangeFilter = (data) => {
    dispatch(getPurchaseAdminList({ ...state, ...data }));

    clearInterval(intervalId);

    const intId = setInterval(() => {
      dispatch(updatePurchaseAdminList());
    }, PURCHASE_ADMIN_LIST_PAGINATION_CRON_UPDATE);

    setIntervalId(intId);
  };

  React.useEffect(() => {
    clearInterval(intervalId);

    dispatch(getPurchaseAdminList());

    const intId = setInterval(() => {
      dispatch(updatePurchaseAdminList());
    }, PURCHASE_ADMIN_LIST_PAGINATION_CRON_UPDATE);

    setIntervalId(intId);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return (
    <PurchaseAdminListPaginationComponent
      data={state}
      onChangeFilter={onChangeFilter}
    />
  );
}
