import React from "react";

import { NoticeAccountListComponent } from "./notice-account-list.component";

import { useDispatch, useSelector } from "react-redux";

import {
  getNoticeAccountList,
  updateNoticeAccountList,
} from "./notice-account-list.action";

import {
  NOTICE_ACCOUNT_LIST_CRON_UPDATE,
  NOTICE_ACCOUNT_LIST_STORE_NAME,
} from "./notice-account-list.constant";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

import { NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_STORE_NAME } from "../notice-account-item-update-view/notice-account-item-update-view.constant";
import { updateNoticeAccountItemView } from "../notice-account-item-update-view/notice-account-item-update-view.action";
import { updateNoticeAccountListShort } from "../notice-account-list-short/notice-account-list-short.action";

export function NoticeAccountListContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, view, fullList } = useSelector((state) => ({
    state: state[NOTICE_ACCOUNT_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    view: state[NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_STORE_NAME],
    fullList: state[NOTICE_ACCOUNT_LIST_STORE_NAME],
  }));

  const isNoticeLoaded = isRequestSuccess(state.request);
  const isNoticeError = isRequestError(state.request);

  const isFullListLoader = isRequestSuccess(fullList.request);
  const fullListData = getRequestData(fullList.request);

  const handleUpdateView = (noticeId) => {
    dispatch(
      updateNoticeAccountItemView(noticeId, () => {
        if (isFullListLoader) {
          return dispatch(updateNoticeAccountList(fullListData));
        }

        return dispatch(updateNoticeAccountListShort());
      })
    );
  };

  const cronUpdateData = () => {
    console.log(state.request.data, "####");
    dispatch(updateNoticeAccountList(state.request.data));
  };

  React.useEffect(() => {
    if (isNoticeLoaded && !isNoticeError) {
      dispatch(updateNoticeAccountList());
    } else {
      dispatch(getNoticeAccountList());
    }

    const setIntervalId = setInterval(
      cronUpdateData,
      NOTICE_ACCOUNT_LIST_CRON_UPDATE
    );

    return () => {
      clearInterval(setIntervalId);
    };
  }, []);

  React.useEffect(() => {
    dispatch(getNoticeAccountList());
  }, []);

  return (
    <NoticeAccountListComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
      viewUpdateId={view.noticeId}
      isViewUpdate={isRequestPending(view.request)}
      handleUpdateView={handleUpdateView}
    />
  );
}
