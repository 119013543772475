import React from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";
import { ACCOUNT_SETTINGS_ROUTE_PATH } from "../../account-settings/account-settings.constant";
import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "../../purchase-customer-item/purchase-customer-item.constant";

export function PurchaseCustomerItemTimezoneViewComponent(props) {
  const { data, timezone } = props;
  const navigate = useNavigate();

  const handleLinkRoute = (path) => (e) => {
    e.preventDefault();
    return navigate(path);
  };

  return (
    <Grid
      container
      spacing={4}
      // sx={{ pt: 4, flexDirection: 'row', flexWrap: 'wrap' }}
      sx={{ pt: 0, flexDirection: "row", flexWrap: "wrap" }}
    >
      <Grid item md={6} xs={12}>
        <Grid container spacing={3}>
          <React.Fragment>
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_TIMEZONE.CUSTOMER_TIMEZONE"
              />
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.CUSTOMER.ITEM_TIMEZONE.TIME_DATA"
                tvalue={{
                  time: timezone.customerTime?.currentTime || "00:00",
                  timezone: timezone.customerTime?.text || "...",
                }}
              />
            </Grid>
          </React.Fragment>
        </Grid>
      </Grid>

      <Grid item md={6} xs={12}>
        <Grid container spacing={3}>
          {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.IS_BOOSTER] ? (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.CUSTOMER.ITEM_TIMEZONE.BOOSTER_TIMEZONE"
                />
                <TextComponent
                  variant="dataViewContent"
                  tid="PURCHASE.CUSTOMER.ITEM_TIMEZONE.TIME_DATA"
                  tvalue={{
                    time: timezone.boosterTime?.currentTime || "00:00",
                    timezone: timezone.boosterTime?.text || "...",
                  }}
                />
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_TIMEZONE.BOOSTER_TIMEZONE"
              />
              <TextComponent variant="dataViewContent">
                <TextComponent tid="PURCHASE.CUSTOMER.ITEM_TIMEZONE.IS_BOOSTER_FALSE" />
              </TextComponent>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {timezone.customerTime?.value &&
          Number(timezone.currentTimezone) !==
            Number(timezone.customerTime.value) && (
            <React.Fragment>
              <TextComponent
                variant="errorText"
                component="span"
                tvalue={{ timezone: timezone.currentTimezoneText }}
                tid="PURCHASE.CUSTOMER.ITEM_TIMEZONE.TIMEZONE_INCORRECT"
              />{" "}
            </React.Fragment>
          )}
        <Link
          variant="body1"
          underline="always"
          sx={{ color: "#fff", cursor: "pointer" }}
          target="_blank"
          href={ACCOUNT_SETTINGS_ROUTE_PATH}
          onClick={handleLinkRoute(ACCOUNT_SETTINGS_ROUTE_PATH)}
        >
          <TextComponent
            component="span"
            variant="body1Small"
            tid="PURCHASE.CUSTOMER.ITEM_TIMEZONE.TIMEZONE_CHANGE"
          />
        </Link>
      </Grid>
    </Grid>
  );
}
