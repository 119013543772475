import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseBoosterItemContainer } from "../purchase-booster-item/purchase-booster-item.container";

import { PurchaseBoosterItemChatListContainer } from "../purchase-booster-item-chat-list/purchase-booster-item-chat-list.container";
import { PurchaseBoosterItemChatCreateContainer } from "../purchase-booster-item-chat-create/purchase-booster-item-chat-create.container";

import { PurchaseBoosterItemTabContainer } from "../purchase-booster-item-tab/purchase-booster-item-tab.container";
import { PurchaseBoosterItemTimezoneContainer } from "../purchase-booster-item-timezone/purchase-booster-item-timezone.container";

export function PurchaseBoosterItemChatPage() {
  return (
    <>
      <PurchaseBoosterItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseBoosterItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseBoosterItemChatListContainer />
          </Grid>
          <Grid item>
            <PurchaseBoosterItemChatCreateContainer />
          </Grid>
          <Grid item>
            <PurchaseBoosterItemTimezoneContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
