export const PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_STORE_NAME =
  'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA';

export const PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_API = {
  UPDATE_DATA: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/customer/boost/account`,
    TYPE: 'POST',
  },
};

export const PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING:
    'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS:
    'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR:
    'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET:
    'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_DATA_NAME = {
  DATA: 'data',
};
