export const PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_STORE_NAME =
  'PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM';

export const PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: (purchaseId, gdsItemId) =>
      `/purchase/${purchaseId}/admin/gds-item/${gdsItemId}`,
    TYPE: 'POST',
  },
};

export const PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_ACTION_TYPE = {
  FORM_PENDING: 'PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_DATA_NAME = {
  ID: 'id',
};
