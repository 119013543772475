import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseBoosterItemTabContainer } from "../purchase-booster-item-tab/purchase-booster-item-tab.container";
import { PurchaseBoosterItemContainer } from "../purchase-booster-item/purchase-booster-item.container";
import { PurchaseBoosterItemCoachingDemoListContainer } from "../purchase-booster-item-coaching-demo-list/purchase-booster-item-coaching-demo-list.container";

export function PurchaseBoosterItemCoachingDemoPage() {
  return (
    <Container maxWidth="sm">
      <PurchaseBoosterItemContainer />
      <Grid spacing={3} container>
        <Grid item>
          <PurchaseBoosterItemTabContainer />
        </Grid>
        <Grid item>
          <PurchaseBoosterItemCoachingDemoListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
