import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { purchaseCustomerItemCoachingDemoCreateFormValidation } from './purchase-customer-item-coaching-demo-create.validation';
import { PurchaseCustomerItemCoachingDemoCreateComponent } from './purchase-customer-item-coaching-demo-create.component';
import {
  uploadPurchaseCustomerItemCoachingDemoCreateForm,
  resetPurchaseCustomerItemCoachingDemoCreateFormState,
} from './purchase-customer-item-coaching-demo-create.action';
import {
  PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_STORE_NAME,
  PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME,
} from './purchase-customer-item-coaching-demo-create.constant';
import { convertPurchaseCustomerItemCoachingDemoCreateFormData } from './purchase-customer-item-coaching-demo-create.convert';
import { PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_STORE_NAME } from '../purchase-customer-item-coaching-demo-list/purchase-customer-item-coaching-demo-list.constant';

export function PurchaseCustomerItemCoachingDemoCreateContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, coachingStore } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    coachingStore: state[PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseCustomerItemCoachingDemoCreateFormState());
  }, []);

  const purchaseCustomerItemCoachingDemoCreateFormSendData = (
    values,
    { resetForm },
  ) => {
    const data = convertPurchaseCustomerItemCoachingDemoCreateFormData(values);
    data.purchaseId = coachingStore.purchaseId;
    dispatch(
      uploadPurchaseCustomerItemCoachingDemoCreateForm(data, () => {
        resetForm();
      }),
    );
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME.INFO]: '',
      [PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME.FILE]: [],
    };
  };

  return (
    <PurchaseCustomerItemCoachingDemoCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(coachingStore.request)}
      initialValue={getInitialValue()}
      validation={purchaseCustomerItemCoachingDemoCreateFormValidation}
      onSubmitForm={purchaseCustomerItemCoachingDemoCreateFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
