import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export const LoaderBarComponent = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        position: 'fixed',
        zIndex: 9999999,
        top: '0px',
        left: '0px',
      }}
    >
      <LinearProgress sx={{ height: '4px' }} />
    </Box>
  );
};
