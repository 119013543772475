import { PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME } from './purchase-customer-item-coaching-demo-create.constant';
import { convertOneFileFieldValue } from '../../lib/common/field-file/field-file.convert';

export const convertPurchaseCustomerItemCoachingDemoCreateFormData = (
  values,
) => {
  return {
    [PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME.INFO]:
      values[PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME.INFO],
    [PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME.FILE]:
      convertOneFileFieldValue(
        values[PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_DATA_NAME.FILE],
      ),
  };
};
