import { NOTICE_ADMIN_LIST_DATA_NAME } from "./notice-admin-list.constant";

import { convertNoticeList } from "../../lib/common/notice/notice.convert";

export const convertNoticeAdminList = (d) => ({
  [NOTICE_ADMIN_LIST_DATA_NAME.LIST]: convertNoticeList(
    d[NOTICE_ADMIN_LIST_DATA_NAME.LIST]
  ),
  [NOTICE_ADMIN_LIST_DATA_NAME.SKIP]: d[NOTICE_ADMIN_LIST_DATA_NAME.SKIP],
  [NOTICE_ADMIN_LIST_DATA_NAME.TAKE]: d[NOTICE_ADMIN_LIST_DATA_NAME.TAKE],
  [NOTICE_ADMIN_LIST_DATA_NAME.AMOUNT]: d[NOTICE_ADMIN_LIST_DATA_NAME.AMOUNT],
});
