export const NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME = 'NOTICE_ACCOUNT_LIST_SHORT';

export const NOTICE_ACCOUNT_LIST_SHORT_API = {
  NOTICE_ACCOUNT_LIST_SHORT: {
    ENDPOINT: `/notice/short`,
    TYPE: 'GET',
  },
};

export const NOTICE_ACCOUNT_LIST_SHORT_CRON_UPDATE = 60000;

export const NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE = {
  REQUEST_PENDING: 'NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_UPDATE_PENDING:
    'NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_UPDATE_PENDING',
  REQUEST_SUCCESS: 'NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_UPDATE_SUCCESS:
    'NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_ERROR: 'NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_ERROR',
};

export const NOTICE_ACCOUNT_LIST_SHORT_DATA_NAME = {
  LIST: 'list',

  SKIP: 'skip',
  TAKE: 'take',
  AMOUNT: 'amount',

  ID: 'id',
  TYPE: 'type',
  CREATE_DATE: 'createDate',
  VIEW: 'view',
  DATA: 'data',
};
