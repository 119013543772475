export const CHAT_MESSAGE_DATA_TYPE = {
  ID: 'id',
  VIEW: 'view',
  DATA: 'data',
  TYPE: 'type',
  CREATE_DATE: 'createDate',
  CREATE_DATE_NORMAL: "createDateNormal",

  USER: 'user',
  USER_LOGIN: 'login',
  USER_ID: 'id',

  FILE: 'file',
  FILE_ID: 'id',
  FILE_NAME: 'name',
  FILE_URL: 'url',
};

export const CHAT_MESSAGE_TYPE_TYPE = {
  CUSTOMER: 1,
  ADMIN: 2,
  BOOSTER: 3,
};

export const CHAT_MESSAGE_TYPE_VALUE = {
  1: 'CUSTOMER',
  2: 'ADMIN',
  3: 'BOOSTER',
};

export const CHAT_MESSAGE_TYPE_VIEW = {
  CUSTOMER: 'CUSTOMER',
  ADMIN: 'ADMIN',
  BOOSTER: 'BOOSTER',
};