import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { UserAdminItemUpdateBonusComponent } from "./user-admin-item-update-bonus.component";
import { userAdminItemUpdateBonusFormValidation } from "./user-admin-item-update-bonus.validation";
import { convertUserAdminItemUpdateBonusFormData } from "./user-admin-item-update-bonus.convert";
import {
  uploadUserAdminItemUpdateBonusForm,
  resetUserAdminItemUpdateBonusFormState,
} from "./user-admin-item-update-bonus.action";
import {
  USER_ADMIN_ITEM_UPDATE_BONUS_STORE_NAME,
  USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME,
} from "./user-admin-item-update-bonus.constant";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { USER_ADMIN_ITEM_DATA_STORE_NAME } from "../user-admin-item-data/user-admin-item-data.constant";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function UserAdminItemUpdateBonusContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, userItemStore } = useSelector((state) => ({
    state: state[USER_ADMIN_ITEM_UPDATE_BONUS_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    userItemStore: state[USER_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetUserAdminItemUpdateBonusFormState());
  }, []);

  const userAdminItemUpdateDataFormSendData = (values) => {
    const data = convertUserAdminItemUpdateBonusFormData(values);
    dispatch(uploadUserAdminItemUpdateBonusForm(data, userItemStore.userId));
  };

  const getInitialValue = () => {
    if (isRequestSuccess(userItemStore.request)) {
      const data = getRequestData(userItemStore.request);
      return {
        [USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME.BONUS]:
          data[USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME.BONUS],
      };
    }

    return {
      [USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME.BONUS]: "",
    };
  };

  return (
    <UserAdminItemUpdateBonusComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(userItemStore.request)}
      initialValue={getInitialValue()}
      validation={userAdminItemUpdateBonusFormValidation}
      onSubmitForm={userAdminItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
