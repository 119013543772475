import React from "react";

import { GdsAdminCreateInfoComponent } from "./gds-admin-create-info.component";

import { GDS_ADMIN_CREATE_ROUTE_PATH } from "../gds-admin-create/gds-admin-create.constant";
import { useNavigate } from "react-router-dom";

export function GdsAdminCreateInfoContainer() {
  const navigate = useNavigate();
  const onSubmitForm = () => {
    return navigate(GDS_ADMIN_CREATE_ROUTE_PATH);
  };

  return <GdsAdminCreateInfoComponent onSubmitForm={onSubmitForm} />;
}
