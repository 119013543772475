export const NOTICE_ADMIN_LIST_ROUTE_PATH = '/admin/notification';

export const NOTICE_ADMIN_LIST_STORE_NAME = 'NOTICE_ADMIN_LIST';

export const NOTICE_ADMIN_LIST_API = {
  NOTICE_ADMIN_LIST: {
    ENDPOINT: `/notice/admin`,
    TYPE: 'GET',
  },
};

export const NOTICE_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'NOTICE_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'NOTICE_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'NOTICE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR',
};

export const NOTICE_ADMIN_LIST_DATA_NAME = {
  LIST: 'list',

  SKIP: 'skip',
  TAKE: 'take',
  AMOUNT: 'amount',

  ID: 'id',
  TYPE: 'type',
  CREATE_DATE: 'createDate',
  VIEW: 'view',
  DATA: 'data',
};
