import { validate } from '../../main/validate';

import { ACCOUNT_RECOVERY_DATA_NAME } from './account-recovery.constant';

import { email, required } from '../../main/validate/validate.service';

const config = {
  [ACCOUNT_RECOVERY_DATA_NAME.EMAIL]: [required, email],
};

export const accountRecoveryFormValidation = (values) =>
  validate(values, config);
