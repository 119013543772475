export const PURCHASE_BOOSTER_LIST_ROUTE_PATH = "/booster/work";

export const PURCHASE_BOOSTER_LIST_STORE_NAME = "PURCHASE_BOOSTER_LIST";

export const PURCHASE_BOOSTER_LIST_API = {
  PURCHASE_BOOSTER_LIST: {
    ENDPOINT: `/purchase/booster`,
    TYPE: "GET",
  },
};

export const PURCHASE_BOOSTER_LIST_ACTION_TYPE = {
  REQUEST_PENDING: "PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING",
  REQUEST_SUCCESS: "PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_ERROR: "PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_ERROR",
};

export const PURCHASE_BOOSTER_LIST_DATA_NAME = {
  LIST: "list",

  ID: "id",
  TYPE: "type",
  CREATE_DATE: "createDate",
  NOTES: "notes",

  BOOST: "boost",
  BOOST_ID: "id",
  BOOST_DESIRED: "desired",
  BOOST_CURRENT: "current",
  BOOST_ACTUAL: "actual",
  BOOST_TYPE: "type",
  BOOST_ADDITIONALS: "additionals",

  COACHING: "coaching",
  COACHING_ADDITIONALS: "additionals",
  COACHING_ID: "id",
  COACHING_DATA: "data",
  COACHING_TYPE: "type",
  COACHING_SESSION_AMOUNT: "sessionAmount",
  COACHING_ACTUAL_SESSION: "actualSession",
  BOOST_DATA: "data",
};

export const PURCHASE_BOOSTER_LIST_PAGINATION_CRON_UPDATE = 60000;
