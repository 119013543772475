export const COACHING_TYPE_TYPE = {
  TEXTUAL_COACHING_ANALYSIS: 'TEXTUAL_COACHING_ANALYSIS',
  CALL_COACHING_ANALYSIS: 'CALL_COACHING_ANALYSIS',
  DEMO_COACHING_ANALYSIS: 'DEMO_COACHING_ANALYSIS',
  TEAM_COACHING_ANALYSIS: 'TEAM_COACHING_ANALYSIS',
};

export const COACHING_TYPE_VALUE = {
  TEXTUAL_COACHING_ANALYSIS: 'TEXTUAL_COACHING_ANALYSIS',
  CALL_COACHING_ANALYSIS: 'CALL_COACHING_ANALYSIS',
  DEMO_COACHING_ANALYSIS: 'DEMO_COACHING_ANALYSIS',
  TEAM_COACHING_ANALYSIS: 'TEAM_COACHING_ANALYSIS',
};

export const COACHING_ADDITIONAL_TYPE = {
  PRIORITY: 'PRIORITY',
};

export const COACHING_ADDITIONAL_VALUE = {
  PRIORITY: 'PRIORITY',
};
