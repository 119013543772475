import { validate } from '../../main/validate';
import {
  required,
  requiredArray,
  arrayLengthMax,
} from '../../main/validate/validate.service';

import { PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_DATA_NAME } from './purchase-booster-item-coaching-report-create.constant';

const config = {
  [PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_DATA_NAME.INFO]: [required],
  [PURCHASE_BOOSTER_ITEM_COACHING_REPORT_CREATE_DATA_NAME.FILE]: [
    requiredArray,
    arrayLengthMax(1),
  ],
};

export const purchaseBoosterItemCoachingReportCreateFormValidation = (values) =>
  validate(values, config);
