import { convertFile } from "../file/file.convert";
import { CHAT_MESSAGE_DATA_TYPE, CHAT_MESSAGE_TYPE_VALUE } from "./chat.type";

import {
  convertDatetime,
  convertDateToCalendarTime,
  convertDateToCalendarTimePm,
} from "../convert/convert.core";

export const convertChatMessage = (data) => {
  if (!data) {
    return null;
  }

  return {
    [CHAT_MESSAGE_DATA_TYPE.ID]: data[CHAT_MESSAGE_DATA_TYPE.ID],
    [CHAT_MESSAGE_DATA_TYPE.DATA]: data[CHAT_MESSAGE_DATA_TYPE.DATA],
    [CHAT_MESSAGE_DATA_TYPE.VIEW]: data[CHAT_MESSAGE_DATA_TYPE.VIEW],
    [CHAT_MESSAGE_DATA_TYPE.CREATE_DATE]: convertDateToCalendarTime(
      data[CHAT_MESSAGE_DATA_TYPE.CREATE_DATE]
    ),
    [CHAT_MESSAGE_DATA_TYPE.TYPE]: convertChatMessageType(
      data[CHAT_MESSAGE_DATA_TYPE.TYPE]
    ),

    [CHAT_MESSAGE_DATA_TYPE.USER]: {
      [CHAT_MESSAGE_DATA_TYPE.USER_ID]:
        data[CHAT_MESSAGE_DATA_TYPE.USER][CHAT_MESSAGE_DATA_TYPE.USER_ID],
      [CHAT_MESSAGE_DATA_TYPE.USER_LOGIN]:
        data[CHAT_MESSAGE_DATA_TYPE.USER][CHAT_MESSAGE_DATA_TYPE.USER_LOGIN],
    },

    [CHAT_MESSAGE_DATA_TYPE.FILE]: convertFile(
      data[CHAT_MESSAGE_DATA_TYPE.FILE]
    ),
  };
};

export const convertChatMessageCustomer = (data) => {
  if (!data) {
    return null;
  }

  return {
    [CHAT_MESSAGE_DATA_TYPE.ID]: data[CHAT_MESSAGE_DATA_TYPE.ID],
    [CHAT_MESSAGE_DATA_TYPE.DATA]: data[CHAT_MESSAGE_DATA_TYPE.DATA],
    [CHAT_MESSAGE_DATA_TYPE.VIEW]: data[CHAT_MESSAGE_DATA_TYPE.VIEW],
    [CHAT_MESSAGE_DATA_TYPE.CREATE_DATE]: convertDateToCalendarTimePm(
      data[CHAT_MESSAGE_DATA_TYPE.CREATE_DATE]
    ),
    [CHAT_MESSAGE_DATA_TYPE.CREATE_DATE_NORMAL]:
      data[CHAT_MESSAGE_DATA_TYPE.CREATE_DATE],
    [CHAT_MESSAGE_DATA_TYPE.TYPE]: convertChatMessageType(
      data[CHAT_MESSAGE_DATA_TYPE.TYPE]
    ),

    [CHAT_MESSAGE_DATA_TYPE.USER]: {
      [CHAT_MESSAGE_DATA_TYPE.USER_ID]:
        data[CHAT_MESSAGE_DATA_TYPE.USER][CHAT_MESSAGE_DATA_TYPE.USER_ID],
      [CHAT_MESSAGE_DATA_TYPE.USER_LOGIN]:
        data[CHAT_MESSAGE_DATA_TYPE.USER][CHAT_MESSAGE_DATA_TYPE.USER_LOGIN],
    },

    [CHAT_MESSAGE_DATA_TYPE.FILE]: convertFile(
      data[CHAT_MESSAGE_DATA_TYPE.FILE]
    ),
  };
};

export const convertChatMessageList = (list) => list.map(convertChatMessage);

export const convertChatMessageListCustomer = (list) =>
  list.map(convertChatMessageCustomer);

export const convertChatMessageType = (chatMessageTypeId) => {
  if (!chatMessageTypeId) {
    return {
      id: null,
      value: null,
    };
  }

  return {
    id: chatMessageTypeId,
    value: CHAT_MESSAGE_TYPE_VALUE[chatMessageTypeId],
  };
};
