import { AccountLogoutContainer } from "../account-logout/account-logout.container";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { NavigationObserver } from "../../lib/common/navigation";

export function AuthLogoutPage() {
  return (
    <>
      <NavigationObserver />
      <Container sx={{ px: 3 }} maxWidth="xs">
        <Grid sx={{ py: 6, minHeight: "100vh" }} container>
          <Grid item>
            <AccountLogoutContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
