import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseBoosterItemBoostTimeListContainer } from "../purchase-booster-item-boost-time-list/purchase-booster-item-boost-time-list.container";

import { PurchaseBoosterItemTabContainer } from "../purchase-booster-item-tab/purchase-booster-item-tab.container";
import { PurchaseBoosterItemContainer } from "../purchase-booster-item/purchase-booster-item.container";

export function PurchaseBoosterItemBoostTimePage() {
  return (
    <>
      <PurchaseBoosterItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseBoosterItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseBoosterItemBoostTimeListContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
