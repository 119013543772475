import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { TextComponent } from "../../../lib/common/text";
import { USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME } from "../user-admin-item-update-bonus.constant";

export const UserAdminItemUpdateBonusFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    setFieldValue,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="USER.ADMIN.ITEM_UPDATE_BONUS.FORM.BONUS" />
              }
              name={USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME.BONUS}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME.BONUS]}
              error={isFieldError(USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME.BONUS)}
              fullWidth
              errorText={getFieldError(
                USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME.BONUS
              )}
            />
          </Grid>

          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="USER.ADMIN.ITEM_UPDATE_DATA.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
