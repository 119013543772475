export const GDS_ADMIN_LIST_ROUTE_PATH = '/admin/gds';

export const GDS_ADMIN_LIST_STORE_NAME = 'GDS_ADMIN_LIST';

export const GDS_ADMIN_LIST_API = {
  GDS_ADMIN_LIST: {
    ENDPOINT: `/gds/admin`,
    TYPE: 'GET',
  },
};

export const GDS_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'GDS_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'GDS_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'GDS_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR',
};

export const GDS_ADMIN_LIST_DATA_NAME = {
  ID: 'id',
  ORDER: 'order',
  STATUS: 'status',
  TITLE_EN: 'titleEn',
  TITLE_RU: 'titleRu',

  PRICE_EN: 'priceEn',
  PRICE_RU: 'priceRu',
  STOCK_EN: 'stockEn',
  STOCK_RU: 'stockRu',

  URL_RU: 'urlRu',
  URL_EN: 'urlEn',

  TYPE: 'type',

  CATEGORY: 'category',
  CREATE_DATA: 'createDate',
  SORT_ORDER: 'sortOrder',
  SORT_ID: 'sortId',
  LIST: 'list',

  SKIP: 'skip',
  AMOUNT: 'amount',
  TAKE: 'take',

  LIST: 'list',

  HAS_VERIFY: 'hasVerify',
};
