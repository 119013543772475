import { validate } from '../../main/validate';

import { GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './gds-admin-item-update-data.constant';

import {
  requiredArray,
  arrayLengthMax,
  required,
  maxLength,
  numberPositive,
  url,
} from '../../main/validate/validate.service';

const config = {
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_LIST_EN]: [arrayLengthMax(10)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_LIST_RU]: [arrayLengthMax(10)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_PREVIEW_EN]: [arrayLengthMax(1)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE_PREVIEW_RU]: [arrayLengthMax(1)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY_EN]: [maxLength(10000)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY_EN]: [maxLength(10000)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY]: [],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_EN]: [maxLength(500)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_RU]: [maxLength(500)],
  // [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]: [numberPositive],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_EN]: [numberPositive],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_RU]: [numberPositive],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]: [],
  // [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_RU]: [numberPositive],
  // [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_EN]: [numberPositive],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TAG]: [],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_EN]: [maxLength(100)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_RU]: [maxLength(100)],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TYPE]: [],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL_RU]: [maxLength(50), url],
  [GDS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL_EN]: [maxLength(50), url],
};

export const gdsAdminItemUpdateDataFormValidation = (values) =>
  validate(values, config);
