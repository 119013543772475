import { httpRequest } from '../../main/http';

import {
  POPUP_ADMIN_ITEM_UPDATE_DATA_API,
  POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE,
} from './popup-admin-item-update-data.constant';

import { reloadPopupAdminItemData } from '../popup-admin-item-data/popup-admin-item-data.action';

export function uploadPopupAdminItemUpdateDataForm(data, popupId) {
  return async (dispatch) => {
    dispatch({
      type: POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: POPUP_ADMIN_ITEM_UPDATE_DATA_API.CHANGE_DATA.TYPE,
        url: POPUP_ADMIN_ITEM_UPDATE_DATA_API.CHANGE_DATA.ENDPOINT(popupId),
        data,
      });

      dispatch({
        type: POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS,
      });

      await dispatch(reloadPopupAdminItemData());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPopupAdminItemUpdateDataFormState() {
  return async (dispatch) => {
    dispatch({
      type: POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET,
    });
  };
}
