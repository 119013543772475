import React from 'react';

import { TextComponent } from '../text/text.component';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';

export const ModalConfirmComponent = ({ children, disabled, action, type }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (disabled) return null;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = () => {
    handleClose();
    action && action();
  };

  return (
    <React.Fragment>
      <div onClick={handleClickOpen}>{children}</div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <TextComponent tid="MODAL.CONFIRM.TITLE" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextComponent tid="MODAL.CONFIRM.CONTENT" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="grey" color="black" onClick={handleClose}>
            <TextComponent tid="MODAL.CONFIRM.BUTTON_NOT_AGREE" />
          </Button>
          <Button color="primary" onClick={handleAction} autoFocus>
            <TextComponent tid="MODAL.CONFIRM.BUTTON_AGREE" />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
