import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { AUTH_LOGIN_ROUTE_PATH } from "../../../core/auth-login/auth-login.constant";
import { ACCOUNT_VERIFICATION_EMAIL_ROUTE_PATH } from "../../../core/account-verification-email/account-verification-email.constant";
import { AUTH_SIGNUP_ROUTE_PATH } from "../../../core/auth-signup/auth-signup.constant";
import { INDEX_ROUTE_PATH } from "../../../core/index/index.constant";
import { ACCOUNT_RECOVERY_ROUTE_PATH } from "../../../core/account-recovery/account-recovery.constant";
import { AUTH_STORE_NAME } from "../../common/auth";
import { USER_ROLE_TYPE } from "../../common/user/user.type";
import { GDS_ADMIN_LIST_ROUTE_PATH } from "../../../core/gds-admin-list/gds-admin-list.constant";
import { INDEX_ADMIN_ROUTE_PATH } from "../../../core/index-admin/index-admin.constant";
import { PURCHASE_CUSTOMER_LIST_ROUTE_PATH } from "../../../core/purchase-customer-list/purchase-customer-list.constant";
import { PURCHASE_BOOSTER_LIST_ROUTE_PATH } from "../../../core/purchase-booster-list/purchase-booster-list.constant";
import { ACCOUNT_UPDATE_AUTH_ROUTE_PATH } from "../../../core/account-update-auth/account-update-auth.constant";
import {
  rememberLinkToRedirectLocalStorage,
  getRememberLinkDataLocalStorage,
} from "../../common/auth/auth.core";

export const AuthRouter = ({ component }) => {
  const { auth } = useSelector((state) => ({
    auth: state[AUTH_STORE_NAME],
  }));

  const { pathname } = useLocation();

  const isAuthRoutes =
    pathname === AUTH_SIGNUP_ROUTE_PATH ||
    pathname === ACCOUNT_RECOVERY_ROUTE_PATH ||
    pathname === AUTH_LOGIN_ROUTE_PATH ||
    pathname === ACCOUNT_UPDATE_AUTH_ROUTE_PATH ||
    pathname === ACCOUNT_VERIFICATION_EMAIL_ROUTE_PATH;

  if (auth && auth.user && !auth.user.confirmEmail) {
    return <Navigate to={ACCOUNT_VERIFICATION_EMAIL_ROUTE_PATH} />;
  }

  if (isAuthRoutes && !auth.token && !auth.logged) {
    return component;
  }

  if (isAuthRoutes && auth.logged) {
    return <Navigate to={INDEX_ROUTE_PATH} />;
  }

  if (!auth.logged) {
    rememberLinkToRedirectLocalStorage(pathname);
    return <Navigate to={AUTH_LOGIN_ROUTE_PATH} />;
  }

  if (!auth.token) {
    return <Navigate to={AUTH_SIGNUP_ROUTE_PATH} />;
  }

  return component;
};

export const IndexRouter = () => {
  const { auth } = useSelector((state) => ({
    auth: state[AUTH_STORE_NAME],
  }));

  if (!auth.logged) {
    return <Navigate to={AUTH_LOGIN_ROUTE_PATH} />;
  }

  const rememberLink = getRememberLinkDataLocalStorage();

  if (rememberLink) {
    <Navigate to={rememberLink} />;
  }

  switch (auth?.user?.role) {
    case USER_ROLE_TYPE.EDITOR: {
      return <Navigate to={GDS_ADMIN_LIST_ROUTE_PATH} />;
    }
    case USER_ROLE_TYPE.ADMIN: {
      return <Navigate to={INDEX_ADMIN_ROUTE_PATH} />;
    }
    case USER_ROLE_TYPE.BLOCKED: {
      return <Navigate to={AUTH_LOGIN_ROUTE_PATH} />;
    }
    case USER_ROLE_TYPE.BOOSTER: {
      return <Navigate to={PURCHASE_BOOSTER_LIST_ROUTE_PATH} />;
    }
    case USER_ROLE_TYPE.USER: {
      return <Navigate to={PURCHASE_CUSTOMER_LIST_ROUTE_PATH} />;
    }
    default: {
      return <></>;
    }
  }
};

export const PrivateRouter = ({ component, roles = [], anotherPath }) => {
  const { auth } = useSelector((state) => ({
    auth: state[AUTH_STORE_NAME],
  }));

  const role = auth?.user?.role;

  if (roles.includes(role)) {
    return component;
  }

  if (!anotherPath) {
    return <Navigate to={INDEX_ROUTE_PATH} />;
  }

  switch (role) {
    case USER_ROLE_TYPE.EDITOR: {
      if (
        anotherPath[USER_ROLE_TYPE.EDITOR] &&
        role === USER_ROLE_TYPE.EDITOR
      ) {
        return <Navigate to={anotherPath[USER_ROLE_TYPE.EDITOR]} />;
      }
    }
    case USER_ROLE_TYPE.ADMIN: {
      if (anotherPath[USER_ROLE_TYPE.ADMIN] && role === USER_ROLE_TYPE.ADMIN) {
        return <Navigate to={anotherPath[USER_ROLE_TYPE.ADMIN]} />;
      }
    }
    case USER_ROLE_TYPE.BOOSTER: {
      if (
        anotherPath[USER_ROLE_TYPE.BOOSTER] &&
        role === USER_ROLE_TYPE.BOOSTER
      ) {
        return <Navigate to={anotherPath[USER_ROLE_TYPE.BOOSTER]} />;
      }
    }
    case USER_ROLE_TYPE.USER: {
      if (anotherPath[USER_ROLE_TYPE.USER] && role === USER_ROLE_TYPE.USER) {
        return <Navigate to={anotherPath[USER_ROLE_TYPE.USER]} />;
      }
    }
    default: {
      return <Navigate to={INDEX_ROUTE_PATH} />;
    }
  }
};

export const PrivateRouterDynamic = ({
  component,
  roles = [],
  anotherPath,
}) => {
  const { purchaseId } = useParams();
  const { auth } = useSelector((state) => ({
    auth: state[AUTH_STORE_NAME],
  }));

  const role = auth?.user?.role;

  if (roles.includes(role)) {
    return component;
  }

  if (!anotherPath) {
    return <Navigate to={INDEX_ROUTE_PATH} />;
  }

  switch (role) {
    case USER_ROLE_TYPE.EDITOR: {
      if (
        anotherPath[USER_ROLE_TYPE.EDITOR] &&
        role === USER_ROLE_TYPE.EDITOR
      ) {
        const getPath = anotherPath[USER_ROLE_TYPE.EDITOR];
        return <Navigate to={getPath({ purchaseId })} />;
      }
    }
    case USER_ROLE_TYPE.ADMIN: {
      if (anotherPath[USER_ROLE_TYPE.ADMIN] && role === USER_ROLE_TYPE.ADMIN) {
        const getPath = anotherPath[USER_ROLE_TYPE.ADMIN];
        return <Navigate to={getPath({ purchaseId })} />;
      }
    }
    case USER_ROLE_TYPE.BOOSTER: {
      if (
        anotherPath[USER_ROLE_TYPE.BOOSTER] &&
        role === USER_ROLE_TYPE.BOOSTER
      ) {
        const getPath = anotherPath[USER_ROLE_TYPE.BOOSTER];
        return <Navigate to={getPath({ purchaseId })} />;
      }
    }
    case USER_ROLE_TYPE.USER: {
      if (anotherPath[USER_ROLE_TYPE.USER] && role === USER_ROLE_TYPE.USER) {
        const getPath = anotherPath[USER_ROLE_TYPE.USER];
        return <Navigate to={getPath({ purchaseId })} />;
      }
    }

    default: {
      return <Navigate to={INDEX_ROUTE_PATH} />;
    }
  }
};
