import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseCustomerItemDataContainer } from "../purchase-customer-item-data/purchase-customer-item-data.container";
import { PurchaseCustomerItemDataPaymentContainer } from "../purchase-customer-item-data-payment/purchase-customer-item-data-payment.container";
import { PurchaseCustomerItemDataContentContainer } from "../purchase-customer-item-data-content/purchase-customer-item-data-content.container";

import { PurchaseCustomerItemContainer } from "../purchase-customer-item/purchase-customer-item.container";
import { PurchaseCustomerItemTabContainer } from "../purchase-customer-item-tab/purchase-customer-item-tab.container";

export function PurchaseCustomerItemPage() {
  return (
    <>
      <PurchaseCustomerItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseCustomerItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemDataContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemDataPaymentContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemDataContentContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
