export const PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_STORE_NAME =
  'PURCHASE_CUSTOMER_ITEM_CHAT_CREATE';

export const PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: (purchaseId) => `/chat-message/purchase/${purchaseId}/customer`,
    TYPE: 'POST',
  },
};

export const PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE = {
  FORM_PENDING: 'PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME = {
  DATA: 'data',
  FILE: 'fileId',
};
