import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PurchaseBoosterItemUpdateBoosterComponent } from "./purchase-booster-item-update-booster.component";
import { uploadPurchaseBoosterItemUpdateBooster } from "./purchase-booster-item-update-booster.action";
import { PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_STORE_NAME } from "./purchase-booster-item-update-booster.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PurchaseBoosterItemUpdateBoosterContainer(purchaseId) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const purchaseBoosterItemUpdateBoosterFormSendData = () => {
    dispatch(uploadPurchaseBoosterItemUpdateBooster(purchaseId, navigate));
  };

  return (
    <PurchaseBoosterItemUpdateBoosterComponent
      updateBooster={purchaseBoosterItemUpdateBoosterFormSendData}
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
