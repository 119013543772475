import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AccountVerificationEmailComponent } from "./account-verification-email.component";
import { accountVerificationEmailFormValidation } from "./account-verification-email.validation";
import { convertAccountVerificationEmailFormData } from "./account-verification-email.convert";
import {
  uploadAccountVerificationEmailForm,
  getAccountVerificationEmailForm,
  resetAccountVerificationEmailForm,
} from "./account-verification-email.action";
import {
  ACCOUNT_VERIFICATION_EMAIL_STORE_NAME,
  ACCOUNT_VERIFICATION_EMAIL_DATA_NAME,
} from "./account-verification-email.constant";
import { AUTH_STORE_NAME } from "../../lib/common/auth";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function AccountVerificationEmailContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { state, pageLoading, userEmail } = useSelector((state) => ({
    state: state[ACCOUNT_VERIFICATION_EMAIL_STORE_NAME],
    userEmail: state[AUTH_STORE_NAME].user?.email,
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const loginFormSendData = (values) => {
    const data = convertAccountVerificationEmailFormData(values);
    dispatch(uploadAccountVerificationEmailForm(data, navigate));
  };

  const getInitialValue = () => {
    return {
      [ACCOUNT_VERIFICATION_EMAIL_DATA_NAME.CODE]: "",
    };
  };

  React.useEffect(() => {
    dispatch(resetAccountVerificationEmailForm());
    dispatch(getAccountVerificationEmailForm()).then(() => {});
  }, []);

  return (
    <AccountVerificationEmailComponent
      isPending={
        isRequestPending(state.form) || isRequestPending(state.request)
      }
      isError={isRequestError(state.form) || isRequestError(state.request)}
      isSuccess={
        isRequestSuccess(state.form) && isRequestSuccess(state.request)
      }
      initialValue={getInitialValue()}
      validation={accountVerificationEmailFormValidation}
      onSubmitForm={loginFormSendData}
      pageLoading={pageLoading}
      userEmail={userEmail}
      errorMessage={
        getRequestErrorMessage(state.form) ||
        getRequestErrorMessage(state.request)
      }
    />
  );
}
