import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FileListComponent } from '../../../lib/common/file-list/file-list.component';

import { TextComponent } from '../../../lib/common/text/text.component';
import { PurchaseAdminItemCoachingDemoDeleteContainer } from '../../purchase-admin-item-coaching-demo-delete/purchase-admin-item-coaching-demo-delete.container';
import { PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME } from '../purchase-admin-item-coaching-demo-list.constant';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { ModalConfirmComponent } from '../../../lib/common/modal-confirm/modal-confirm.component';

export function PurchaseAdminItemCoachingDemoListViewComponent(props) {
  const { data } = props;

  if (!data.list?.length) {
    return (
      <Box sx={{ pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="PURCHASE.ADMIN.ITEM_COACHING_DEMO.LIST.VIEW.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  return (
    <List>
      {data.list.map((item, index) => (
        <React.Fragment>
          {index !== 0 && (
            <ListItem sx={{ p: 0 }}>
              <Divider sx={{ width: '100%', opacity: 0.4 }} />
            </ListItem>
          )}
          <ListItem
            sx={{ px: 0 }}
            variant="list"
            secondaryAction={
              <PurchaseAdminItemCoachingDemoDeleteContainer
                coachingDemoId={
                  item[PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.ID]
                }
              />
            }
          >
            <Grid container spacing={2} sx={{ pr: 12 }}>
              <Grid item>
                <TextComponent variant="body1">
                  {item[PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.INFO]}
                </TextComponent>
              </Grid>
              <Grid item>
                <FileListComponent
                  item={
                    item[
                      PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.DEMO_FILE
                    ]
                  }
                />
              </Grid>
              <Grid item>
                <TextComponent variant="body1Small" sx={{ opacity: 0.5 }}>
                  {
                    item[
                      PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME
                        .CREATE_DATE
                    ]
                  }
                </TextComponent>
              </Grid>
            </Grid>
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
}
