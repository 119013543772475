import { Formik } from 'formik';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import { SkeletonFormComponent } from '../../lib/common/skeleton-form/skeleton-form.component';
import { LoaderBarComponent } from '../../lib/common/loader-bar';
import { TextComponent } from '../../lib/common/text';

import { PurchaseAdminItemCoachingReportCreateFormComponent } from './frame/purchase-admin-item-coaching-report-create-form.component';

export function PurchaseAdminItemCoachingReportCreateComponent(props) {
  const {
    initialValue,
    pageLoading,
    isPending,
    validation,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
    userEmail,
    isDependentPending,
  } = props;
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="PURCHASE.ADMIN.ITEM_COACHING_REPORT.CREATE.TITLE"
            />
          </Box>
          <Divider />
          {isDependentPending ? (
            <Box sx={{ pt: 4 }}>
              <SkeletonFormComponent />
            </Box>
          ) : (
            <Formik
              enableReinitialize
              initialValues={initialValue}
              validate={validation}
              onSubmit={onSubmitForm}
            >
              {(props) => (
                <PurchaseAdminItemCoachingReportCreateFormComponent
                  {...props}
                  isPending={isPending}
                  isError={isError}
                  errorMessage={errorMessage}
                  pageLoading={pageLoading}
                  userEmail={userEmail}
                />
              )}
            </Formik>
          )}

          {isError && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isSuccess && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="success">
                <TextComponent tid="PURCHASE.ADMIN.ITEM_COACHING_REPORT.CREATE.SUCCESS_MESSAGE" />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
