export const NEWS_ADMIN_CREATE_ROUTE_PATH = '/admin/news/create';

export const NEWS_ADMIN_CREATE_STORE_NAME = 'NEWS_ADMIN_CREATE';

export const NEWS_ADMIN_CREATE_API = {
  NEWS_ADMIN_CREATE: {
    ENDPOINT: '/news/',
    TYPE: 'POST',
  },
};

export const NEWS_ADMIN_CREATE_ACTION_TYPE = {
  FORM_PENDING: 'NEWS_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'NEWS_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'NEWS_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'NEWS_ADMIN_CREATE_ACTION_TYPE.FORM_RESET',
};

export const NEWS_ADMIN_CREATE_DATA_NAME = {
  ORDER: 'order',
  TITLE: 'title',
  DESCRIPTION: 'description',
  BODY: 'body',
  URL: 'url',
  PREVIEW: 'fileImageId',
  CATEGORY: 'category',
  LANG: 'lang',
};
