import { httpRequest } from '../../main/http';

import {
  POPUP_ADMIN_ITEM_DATA_API,
  POPUP_ADMIN_ITEM_DATA_ACTION_TYPE,
  POPUP_ADMIN_ITEM_DATA_STORE_NAME,
} from './popup-admin-item-data.constant';

import { convertPopupAdminItemData } from './popup-admin-item-data.convert';

export function getPopupAdminItemData(data) {
  return async (dispatch) => {
    dispatch({
      type: POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: POPUP_ADMIN_ITEM_DATA_API.POPUP_ADMIN_ITEM_DATA.TYPE,
        url: POPUP_ADMIN_ITEM_DATA_API.POPUP_ADMIN_ITEM_DATA.ENDPOINT(
          data.popupId,
        ),
      });

      dispatch({
        type: POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPopupAdminItemData(res.data),
        popupId: data.popupId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadPopupAdminItemData() {
  return async (dispatch, getState) => {
    const { popupId } = getState()[POPUP_ADMIN_ITEM_DATA_STORE_NAME];

    dispatch({
      type: POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: POPUP_ADMIN_ITEM_DATA_API.POPUP_ADMIN_ITEM_DATA.TYPE,
        url: POPUP_ADMIN_ITEM_DATA_API.POPUP_ADMIN_ITEM_DATA.ENDPOINT(popupId),
      });

      dispatch({
        type: POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPopupAdminItemData(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: POPUP_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
