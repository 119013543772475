import { PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME } from './purchase-admin-item-coaching-demo-list.constant';

import { convertFile } from '../../lib/common/file/file.convert';
import { convertDatetime } from '../../lib/common/convert/convert.core';

export const convertPurchaseAdminItemCoachingDemoList = (d) => {
  return {
    list: d.list.map((data) => {
      return {
        [PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.ID]:
          data[PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.ID],
        [PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.CREATE_DATE]:
          convertDatetime(
            data[PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.CREATE_DATE],
          ),
        [PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.INFO]:
          data[PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.INFO],
        [PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.DEMO_FILE]:
          convertFile(
            data[PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_DATA_NAME.DEMO_FILE],
          ),
      };
    }),
  };
};
