import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

import { TextComponent } from '../../text';

export const FieldFileFormComponent = (props) => {
  const { onFileAdd } = props;

  return (
    <Paper fullWidth sx={{ width: '100%', borderColor: '#5c5e76' }}>
      <Button
        fullWidth
        variant="grey"
        color="black"
        size="small"
        component="label"
        sx={{ height: '46px' }}
      >
        <TextComponent variant="fieldText" tid="FILE.FIELD.SUBMIT" />
        <input onChange={onFileAdd} value="" type="file" hidden accept="*" />
      </Button>
    </Paper>
  );
};
