import { GDS_ADMIN_ITEM_DATA_DATA_NAME } from './gds-admin-item-data.constant';

import {
  convertGdsStatus,
  convertGdsTagList,
  convertGdsType,
  convertGdsCategory,
} from '../../lib/common/gds/gds.convert';

import { convertDatetime } from '../../lib/common/convert/convert.core';
import {
  convertFile,
  convertFileList,
} from '../../lib/common/file/file.convert';

export const convertGdsAdminItemData = (data) => ({
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY_EN]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY_EN],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY_RU]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.BODY_RU],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY]: convertGdsCategory(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]: convertDatetime(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_EN]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_EN],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_RU]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_RU],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST_EN]: convertFileList(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST_EN],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST_RU]: convertFileList(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_LIST_RU],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW_EN]: convertFile(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW_EN],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW_RU]: convertFile(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.FILE_PREVIEW_RU],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.ID]: data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ID],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.ORDER]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.ORDER],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.STATUS]: convertGdsStatus(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STATUS],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.STOCK_RU]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STOCK_RU],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE_RU]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE_RU],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.STOCK_EN]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.STOCK_EN],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE_EN]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.PRICE_EN],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.TAG]: convertGdsTagList(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TAG],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE_EN]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE_EN],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE_RU]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TITLE_RU],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.TYPE]: convertGdsType(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.TYPE],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]: convertDatetime(
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE],
  ),
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.URL_RU]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.URL_RU],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.URL_EN]:
    data[GDS_ADMIN_ITEM_DATA_DATA_NAME.URL_EN],
  [GDS_ADMIN_ITEM_DATA_DATA_NAME.HAS_VERIFY]:
    !!data[GDS_ADMIN_ITEM_DATA_DATA_NAME.HAS_VERIFY],
});
