import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseCustomerItemTabContainer } from "../purchase-customer-item-tab/purchase-customer-item-tab.container";
import { PurchaseCustomerItemCoachingDemoListContainer } from "../purchase-customer-item-coaching-demo-list/purchase-customer-item-coaching-demo-list.container";
import { PurchaseCustomerItemContainer } from "../purchase-customer-item/purchase-customer-item.container";
import { PurchaseCustomerItemCoachingDemoCreateContainer } from "../purchase-customer-item-coaching-demo-create/purchase-customer-item-coaching-demo-create.container";

export function PurchaseCustomerItemCoachingDemoPage() {
  return (
    <Container maxWidth="sm">
      <PurchaseCustomerItemContainer />
      <Grid spacing={3} container>
        <Grid item>
          <PurchaseCustomerItemTabContainer />
        </Grid>
        <Grid item>
          <PurchaseCustomerItemCoachingDemoListContainer />
        </Grid>
        <Grid item>
          <PurchaseCustomerItemCoachingDemoCreateContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
