import React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";

import { NoticeAccountListShortContainer } from "../notice-account-list-short/notice-account-list-short.container";
import { NoticeAccountListShortHandler } from "../notice-account-list-short/notice-account-list-short.handler";
import { USER_ROLE_TYPE } from "../../lib/common/user/user.type";
import { NavigationOnlineItemContainer } from "../navigation-online-item/navigation-online-item.container";

export function NavigationNoticeComponent({ noticeAmount, role }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <NoticeAccountListShortHandler />
      <Container>
        {role === USER_ROLE_TYPE.BOOSTER && <NavigationOnlineItemContainer />}
        <IconButton
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          {noticeAmount ? (
            <Badge max={30} badgeContent={noticeAmount} color="primary">
              <NotificationsIcon />
            </Badge>
          ) : (
            <NotificationsNoneIcon />
          )}
        </IconButton>
      </Container>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ width: "420px" }}>
          <NoticeAccountListShortContainer />
        </Box>
      </Popover>
    </Box>
  );
}

const Container = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;
