import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { PurchaseBoosterItemCoachingReportListComponent } from './purchase-booster-item-coaching-report-list.component';
import { getPurchaseBoosterItemCoachingReportList } from './purchase-booster-item-coaching-report-list.action';
import { PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_STORE_NAME } from './purchase-booster-item-coaching-report-list.constant';

export function PurchaseBoosterItemCoachingReportListContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {

    const data = { purchaseId };

    dispatch(getPurchaseBoosterItemCoachingReportList(data));
  }, []);

  return (
    <PurchaseBoosterItemCoachingReportListComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
