import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { PromocodeAdminCreateComponent } from "./promocode-admin-create.component";
import { promocodeAdminCreateFormValidation } from "./promocode-admin-create.validation";
import { convertPromocodeAdminCreateFormData } from "./promocode-admin-create.convert";
import {
  uploadPromocodeAdminCreateForm,
  resetPromocodeAdminCreateForm,
} from "./promocode-admin-create.action";
import { PROMOCODE_ADMIN_CREATE_STORE_NAME } from "./promocode-admin-create.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PromocodeAdminCreateContainer() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PROMOCODE_ADMIN_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const signupFormSendData = (values) => {
    const data = convertPromocodeAdminCreateFormData(values);
    dispatch(uploadPromocodeAdminCreateForm(data, navigate));
  };

  React.useEffect(() => {
    dispatch(resetPromocodeAdminCreateForm());
  }, []);

  const getInitialValue = () => {
    return {};
  };

  return (
    <PromocodeAdminCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={promocodeAdminCreateFormValidation}
      onSubmitForm={signupFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
