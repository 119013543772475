import { httpRequest } from '../../main/http';

import {
  NOTICE_ACCOUNT_LIST_SHORT_API,
  NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE,
} from './notice-account-list-short.constant';

import { convertNoticeAccountListShort } from './notice-account-list-short.convert';

export function getNoticeAccountListShort() {
  return async (dispatch) => {
    dispatch({
      type: NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: NOTICE_ACCOUNT_LIST_SHORT_API.NOTICE_ACCOUNT_LIST_SHORT.TYPE,
        url: NOTICE_ACCOUNT_LIST_SHORT_API.NOTICE_ACCOUNT_LIST_SHORT.ENDPOINT,
      });

      dispatch({
        type: NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertNoticeAccountListShort(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function updateNoticeAccountListShort() {
  return async (dispatch) => {
    dispatch({
      type: NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: NOTICE_ACCOUNT_LIST_SHORT_API.NOTICE_ACCOUNT_LIST_SHORT.TYPE,
        url: NOTICE_ACCOUNT_LIST_SHORT_API.NOTICE_ACCOUNT_LIST_SHORT.ENDPOINT,
      });

      dispatch({
        type: NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertNoticeAccountListShort(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NOTICE_ACCOUNT_LIST_SHORT_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
