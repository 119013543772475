import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { NewsAdminListPaginationComponent } from "./news-admin-list-pagination.component";
import { NEWS_ADMIN_LIST_STORE_NAME } from "../news-admin-list/news-admin-list.constant";
import { getNewsAdminList } from "../news-admin-list/news-admin-list.action";
import { getRequestData } from "../../main/store/store.service";

export function NewsAdminListPaginationContainer() {
  const dispatch = useDispatch();

  const { state } = useSelector((state) => ({
    state: getRequestData(state[NEWS_ADMIN_LIST_STORE_NAME].request),
  }));

  const onChangeFilter = (data) => {
    dispatch(getNewsAdminList({ ...state, ...data }));
  };

  return (
    <NewsAdminListPaginationComponent
      data={state}
      onChangeFilter={onChangeFilter}
    />
  );
}
