export enum AUTH_USER_DATA {
  ID = 'id',
  ROLE = 'role',
  EMAIL = 'email',
  LOGIN = 'login',
  TIMEZONE = 'timezone',
  CONFIRM_EMAIL = 'confirmEmail',
}

export interface AuthUserDto {
  id: number;
  role: number;
  login: string;
  email: string;
  timezone: number;
  confirmEmail: boolean;
}

export interface AuthStoreState {
  token: string | null;
  logged: boolean | null;
  user: AuthUserDto | null;
}

export interface AuthStoreAction extends AuthStoreState {
  type: AUTH_ACTION_TYPE;
}

export enum AUTH_ACTION_TYPE {
  SET_DATA = 'AUTH.SET_DATA',
}
