import React from 'react';

import { PurchaseAdminItemDataComponent } from './purchase-admin-item-data.component';

import { useSelector } from 'react-redux';

import {
  PURCHASE_ADMIN_ITEM_DATA_NAME,
  PURCHASE_ADMIN_ITEM_STORE_NAME,
} from '../purchase-admin-item/purchase-admin-item.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';

export function PurchaseAdminItemDataContainer() {
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  return (
    <PurchaseAdminItemDataComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
