import { GdsItemAdminCreateContainer } from "./gds-item-admin-create.container";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

export function GdsItemAdminCreatePage() {
  return (
    <Container maxWidth="sm">
      <Grid container>
        <Grid item>
          <GdsItemAdminCreateContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
