import { NOTICE_ACCOUNT_LIST_DATA_NAME } from "./notice-account-list.constant";

import { convertNoticeList } from "../../lib/common/notice/notice.convert";

export const convertNoticeAccountList = (d) => ({
  [NOTICE_ACCOUNT_LIST_DATA_NAME.LIST]: convertNoticeList(
    d[NOTICE_ACCOUNT_LIST_DATA_NAME.LIST]
  ),

  [NOTICE_ACCOUNT_LIST_DATA_NAME.SKIP]: d[NOTICE_ACCOUNT_LIST_DATA_NAME.SKIP],
  [NOTICE_ACCOUNT_LIST_DATA_NAME.TAKE]: d[NOTICE_ACCOUNT_LIST_DATA_NAME.TAKE],
  [NOTICE_ACCOUNT_LIST_DATA_NAME.AMOUNT]:
    d[NOTICE_ACCOUNT_LIST_DATA_NAME.AMOUNT],
});
