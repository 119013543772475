export const USER_ADMIN_LIST_ROUTE_PATH = '/admin/user';

export const USER_ADMIN_LIST_STORE_NAME = 'USER_ADMIN_LIST';

export const USER_ADMIN_LIST_API = {
  USER_ADMIN_LIST: {
    ENDPOINT: `/user/admin/user`,
    TYPE: 'GET',
  },
};

export const USER_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'USER_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'USER_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'USER_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR',
};

export const USER_ADMIN_LIST_DATA_NAME = {
  LOGIN: 'login',
  EMAIL: 'email',
  ID: 'id',

  SKIP: 'skip',
  TAKE: 'take',
  AMOUNT: 'amount',
};
