import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { NavigationContainer } from "../navigation/navigation.container";
import { PurchaseCustomerItemBoostTimeListContainer } from "../purchase-customer-item-boost-time-list/purchase-customer-item-boost-time-list.container";
import { PurchaseCustomerItemBoostTimeCreateContainer } from "../purchase-customer-item-boost-time-create/purchase-customer-item-boost-time-create.container";
import { PurchaseCustomerItemContainer } from "../purchase-customer-item/purchase-customer-item.container";
import { PurchaseCustomerItemTabContainer } from "../purchase-customer-item-tab/purchase-customer-item-tab.container";

export function PurchaseCustomerItemBoostTimePage() {
  return (
    <>
      <PurchaseCustomerItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseCustomerItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemBoostTimeListContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemBoostTimeCreateContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
