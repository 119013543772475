import {
  convertFileFieldValue,
  convertOneFileFieldValue,
} from '../../lib/common/field-file/field-file.convert';
import { convertSelectFieldValue } from '../../lib/common/field-select/field-select.convert';
import { NEWS_ADMIN_CREATE_DATA_NAME } from './news-admin-create.constant';

export const convertNewsAdminCreateFormData = (data) => ({
  [NEWS_ADMIN_CREATE_DATA_NAME.ORDER]: Number(
    data[NEWS_ADMIN_CREATE_DATA_NAME.ORDER],
  ),
  [NEWS_ADMIN_CREATE_DATA_NAME.BODY]: String(
    data[NEWS_ADMIN_CREATE_DATA_NAME.BODY],
  ),
  [NEWS_ADMIN_CREATE_DATA_NAME.CATEGORY]: convertSelectFieldValue(
    data[NEWS_ADMIN_CREATE_DATA_NAME.CATEGORY],
  ),
  [NEWS_ADMIN_CREATE_DATA_NAME.DESCRIPTION]:
    data[NEWS_ADMIN_CREATE_DATA_NAME.DESCRIPTION],
  [NEWS_ADMIN_CREATE_DATA_NAME.LANG]: convertSelectFieldValue(
    data[NEWS_ADMIN_CREATE_DATA_NAME.LANG],
  ),
  [NEWS_ADMIN_CREATE_DATA_NAME.PREVIEW]: convertOneFileFieldValue(
    data[NEWS_ADMIN_CREATE_DATA_NAME.PREVIEW],
  ),

  [NEWS_ADMIN_CREATE_DATA_NAME.TITLE]: String(
    data[NEWS_ADMIN_CREATE_DATA_NAME.TITLE],
  ),
  [NEWS_ADMIN_CREATE_DATA_NAME.URL]: String(
    data[NEWS_ADMIN_CREATE_DATA_NAME.URL],
  ),
});
