import { USER_ADMIN_LIST_DATA_NAME } from './user-admin-list.constant';

export const convertUserAdminList = (d) => ({
  list: d.list.map((data) => {
    return {
      [USER_ADMIN_LIST_DATA_NAME.LOGIN]: data[USER_ADMIN_LIST_DATA_NAME.LOGIN],
      [USER_ADMIN_LIST_DATA_NAME.EMAIL]: data[USER_ADMIN_LIST_DATA_NAME.EMAIL],
      [USER_ADMIN_LIST_DATA_NAME.ID]: data[USER_ADMIN_LIST_DATA_NAME.ID],
    };
  }),

  [USER_ADMIN_LIST_DATA_NAME.SKIP]: d[USER_ADMIN_LIST_DATA_NAME.SKIP],
  [USER_ADMIN_LIST_DATA_NAME.TAKE]: d[USER_ADMIN_LIST_DATA_NAME.TAKE],
  [USER_ADMIN_LIST_DATA_NAME.AMOUNT]: d[USER_ADMIN_LIST_DATA_NAME.AMOUNT],
});
