import { httpRequest } from '../../main/http';

import {
  NEWS_ADMIN_ITEM_UPDATE_DATA_API,
  NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE,
} from './news-admin-item-update-data.constant';

import { reloadNewsAdminItemData } from '../news-admin-item-data/news-admin-item-data.action';

export function uploadNewsAdminItemUpdateDataForm(data, newsId) {
  return async (dispatch) => {
    dispatch({
      type: NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: NEWS_ADMIN_ITEM_UPDATE_DATA_API.CHANGE_DATA.TYPE,
        url: NEWS_ADMIN_ITEM_UPDATE_DATA_API.CHANGE_DATA.ENDPOINT(newsId),
        data,
      });

      dispatch({
        type: NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS,
      });

      await dispatch(reloadNewsAdminItemData());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetNewsAdminItemUpdateDataFormState() {
  return async (dispatch) => {
    dispatch({
      type: NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET,
    });
  };
}
