import { httpRequest } from "../../main/http";

import {
  GDS_ADMIN_ITEM_DELETE_API,
  GDS_ADMIN_ITEM_DELETE_ACTION_TYPE,
} from "./gds-admin-item-delete.constant";

import { GDS_ADMIN_LIST_ROUTE_PATH } from "../gds-admin-list/gds-admin-list.constant";

export function uploadGdsAdminItemDeleteForm(gdsId, redirect) {
  return async (dispatch) => {
    dispatch({
      type: GDS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: GDS_ADMIN_ITEM_DELETE_API.DELETE_DATA.TYPE,
        url: GDS_ADMIN_ITEM_DELETE_API.DELETE_DATA.ENDPOINT(gdsId),
      });

      await redirect(GDS_ADMIN_LIST_ROUTE_PATH);

      dispatch({
        type: GDS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetGdsAdminItemDeleteFormState() {
  return async (dispatch) => {
    dispatch({
      type: GDS_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_RESET,
    });
  };
}
