import {
  initRequestState,
  setRequestPending,
  setRequestSuccess,
  setRequestError,
  setRequestUpdatePending,
  setRequestUpdateSuccess,
} from '../../main/store/store.service';
import { FILE_ADMIN_LIST_ACTION_TYPE } from './file-admin-list.constant';

const initialState = {
  request: initRequestState(),
};

export function fileAdminListStore(state = initialState, action) {
  switch (action.type) {
    case FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.data),
      };

    case FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.errorMessage),
      };

    case FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING:
      return {
        ...state,
        request: setRequestUpdatePending(state.request),
      };

    case FILE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        request: setRequestUpdateSuccess(state.request, action.data),
      };

    default:
      return state;
  }
}
