import { PURCHASE_ADMIN_ITEM_COACHING_REPORT_CREATE_DATA_NAME } from './purchase-admin-item-coaching-report-create.constant';
import { convertOneFileFieldValue } from '../../lib/common/field-file/field-file.convert';

export const convertPurchaseAdminItemCoachingReportCreateFormData = (
  values,
) => {
  return {
    [PURCHASE_ADMIN_ITEM_COACHING_REPORT_CREATE_DATA_NAME.INFO]:
      values[PURCHASE_ADMIN_ITEM_COACHING_REPORT_CREATE_DATA_NAME.INFO],
    [PURCHASE_ADMIN_ITEM_COACHING_REPORT_CREATE_DATA_NAME.FILE]:
      convertOneFileFieldValue(
        values[PURCHASE_ADMIN_ITEM_COACHING_REPORT_CREATE_DATA_NAME.FILE],
      ),
  };
};
