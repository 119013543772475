import { httpRequest } from "../../main/http";

import {
  NEWS_ADMIN_CREATE_API,
  NEWS_ADMIN_CREATE_ACTION_TYPE,
} from "./news-admin-create.constant";

import { NEWS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../news-admin-item/news-admin-item.constant";

export function uploadNewsAdminCreateForm(data, redirect) {
  return async (dispatch) => {
    dispatch({
      type: NEWS_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING,
    });

    try {
      const res = await httpRequest({
        method: NEWS_ADMIN_CREATE_API.NEWS_ADMIN_CREATE.TYPE,
        url: NEWS_ADMIN_CREATE_API.NEWS_ADMIN_CREATE.ENDPOINT,
        data,
      });

      await redirect(NEWS_ADMIN_ITEM_ROUTE_PATH_DYNAMIC(res.data.id));

      dispatch({
        type: NEWS_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NEWS_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetNewsAdminCreateForm(data) {
  return async (dispatch) => {
    dispatch({
      type: NEWS_ADMIN_CREATE_ACTION_TYPE.FORM_RESET,
    });
  };
}
