import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { GdsAdminListContainer } from "./gds-admin-list.container";
import { GdsAdminCreateInfoContainer } from "../gds-admin-create-info/gds-admin-create-info.container";
import { GdsAdminListPaginationContainer } from "../gds-admin-list-pagination/gds-admin-list-pagination.container";

export function GdsAdminListPage() {
  return (
    <Container maxWidth="md">
      <Grid spacing={6} container>
        <Grid item>
          <GdsAdminCreateInfoContainer />
        </Grid>
        <Grid item>
          <GdsAdminListPaginationContainer />
        </Grid>
        <Grid item sx={{ width: "100%", alignSelf: "baseline" }}>
          <GdsAdminListContainer />
        </Grid>
        <Grid item>
          <GdsAdminListPaginationContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
