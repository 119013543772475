export const ACCOUNT_RECOVERY_ROUTE_PATH = '/account/recovery';

export const ACCOUNT_RECOVERY_STORE_NAME = 'ACCOUNT_RECOVERY';

export const ACCOUNT_RECOVERY_API = {
  GET_EMAIL: {
    ENDPOINT: '/user-recovery/email',
    TYPE: 'POST',
  },
};

export const ACCOUNT_RECOVERY_ACTION_TYPE = {
  FORM_PENDING: 'ACCOUNT_RECOVERY_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'ACCOUNT_RECOVERY_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'ACCOUNT_RECOVERY_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'ACCOUNT_RECOVERY_ACTION_TYPE.FORM_RESET',
};

export const ACCOUNT_RECOVERY_DATA_NAME = {
  EMAIL: 'email',
};
