import * as React from "react";
import { useSelector } from "react-redux";

import { NavigationDesctopComponent } from "./navigation-desctop.component";
import { NavigationMobileAppComponent } from "./navigation-mobile-app.component";
import { NavigationMobileComponent } from "./navigation-mobile.component";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { AUTH_STORE_NAME } from "../../lib/common/auth";
import { USER_ROLE_TYPE } from "../../lib/common/user/user.type";
import {
  NAVIGATION_ADMIN_ROUTE_LIST,
  NAVIGATION_USER_ROUTE_LIST,
  NAVIGATION_BOOSTER_ROUTE_LIST,
  NAVIGATION_EDITOR_ROUTE_LIST,
} from "./navigation.constant";
import { NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME } from "../notice-account-list-short/notice-account-list-short.constant";
import {
  getRequestData,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NavigationNotLoggedComponent } from "./navigation-not-logged.component";

export function NavigationContainer(props) {
  const { activePath, role, notice, logged } = useSelector((state) => ({
    activePath: state[NAVIGATION_STORE_NAME].activePath,
    role: state[AUTH_STORE_NAME].user?.role,
    logged: state[AUTH_STORE_NAME].logged,
    notice: state[NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME].request,
  }));

  if (!logged) {
    return <NavigationNotLoggedComponent {...props} />;
  }

  const isNoticeLoaded = isRequestSuccess(notice);
  const noticeAmount = isNoticeLoaded ? getRequestData(notice).amount : 0;

  if (typeof document !== "undefined") {
    const width = document ? document.body.clientWidth || 1200 : 1200;

    if (width >= 1200) {
      if (role === USER_ROLE_TYPE.ADMIN) {
        return (
          <NavigationDesctopComponent
            role={role}
            routeList={NAVIGATION_ADMIN_ROUTE_LIST}
            activePath={activePath}
            noticeAmount={noticeAmount}
            {...props}
          />
        );
      }

      if (role === USER_ROLE_TYPE.USER) {
        return (
          <NavigationDesctopComponent
            routeList={NAVIGATION_USER_ROUTE_LIST}
            activePath={activePath}
            noticeAmount={noticeAmount}
            {...props}
          />
        );
      }

      if (role === USER_ROLE_TYPE.BOOSTER) {
        return (
          <NavigationDesctopComponent
            role={role}
            routeList={NAVIGATION_BOOSTER_ROUTE_LIST}
            activePath={activePath}
            noticeAmount={noticeAmount}
            {...props}
          />
        );
      }
      if (role === USER_ROLE_TYPE.EDITOR) {
        return (
          <NavigationDesctopComponent
            routeList={NAVIGATION_EDITOR_ROUTE_LIST}
            activePath={activePath}
            noticeAmount={noticeAmount}
            {...props}
          />
        );
      }
    } else {
      if (role === USER_ROLE_TYPE.ADMIN) {
        return (
          <NavigationMobileComponent
            routeList={NAVIGATION_ADMIN_ROUTE_LIST}
            activePath={activePath}
            noticeAmount={noticeAmount}
            {...props}
          />
        );
      }
      if (role === USER_ROLE_TYPE.USER) {
        return (
          <NavigationMobileAppComponent
            routeList={NAVIGATION_USER_ROUTE_LIST}
            activePath={activePath}
            noticeAmount={noticeAmount}
            {...props}
          />
        );
      }
      if (role === USER_ROLE_TYPE.BOOSTER) {
        return (
          <NavigationMobileAppComponent
            role={role}
            routeList={NAVIGATION_BOOSTER_ROUTE_LIST}
            activePath={activePath}
            noticeAmount={noticeAmount}
            {...props}
          />
        );
      }
      if (role === USER_ROLE_TYPE.EDITOR) {
        return (
          <NavigationMobileAppComponent
            routeList={NAVIGATION_EDITOR_ROUTE_LIST}
            activePath={activePath}
            noticeAmount={noticeAmount}
            {...props}
          />
        );
      }
    }
  }

  return null;
}
