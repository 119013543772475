import { formatDateToUtc } from '../../lib/common/convert/convert.core';
import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME } from './purchase-customer-item-boost-time-create.constant';

export const convertPurchaseCustomerItemBoostTimeCreateFormData = (values) => {
  return {
    [PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME]:
      formatDateToUtc(
        values[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME],
      ),
    [PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME]:
      formatDateToUtc(
        values[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME],
      ),
  };
};
