export const USER_ADMIN_CREATE_ROUTE_PATH = '/admin/user/create';

export const USER_ADMIN_CREATE_STORE_NAME = 'USER_ADMIN_CREATE';

export const USER_ADMIN_CREATE_API = {
  USER_ADMIN_CREATE: {
    ENDPOINT: '/auth/admin/user',
    TYPE: 'POST',
  },
};

export const USER_ADMIN_CREATE_ACTION_TYPE = {
  FORM_PENDING: 'USER_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'USER_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'USER_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'USER_ADMIN_CREATE_ACTION_TYPE.FORM_RESET',
};

export const USER_ADMIN_CREATE_DATA_NAME = {
  LOGIN: 'login',
  ROLE: 'role',
  EMAIL: 'email',
  TIMEZONE: 'timezone',
  BOOSTER_ROLE: 'boosterRole',
};
