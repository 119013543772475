import React from 'react';

import { PurchaseBoosterItemTimezoneComponent } from './purchase-booster-item-timezone.component';

import { useSelector } from 'react-redux';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import {
  calcCurrentTimeByTimezone,
  calcTimezone,
} from '../../lib/common/time/time.core';
import { convertTime } from '../../lib/common/convert/convert.core';
import { convertTimezone } from '../../lib/common/user/user.convert';
import { AUTH_STORE_NAME } from '../../lib/common/auth';
import {
  PURCHASE_BOOSTER_ITEM_DATA_NAME,
  PURCHASE_BOOSTER_ITEM_STORE_NAME,
} from '../purchase-booster-item/purchase-booster-item.constant';

export function PurchaseBoosterItemTimezoneContainer() {
  const { state, pageLoading, auth } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    auth: state[AUTH_STORE_NAME],
  }));

  const [timezone, setTimezoneData] = React.useState({});

  const calcTime = () => {
    if (!isRequestSuccess(state.request)) return null;

    let customerTime = null;
    let boosterTime = null;

    const currentTimezone = calcTimezone();

    const data = getRequestData(state.request);

    if (data[PURCHASE_BOOSTER_ITEM_DATA_NAME.IS_CUSTOMER]) {
      customerTime = data[PURCHASE_BOOSTER_ITEM_DATA_NAME.CUSTOMER].timezone;
      customerTime.currentTime = calcCurrentTimeByTimezone(
        Number(customerTime.value),
        Number(currentTimezone),
      );

      customerTime.currentTime = convertTime(customerTime.currentTime);
    }

    boosterTime = convertTimezone(Number(auth.user.timezone));
    boosterTime.currentTime = calcCurrentTimeByTimezone(
      Number(boosterTime.value),
      Number(currentTimezone),
    );

    boosterTime.currentTime = convertTime(boosterTime.currentTime);

    setTimezoneData({
      customerTime,
      boosterTime,
      currentTimezone,
      currentTimezoneText: convertTimezone(Number(currentTimezone)).text,
    });
  };

  React.useEffect(calcTime, [isRequestSuccess(state.request)]);

  React.useEffect(() => {
    const id = setInterval(calcTime, 2000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <PurchaseBoosterItemTimezoneComponent
      timezone={timezone}
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
