import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { FileAdminCreateContainer } from "../file-admin-create/file-admin-create.container";
import { FileAdminListContainer } from "../file-admin-list/file-admin-list.container";

export function FileAdminPage() {
  return (
    <Container maxWidth="sm">
      <Grid container spacing={3}>
        <Grid item>
          <FileAdminCreateContainer />
        </Grid>
        <Grid item>
          <FileAdminListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
