import { REMEMBER_LINK_TO_REDIRECT_LOCALSTORAGE_NAME } from "./auth.constant";
import { INDEX_ROUTE_PATH } from "../../../core/index/index.constant";

export const rememberLinkToRedirectLocalStorage = (path: string) => {
  if (path === INDEX_ROUTE_PATH) return null;

  localStorage.setItem(
    REMEMBER_LINK_TO_REDIRECT_LOCALSTORAGE_NAME,
    JSON.stringify({
      path,
    })
  );

  return null;
};

export const getRememberLinkDataLocalStorage = () => {
  const dataJson = localStorage.getItem(
    REMEMBER_LINK_TO_REDIRECT_LOCALSTORAGE_NAME
  );

  if (dataJson) {
    localStorage.removeItem(REMEMBER_LINK_TO_REDIRECT_LOCALSTORAGE_NAME);

    const data = JSON.parse(dataJson);
    return data.path;
  }

  return null;
};
