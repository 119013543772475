import { NewsAdminCreateContainer } from "./news-admin-create.container";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

export function NewsAdminCreatePage() {
  return (
    <Container maxWidth="sm">
      <Grid container>
        <Grid item>
          <NewsAdminCreateContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
