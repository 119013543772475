import { httpRequest } from "../../main/http";

import {
  PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_API,
  PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE,
} from "./purchase-booster-item-update-booster.constant";

export function uploadPurchaseBoosterItemUpdateBooster(data, redirect) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_API.CHANGE_PASSWORD.TYPE,
        url: PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_API.CHANGE_PASSWORD.ENDPOINT(
          data.purchaseId
        ),
        data,
      });

      dispatch({
        type: PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_SUCCESS,
      });

      await redirect(`/booster/work/${data.purchaseId}`);
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseAdminItemUpdateBoosterFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_BOOSTER_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_RESET,
    });
  };
}
