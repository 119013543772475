import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import DoneIcon from "@mui/icons-material/Done";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CircularProgress from "@mui/material/CircularProgress";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { TextComponent } from "../text";

export function NoticeItemButtonComponent({
  text,
  createDate,
  view,
  action,
  loading,
  disabled,
  onDelete,
  link,
}) {
  const isSecondAction = onDelete && !view;
  const navigate = useNavigate();
  const handleAction = (e) => {
    e.preventDefault();
    navigate(link);
    action();
  };

  return (
    <ListItem
      sx={{ p: 0 }}
      disablePadding
      secondaryAction={
        isSecondAction ? (
          <IconButton
            disabled={disabled}
            onClick={onDelete}
            edge="start"
            aria-label="delete"
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <DoneIcon
                sx={{
                  fontSize: "24px",
                  color: "#fff",
                  opacity: disabled ? 0.35 : 1,
                }}
              />
            )}
          </IconButton>
        ) : null
      }
    >
      <ListItemButton
        component="a"
        href={link}
        onClick={handleAction}
        sx={{ px: 6, py: 2 }}
      >
        {!view && (
          <ListItemIcon sx={{ minWidth: "4px", marginRight: 3 }}>
            <Brightness1Icon color="primary" sx={{ fontSize: "12px" }} />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Box sx={{ pr: isSecondAction ? 4 : 0 }}>
              <TextComponent variant="noticeData">{text}</TextComponent>
              <TextComponent sx={{ pt: 2 }} variant="noticeTime">
                {createDate}
              </TextComponent>
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
