import { validate } from '../../main/validate';
import { required } from '../../main/validate/validate.service';
import { PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME } from './purchase-booster-item-update-boost-data.constant';

const config = {
  [PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]: [required],
};

export const purchaseBoosterItemUpdateBoostDataFormValidation = (values) =>
  validate(values, config);
