import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { TextComponent } from "../../../lib/common/text/text.component";
import { USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../../user-admin-item/user-admin-item.constant";

export function UserAdminListViewComponent(props) {
  const { data } = props;

  if (data.list.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent variant="body1" tid="USER.ADMIN.LIST.LIST_IS_EMPTY" />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (userId) => () =>
    navigate(USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ userId }));

  return (
    <List sx={{ pt: 4 }}>
      {data.list.map((item) => (
        <ListItem onClick={handleClickItem(item.id)} button variant="list">
          <ListItemText
            primary={
              <TextComponent gutterBottom variant="body1">
                <TextComponent
                  tvalue={{ id: item.id }}
                  tid="USER.ADMIN.LIST.ID"
                />
              </TextComponent>
            }
            secondary={
              <TextComponent
                tid="USER.ADMIN.LIST.DATA"
                tvalue={{ login: item.login, email: item.email }}
                variant="body1"
              ></TextComponent>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
