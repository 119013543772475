import { httpRequest } from "../../main/http";

import {
  PURCHASE_CUSTOMER_ITEM_CHAT_LIST_API,
  PURCHASE_CUSTOMER_ITEM_CHAT_LIST_STORE_NAME,
  PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE,
} from "./purchase-customer-item-chat-list.constant";

import { convertPurchaseCustomerItemChatList } from "./purchase-customer-item-chat-list.convert";

export function createNoticeItemChatBoosterNoAnswer(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      await httpRequest({
        method:
          PURCHASE_CUSTOMER_ITEM_CHAT_LIST_API
            .PURCHASE_CUSTOMER_ITEM_CHAT_BOOSTER_NO_ANSWER.TYPE,
        url: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_API.PURCHASE_CUSTOMER_ITEM_CHAT_BOOSTER_NO_ANSWER.ENDPOINT(
          data.purchaseId
        ),
      });

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        purchaseId: data.purchaseId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function getPurchaseCustomerItemChatList(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method:
          PURCHASE_CUSTOMER_ITEM_CHAT_LIST_API.PURCHASE_CUSTOMER_ITEM_CHAT_LIST
            .TYPE,
        url: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_API.PURCHASE_CUSTOMER_ITEM_CHAT_LIST.ENDPOINT(
          data.purchaseId
        ),
      });

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseCustomerItemChatList(res.data),
        purchaseId: data.purchaseId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadPurchaseCustomerItemChatList({ purchaseId }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING,
      });

      const res = await httpRequest({
        method:
          PURCHASE_CUSTOMER_ITEM_CHAT_LIST_API.PURCHASE_CUSTOMER_ITEM_CHAT_LIST
            .TYPE,
        url: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_API.PURCHASE_CUSTOMER_ITEM_CHAT_LIST.ENDPOINT(
          purchaseId
        ),
      });

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseCustomerItemChatList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
