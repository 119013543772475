import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { TextComponent } from "../../lib/common/text";
import { AccountTelegramConnectFormComponent } from "./frame/account-telegram-connect-form.component";

export function AccountTelegramConnectComponent(props) {
  const {
    pageLoading,
    isPending,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
    userEmail,
  } = props;
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="heading"
              gutterBottom
              component="div"
              tid="ACCOUNT.TELEGRAM.CONNECT.HEADING"
            />
            <TextComponent
              variant="subtitle"
              component="div"
              tid="ACCOUNT.TELEGRAM.CONNECT.SUBTITLE"
              tvalue={{ email: userEmail }}
            />
          </Box>
          <Divider />
          <AccountTelegramConnectFormComponent
            onSubmit={onSubmitForm}
            isPending={isPending}
            isError={isError}
            isSuccess={isSuccess}
            errorMessage={errorMessage}
            pageLoading={pageLoading}
          />
          {isError && (
            <Box sx={{ pb: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
          {isSuccess && (
            <Box sx={{ pb: 4 }}>
              <Alert severity="success">
                <TextComponent tid="ACCOUNT.TELEGRAM.CONNECT.SUCCESS" />
              </Alert>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
