import { httpRequest } from "../../main/http";

import {
  PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_API,
  PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE,
} from "./purchase-admin-item-update-booster.constant";

export function uploadPurchaseAdminItemUpdateBoosterForm(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_PENDING,
    });

    const isRemoveBooster = data.id.length < 1;
    const newData = isRemoveBooster ? { purchaseId: data.purchaseId } : data;
    const endpoint = isRemoveBooster
      ? PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_API.REMOVE_BOOSTER.ENDPOINT(
          data.purchaseId
        )
      : PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_API.CHANGE_PASSWORD.ENDPOINT(
          data.purchaseId,
          data.id
        );

    try {
      await httpRequest({
        method: PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_API.CHANGE_PASSWORD.TYPE,
        url: endpoint,
        newData,
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseAdminItemUpdateBoosterFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_UPDATE_BOOSTER_ACTION_TYPE.FORM_RESET,
    });
  };
}
