export const PROMOCODE_ADMIN_ITEM_DELETE_STORE_NAME =
  'PROMOCODE_ADMIN_ITEM_DELETE';

export const PROMOCODE_ADMIN_ITEM_DELETE_API = {
  DELETE_DATA: {
    ENDPOINT: (id) => `/promocode/${id}`,
    TYPE: 'DELETE',
  },
};

export const PROMOCODE_ADMIN_ITEM_DELETE_ACTION_TYPE = {
  FORM_PENDING: 'PROMOCODE_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PROMOCODE_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PROMOCODE_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PROMOCODE_ADMIN_ITEM_DELETE_ACTION_TYPE.FORM_RESET',
};
