import { NEWS_ADMIN_ITEM_DATA_DATA_NAME } from './news-admin-item-data.constant';

import {
  convertNewsStatus,
  convertNewsCategory,
  convertNewsLang,
} from '../../lib/common/news/news.convert';

import { convertDatetime } from '../../lib/common/convert/convert.core';
import { convertFile } from '../../lib/common/file/file.convert';

export const convertNewsAdminItemData = (data) => ({
  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.ID]: data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.ID],
  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.BODY]:
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.BODY],
  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY]: convertNewsCategory(
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY],
  ),
  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]: convertDatetime(
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE],
  ),
  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]: convertDatetime(
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE],
  ),
  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION]:
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION],

  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.LANG]: convertNewsLang(
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.LANG],
  ),

  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.ORDER]:
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.ORDER],

  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.PREVIEW]: convertFile(
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.PREVIEW],
  ),

  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.STATUS]: convertNewsStatus(
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.STATUS],
  ),

  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.TITLE]:
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.TITLE],

  [NEWS_ADMIN_ITEM_DATA_DATA_NAME.URL]:
    data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.URL],
});
