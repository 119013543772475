import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { NoticeAdminListPaginationComponent } from './notice-admin-list-pagination.component';

import {
  getRequestData,
  isRequestPending,
} from '../../main/store/store.service';
import { NOTICE_ADMIN_LIST_STORE_NAME } from '../notice-admin-list/notice-admin-list.constant';
import { getNoticeAdminList } from '../notice-admin-list/notice-admin-list.action';

export function NoticeAdminListPaginationContainer() {
  const dispatch = useDispatch();

  const { state, isLoading } = useSelector((state, isLoading) => ({
    state: getRequestData(state[NOTICE_ADMIN_LIST_STORE_NAME].request),
    isLoading: isRequestPending(state[NOTICE_ADMIN_LIST_STORE_NAME].request),
  }));

  const onChangeFilter = (data) => {
    dispatch(getNoticeAdminList({ ...state, ...data }));
  };

  return (
    <NoticeAdminListPaginationComponent
      data={state}
      onChangeFilter={onChangeFilter}
      isLoading={isLoading}
    />
  );
}
