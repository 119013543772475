import React from 'react';

import { PurchaseCustomerItemDataCoachingComponent } from './purchase-customer-item-data-coaching.component';

import { useSelector } from 'react-redux';

import { PURCHASE_CUSTOMER_ITEM_STORE_NAME } from '../purchase-customer-item/purchase-customer-item.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';

export function PurchaseCustomerItemDataCoachingContainer() {
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  return (
    <PurchaseCustomerItemDataCoachingComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
