import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PurchaseBoosterOrderListComponent } from './purchase-booster-order-list.component';
import {
  getPurchaseBoosterOrderList,
  updatePurchaseBoosterOrderList,
} from './purchase-booster-order-list.action';
import { PURCHASE_BOOSTER_ORDER_LIST_STORE_NAME } from './purchase-booster-order-list.constant';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from '../../main/store/store.service';

export function PurchaseBoosterOrderListContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ORDER_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(getPurchaseBoosterOrderList());
  }, []);

  const updateList = () => {
    dispatch(updatePurchaseBoosterOrderList());
  };

  const requestData = getRequestData(state.request);
  const isPending = isRequestPending(state.request);
  const isUpdating = isRequestUpdatePending(state.request);
  const isError = isRequestError(state.request);
  const isSuccess = isRequestSuccess(state.request);
  const errorMessage = getRequestErrorMessage(state.request);

  return (
    <PurchaseBoosterOrderListComponent
      updateList={updateList}
      isPending={isPending}
      isUpdating={isUpdating}
      isError={isError}
      isSuccess={isSuccess}
      pageLoading={pageLoading}
      data={requestData}
      errorMessage={errorMessage}
    />
  );
}
