import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { TextComponent } from "../../../lib/common/text";
import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME } from "../purchase-booster-item-update-coaching-data.constant";
import { ModalConfirmComponent } from "../../../lib/common/modal-confirm/modal-confirm.component";

export const PurchaseBoosterItemUpdateCoachingDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    submitForm,
    setFieldValue,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    isCoaching,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (!isCoaching) {
      return true;
    }

    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              type="number"
              disabled={!isCoaching}
              label={
                <TextComponent tid="PURCHASE.BOOSTER.UPDATE_COACHING_DATA.FORM.BOOST_ACTUAL" />
              }
              name={
                PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION
              }
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={
                values[
                  PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME
                    .ACTUAL_SESSION
                ]
              }
              error={isFieldError(
                PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION
              )}
              fullWidth
              errorText={getFieldError(
                PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION
              )}
            />
          </Grid>
          <Grid item>
            <ModalConfirmComponent
              disabled={isSubmitDisabled()}
              action={submitForm}
            >
              <Button disabled={isSubmitDisabled()} fullWidth>
                <TextComponent tid="PURCHASE.BOOSTER.UPDATE_COACHING_DATA.FORM.SUBMIT" />
              </Button>
            </ModalConfirmComponent>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
