import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { PURCHASE_BOOSTER_ITEM_STORE_NAME } from "../purchase-booster-item/purchase-booster-item.constant";
import { PURCHASE_TYPE_TYPE } from "../../lib/common/purchase/purchase.type";
import { TabContainer } from "../../lib/common/tab/tab.container";
import { TabSkeletonComponent } from "../../lib/common/tab/tab-skeleton.component";
import {
  getPurchaseBoosterItemBoost,
  getPurchaseBoosterItemCoaching,
} from "./purchase-booster-item-tab.constant";
import { getRequestData } from "../../main/store/store.service";

export function PurchaseBoosterItemTabContainer() {
  let { purchaseId } = useParams();

  const { state } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ITEM_STORE_NAME],
  }));

  const PURCHASE_BOOSTER_ITEM_TAB_BOOST =
    getPurchaseBoosterItemBoost(purchaseId);
  const PURCHASE_BOOSTER_ITEM_TAB_COACHING =
    getPurchaseBoosterItemCoaching(purchaseId);

  const stateData = getRequestData(state.request);

  if (stateData?.type?.id === PURCHASE_TYPE_TYPE.BOOST) {
    return <TabContainer tab={PURCHASE_BOOSTER_ITEM_TAB_BOOST} />;
  }

  if (stateData?.type?.id === PURCHASE_TYPE_TYPE.COACHING) {
    return <TabContainer tab={PURCHASE_BOOSTER_ITEM_TAB_COACHING} />;
  }

  return <TabSkeletonComponent />;
}
