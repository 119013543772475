import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { NoticeAccountListShortComponent } from "./notice-account-list-short.component";
import { updateNoticeAccountListShort } from "./notice-account-list-short.action";
import { NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME } from "./notice-account-list-short.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

import { NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_STORE_NAME } from "../notice-account-item-update-view/notice-account-item-update-view.constant";
import {
  updateNoticeAccountItemView,
  updateNoticeAccountItemViewAll,
} from "../notice-account-item-update-view/notice-account-item-update-view.action";
import { NOTICE_ACCOUNT_LIST_STORE_NAME } from "../notice-account-list/notice-account-list.constant";
import { updateNoticeAccountList } from "../notice-account-list/notice-account-list.action";

export function NoticeAccountListShortContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, view, fullList } = useSelector((state) => ({
    state: state[NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME],
    view: state[NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_STORE_NAME],
    fullList: state[NOTICE_ACCOUNT_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const isFullListLoader = isRequestSuccess(fullList.request);
  const fullListData = getRequestData(fullList.request);

  const handleUpdateView = (noticeId) => {
    dispatch(
      updateNoticeAccountItemView(noticeId, () => {
        if (isFullListLoader) {
          return dispatch(updateNoticeAccountList(fullListData));
        }
        return dispatch(updateNoticeAccountListShort());
      })
    );
  };

  const handleUpdateViewAll = () => {
    dispatch(
      updateNoticeAccountItemViewAll(() => {
        if (isFullListLoader) {
          return dispatch(updateNoticeAccountList(fullListData));
        }
        return dispatch(updateNoticeAccountListShort());
      })
    );
  };

  return (
    <NoticeAccountListShortComponent
      handleUpdateView={handleUpdateView}
      viewUpdateId={view.noticeId}
      isViewUpdate={isRequestPending(view.request)}
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
      handleUpdateViewAll={handleUpdateViewAll}
    />
  );
}
