export const PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_STORE_NAME =
  'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST';

export const PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_API = {
  PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/customer/coaching/demo`,
    TYPE: 'GET',
  },
};

export const PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_ACTION_TYPE = {
  REQUEST_PENDING:
    'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS:
    'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR:
    'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_LIST_DATA_NAME = {
  ID: 'id',
  CREATE_DATE: 'createDate',
  DEMO_FILE: 'file',
  INFO: 'info',
};
