import { httpRequest } from '../../main/http';

import {
  POPUP_ADMIN_LIST_API,
  POPUP_ADMIN_LIST_ACTION_TYPE,
} from './popup-admin-list.constant';

import { convertPopupAdminList } from './popup-admin-list.convert';

export function getPopupAdminList(data) {
  return async (dispatch) => {
    dispatch({
      type: POPUP_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: POPUP_ADMIN_LIST_API.POPUP_ADMIN_LIST.TYPE,
        url: POPUP_ADMIN_LIST_API.POPUP_ADMIN_LIST.ENDPOINT,
      });

      dispatch({
        type: POPUP_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPopupAdminList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: POPUP_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
