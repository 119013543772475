import { GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './gds-item-admin-item-update-data.constant';
import { convertSelectFieldValue } from '../../lib/common/field-select/field-select.convert';

export const convertGdsItemAdminItemUpdateDataFormData = (data) => ({
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]: Number(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER],
  ),
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_RU]: Number(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_RU],
  ),
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_RU]: Number(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_RU],
  ),

  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_EN]: Number(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_EN],
  ),
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_EN]: Number(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_EN],
  ),

  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_EN]: String(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_EN],
  ),
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_RU]: String(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_RU],
  ),

  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_EN]: String(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_EN],
  ),
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_RU]: String(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_RU],
  ),

  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL]: String(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL],
  ),

  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DATA]: String(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DATA],
  ),
  [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]: convertSelectFieldValue(
    data[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS],
  ),
});
