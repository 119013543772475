import { validate } from '../../main/validate';

import { GDS_ITEM_ADMIN_CREATE_DATA_NAME } from './gds-item-admin-create.constant';

import {
  requiredArray,
  required,
  arrayLengthMax,
  maxLength,
  numberPositive,
  url,
} from '../../main/validate/validate.service';

const config = {
  [GDS_ITEM_ADMIN_CREATE_DATA_NAME.DATA]: [required, maxLength(1000)],
  // [GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_EN]: [maxLength(500)],
  // [GDS_ITEM_ADMIN_CREATE_DATA_NAME.DESCRIPTION_RU]: [maxLength(500)],
  // [GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_RU]: [numberPositive],
  // [GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_RU]: [numberPositive],
  // [GDS_ITEM_ADMIN_CREATE_DATA_NAME.PRICE_EN]: [numberPositive],
  // [GDS_ITEM_ADMIN_CREATE_DATA_NAME.STOCK_EN]: [numberPositive],
  // [GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_EN]: [maxLength(100)],
  // [GDS_ITEM_ADMIN_CREATE_DATA_NAME.TITLE_RU]: [maxLength(100)],
};

export const gdsItemAdminCreateFormValidation = (values) =>
  validate(values, config);
