import { httpRequest } from "../../main/http";

import {
  PURCHASE_BOOSTER_ITEM_API,
  PURCHASE_BOOSTER_ITEM_ACTION_TYPE,
  PURCHASE_BOOSTER_ITEM_STORE_NAME,
} from "./purchase-booster-item.constant";

import { convertPurchaseBoosterItem } from "./purchase-booster-item.convert";

export function getPurchaseBoosterItem(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_BOOSTER_ITEM_API.PURCHASE_BOOSTER_ITEM.TYPE,
        url: PURCHASE_BOOSTER_ITEM_API.PURCHASE_BOOSTER_ITEM.ENDPOINT(
          data.purchaseId
        ),
      });

      dispatch({
        type: PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseBoosterItem(res.data),
        purchaseId: data.purchaseId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadPurchaseBoosterItem() {
  return async (dispatch, getState) => {
    const { purchaseId } = getState()[PURCHASE_BOOSTER_ITEM_STORE_NAME];

    dispatch({
      type: PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_BOOSTER_ITEM_API.PURCHASE_BOOSTER_ITEM.TYPE,
        url: PURCHASE_BOOSTER_ITEM_API.PURCHASE_BOOSTER_ITEM.ENDPOINT(
          purchaseId
        ),
      });

      dispatch({
        type: PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseBoosterItem(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_ITEM_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
