import { httpRequest } from '../../main/http';

import {
  GDS_ITEM_ADMIN_LIST_API,
  GDS_ITEM_ADMIN_LIST_DATA_NAME,
  GDS_ITEM_ADMIN_LIST_ACTION_TYPE,
} from './gds-item-admin-list.constant';

import { convertGdsItemAdminList } from './gds-item-admin-list.convert';

export function getGdsItemAdminList(data) {
  return async (dispatch) => {
    dispatch({
      type: GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: GDS_ITEM_ADMIN_LIST_API.GDS_ITEM_ADMIN_LIST.TYPE,
        url: GDS_ITEM_ADMIN_LIST_API.GDS_ITEM_ADMIN_LIST.ENDPOINT(data.gdsId),
        params: {
          [GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP]:
            data[GDS_ITEM_ADMIN_LIST_DATA_NAME.SKIP],
          [GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS]:
            data[GDS_ITEM_ADMIN_LIST_DATA_NAME.STATUS],
          [GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT]:
            data[GDS_ITEM_ADMIN_LIST_DATA_NAME.BOUGHT],
          [GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ID]:
            data[GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ID],
          [GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ORDER]:
            data[GDS_ITEM_ADMIN_LIST_DATA_NAME.SORT_ORDER],
        },
      });

      dispatch({
        type: GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertGdsItemAdminList(res.data),
        gdsId: data.gdsId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
