import { httpRequest } from '../../main/http';

import {
  PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_API,
  PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_STORE_NAME,
  PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_ACTION_TYPE,
} from './purchase-booster-item-coaching-report-list.constant';

import { convertPurchaseBoosterItemCoachingReportList } from './purchase-booster-item-coaching-report-list.convert';

export function getPurchaseBoosterItemCoachingReportList(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method:
          PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_API
            .PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST.TYPE,
        url: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_API.PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST.ENDPOINT(
          data.purchaseId,
        ),
      });

      dispatch({
        type: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseBoosterItemCoachingReportList(res.data),
        purchaseId: data.purchaseId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadPurchaseBoosterItemCoachingReportList() {
  return async (dispatch, getState) => {
    const { purchaseId } =
      getState()[PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_STORE_NAME];

    dispatch({
      type: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method:
          PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_API
            .PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST.TYPE,
        url: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_API.PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST.ENDPOINT(
          purchaseId,
        ),
      });

      dispatch({
        type: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseBoosterItemCoachingReportList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
