import Box from '@mui/material/Box';

import { FieldTextComponent } from '../../../lib/common/field-text/field-text.component';
import { FieldSelectComponent } from '../../../lib/common/field-select/field-select.component';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { POPUP_ADMIN_CREATE_DATA_NAME } from '../popup-admin-create.constant';
import {
  USER_TIMEZONE_OPTION,
  USER_ROLE_OPTION,
} from '../../../lib/common/user/user.type';
import {
  NEWS_CATEGORY_OPTION,
  NEWS_LANG_OPTION,
} from '../../../lib/common/news/news.type';
import { FieldFileContainer } from '../../../lib/common/field-file/field-file.container';
import {
  POPUP_LANG_OPTION,
  POPUP_TYPE_OPTION,
} from '../../../lib/common/popup/popup.type';

export const PopupAdminCreateFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,

    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === '{}') {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  const hanldePreviewChange = (v) => {
    setFieldValue(v);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={<TextComponent tid="POPUP.ADMIN.CREATE.FORM.TEXT" />}
              name={POPUP_ADMIN_CREATE_DATA_NAME.TEXT}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[POPUP_ADMIN_CREATE_DATA_NAME.TEXT]}
              error={isFieldError(POPUP_ADMIN_CREATE_DATA_NAME.TEXT)}
              fullWidth
              errorText={getFieldError(POPUP_ADMIN_CREATE_DATA_NAME.TEXT)}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={POPUP_TYPE_OPTION}
              label={<TextComponent tid="POPUP.ADMIN.CREATE.FORM.TYPE" />}
              name={POPUP_ADMIN_CREATE_DATA_NAME.TYPE}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[POPUP_ADMIN_CREATE_DATA_NAME.TYPE]}
              error={isFieldError(POPUP_ADMIN_CREATE_DATA_NAME.TYPE)}
              fullWidth
              errorText={getFieldError(POPUP_ADMIN_CREATE_DATA_NAME.TYPE)}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={POPUP_LANG_OPTION}
              label={<TextComponent tid="POPUP.ADMIN.CREATE.FORM.LANG" />}
              name={POPUP_ADMIN_CREATE_DATA_NAME.LANG}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[POPUP_ADMIN_CREATE_DATA_NAME.LANG]}
              error={isFieldError(POPUP_ADMIN_CREATE_DATA_NAME.LANG)}
              fullWidth
              errorText={getFieldError(POPUP_ADMIN_CREATE_DATA_NAME.LANG)}
            />
          </Grid>
          <Grid item>
            <FieldFileContainer
              label={<TextComponent tid="POPUP.ADMIN.CREATE.FORM.FILE" />}
              name={POPUP_ADMIN_CREATE_DATA_NAME.FILE}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[POPUP_ADMIN_CREATE_DATA_NAME.FILE]}
              error={isFieldError(POPUP_ADMIN_CREATE_DATA_NAME.FILE)}
              errorText={getFieldError(POPUP_ADMIN_CREATE_DATA_NAME.FILE)}
            />
          </Grid>
          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="POPUP.ADMIN.CREATE.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
