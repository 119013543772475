import { httpRequest } from "../../main/http";
import {
  ACCOUNT_RECOVERY_API,
  ACCOUNT_RECOVERY_ACTION_TYPE,
} from "./account-recovery.constant";
import { ACCOUNT_UPDATE_AUTH_ROUTE_PATH } from "../account-update-auth/account-update-auth.constant";

export function uploadAccountRecoveryForm(data, redirect) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_RECOVERY_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: ACCOUNT_RECOVERY_API.GET_EMAIL.TYPE,
        url: ACCOUNT_RECOVERY_API.GET_EMAIL.ENDPOINT,
        data,
      });

      await redirect(ACCOUNT_UPDATE_AUTH_ROUTE_PATH);

      dispatch({
        type: ACCOUNT_RECOVERY_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: ACCOUNT_RECOVERY_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetAccountRecoveryForm(data) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_RECOVERY_ACTION_TYPE.FORM_RESET,
    });
  };
}
