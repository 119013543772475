import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RELOAD_PURCHASE_ADMIN_ITEM_CHAT_LIST } from "./purchase-admin-item-chat-list.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from "../../main/store/store.service";

import { PurchaseAdminItemChatListComponent } from "./purchase-admin-item-chat-list.component";
import {
  getPurchaseAdminItemChatList,
  reloadPurchaseAdminItemChatList,
} from "./purchase-admin-item-chat-list.action";
import { PURCHASE_ADMIN_ITEM_CHAT_LIST_STORE_NAME } from "./purchase-admin-item-chat-list.constant";

export function PurchaseAdminItemChatListContainer() {
  let { purchaseId } = useParams();

  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_CHAT_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    const data = { purchaseId };

    dispatch(getPurchaseAdminItemChatList(data));

    const intervalId = setInterval(() => {
      dispatch(reloadPurchaseAdminItemChatList(data));
    }, RELOAD_PURCHASE_ADMIN_ITEM_CHAT_LIST);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <PurchaseAdminItemChatListComponent
      isPending={isRequestPending(state.request)}
      isUpdatePending={isRequestUpdatePending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
