import { PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_DATA_NAME } from './purchase-admin-item-coaching-demo-create.constant';
import { convertOneFileFieldValue } from '../../lib/common/field-file/field-file.convert';

export const convertPurchaseAdminItemCoachingDemoCreateFormData = (values) => {
  return {
    [PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_DATA_NAME.INFO]:
      values[PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_DATA_NAME.INFO],
    [PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_DATA_NAME.FILE]:
      convertOneFileFieldValue(
        values[PURCHASE_ADMIN_ITEM_COACHING_DEMO_CREATE_DATA_NAME.FILE],
      ),
  };
};
