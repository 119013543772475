export const POPUP_TYPE_TYPE = {
  ALERT: 1,
  MODAL: 2,
};

export const POPUP_LANG_TYPE = {
  RU: 1,
  EN: 2,
};

export const POPUP_TYPE_VALUE = {
  1: 'ALERT',
  2: 'MODAL',
};

export const POPUP_LANG_VALUE = {
  1: 'RU',
  2: 'EN',
};

export const POPUP_TYPE_OPTION = [
  { id: 1, value: 1, tid: 'POPUP.DATA.TYPE.ALERT' },
  { id: 2, value: 2, tid: 'POPUP.DATA.TYPE.MODAL' },
];

export const POPUP_LANG_OPTION = [
  { id: 1, value: 1, tid: 'POPUP.DATA.LANG.RU' },
  { id: 2, value: 2, tid: 'POPUP.DATA.LANG.EN' },
];
