import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PromocodeAdminItemDataComponent } from "./promocode-admin-item-data.component";
import { getPromocodeAdminItemData } from "./promocode-admin-item-data.action";
import { PROMOCODE_ADMIN_ITEM_DATA_STORE_NAME } from "./promocode-admin-item-data.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PromocodeAdminItemDataContainer() {
  let { promocodeId } = useParams();

  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PROMOCODE_ADMIN_ITEM_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    const data = { promocodeId };

    dispatch(getPromocodeAdminItemData(data));
  }, []);

  return (
    <PromocodeAdminItemDataComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
