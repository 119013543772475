import {
  PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-update/purchase-admin-item-update.constant";
import {
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-boost-account/purchase-admin-item-boost-account.constant";
import {
  PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-boost-time/purchase-admin-item-boost-time.constant";
import {
  PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-chat/purchase-admin-item-chat.constant";
import {
  PURCHASE_ADMIN_ITEM_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item/purchase-admin-item.constant";
import {
  PURCHASE_ADMIN_ITEM_COACHING_DEMO_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-coaching-demo/purchase-admin-item-coaching-demo.constant";
import {
  PURCHASE_ADMIN_ITEM_COACHING_REPORT_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-coaching-report/purchase-admin-item-coaching-report.constant";

export const getPurchaseAdminItem = (purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM",
    path: PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_UPDATE",
    path: PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 2,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_BOOST_ACCOUNT",
    path: PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 3,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_BOOST_TIME",
    path: PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 4,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_CHAT",
    path: PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
];

export const getPurchaseAdminItemCoaching = (purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM",
    path: PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_UPDATE",
    path: PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 2,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_COACHING_DEMO",
    path: PURCHASE_ADMIN_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 3,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_COACHING_REPORT",
    path: PURCHASE_ADMIN_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 4,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_BOOST_TIME",
    path: PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 5,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_CHAT",
    path: PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
];

export const getPurchaseAdminItemGds = (purchaseId, redirect) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM",
    path: PURCHASE_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.ADMIN.ITEM_UPDATE",
    path: PURCHASE_ADMIN_ITEM_UPDATE_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
];
