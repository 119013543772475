export const PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA_STORE_NAME =
  'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA';

export const PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA_API = {
  DELETE_DATA: {
    ENDPOINT: (purchaseId, boostAccountId) =>
      `/purchase/${purchaseId}/customer/boost/account/${boostAccountId}`,
    TYPE: 'DELETE',
  },
};

export const PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE = {
  FORM_PENDING:
    'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS:
    'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR:
    'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET:
    'PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA_ACTION_TYPE.FORM_RESET',
};
