import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from "../../main/store/store.service";
import { PurchaseCustomerItemChatListComponent } from "./purchase-customer-item-chat-list.component";
import {
  createNoticeItemChatBoosterNoAnswer,
  getPurchaseCustomerItemChatList,
  reloadPurchaseCustomerItemChatList,
} from "./purchase-customer-item-chat-list.action";
import {
  PURCHASE_CUSTOMER_ITEM_CHAT_LIST_STORE_NAME,
  RELOAD_PURCHASE_CUSTOMER_DATA_CHAT_LIST,
  RELOAD_PURCHASE_CUSTOMER_ITEM_CHAT_LIST,
} from "./purchase-customer-item-chat-list.constant";
import { PURCHASE_CUSTOMER_ITEM_STORE_NAME } from "../purchase-customer-item/purchase-customer-item.constant";
import { getPurchaseCustomerItem } from "../purchase-customer-item/purchase-customer-item.action";
import { CHAT_MESSAGE_TYPE_VIEW } from "../../lib/common/chat/chat.type";
import { PURCHASE_STATUS_TYPE } from "../../lib/common/purchase/purchase.type";

export function PurchaseCustomerItemChatListContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading, purchaseState } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_ITEM_CHAT_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    purchaseState: state[PURCHASE_CUSTOMER_ITEM_STORE_NAME],
  }));

  const data = { purchaseId };
  const messageData = getRequestData(state.request);
  const purchaseData = getRequestData(purchaseState.request);
  const purchaseStatus = purchaseData?.status?.id;

  const reloadStore = () => {
    dispatch(reloadPurchaseCustomerItemChatList(data));
  };

  const fetchData = async () => {
    dispatch(getPurchaseCustomerItemChatList(data));
  };

  const fetchReload = async () => {
    dispatch(getPurchaseCustomerItem(data));
  };

  const checkAndSendNotification = async () => {
    if (purchaseStatus !== PURCHASE_STATUS_TYPE.IN_PROGRESS) {
      return;
    }

    const lastMessage =
      messageData?.list && messageData?.list[messageData?.list?.length - 1];

    if (
      lastMessage &&
      lastMessage.type.value === CHAT_MESSAGE_TYPE_VIEW.CUSTOMER
    ) {
      const createDateMoment = moment(lastMessage.createDateNormal);

      let now = new Date();
      let clientTime = now.toUTCString();

      const currentTimeMoment = moment(clientTime);

      const isMessageOlderThanHour =
        currentTimeMoment.diff(createDateMoment, "hours") >= 1;

      if (currentTimeMoment && isMessageOlderThanHour) {
        // Получаем объект purchaseMessages из local storage
        const purchaseMessages = localStorage.getItem("purchaseMessages")
          ? JSON.parse(localStorage.getItem("purchaseMessages"))
          : {};
        // Если по данному сообщению запрос не отправлен - отправляем
        if (
          !purchaseMessages[purchaseId] ||
          !purchaseMessages[purchaseId][lastMessage.id]
        ) {
          dispatch(createNoticeItemChatBoosterNoAnswer(data));

          // Создаём объект заказа в local storage, если он не имеется
          if (!purchaseMessages[purchaseId]) {
            purchaseMessages[purchaseId] = {};
          }
          // Актуализация информации в local storage, присваивая true последнему сообщению
          purchaseMessages[purchaseId][lastMessage.id] = true;
          localStorage.setItem(
            "purchaseMessages",
            JSON.stringify(purchaseMessages)
          );
        }
      }
    }
  };

  React.useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchReload();
    }, RELOAD_PURCHASE_CUSTOMER_DATA_CHAT_LIST);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(
      reloadStore,
      RELOAD_PURCHASE_CUSTOMER_ITEM_CHAT_LIST
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [purchaseId]);

  React.useEffect(() => {
    checkAndSendNotification();
  }, [messageData]);

  return (
    <PurchaseCustomerItemChatListComponent
      purchaseData={purchaseData}
      isPending={isRequestPending(state.request)}
      isUpdatePending={isRequestUpdatePending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={messageData}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
