import Box from '@mui/material/Box';

import { ChatMessageFieldComponent } from '../../../lib/common/chat/chat-message-field.component';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME } from '../purchase-customer-item-chat-create.constant';

export const PurchaseCustomerItemChatCreateFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    submitForm,

    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isPending) {
      return true;
    }

    if (
      values[PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.DATA].length === 0
    ) {
      return true;
    }

    if (
      values[PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.DATA].trim() === ''
    ) {
      return true;
    }

    // if (JSON.stringify(touched) === '{}') {
    //   return true;
    // }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Grid spacing={3} container>
          <Grid item>
            <ChatMessageFieldComponent
              placeholder="PURCHASE.CUSTOMER.CHAT.CREATE.FORM.DATA"
              name={PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.DATA}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.DATA]}
              // error={isFieldError(
              //   PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.DATA,
              // )}
              fileInput={{
                oneFile: true,
                name: PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.FILE,
                onChange: handleChange,
                onBlur: handleBlur,
                value:
                  values[PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.FILE],
                error: isFieldError(
                  PURCHASE_CUSTOMER_ITEM_CHAT_CREATE_DATA_NAME.FILE,
                ),
              }}
              fullWidth
              onSend={submitForm}
              disabled={isSubmitDisabled()}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
