import { AccountVerificationEmailContainer } from './account-verification-email.container';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export function AccountVerificationEmailPage() {
  return (
    <Container sx={{ px: 3 }} maxWidth="xs">
      <Grid sx={{ py: 6, minHeight: '100vh' }} container>
        <Grid item>
          <AccountVerificationEmailContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
