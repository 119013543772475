import { PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME } from './purchase-booster-item-update-coaching-data.constant';

export const convertPurchaseBoosterItemUpdateCoachingDataFormData = (data) => {
  return {
    [PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION]:
      Number(
        data[
          PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION
        ],
      ),
  };
};
