import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { NewsAdminCreateComponent } from "./news-admin-create.component";
import { newsAdminCreateFormValidation } from "./news-admin-create.validation";
import { convertNewsAdminCreateFormData } from "./news-admin-create.convert";
import {
  uploadNewsAdminCreateForm,
  resetNewsAdminCreateForm,
} from "./news-admin-create.action";
import { NEWS_ADMIN_CREATE_STORE_NAME } from "./news-admin-create.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function NewsAdminCreateContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[NEWS_ADMIN_CREATE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const signupFormSendData = (values) => {
    const data = convertNewsAdminCreateFormData(values);
    dispatch(uploadNewsAdminCreateForm(data, navigate));
  };

  React.useEffect(() => {
    dispatch(resetNewsAdminCreateForm());
  }, []);

  const getInitialValue = () => {
    return {};
  };

  return (
    <NewsAdminCreateComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={newsAdminCreateFormValidation}
      onSubmitForm={signupFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
