import Box from '@mui/material/Box';

import { FieldTextComponent } from '../../../lib/common/field-text/field-text.component';
import { FieldPasswordComponent } from '../../../lib/common/field-password/field-password.component';
import { FieldSelectComponent } from '../../../lib/common/field-select/field-select.component';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from '../user-admin-item-update-data.constant';
import {
  USER_TIMEZONE_OPTION,
  USER_ROLE_OPTION,
  BOOSTER_ROLE_OPTION,
} from '../../../lib/common/user/user.type';

export const UserAdminItemUpdateDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    setFieldValue,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === '{}') {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="USER.ADMIN.ITEM_UPDATE_DATA.FORM.EMAIL" />
              }
              name={USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.EMAIL}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.EMAIL]}
              error={isFieldError(USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.EMAIL)}
              fullWidth
              errorText={getFieldError(
                USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.EMAIL,
              )}
            />
          </Grid>
          <Grid item>
            <FieldPasswordComponent
              label={
                <TextComponent tid="USER.ADMIN.ITEM_UPDATE_DATA.FORM.PASSWORD" />
              }
              name={USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PASSWORD}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PASSWORD]}
              error={isFieldError(
                USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PASSWORD,
              )}
              fullWidth
              errorText={getFieldError(
                USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PASSWORD,
              )}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={USER_TIMEZONE_OPTION}
              label={
                <TextComponent tid="USER.ADMIN.ITEM_UPDATE_DATA.FORM.TIMEZONE" />
              }
              name={USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TIMEZONE}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TIMEZONE]}
              error={isFieldError(
                USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TIMEZONE,
              )}
              fullWidth
              errorText={getFieldError(
                USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TIMEZONE,
              )}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={USER_ROLE_OPTION}
              label={
                <TextComponent tid="USER.ADMIN.ITEM_UPDATE_DATA.FORM.ROLE" />
              }
              name={USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ROLE}
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldValue={setFieldValue}
              value={values[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ROLE]}
              error={isFieldError(USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ROLE)}
              fullWidth
              errorText={getFieldError(
                USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ROLE,
              )}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={BOOSTER_ROLE_OPTION}
              label={
                <TextComponent tid="USER.ADMIN.ITEM_UPDATE_DATA.FORM.BOOSTER_ROLE" />
              }
              name={USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BOOSTER_ROLE}
              onChange={handleChange}
              onBlur={handleBlur}
              setFieldValue={setFieldValue}
              value={values[USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BOOSTER_ROLE]}
              error={isFieldError(
                USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BOOSTER_ROLE,
              )}
              fullWidth
              errorText={getFieldError(
                USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BOOSTER_ROLE,
              )}
            />
          </Grid>

          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="USER.ADMIN.ITEM_UPDATE_DATA.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
