import { httpRequest } from '../../main/http';

import {
  NOTICE_ACCOUNT_LIST_API,
  NOTICE_ACCOUNT_LIST_ACTION_TYPE,
} from './notice-account-list.constant';

import { convertNoticeAccountList } from './notice-account-list.convert';
import { NOTICE_ACCOUNT_LIST_DATA_NAME } from '../notice-account-list/notice-account-list.constant';

export function getNoticeAccountList(data = {}) {
  return async (dispatch) => {
    dispatch({
      type: NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: NOTICE_ACCOUNT_LIST_API.NOTICE_ACCOUNT_LIST.TYPE,
        url: NOTICE_ACCOUNT_LIST_API.NOTICE_ACCOUNT_LIST.ENDPOINT,
        params: {
          [NOTICE_ACCOUNT_LIST_DATA_NAME.SKIP]:
            data[NOTICE_ACCOUNT_LIST_DATA_NAME.SKIP],
          [NOTICE_ACCOUNT_LIST_DATA_NAME.TAKE]:
            data[NOTICE_ACCOUNT_LIST_DATA_NAME.TAKE],
        },
      });

      dispatch({
        type: NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertNoticeAccountList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function updateNoticeAccountList(data = {}) {
  return async (dispatch) => {
    dispatch({
      type: NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: NOTICE_ACCOUNT_LIST_API.NOTICE_ACCOUNT_LIST.TYPE,
        url: NOTICE_ACCOUNT_LIST_API.NOTICE_ACCOUNT_LIST.ENDPOINT,
        params: {
          [NOTICE_ACCOUNT_LIST_DATA_NAME.SKIP]:
            data[NOTICE_ACCOUNT_LIST_DATA_NAME.SKIP],
          [NOTICE_ACCOUNT_LIST_DATA_NAME.TAKE]:
            data[NOTICE_ACCOUNT_LIST_DATA_NAME.TAKE],
        },
      });

      dispatch({
        type: NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertNoticeAccountList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NOTICE_ACCOUNT_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
