import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PromocodeAdminItemDataContainer } from "../promocode-admin-item-data/promocode-admin-item-data.container";
import { PromocodeAdminItemUpdateDataContainer } from "../promocode-admin-item-update-data/promocode-admin-item-update-data.container";
import { PromocodeAdminItemDeleteContainer } from "../promocode-admin-item-delete/promocode-admin-item-delete.container";

export function PromocodeAdminItemPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <PromocodeAdminItemDataContainer />
        </Grid>
        <Grid item>
          <PromocodeAdminItemUpdateDataContainer />
        </Grid>
        <Grid item>
          <PromocodeAdminItemDeleteContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
