import { httpRequest } from "../../main/http";

import {
  PURCHASE_CUSTOMER_ITEM_API,
  PURCHASE_CUSTOMER_ITEM_ACTION_TYPE,
  PURCHASE_CUSTOMER_ITEM_STORE_NAME,
} from "./purchase-customer-item.constant";

import { convertPurchaseCustomerItem } from "./purchase-customer-item.convert";

export function getPurchaseCustomerItem(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_CUSTOMER_ITEM_API.PURCHASE_CUSTOMER_ITEM.TYPE,
        url: PURCHASE_CUSTOMER_ITEM_API.PURCHASE_CUSTOMER_ITEM.ENDPOINT(
          data.purchaseId
        ),
      });

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseCustomerItem(res.data),
        purchaseId: data.purchaseId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadPurchaseCustomerItem() {
  return async (dispatch, getState) => {
    const { purchaseId } = getState()[PURCHASE_CUSTOMER_ITEM_STORE_NAME];

    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_CUSTOMER_ITEM_API.PURCHASE_CUSTOMER_ITEM.TYPE,
        url: PURCHASE_CUSTOMER_ITEM_API.PURCHASE_CUSTOMER_ITEM.ENDPOINT(
          purchaseId
        ),
      });

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseCustomerItem(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
