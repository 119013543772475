import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { TextComponent } from '../../lib/common/text';
import Button from '@mui/material/Button';

export function UserAdminCreateInfoComponent(props) {
  const { onSubmitForm } = props;
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="USER.ADMIN.CREATE_INFO.TITLE"
            />
          </Box>
          <Divider />
          <Button sx={{ mt: 4 }} onClick={onSubmitForm} fullWidth>
            <TextComponent tid="USER.ADMIN.CREATE_INFO.SUBMIT" />
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
