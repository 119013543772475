import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_STORE_NAME,
  PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME,
} from '../purchase-customer-item-boost-account-data/purchase-customer-item-boost-account-data.constant';

import { purchaseCustomerItemBoostAccountUpdateDataFormValidation } from './purchase-customer-item-boost-account-update-data.validation';
import { PurchaseCustomerItemBoostAccountUpdateDataComponent } from './purchase-customer-item-boost-account-update-data.component';
import {
  uploadPurchaseCustomerItemBoostAccountUpdateDataForm,
  resetPurchaseCustomerItemBoostAccountUpdateDataFormState,
} from './purchase-customer-item-boost-account-update-data.action';
import {
  PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_STORE_NAME,
  PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_DATA_NAME,
} from './purchase-customer-item-boost-account-update-data.constant';

export function PurchaseCustomerItemBoostAccountUpdateDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, boostAccountItemStore } = useSelector(
    (state) => ({
      state: state[PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_STORE_NAME],
      pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
      boostAccountItemStore:
        state[PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_STORE_NAME],
    }),
  );

  React.useEffect(() => {
    dispatch(resetPurchaseCustomerItemBoostAccountUpdateDataFormState());
  }, []);

  const purchaseCustomerItemBoostAccountUpdateDataFormSendData = (values) => {
    dispatch(
      uploadPurchaseCustomerItemBoostAccountUpdateDataForm(
        values,
        boostAccountItemStore.purchaseId,
      ),
    );
  };

  const getInitialValue = () => {
    if (isRequestSuccess(boostAccountItemStore.request)) {
      const data = getRequestData(boostAccountItemStore.request);
      return {
        [PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_UPDATE_DATA_DATA_NAME.DATA]:
          data[PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.DATA],
      };
    }

    return {};
  };

  return (
    <PurchaseCustomerItemBoostAccountUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(boostAccountItemStore.request)}
      initialValue={getInitialValue()}
      validation={purchaseCustomerItemBoostAccountUpdateDataFormValidation}
      onSubmitForm={purchaseCustomerItemBoostAccountUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
