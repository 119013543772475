export const NEWS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME =
  'NEWS_ADMIN_ITEM_UPDATE_DATA';

export const NEWS_ADMIN_ITEM_UPDATE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (newsId) => `/news/${newsId}`,
    TYPE: 'PATCH',
  },
};

export const NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING: 'NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'NEWS_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET',
};

export const NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME = {
  ORDER: 'order',
  TITLE: 'title',
  DESCRIPTION: 'description',
  BODY: 'body',
  URL: 'url',
  PREVIEW: 'fileImageId',
  CATEGORY: 'category',
  LANG: 'lang',
  STATUS: 'status',
};
