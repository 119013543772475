import { httpRequest } from "../../main/http";

import {
  GDS_ITEM_ADMIN_CREATE_API,
  GDS_ITEM_ADMIN_CREATE_ACTION_TYPE,
} from "./gds-item-admin-create.constant";
import { GDS_ITEM_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../gds-item-admin-item/gds-item-admin-item.constant";

export function uploadGdsItemAdminCreateForm(data, redirect) {
  return async (dispatch) => {
    dispatch({
      type: GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING,
    });

    try {
      const res = await httpRequest({
        method: GDS_ITEM_ADMIN_CREATE_API.GDS_ITEM_ADMIN_CREATE.TYPE,
        url: GDS_ITEM_ADMIN_CREATE_API.GDS_ITEM_ADMIN_CREATE.ENDPOINT(
          data.gdsId
        ),
        data,
      });

      await redirect(
        GDS_ITEM_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({
          gdsItemId: res.data.id,
          gdsId: data.gdsId,
        })
      );
      dispatch({
        type: GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetGdsItemAdminCreateForm(data) {
  return async (dispatch) => {
    dispatch({
      type: GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_RESET,
    });
  };
}
