import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { Formik } from "formik";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { TextComponent } from "../../lib/common/text";

import { GdsItemAdminItemDeleteFormComponent } from "./frame/gds-item-admin-item-delete-form.component";

export function GdsItemAdminItemDeleteComponent(props) {
  const {
    initialValue,
    pageLoading,
    isPending,
    validation,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
    isDependentPending,
  } = props;
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="GDS_ITEM.ADMIN.DELETE.TITLE"
            />
          </Box>
          <Divider />
          <GdsItemAdminItemDeleteFormComponent
            isPending={isPending || isDependentPending}
            isError={isError}
            onSubmitForm={onSubmitForm}
            errorMessage={errorMessage}
            pageLoading={pageLoading}
          />

          {isError && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isSuccess && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="success">
                <TextComponent tid="GDS_ITEM.DELETE.SUCCESS_MESSAGE" />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
