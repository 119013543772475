import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { TextComponent } from "../../../lib/common/text";
import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { PURCHASE_BOOSTER_ITEM_UPDATE_DATA_DATA_NAME } from "../purchase-booster-item-update-data.constant";
import { ModalConfirmComponent } from "../../../lib/common/modal-confirm/modal-confirm.component";

export const PurchaseBoosterItemUpdateDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,
    submitForm,
    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === "{}") {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid spacing={3} item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.BOOSTER.UPDATE.DATA.FORM.NOTES" />
              }
              name={PURCHASE_BOOSTER_ITEM_UPDATE_DATA_DATA_NAME.NOTES}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[PURCHASE_BOOSTER_ITEM_UPDATE_DATA_DATA_NAME.NOTES]}
              error={isFieldError(
                PURCHASE_BOOSTER_ITEM_UPDATE_DATA_DATA_NAME.NOTES
              )}
              fullWidth
              errorText={getFieldError(
                PURCHASE_BOOSTER_ITEM_UPDATE_DATA_DATA_NAME.NOTES
              )}
            />
          </Grid>
          <Grid item>
            <TextComponent
              variant="fieldLabel"
              tid="PURCHASE.BOOSTER.UPDATE.DATA.FORM.EMOJI"
            />
          </Grid>
          <Grid item>
            <ModalConfirmComponent
              disabled={isSubmitDisabled()}
              action={submitForm}
            >
              <Button fullWidth>
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.DATA.FORM.SUBMIT" />
              </Button>
            </ModalConfirmComponent>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
