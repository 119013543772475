import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { PopupAdminItemUpdateDataComponent } from "./popup-admin-item-update-data.component";
import { popupAdminItemUpdateDataFormValidation } from "./popup-admin-item-update-data.validation";
import { convertPopupAdminItemUpdateDataFormData } from "./popup-admin-item-update-data.convert";
import {
  uploadPopupAdminItemUpdateDataForm,
  resetPopupAdminItemUpdateDataFormState,
} from "./popup-admin-item-update-data.action";
import {
  POPUP_ADMIN_ITEM_UPDATE_DATA_STORE_NAME,
  POPUP_ADMIN_ITEM_UPDATE_DATA_DATA_NAME,
} from "./popup-admin-item-update-data.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  POPUP_ADMIN_ITEM_DATA_STORE_NAME,
  POPUP_ADMIN_ITEM_DATA_DATA_NAME,
} from "../popup-admin-item-data/popup-admin-item-data.constant";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PopupAdminItemUpdateDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, popupItemStore } = useSelector((state) => ({
    state: state[POPUP_ADMIN_ITEM_UPDATE_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    popupItemStore: state[POPUP_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPopupAdminItemUpdateDataFormState());
  }, []);

  const popupAdminItemUpdateDataFormSendData = (values) => {
    const data = convertPopupAdminItemUpdateDataFormData(values);
    dispatch(uploadPopupAdminItemUpdateDataForm(data, popupItemStore.popupId));
  };

  const getInitialValue = () => {
    if (isRequestSuccess(popupItemStore.request)) {
      const data = getRequestData(popupItemStore.request);
      return {
        [POPUP_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TEXT]:
          data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.TEXT],
        [POPUP_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TYPE]:
          data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.TYPE],
        [POPUP_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.LANG]:
          data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.LANG],
        [POPUP_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE]: data[
          POPUP_ADMIN_ITEM_DATA_DATA_NAME.FILE
        ]
          ? [data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.FILE]]
          : [],
      };
    }

    return {};
  };

  return (
    <PopupAdminItemUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(popupItemStore.request)}
      initialValue={getInitialValue()}
      validation={popupAdminItemUpdateDataFormValidation}
      onSubmitForm={popupAdminItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
