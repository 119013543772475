import { convertSelectFieldValue } from '../../lib/common/field-select/field-select.convert';
import { USER_ADMIN_CREATE_DATA_NAME } from './user-admin-create.constant';

export const convertUserAdminCreateFormData = (data) => ({
  [USER_ADMIN_CREATE_DATA_NAME.LOGIN]: data[USER_ADMIN_CREATE_DATA_NAME.LOGIN],
  [USER_ADMIN_CREATE_DATA_NAME.EMAIL]: data[USER_ADMIN_CREATE_DATA_NAME.EMAIL],
  [USER_ADMIN_CREATE_DATA_NAME.ROLE]: convertSelectFieldValue(
    data[USER_ADMIN_CREATE_DATA_NAME.ROLE],
  ),
  [USER_ADMIN_CREATE_DATA_NAME.BOOSTER_ROLE]: convertSelectFieldValue(
    data[USER_ADMIN_CREATE_DATA_NAME.BOOSTER_ROLE],
  ),
  [USER_ADMIN_CREATE_DATA_NAME.TIMEZONE]: Number(
    data[USER_ADMIN_CREATE_DATA_NAME.TIMEZONE].value,
  ),
});
