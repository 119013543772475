import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  uploadPurchaseBoosterItemUpdateCoachingDataForm,
  resetPurchaseBoosterItemUpdateCoachingDataFormState,
} from "./purchase-booster-item-update-coaching-data.action";
import {
  PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_STORE_NAME,
  PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME,
} from "./purchase-booster-item-update-coaching-data.constant";
import { convertPurchaseBoosterItemUpdateCoachingDataFormData } from "./purchase-booster-item-update-coaching-data.convert";
import { purchaseBoosterItemUpdateCoachingDataFormValidation } from "./purchase-booster-item-update-coaching-data.validation";
import { PurchaseBoosterItemUpdateCoachingDataComponent } from "./purchase-booster-item-update-coaching-data.component";
import {
  PURCHASE_BOOSTER_ITEM_DATA_NAME,
  PURCHASE_BOOSTER_ITEM_STORE_NAME,
} from "../purchase-booster-item/purchase-booster-item.constant";

export function PurchaseBoosterItemUpdateCoachingDataContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading, coachingItemState } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_STORE_NAME],
    coachingItemState: state[PURCHASE_BOOSTER_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const coachingData = getRequestData(coachingItemState.request)?.[
    PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING
  ];

  const coachingDataSuccess =
    isRequestSuccess(coachingItemState.request) && !!coachingData;

  React.useEffect(() => {
    dispatch(resetPurchaseBoosterItemUpdateCoachingDataFormState());
  }, []);

  const purchaseBoosterItemUpdateCoachingDataFormSendData = (values) => {
    const data = convertPurchaseBoosterItemUpdateCoachingDataFormData(values);

    data.purchaseId = purchaseId;
    data.coachingId = coachingData[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ID];
    dispatch(uploadPurchaseBoosterItemUpdateCoachingDataForm(data));
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_BOOSTER_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION]:
        coachingData?.[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION],
    };
  };

  return (
    <PurchaseBoosterItemUpdateCoachingDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseBoosterItemUpdateCoachingDataFormValidation}
      onSubmitForm={purchaseBoosterItemUpdateCoachingDataFormSendData}
      pageLoading={pageLoading}
      isCoaching={coachingDataSuccess}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
