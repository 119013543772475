import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PromocodeAdminListContainer } from "./promocode-admin-list.container";
import { PromocodeAdminCreateInfoContainer } from "../promocode-admin-create-info/promocode-admin-create-info.container";

export function PromocodeAdminListPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <PromocodeAdminCreateInfoContainer />
        </Grid>
        <Grid item>
          <PromocodeAdminListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
