import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseAdminListContainer } from "./purchase-admin-list.container";
import { PurchaseAdminListPaginationContainer } from "../purchase-admin-list-pagination/purchase-admin-list-pagination.container";

export function PurchaseAdminListPage() {
  return (
    <Container maxWidth="md">
      <Grid spacing={6} container>
        <Grid item>
          <PurchaseAdminListPaginationContainer />
        </Grid>
        <Grid item sx={{ width: "100%", alignSelf: "baseline" }}>
          <PurchaseAdminListContainer />
        </Grid>
        <Grid item>
          <PurchaseAdminListPaginationContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
