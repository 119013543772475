import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { TextComponent } from '../../lib/common/text';
import { FieldFileContainer } from '../../lib/common/field-file/field-file.container';

export function FileAdminCreateComponent(props) {
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="FILE.ADMIN.CREATE.TITLE"
            />
          </Box>
          <Divider />
          <Box sx={{ mt: 4 }}>
            <FieldFileContainer
              onChange={props.onSubmitForm}
              label={
                <TextComponent variant="label" tid="FILE.ADMIN.CREATE.FILE" />
              }
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
