import { httpRequest } from '../../main/http';

import {
  PURCHASE_ADMIN_LIST_API,
  PURCHASE_ADMIN_LIST_ACTION_TYPE,
  PURCHASE_ADMIN_LIST_DATA_NAME,
  PURCHASE_ADMIN_LIST_STORE_NAME,
} from './purchase-admin-list.constant';

import { convertPurchaseAdminList } from './purchase-admin-list.convert';
import {
  getRequestData,
  isRequestPending,
  isRequestUpdatePending,
} from '../../main/store/store.service';

export function getPurchaseAdminList(data) {
  return async (dispatch, getState) => {
    const stateData = getRequestData(
      getState()[PURCHASE_ADMIN_LIST_STORE_NAME].request,
    );

    const params = data
      ? {
          [PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]:
            data[PURCHASE_ADMIN_LIST_DATA_NAME.STATUS],
          [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]:
            data[PURCHASE_ADMIN_LIST_DATA_NAME.PAID],
          [PURCHASE_ADMIN_LIST_DATA_NAME.SKIP]:
            data[PURCHASE_ADMIN_LIST_DATA_NAME.SKIP],
          [PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]:
            data[PURCHASE_ADMIN_LIST_DATA_NAME.TYPE],
        }
      : {
          [PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]:
            stateData[PURCHASE_ADMIN_LIST_DATA_NAME.STATUS],
          [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]:
            stateData[PURCHASE_ADMIN_LIST_DATA_NAME.PAID],
          [PURCHASE_ADMIN_LIST_DATA_NAME.SKIP]:
            stateData[PURCHASE_ADMIN_LIST_DATA_NAME.SKIP],
          [PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]:
            stateData[PURCHASE_ADMIN_LIST_DATA_NAME.TYPE],
        };

    dispatch({
      type: PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_ADMIN_LIST_API.PURCHASE_ADMIN_LIST.TYPE,
        url: PURCHASE_ADMIN_LIST_API.PURCHASE_ADMIN_LIST.ENDPOINT,
        params,
      });

      dispatch({
        type: PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseAdminList(res.data),
      });
    } catch (error) {
      console.log(error);
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function updatePurchaseAdminList(data) {
  return async (dispatch, getStateF) => {
    const getState = getStateF();

    const stateIsPending = isRequestPending(
      getState[PURCHASE_ADMIN_LIST_STORE_NAME].request,
    );

    const stateIsUpdatePending = isRequestUpdatePending(
      getState[PURCHASE_ADMIN_LIST_STORE_NAME].request,
    );

    if (stateIsPending || stateIsUpdatePending) return null;

    const stateData = getRequestData(
      getState[PURCHASE_ADMIN_LIST_STORE_NAME].request,
    );

    const params = data
      ? {
          [PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]:
            data[PURCHASE_ADMIN_LIST_DATA_NAME.STATUS],
          [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]:
            data[PURCHASE_ADMIN_LIST_DATA_NAME.PAID],
          [PURCHASE_ADMIN_LIST_DATA_NAME.SKIP]:
            data[PURCHASE_ADMIN_LIST_DATA_NAME.SKIP],
          [PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]:
            data[PURCHASE_ADMIN_LIST_DATA_NAME.TYPE],
        }
      : {
          [PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]:
            stateData[PURCHASE_ADMIN_LIST_DATA_NAME.STATUS],
          [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]:
            stateData[PURCHASE_ADMIN_LIST_DATA_NAME.PAID],
          [PURCHASE_ADMIN_LIST_DATA_NAME.SKIP]:
            stateData[PURCHASE_ADMIN_LIST_DATA_NAME.SKIP],
          [PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]:
            stateData[PURCHASE_ADMIN_LIST_DATA_NAME.TYPE],
        };

    dispatch({
      type: PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_ADMIN_LIST_API.PURCHASE_ADMIN_LIST.TYPE,
        url: PURCHASE_ADMIN_LIST_API.PURCHASE_ADMIN_LIST.ENDPOINT,
        params,
      });

      dispatch({
        type: PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseAdminList(res.data),
      });
    } catch (error) {
      console.log(error);
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
