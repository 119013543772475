import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseBoosterOrderListContainer } from "./purchase-booster-order-list.container";

export function PurchaseBoosterOrderListPage() {
  return (
    <Container maxWidth="md">
      <Grid spacing={6} container>
        <Grid item sx={{ width: "100%", alignSelf: "baseline" }}>
          <PurchaseBoosterOrderListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
