import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";

export function UserAdminItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.ID"
        />
        <TextComponent variant="dataViewContent">{data?.id}</TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.LOGIN"
        />
        <TextComponent variant="dataViewContent">{data?.login}</TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.EMAIL"
        />

        <TextComponent variant="dataViewContent">{data?.email}</TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.ROLE"
        />

        <TextComponent variant="dataViewContent">
          {data?.role?.text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.BONUS"
        />
        <TextComponent variant="dataViewContent">{data?.bonus}</TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.ONLINE"
        />
        <TextComponent variant="dataViewContent">
          {String(data?.online)}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.TIMEZONE"
        />
        <TextComponent variant="dataViewContent">
          {data?.timezone?.text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="USER.ADMIN.ITEM_DATA.TELEGRAM"
        />
        {data?.telegramId ? (
          <TextComponent variant="dataViewContent">
            {data?.telegramId}
          </TextComponent>
        ) : (
          <TextComponent variant="dataViewContent">
            <TextComponent tid="USER.ADMIN.ITEM_DATA.TELEGRAM_NOT_SET" />
          </TextComponent>
        )}
      </Grid>
    </Grid>
  );
}
