import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PopupAdminItemDataComponent } from "./popup-admin-item-data.component";
import { getPopupAdminItemData } from "./popup-admin-item-data.action";
import { POPUP_ADMIN_ITEM_DATA_STORE_NAME } from "./popup-admin-item-data.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PopupAdminItemDataContainer() {
  let { popupId } = useParams();

  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[POPUP_ADMIN_ITEM_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    const data = { popupId };

    dispatch(getPopupAdminItemData(data));
  }, []);

  return (
    <PopupAdminItemDataComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
