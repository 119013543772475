let flashing = null;
let originalTitle = null;

const formats = ['%t', '%n · %t'];

export function clearFlashDocumentTitle() {
  if (!flashing) return;

  clearTimeout(flashing);
  document.title = originalTitle;

  flashing = null;
  originalTitle = null;
}

export function flashDocumentTitle(text) {
  clearFlashDocumentTitle();

  originalTitle = document.title;

  let i = 0;

  const flash = () => {
    document.title = formats[i]
      .replace('%n', text)
      .replace('%t', originalTitle);
    i = (i + 1) % formats.length;

    return null;
  };

  flash();
  flashing = setInterval(flash, 1000);
}
