import { validate } from '../../main/validate';

import { USER_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './user-admin-item-update-data.constant';

import { email, password } from '../../main/validate/validate.service';

const config = {};

export const userAdminItemUpdateDataFormValidation = (values) =>
  validate(values, config);
