import { httpRequest } from '../../main/http';

import {
  NEWS_ADMIN_LIST_API,
  NEWS_ADMIN_LIST_ACTION_TYPE,
  NEWS_ADMIN_LIST_DATA_NAME,
} from './news-admin-list.constant';

import { convertNewsAdminList } from './news-admin-list.convert';

export function getNewsAdminList(data) {
  return async (dispatch) => {
    dispatch({
      type: NEWS_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: NEWS_ADMIN_LIST_API.NEWS_ADMIN_LIST.TYPE,
        url: NEWS_ADMIN_LIST_API.NEWS_ADMIN_LIST.ENDPOINT,
        params: {
          [NEWS_ADMIN_LIST_DATA_NAME.LANG]:
            data[NEWS_ADMIN_LIST_DATA_NAME.LANG],
          [NEWS_ADMIN_LIST_DATA_NAME.CATEGORY]:
            data[NEWS_ADMIN_LIST_DATA_NAME.CATEGORY],
          [NEWS_ADMIN_LIST_DATA_NAME.STATUS]:
            data[NEWS_ADMIN_LIST_DATA_NAME.STATUS],
          [NEWS_ADMIN_LIST_DATA_NAME.SORT_ORDER]:
            data[NEWS_ADMIN_LIST_DATA_NAME.SORT_ORDER],
          [NEWS_ADMIN_LIST_DATA_NAME.SORT_ID]:
            data[NEWS_ADMIN_LIST_DATA_NAME.SORT_ID],
          [NEWS_ADMIN_LIST_DATA_NAME.SKIP]:
            data[NEWS_ADMIN_LIST_DATA_NAME.SKIP],
        },
      });

      dispatch({
        type: NEWS_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertNewsAdminList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NEWS_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
