import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { NoticeAdminListContainer } from "./notice-admin-list.container";
import { NoticeAdminListPaginationContainer } from "../notice-admin-list-pagination/notice-admin-list-pagination.container";

export function NoticeAdminListPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={3} container>
        <Grid item>
          <NoticeAdminListContainer />
        </Grid>
        <Grid item>
          <NoticeAdminListPaginationContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
