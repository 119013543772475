import React from 'react';
import styled from 'styled-components';
import { Field, Formik } from 'formik';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';

import { TextComponent } from '../../lib/common/text';
import { NAVIGATION_FORM } from './navigation-online-item.constant';

export function NavigationOnlineComponent({
  getInitialValue,
  setOnlineStatus,
}) {
  const initialValues = getInitialValue();
  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {(props) => (
        <FormStyled>
          <Field name={NAVIGATION_FORM.ONLINE}>
            {({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      field.onChange(e);
                      setOnlineStatus(checked);
                    }}
                  />
                }
                label={
                  <TextComponentContainer>
                    <TextComponent
                      tid={
                        field.value ? 'NAVIGATION.ONLINE' : 'NAVIGATION.OFFLINE'
                      }
                    />
                  </TextComponentContainer>
                }
              />
            )}
          </Field>
        </FormStyled>
      )}
    </Formik>
  );
}

const TextComponentContainer = styled.div`
  min-width: 45px;
`;

const FormStyled = styled.form`
  align-self: center;
  background-color: rgb(43, 46, 77);
  padding: 5px 10px;
  border-radius: 12px;

  @media screen and (max-width: 1199px) {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
`;
