import React from "react";
import Box from "@mui/material/Box";

import { TextComponent } from "../../../lib/common/text/text.component";
import { ChatMessageComponent } from "../../../lib/common/chat/chat-message.component";

export function PurchaseCustomerItemChatListViewComponent(props) {
  const { data } = props;

  if (!data.list?.length) {
    return (
      <Box sx={{ pt: 4, pb: 6 }}>
        <TextComponent
          variant="body1"
          tid="PURCHASE.ADMIN.CHAT.LIST.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  const chatWrapperId = "CHAT_WRAPPER_ID";

  React.useEffect(() => {
    const objDiv = document.getElementById(chatWrapperId);
    objDiv.scrollTop = objDiv.scrollHeight;

    document.querySelectorAll(".markdown * a").forEach((item) => {
      item.addEventListener("click", (e) => {
        e.preventDefault();
        window.open(item.href);
      });
    });
  }, [data.list.length]);

  return (
    <Box
      id={chatWrapperId}
      sx={{ height: "calc(100vh - 384px)", overflow: "scroll" }}
    >
      <Box sx={{ mb: 6 }}>
        {data.list.map((msg) => (
          <React.Fragment key={msg.id}>
            <ChatMessageComponent data={msg} />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
}
