import Box from '@mui/material/Box';

import { ChatMessageFieldComponent } from '../../../lib/common/chat/chat-message-field.component';

import Grid from '@mui/material/Grid';
import {
  PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME,
  PURCHASE_ADMIN_ITEM_CHAT_CREATE_SHORTCUT_LIST,
} from '../purchase-admin-item-chat-create.constant';
import { ChatMessageShortcutComponent } from '../../../lib/common/chat/chat-message-shortcut.component';

export const PurchaseAdminItemChatCreateFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    submitForm,

    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  const isFieldError = (name) => {
    return errors[name];
  };

  console.log(errors);

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isPending) {
      return true;
    }

    if (values[PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.DATA].length === 0) {
      return true;
    }

    if (values[PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.DATA].trim() === '') {
      return true;
    }

    // if (JSON.stringify(touched) === '{}') {
    //   return true;
    // }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Grid spacing={4} container>
          <Grid item>
            <ChatMessageFieldComponent
              placeholder="PURCHASE.ADMIN.CHAT.CREATE.FORM.DATA"
              name={PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.DATA}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.DATA]}
              // error={isFieldError(
              //   PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.DATA,
              // )}
              fileInput={{
                oneFile: true,
                name: PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.FILE,
                onChange: handleChange,
                onBlur: handleBlur,
                value: values[PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.FILE],
                error: isFieldError(
                  PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.FILE,
                ),
              }}
              fullWidth
              onSend={submitForm}
              disabled={isSubmitDisabled()}
            />
          </Grid>
          <Grid item>
            <ChatMessageShortcutComponent
              name={PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME.DATA}
              onChange={handleChange}
              list={PURCHASE_ADMIN_ITEM_CHAT_CREATE_SHORTCUT_LIST}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
