import { ERROR_ROUTE_PATH } from '../../core/error/error.constant';
import { ACCOUNT_LOGOUT_ROUTE_PATH } from '../../core/account-logout/account-logout.constant';

export const HTTP_ERROR_ROUTER = {
  ACCESS_DENIED: ERROR_ROUTE_PATH,
  SERVER_ERROR: ERROR_ROUTE_PATH,
  NOT_FOUND: ERROR_ROUTE_PATH,
  UNAUTHORIZED_ERROR: ACCOUNT_LOGOUT_ROUTE_PATH,
  INTERNAL_SERVER_ERROR: ERROR_ROUTE_PATH,
};

export { httpRequest } from './http.core';
