import Box from '@mui/material/Box';

import { FieldTextComponent } from '../../../lib/common/field-text/field-text.component';
import { FieldPasswordComponent } from '../../../lib/common/field-password/field-password.component';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { PURCHASE_ADMIN_ITEM_PAYMENT_CREATE_DATA_NAME } from '../purchase-admin-item-payment-create.constant';
import { ModalConfirmComponent } from '../../../lib/common/modal-confirm/modal-confirm.component';

export const PurchaseAdminItemPaymentCreateFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    submitForm,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    // if (JSON.stringify(touched) === '{}') {
    //   return true;
    // }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <ModalConfirmComponent
              action={submitForm}
              disabled={isSubmitDisabled()}
            >
              <Button disabled={isSubmitDisabled()} fullWidth>
                <TextComponent tid="PURCHASE.ADMIN.PAYMENT.CREATE.FORM.SUBMIT" />
              </Button>
            </ModalConfirmComponent>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
