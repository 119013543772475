import React from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { TabContainer } from "../../lib/common/tab/tab.container";
import { getGdsAdminItemTab } from "./gds-admin-item.constant";
import { GdsAdminItemDataContainer } from "../gds-admin-item-data/gds-admin-item-data.container";
import { GdsAdminItemUpdateDataContainer } from "../gds-admin-item-update-data/gds-admin-item-update-data.container";
import { GdsAdminItemDeleteContainer } from "../gds-admin-item-delete/gds-admin-item-delete.container";

export function GdsAdminItemPage() {
  let { gdsId } = useParams();

  const GDS_ADMIN_ITEM_TAB = getGdsAdminItemTab(gdsId);

  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <TabContainer tab={GDS_ADMIN_ITEM_TAB} />
        </Grid>
        <Grid item>
          <GdsAdminItemDataContainer />
        </Grid>
        <Grid item>
          <GdsAdminItemUpdateDataContainer />
        </Grid>
        <Grid item>
          <GdsAdminItemDeleteContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
