export const PURCHASE_ADMIN_ITEM_CHAT_CREATE_STORE_NAME =
  'PURCHASE_ADMIN_ITEM_CHAT_CREATE';

export const PURCHASE_ADMIN_ITEM_CHAT_CREATE_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: (purchaseId) => `/chat-message/purchase/${purchaseId}/admin`,
    TYPE: 'POST',
  },
};

export const PURCHASE_ADMIN_ITEM_CHAT_CREATE_ACTION_TYPE = {
  FORM_PENDING: 'PURCHASE_ADMIN_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PURCHASE_ADMIN_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_ADMIN_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_ADMIN_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_ADMIN_ITEM_CHAT_CREATE_DATA_NAME = {
  DATA: 'data',
  FILE: 'fileId',
};

export const PURCHASE_ADMIN_ITEM_CHAT_CREATE_SHORTCUT_LIST = [
  {
    id: 1,
    title: 'PURCHASE.CHAT.SHORTCUT.FLEN.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.FLEN.DATA',
  },
  {
    id: 2,
    title: 'PURCHASE.CHAT.SHORTCUT.FLRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.FLRU.DATA',
  },
  {
    id: 3,
    title: 'PURCHASE.CHAT.SHORTCUT.FEN.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.FEN.DATA',
  },
  {
    id: 4,
    title: 'PURCHASE.CHAT.SHORTCUT.FRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.FRU.DATA',
  },
  {
    id: 5,
    title: 'PURCHASE.CHAT.SHORTCUT.MMLEN.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.MMLEN.DATA',
  },
  {
    id: 6,
    title: 'PURCHASE.CHAT.SHORTCUT.MMLRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.MMLRU.DATA',
  },
  {
    id: 7,
    title: 'PURCHASE.CHAT.SHORTCUT.MMEN.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.MMEN.DATA',
  },
  {
    id: 8,
    title: 'PURCHASE.CHAT.SHORTCUT.MMRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.MMRU.DATA',
  },
  // {
  //   id: 9,
  //   title: 'PURCHASE.CHAT.SHORTCUT.ELEN.TITLE',
  //   data: 'PURCHASE.CHAT.SHORTCUT.ELEN.DATA',
  // },
  // {
  //   id: 10,
  //   title: 'PURCHASE.CHAT.SHORTCUT.ELRU.TITLE',
  //   data: 'PURCHASE.CHAT.SHORTCUT.ELRU.DATA',
  // },
  // {
  //   id: 11,
  //   title: 'PURCHASE.CHAT.SHORTCUT.EEN.TITLE',
  //   data: 'PURCHASE.CHAT.SHORTCUT.EEN.DATA',
  // },
  // {
  //   id: 12,
  //   title: 'PURCHASE.CHAT.SHORTCUT.ERU.TITLE',
  //   data: 'PURCHASE.CHAT.SHORTCUT.ERU.DATA',
  // },
  {
    id: 9,
    title: 'PURCHASE.CHAT.SHORTCUT.OK.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.OK.DATA',
  },
  {
    id: 10,
    title: 'PURCHASE.CHAT.SHORTCUT.OKRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.OKRU.DATA',
  },
  {
    id: 11,
    title: 'PURCHASE.CHAT.SHORTCUT.GG.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.GG.DATA',
  },
  {
    id: 12,
    title: 'PURCHASE.CHAT.SHORTCUT.GGRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.GGRU.DATA',
  },
];
