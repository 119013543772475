import React from 'react';

import { PurchaseCustomerItemTimezoneComponent } from './purchase-customer-item-timezone.component';

import { useSelector } from 'react-redux';

import {
  PURCHASE_ADMIN_ITEM_DATA_NAME,
  PURCHASE_ADMIN_ITEM_STORE_NAME,
} from '../purchase-admin-item/purchase-admin-item.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import {
  calcCurrentTimeByTimezone,
  calcTimezone,
} from '../../lib/common/time/time.core';
import {
  convertTime,
  convertTimePm,
} from '../../lib/common/convert/convert.core';
import {
  PURCHASE_CUSTOMER_ITEM_DATA_NAME,
  PURCHASE_CUSTOMER_ITEM_STORE_NAME,
} from '../purchase-customer-item/purchase-customer-item.constant';
import { convertTimezone } from '../../lib/common/user/user.convert';
import { AUTH_STORE_NAME } from '../../lib/common/auth';

export function PurchaseCustomerItemTimezoneContainer() {
  const { state, pageLoading, auth } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_ITEM_STORE_NAME],
    auth: state[AUTH_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const [timezone, setTimezoneData] = React.useState({});

  const calcTime = () => {
    if (!isRequestSuccess(state.request)) return null;

    let customerTime = null;
    let boosterTime = null;

    const currentTimezone = calcTimezone();

    const data = getRequestData(state.request);

    customerTime = convertTimezone(Number(auth.user.timezone));
    customerTime.currentTime = calcCurrentTimeByTimezone(
      Number(customerTime.value),
      Number(currentTimezone),
    );

    customerTime.currentTime = convertTimePm(customerTime.currentTime);

    if (data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.IS_BOOSTER]) {
      boosterTime = data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOSTER].timezone;
      boosterTime.currentTime = calcCurrentTimeByTimezone(
        Number(boosterTime.value),
        Number(currentTimezone),
      );

      boosterTime.currentTime = convertTimePm(boosterTime.currentTime);
    }

    setTimezoneData({
      customerTime,
      boosterTime,
      currentTimezone,
      currentTimezoneText: convertTimezone(Number(currentTimezone)).text,
    });
  };

  React.useEffect(calcTime, [isRequestSuccess(state.request)]);

  React.useEffect(() => {
    const id = setInterval(calcTime, 2000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <PurchaseCustomerItemTimezoneComponent
      timezone={timezone}
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
