export const POPUP_ADMIN_ITEM_UPDATE_DATA_STORE_NAME =
  'POPUP_ADMIN_ITEM_UPDATE_DATA';

export const POPUP_ADMIN_ITEM_UPDATE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (popupId) => `/popup/${popupId}`,
    TYPE: 'PATCH',
  },
};

export const POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING: 'POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'POPUP_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET',
};

export const POPUP_ADMIN_ITEM_UPDATE_DATA_DATA_NAME = {
  TEXT: 'text',
  TYPE: 'type',
  LANG: 'lang',
  FILE: 'fileImageId',
};
