export const PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_STORE_NAME =
  'PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER';

export const PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: (purchaseId, userId) =>
      `/purchase/${purchaseId}/admin/customer/${userId}`,
    TYPE: 'POST',
  },
};

export const PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_ACTION_TYPE = {
  FORM_PENDING: 'PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_ADMIN_ITEM_UPDATE_CUSTOMER_DATA_NAME = {
  ID: 'id',
};
