import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { TabContainer } from "../../lib/common/tab/tab.container";
import { getGdsAdminItemTab } from "../gds-admin-item/gds-admin-item.constant";
import { GdsItemAdminItemDataContainer } from "../gds-item-admin-item-data/gds-item-admin-item-data.container";
import { GdsItemAdminItemUpdateDataContainer } from "../gds-item-admin-item-update-data/gds-item-admin-item-update-data.container";
import { GdsItemAdminItemDeleteContainer } from "../gds-item-admin-item-delete/gds-item-admin-item-delete.container";

export function GdsItemAdminItemPage() {
  const { gdsId } = useParams();

  const GDS_ADMIN_ITEM_TAB = getGdsAdminItemTab(gdsId);

  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <TabContainer tab={GDS_ADMIN_ITEM_TAB} />
        </Grid>
        <Grid item>
          <GdsItemAdminItemDataContainer />
        </Grid>
        <Grid item>
          <GdsItemAdminItemUpdateDataContainer />
        </Grid>
        <Grid item>
          <GdsItemAdminItemDeleteContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
