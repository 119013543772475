import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_STORE_NAME } from './purchase-admin-item-coaching-report-delete.constant';
import { PurchaseAdminItemCoachingReportDeleteComponent } from './purchase-admin-item-coaching-report-delete.component';
import {
  uploadPurchaseAdminItemCoachingReportDeleteForm,
  resetPurchaseAdminItemCoachingReportDeleteFormState,
} from './purchase-admin-item-coaching-report-delete.action';
import { PURCHASE_ADMIN_ITEM_COACHING_REPORT_LIST_STORE_NAME } from '../purchase-admin-item-coaching-report-list/purchase-admin-item-coaching-report-list.constant';

export function PurchaseAdminItemCoachingReportDeleteContainer(props) {
  const { coachingReportId } = props;
  const dispatch = useDispatch();
  const { state, pageLoading, coachingStore } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    coachingStore: state[PURCHASE_ADMIN_ITEM_COACHING_REPORT_LIST_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemCoachingReportDeleteFormState());
  }, []);

  const purchaseAdminItemCoachingReportDeleteFormSendData = () => {
    dispatch(
      uploadPurchaseAdminItemCoachingReportDeleteForm(
        coachingStore?.purchaseId,
        coachingReportId,
      ),
    );
  };

  return (
    <PurchaseAdminItemCoachingReportDeleteComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      stateCoachingReportId={state.coachingReportId}
      coachingReportId={coachingReportId}
      isDependentPending={isRequestPending(coachingStore.request)}
      onSubmitForm={purchaseAdminItemCoachingReportDeleteFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
