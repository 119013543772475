import { validate } from '../../main/validate';

import { POPUP_ADMIN_CREATE_DATA_NAME } from './popup-admin-create.constant';

import {
  required,
  arrayLengthMax,
  requiredArray,
  maxLength,
} from '../../main/validate/validate.service';

const config = {
  [POPUP_ADMIN_CREATE_DATA_NAME.TEXT]: [required, maxLength(300)],
  // [POPUP_ADMIN_CREATE_DATA_NAME.FILE]: [
  //   required,
  //   requiredArray,
  //   arrayLengthMax(1),
  // ],
  [POPUP_ADMIN_CREATE_DATA_NAME.TYPE]: [required],
  [POPUP_ADMIN_CREATE_DATA_NAME.LANG]: [required],
};

export const popupAdminCreateFormValidation = (values) =>
  validate(values, config);
