import { validate } from '../../main/validate';

import { PURCHASE_BOOSTER_ITEM_CHAT_CREATE_DATA_NAME } from './purchase-booster-item-chat-create.constant';

import { required } from '../../main/validate/validate.service';

const config = {
  [PURCHASE_BOOSTER_ITEM_CHAT_CREATE_DATA_NAME.DATA]: [required],
};

export const purchaseBoosterItemChatCreateFormValidation = (values) =>
  validate(values, config);
