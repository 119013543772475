export const GDS_ITEM_ADMIN_LIST_ROUTE_PATH = "/admin/gds/:gdsId/item";

export const GDS_ITEM_ADMIN_LIST_ROUTE_PATH_DYNAMIC = (gdsId) =>
  `/admin/gds/${gdsId}/item`;

export const GDS_ITEM_ADMIN_LIST_STORE_NAME = "GDS_ITEM_ADMIN_LIST";

export const GDS_ITEM_ADMIN_LIST_API = {
  GDS_ITEM_ADMIN_LIST: {
    ENDPOINT: (gdsId) => `/gds/${gdsId}/item/admin`,
    TYPE: "GET",
  },
};

export const GDS_ITEM_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: "GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS: "GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "GDS_ITEM_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR",
};

export const GDS_ITEM_ADMIN_LIST_DATA_NAME = {
  ID: "id",
  ORDER: "order",
  STATUS: "status",
  BOUGHT: "bought",
  PRICE_RU: "priceRu",
  PRICE_EN: "priceEn",
  STOCK_RU: "stockRu",
  STOCK_EN: "stockEn",

  LIST: "list",
  SKIP: "skip",
  TAKE: "take",
  BOUGHT: "bought",
  SORT_ORDER: "sortOrder",
  SORT_ID: "sortId",
  AMOUNT: "amount",
};
