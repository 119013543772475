import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { LoaderBarComponent } from '../loader-bar';
import { TextComponent } from '../text';
import { FileListComponent } from '../file-list/file-list.component';
import { FieldFileFormComponent } from './frame/field-file-form.component';
import { FieldFileIconComponent } from './frame/field-file-icon.component';

export function FieldFileComponent(props) {
  const {
    pageLoading,
    isPending,
    isError,
    isSuccess,
    errorMessage,
    data,
    handleDelete,
    label,
    errorText,
    error,
    valueEmpty,

    icon,
    iconProps,
  } = props;
  return (
    <Box>
      {icon ? (
        <Box>
          <FieldFileIconComponent
            {...props}
            isPending={isPending}
            isError={isError}
            isSuccess={isSuccess}
            errorMessage={errorMessage}
            pageLoading={pageLoading}
            iconProps={iconProps}
            valueEmpty={valueEmpty}
          />
          {isPending && <LoaderBarComponent />}
        </Box>
      ) : (
        <Box>
          <Typography variant="fieldLabel" gutterBottom component="div">
            {label}
          </Typography>

          <Grid container spacing={2}>
            {data.length > 0 && (
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <FileListComponent
                      handleDelete={handleDelete}
                      list={data}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item>
              <FieldFileFormComponent
                {...props}
                isPending={isPending}
                isError={isError}
                isSuccess={isSuccess}
                errorMessage={errorMessage}
                pageLoading={pageLoading}
              />
            </Grid>

            {error && errorText && (
              <Grid item>
                <Typography variant="errorText">{errorText}</Typography>
              </Grid>
            )}
            {isError && (
              <Grid item>
                <TextComponent
                  variant="errorText"
                  tid={`ERROR.${errorMessage}`}
                />
              </Grid>
            )}
            {isPending && <LoaderBarComponent />}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
