import React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { TextComponent } from "../text/text.component";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function TabComponent({ tab, value, handleChange }) {
  return (
    <Grid item>
      <Paper sx={{ py: 0, px: 0 }}>
        <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          aria-label="basic tabs example"
        >
          {tab.map((tabItem) => (
            <Tab
              key={tabItem.id}
              sx={{ py: 4, px: 6 }}
              label={<TextComponent tid={tabItem.tid} />}
              {...a11yProps(tabItem.id)}
            />
          ))}
        </Tabs>
      </Paper>
    </Grid>
  );
}
