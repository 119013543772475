import * as React from 'react';
import Box from '@mui/material/Box';

export function NavigationNotLoggedComponent(props) {
  const { children } = props;

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
}
