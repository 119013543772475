import { validate } from '../../main/validate';

import { ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME } from './account-settings-update-email.constant';

import {
  email,
  required,
  fieldMatch,
} from '../../main/validate/validate.service';

const config = {
  [ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.PASSWORD]: [required],
  [ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.EMAIL]: [required, email],
  [ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.NEW_EMAIL]: [
    required,
    email,
    fieldMatch(
      ACCOUNT_SETTINGS_UPDATE_EMAIL_DATA_NAME.EMAIL,
      'New email matching old one',
    ),
  ],
};

export const accountSettingsUpdateEmailFormValidation = (values) =>
  validate(values, config);
