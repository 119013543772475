import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

import { PurchaseAdminItemCoachingDemoListComponent } from "./purchase-admin-item-coaching-demo-list.component";
import { getPurchaseAdminItemCoachingDemoList } from "./purchase-admin-item-coaching-demo-list.action";
import { PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_STORE_NAME } from "./purchase-admin-item-coaching-demo-list.constant";

export function PurchaseAdminItemCoachingDemoListContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_COACHING_DEMO_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    const data = { purchaseId };

    dispatch(getPurchaseAdminItemCoachingDemoList(data));
  }, []);

  return (
    <PurchaseAdminItemCoachingDemoListComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
