export const PURCHASE_BOOSTER_ITEM_CHAT_CREATE_STORE_NAME =
  'PURCHASE_BOOSTER_ITEM_CHAT_CREATE';

export const PURCHASE_BOOSTER_ITEM_CHAT_CREATE_API = {
  CHANGE_PASSWORD: {
    ENDPOINT: (purchaseId) => `/chat-message/purchase/${purchaseId}/booster`,
    TYPE: 'POST',
  },
};

export const PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE = {
  FORM_PENDING: 'PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_BOOSTER_ITEM_CHAT_CREATE_DATA_NAME = {
  DATA: 'data',
  FILE: 'fileId',
};

export const PURCHASE_BOOSTER_ITEM_CHAT_CREATE_SHORTCUT_LIST = [
  {
    id: 1,
    title: 'PURCHASE.CHAT.SHORTCUT.A_FLEN.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.A_FLEN.DATA',
  },
  {
    id: 2,
    title: 'PURCHASE.CHAT.SHORTCUT.A_FLRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.A_FLRU.DATA',
  },
  {
    id: 3,
    title: 'PURCHASE.CHAT.SHORTCUT.A_FEN.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.A_FEN.DATA',
  },
  {
    id: 4,
    title: 'PURCHASE.CHAT.SHORTCUT.A_FRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.A_FRU.DATA',
  },
  {
    id: 5,
    title: 'PURCHASE.CHAT.SHORTCUT.A_MMLEN.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.A_MMLEN.DATA',
  },
  {
    id: 6,
    title: 'PURCHASE.CHAT.SHORTCUT.A_MMLRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.A_MMLRU.DATA',
  },
  {
    id: 7,
    title: 'PURCHASE.CHAT.SHORTCUT.A_MMEN.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.A_MMEN.DATA',
  },
  {
    id: 8,
    title: 'PURCHASE.CHAT.SHORTCUT.A_MMRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.A_MMRU.DATA',
  },
  // {
  //   id: 9,
  //   title: 'PURCHASE.CHAT.SHORTCUT.A_ELEN.TITLE',
  //   data: 'PURCHASE.CHAT.SHORTCUT.A_ELEN.DATA',
  // },
  // {
  //   id: 10,
  //   title: 'PURCHASE.CHAT.SHORTCUT.A_ELRU.TITLE',
  //   data: 'PURCHASE.CHAT.SHORTCUT.A_ELRU.DATA',
  // },
  // {
  //   id: 11,
  //   title: 'PURCHASE.CHAT.SHORTCUT.A_EEN.TITLE',
  //   data: 'PURCHASE.CHAT.SHORTCUT.A_EEN.DATA',
  // },
  // {
  //   id: 12,
  //   title: 'PURCHASE.CHAT.SHORTCUT.A_ERU.TITLE',
  //   data: 'PURCHASE.CHAT.SHORTCUT.A_ERU.DATA',
  // },
  {
    id: 9,
    title: 'PURCHASE.CHAT.SHORTCUT.REV.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.REV.DATA',
  },
  {
    id: 10,
    title: 'PURCHASE.CHAT.SHORTCUT.REVRU.TITLE',
    data: 'PURCHASE.CHAT.SHORTCUT.REVRU.DATA',
  },
];
