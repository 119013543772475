import { validate } from '../../main/validate';

import { PURCHASE_ADMIN_ITEM_PAYMENT_CREATE_DATA_NAME } from './purchase-admin-item-payment-create.constant';

import { required } from '../../main/validate/validate.service';

const config = {
  // [PURCHASE_ADMIN_ITEM_PAYMENT_CREATE_DATA_NAME.ID]: [required],
};

export const purchaseAdminItemPaymentCreateFormValidation = (values) =>
  validate(values, config);
