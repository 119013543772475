import React from 'react';

import { GdsItemAdminItemUpdateDataComponent } from './gds-item-admin-item-update-data.component';

import { useDispatch, useSelector } from 'react-redux';

import { gdsItemAdminItemUpdateDataFormValidation } from './gds-item-admin-item-update-data.validation';

import { convertGdsItemAdminItemUpdateDataFormData } from './gds-item-admin-item-update-data.convert';

import {
  uploadGdsItemAdminItemUpdateDataForm,
  resetGdsItemAdminItemUpdateDataFormState,
} from './gds-item-admin-item-update-data.action';

import {
  GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_STORE_NAME,
  GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME,
} from './gds-item-admin-item-update-data.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';
import {
  GDS_ITEM_ADMIN_ITEM_DATA_STORE_NAME,
  GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME,
} from '../gds-item-admin-item-data/gds-item-admin-item-data.constant';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';

export function GdsItemAdminItemUpdateDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, gdsItemStore } = useSelector((state) => ({
    state: state[GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    gdsItemStore: state[GDS_ITEM_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetGdsItemAdminItemUpdateDataFormState());
  }, []);

  const gdsItemAdminItemUpdateDataFormSendData = (values) => {
    const data = convertGdsItemAdminItemUpdateDataFormData(values);
    dispatch(
      uploadGdsItemAdminItemUpdateDataForm(
        data,
        gdsItemStore.gdsId,
        gdsItemStore.gdsItemId,
      ),
    );
  };

  const getInitialValue = () => {
    if (isRequestSuccess(gdsItemStore.request)) {
      const data = getRequestData(gdsItemStore.request);
      return {
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ORDER],
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_RU]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.PRICE_RU],
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_EN]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STOCK_EN],
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PRICE_EN]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.PRICE_EN],
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STOCK_RU]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STOCK_RU],

        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_EN]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.TITLE_EN],
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE_RU]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.TITLE_RU],
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_EN]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_EN],
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION_RU]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_RU],
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DATA]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DATA],
        [GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]:
          data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STATUS],
      };
    }

    return {};
  };

  return (
    <GdsItemAdminItemUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(gdsItemStore.request)}
      initialValue={getInitialValue()}
      validation={gdsItemAdminItemUpdateDataFormValidation}
      onSubmitForm={gdsItemAdminItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
