// import { AuthSignupContainer } from './auth-signup.container';

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { AccountLogoutContainer } from "../account-logout/account-logout.container";
import { AccountSettingsUpdatePasswordContainer } from "../account-settings-update-password/account-settings-update-password.container";
import { AccountSettingsUpdateEmailContainer } from "../account-settings-update-email/account-settings-update-email.container";
import { AccountSettingsUpdateTimezoneContainer } from "../account-settings-update-timezone/account-settings-update-timezone.container";

export function AccountSettingsPage() {
  return (
    <Container maxWidth="sm">
      <Grid container spacing={3}>
        <Grid item>
          <AccountSettingsUpdateTimezoneContainer />
        </Grid>
        <Grid item>
          <AccountSettingsUpdatePasswordContainer />
        </Grid>
        <Grid item>
          <AccountSettingsUpdateEmailContainer />
        </Grid>

        <Grid item>
          <AccountLogoutContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
