import React from 'react';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

export const FieldDatePickerComponent = ({
  errorText,
  error,
  label,
  name,
  setValue,
  onBlur,
  onChange,
  ...props
}) => {
  const handleChange = (value) => {
    onBlur && onBlur({ target: { value: value, name } });
    onChange && onChange({ target: { value: value, name } });
    setValue && setValue(name, value);
    setOpen(false);
  };

  const [isOpen, setOpen] = React.useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="fieldLabel">{label}</Typography>
        </Grid>
        <Grid item onClick={() => setOpen(true)}>
          <MobileDateTimePicker
            sx={{ height: '60px' }}
            error={error}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: '',
                }}
              />
            )}
            onChange={handleChange}
            {...props}
          />
        </Grid>
        {error && (
          <Grid item>
            <Typography variant="errorText">{errorText}</Typography>
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  );
};
