import React from "react";

import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_BOOSTER_ITEM_DATA_NAME } from "../../purchase-booster-item/purchase-booster-item.constant";
import { PURCHASE_TYPE_TYPE } from "../../../lib/common/purchase/purchase.type";
import { PURCHASE_ADMIN_ITEM_DATA_NAME } from "../../purchase-admin-item/purchase-admin-item.constant";

export function PurchaseBoosterItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.BOOSTER.ITEM_DATA.ID"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.ID]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.BOOSTER.ITEM_DATA.CREATE_DATE"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.CREATE_DATE]}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.BOOSTER.ITEM_DATA.LANG"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.LANG].text}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.BOOSTER.ITEM_DATA.TIMEZONE"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.TIMEZONE].text}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.BOOSTER.ITEM_DATA.NOTES"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.NOTES] || (
                <TextComponent tid="PURCHASE.BOOSTER.ITEM_DATA.NOTES_NULL" />
              )}
            </TextComponent>
          </Grid>

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.BOOSTER.ITEM_DATA.COMMENTS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COMMENTS] || (
                <TextComponent tid="PURCHASE.BOOSTER.ITEM_DATA.COMMENTS_NULL" />
              )}
            </TextComponent>
          </Grid>

          {data.type.id === PURCHASE_TYPE_TYPE.BOOST && (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_TYPE"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST].type.text}
                </TextComponent>
              </Grid>
              {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST].current.value && (
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_CURRENT"
                  />
                  <TextComponent variant="dataViewContent">
                    {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST].current.text}
                  </TextComponent>
                </Grid>
              )}
              {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST].desired.value && (
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_DESIRED"
                  />
                  <TextComponent variant="dataViewContent">
                    {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST].desired.text}
                  </TextComponent>
                </Grid>
              )}

              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_ADDITIONALS"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST][
                    PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ADDITIONALS
                  ].length ? (
                    data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST][
                      PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ADDITIONALS
                    ]
                      .map((item) => item.text)
                      .join(", ")
                  ) : (
                    <TextComponent tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_ADDITIONALS_NULL" />
                  )}
                </TextComponent>
              </Grid>
              {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST].desired.value && (
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_ACTUAL"
                  />
                  <TextComponent variant="dataViewContent">
                    {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST].actual
                      .text || (
                      <TextComponent tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_ACTUAL_NULL" />
                    )}
                  </TextComponent>
                </Grid>
              )}
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_DATA"
                />

                {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST]?.data &&
                Object.keys(data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST].data)
                  .length ? (
                  <Grid>
                    {Object.entries(
                      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST].data
                    ).map((item, index) => (
                      <Grid key={index} item>
                        {Array.isArray(item[1]) ? (
                          <>
                            <TextComponent
                              variant="dataViewContent"
                              tid={`PURCHASE.BOOSTER.ITEM_DATA.BOOST_DATA_DATA.${item[0].toUpperCase()}`}
                              sx={{ lineHeight: "1.3em" }}
                            />
                            <ListElem>
                              {item[1].map((elem) => (
                                <TextComponent
                                  key={elem}
                                  variant="dataViewContent"
                                  tid={elem}
                                  sx={{ lineHeight: "1.3em" }}
                                />
                              ))}
                            </ListElem>
                          </>
                        ) : (
                          <TextComponent
                            variant="dataViewContent"
                            tid={`PURCHASE.BOOSTER.ITEM_DATA.BOOST_DATA_DATA.${item[0].toUpperCase()}`}
                            tvalue={{ value: item[1] }}
                            sx={{ lineHeight: "1.3em" }}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <TextComponent
                    variant="dataViewContent"
                    tid="PURCHASE.BOOSTER.ITEM_DATA.BOOST_DATA_NULL"
                  />
                )}
              </Grid>
            </React.Fragment>
          )}

          {data.type.id === PURCHASE_TYPE_TYPE.COACHING && (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.COACHING_TYPE"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING].type.text}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.COACHING_SESSION_AMOUNT"
                />
                <TextComponent
                  variant="dataViewContent"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.COACHING_SESSION_AMOUNT_VALUE"
                  tvalue={{
                    value:
                      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING]
                        .sessionAmount,
                  }}
                />
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.COACHING_ACTUAL_SESSION"
                />
                <TextComponent
                  variant="dataViewContent"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.COACHING_ACTUAL_SESSION_VALUE"
                  tvalue={{
                    value:
                      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING]
                        .actualSession,
                  }}
                />
              </Grid>

              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.COACHING_ADDITIONALS"
                />
                <TextComponent variant="dataViewContent">
                  {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING][
                    PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ADDITIONALS
                  ] ? (
                    data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING][
                      PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ADDITIONALS
                    ]
                      .map((item) => item.text)
                      .join(", ")
                  ) : (
                    <TextComponent tid="PURCHASE.BOOSTER.ITEM_DATA.COACHING_ADDITIONALS_NULL" />
                  )}
                </TextComponent>
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.BOOSTER.ITEM_DATA.COACHING_DATA"
                />

                {data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING].data ? (
                  <Grid>
                    {Object.entries(
                      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING].data
                    ).map((item, index) => (
                      <Grid key={index} item>
                        <TextComponent
                          variant="dataViewContent"
                          tid={`PURCHASE.BOOSTER.ITEM_DATA.COACHING_DATA_DATA.${item[0].toUpperCase()}`}
                          tvalue={{ value: item[1] }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <TextComponent
                    variant="dataViewContent"
                    tid="PURCHASE.BOOSTER.ITEM_DATA.COACHING_DATA_NULL"
                  />
                )}
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const ListElem = styled.div`
  margin-left: 12px;
`;

import styled from "styled-components";
