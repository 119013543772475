import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AccountLogoutComponent } from "./account-logout.component";
import { AUTH_STORE_NAME, authLogout } from "../../lib/common/auth";
import { INDEX_ROUTE_PATH } from "../index/index.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

export function AccountLogoutContainer() {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const { state, pageLoading, logged } = useSelector((state) => ({
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    logged: state[AUTH_STORE_NAME].logged,
  }));

  const accountLogoutFormSendData = (values) => {
    dispatch(authLogout());
    redirect(INDEX_ROUTE_PATH);
  };

  return (
    <AccountLogoutComponent
      isLoading={pageLoading && !logged}
      onSubmitForm={accountLogoutFormSendData}
    />
  );
}
