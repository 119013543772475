export const GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_STORE_NAME =
  'GDS_ITEM_ADMIN_ITEM_UPDATE_DATA';

export const GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_API = {
  CHANGE_DATA: {
    ENDPOINT: (gdsId, gdsItemId) => `/gds/${gdsId}/item/${gdsItemId}`,
    TYPE: 'PATCH',
  },
};

export const GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE = {
  FORM_PENDING: 'GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_ACTION_TYPE.FORM_RESET',
};

export const GDS_ITEM_ADMIN_ITEM_UPDATE_DATA_DATA_NAME = {
  ORDER: 'order',
  PRICE_RU: 'priceRu',
  STOCK_RU: 'stockRu',
  PRICE_EN: 'priceEn',
  STOCK_EN: 'stockEn',
  TITLE_EN: 'titleEn',
  TITLE_RU: 'titleRu',
  DESCRIPTION_EN: 'descriptionEn',
  DESCRIPTION_RU: 'descriptionRu',
  URL: 'url',
  DATA: 'data',
  STATUS: 'status',
};
