import * as React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";

import { text } from "../text";

import Grid from "@mui/material/Grid";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.id} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {/* <TextComponent tid={dataSet[1].tid} /> */}
      {text(dataSet[1].tid)}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
      if (child.hasOwnProperty("group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

function random(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export default function Virtualize({ disabled, name, onChange, onBlur }) {
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isClose, setIsClose] = React.useState(true);

  async function fetchData() {
    try {
      setIsLoading(true);

      const response = await fetch("/boost.type.json");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const partialData = await response.json();

      const firstPart = partialData.slice(
        0,
        Math.floor(partialData.length / 3)
      );
      const secondPart = partialData.slice(
        Math.floor(partialData.length / 3),
        Math.floor((partialData.length * 2) / 3)
      );
      const thirdPart = partialData.slice(
        Math.floor((partialData.length * 2) / 3)
      );

      setData(firstPart);

      setTimeout(() => {
        setData(firstPart.concat(secondPart));
      }, 500);

      setTimeout(() => {
        setData(firstPart.concat(secondPart, thirdPart));
        setIsLoading(false);
        setFilteredData(data.slice(0, 200));
      }, 1200);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleInputChange = (inputValue = "") => {
    setIsLoading(true);

    const parts = inputValue.split(" ");
    const prefix = parts[0];
    const suffix = parts[1];

    const regexPattern = new RegExp(prefix, "i");

    const filterData = new Promise((resolve, reject) => {
      const filtered = data.filter((item) => {
        return (
          regexPattern.test(item.tid) || regexPattern.test(item.otherProperty)
        );
      });

      if (suffix) {
        const filteredData = filtered.filter((item) =>
          item.tid.includes(suffix)
        );
        resolve(filteredData);
      } else {
        const sortedFiltered = filtered.sort((a, b) => {
          const aMatchCount = (a.tid.match(regexPattern) || []).length;
          const bMatchCount = (b.tid.match(regexPattern) || []).length;

          return bMatchCount - aMatchCount;
        });

        const filteredData = sortedFiltered.slice(0, 50);
        resolve(filteredData);
      }
    });

    filterData
      .then((filteredData) => {
        setFilteredData(filteredData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (isOpen) {
      fetchData();
    }
    if (isClose) {
      setFilteredData([]);
    }
  }, [isOpen, isClose]);

  return (
    <Autocomplete
      loading={isLoading}
      disabled={disabled}
      name={name}
      id="virtualize-demo"
      sx={{ width: "100%" }}
      disableListWrap
      onChange={(e, data) => {
        onBlur && onBlur({ target: { value: data, name } });
        onChange && onChange({ target: { value: data, name } });
      }}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={filteredData}
      getOptionLabel={(t) => text(t.tid)}
      onInputChange={(e, value) => handleInputChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            tabIndex: -1,
            style: { paddingTop: 0, paddingBottom: 0 },
          }}
        />
      )}
      renderOption={(props, option) => [props, option]}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
        setIsClose(false);
      }}
      onClose={() => {
        setIsOpen(false);
        setIsClose(true);
      }}
    />
  );
}

export const FieldBoostRankComponent = (props) => (
  <Grid container spacing={2}>
    {props.label && (
      <Grid item>
        <Typography variant="fieldLabel">{props.label}</Typography>
      </Grid>
    )}
    <Grid item>
      <Virtualize {...props} />
    </Grid>
    {props.error && props.errorText && (
      <Grid item>
        <Typography variant="errorText">{props.errorText}</Typography>
      </Grid>
    )}
  </Grid>
);
