import { PURCHASE_BOOSTER_ITEM_DATA_NAME } from "./purchase-booster-item.constant";

import {
  convertPurchaseType,
  convertPurchaseStatus,
  convertPurchaseLang,
} from "../../lib/common/purchase/purchase.convert";
import {
  convertBoostRank,
  convertBoostType,
  convertBoostAdditionalList,
} from "../../lib/common/boost/boost.convert";
import { convertPromocode } from "../../lib/common/promocode/promocode.convert";
import { convertTimezone } from "../../lib/common/user/user.convert";
import { PURCHASE_TYPE_TYPE } from "../../lib/common/purchase/purchase.type";
import { convertDatetime } from "../../lib/common/convert/convert.core";
import {
  convertPaymentSystemType,
  convertPaymentValuteType,
} from "../../lib/common/payment/payment.convert";
import { PURCHASE_ADMIN_ITEM_DATA_NAME } from "../purchase-admin-item/purchase-admin-item.constant";
import {
  convertCoachingAdditionalList,
  convertCoachingType,
} from "../../lib/common/coaching/coaching.convert";

export const convertPurchaseBoosterItem = (data) => {
  const purchaseType = convertPurchaseType(
    data[PURCHASE_BOOSTER_ITEM_DATA_NAME.TYPE]
  );

  const boostData = data[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST];

  const boost =
    purchaseType.id === PURCHASE_TYPE_TYPE.BOOST
      ? {
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_CURRENT]: convertBoostRank(
            boostData[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_CURRENT]
          ),
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_DESIRED]: convertBoostRank(
            boostData[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_DESIRED]
          ),
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ACTUAL]: convertBoostRank(
            boostData[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ACTUAL]
          ),
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ADDITIONALS]:
            convertBoostAdditionalList(
              boostData[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ADDITIONALS]
            ),
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ID]:
            boostData[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ID],
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_DATA]:
            boostData[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_DATA],
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_TYPE]: convertBoostType(
            boostData[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_TYPE]
          ),
        }
      : null;

  const coachingData = data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING];

  const coaching =
    purchaseType.id === PURCHASE_TYPE_TYPE.COACHING
      ? {
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ADDITIONALS]:
            convertCoachingAdditionalList(
              coachingData[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ADDITIONALS]
            ),
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ID]:
            coachingData[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ID],
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_DATA]:
            coachingData[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_DATA],
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_TYPE]: convertCoachingType(
            coachingData[PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_TYPE]
          ),
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_SESSION_AMOUNT]:
            coachingData[
              PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_SESSION_AMOUNT
            ],
          [PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION]:
            coachingData[
              PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING_ACTUAL_SESSION
            ],
        }
      : null;

  const isCustomer = !!data[PURCHASE_BOOSTER_ITEM_DATA_NAME.CUSTOMER];
  const customerData = data[PURCHASE_BOOSTER_ITEM_DATA_NAME.CUSTOMER];

  const customer = isCustomer
    ? {
        [PURCHASE_BOOSTER_ITEM_DATA_NAME.CUSTOMER_ID]:
          customerData[PURCHASE_BOOSTER_ITEM_DATA_NAME.CUSTOMER_ID],
        [PURCHASE_BOOSTER_ITEM_DATA_NAME.CUSTOMER_TIMEZONE]: convertTimezone(
          customerData[PURCHASE_BOOSTER_ITEM_DATA_NAME.CUSTOMER_TIMEZONE]
        ),
      }
    : null;

  return {
    [PURCHASE_BOOSTER_ITEM_DATA_NAME.ID]:
      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.ID],
    [PURCHASE_BOOSTER_ITEM_DATA_NAME.METADATA]:
      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.METADATA],
    [PURCHASE_BOOSTER_ITEM_DATA_NAME.TIMEZONE]: convertTimezone(
      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.TIMEZONE]
    ),
    [PURCHASE_BOOSTER_ITEM_DATA_NAME.LANG]: convertPurchaseLang(
      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.LANG]
    ),

    [PURCHASE_BOOSTER_ITEM_DATA_NAME.NOTES]:
      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.NOTES],

    [PURCHASE_BOOSTER_ITEM_DATA_NAME.COMMENTS]:
      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.COMMENTS],

    [PURCHASE_BOOSTER_ITEM_DATA_NAME.STATUS]: convertPurchaseStatus(
      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.STATUS]
    ),

    [PURCHASE_BOOSTER_ITEM_DATA_NAME.TYPE]: purchaseType,

    [PURCHASE_BOOSTER_ITEM_DATA_NAME.CREATE_DATE]: convertDatetime(
      data[PURCHASE_BOOSTER_ITEM_DATA_NAME.CREATE_DATE]
    ),

    [PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST]: boost,

    [PURCHASE_BOOSTER_ITEM_DATA_NAME.IS_CUSTOMER]: isCustomer,
    [PURCHASE_BOOSTER_ITEM_DATA_NAME.CUSTOMER]: customer,

    [PURCHASE_BOOSTER_ITEM_DATA_NAME.COACHING]: coaching,
  };
};
