import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC } from "../../purchase-customer-item/purchase-customer-item.constant";

export function PurchaseCustomerListViewComponent(props) {
  const { data } = props;

  if (data.list.length === 0) {
    return (
      <Box sx={{ px: 6, pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="PURCHASE.CUSTOMER.LIST.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  const navigate = useNavigate();

  const handleClickItem = (purchaseId) => (e) => {
    e.preventDefault();
    return navigate(PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }));
  };

  const getCurent = (item) => {
    switch (item.boost.type.value) {
      case "APEX_BOOST_TYPE_BADGE":
        return (
          <>
            <Grid item>
              <TextComponent
                sx={{
                  wordBreak: "break-all",
                  whiteSpace: "break-spaces",
                }}
                variant="body1"
              >
                {item.boost.data.badge.map((badge) => (
                  <React.Fragment key={badge}>
                    <p>{badge}</p>
                  </React.Fragment>
                ))}
              </TextComponent>
            </Grid>
          </>
        );
      default:
        return (
          <TextComponent
            tid="PURCHASE.CUSTOMER.LIST.BOOST"
            variant="body1"
            component="span"
            tvalue={{
              current: item.boost.current.text,
              desired: item.boost.desired.text,
            }}
          />
        );
    }
  };

  return (
    <List sx={{ pt: 4 }}>
      {data.list.map((item) => (
        <ListItem
          key={item.id}
          onClick={handleClickItem(item.id)}
          button
          variant="list"
          component="a"
          href={PURCHASE_CUSTOMER_ITEM_ROUTE_PATH_DYNAMIC({
            purchaseId: item.id,
          })}
        >
          <Grid
            sx={{
              flexDirection: "row",
              justifyContent: "flex-start !important",
            }}
            container
          >
            <Grid item xs={12} md={1.5}>
              <Grid container spacing={2}>
                <Grid item>
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.LIST.CREATE_DATE"
                    variant="body1"
                    component="div"
                    tvalue={{ date: item.createDate }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container spacing={2}>
                {item.boost && (
                  <React.Fragment>
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.CUSTOMER.LIST.TYPE"
                        variant="body1"
                        component="span"
                        tvalue={{ type: item.boost.type.text }}
                      />
                      {getCurent(item)}
                    </Grid>
                  </React.Fragment>
                )}
                {item.gdsItem ? (
                  <React.Fragment>
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.CUSTOMER.LIST.GDS_ITEM"
                        variant="body1"
                        tvalue={{ title: item.gdsItem.titleEn }}
                      />
                    </Grid>
                  </React.Fragment>
                ) : item.gds ? (
                  <React.Fragment>
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.CUSTOMER.LIST.GDS"
                        variant="body1"
                        tvalue={{ title: item.gds.titleEn }}
                      />
                    </Grid>
                  </React.Fragment>
                ) : null}

                {item.coaching && (
                  <React.Fragment>
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.CUSTOMER.LIST.TYPE"
                        variant="body1"
                        component="span"
                        tvalue={{ type: item.coaching.type.text }}
                      />
                      <TextComponent
                        tid="PURCHASE.CUSTOMER.LIST.COACHING"
                        variant="body1"
                        component="span"
                        tvalue={{
                          sessionAmount: item.coaching.sessionAmount,
                          actualSession: item.coaching.actualSession,
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={1.5}>
              <Grid container spacing={2}>
                <React.Fragment>
                  <Grid item>
                    <TextComponent
                      tid="PURCHASE.CUSTOMER.LIST.PRICE"
                      variant="body1"
                      tvalue={{
                        price: item.price,
                        valute: item.valute.text,
                      }}
                    />
                  </Grid>
                </React.Fragment>
              </Grid>
            </Grid>
            <Grid item xs={12} md={1.2}>
              <Grid container spacing={2}>
                {item.paid ? (
                  <React.Fragment>
                    <Grid item>
                      <TextComponent
                        tid="PURCHASE.CUSTOMER.LIST.PAID_TRUE"
                        variant="body1"
                        sx={{ color: "green" }}
                      />
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Grid item>
                    <TextComponent
                      tid="PURCHASE.CUSTOMER.LIST.PAID_FALSE"
                      variant="body1"
                      sx={{ color: "red" }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={2.5}>
              <Grid container spacing={2}>
                <Grid item>
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.LIST.STATUS"
                    variant="body1"
                    component="div"
                    tvalue={{ status: item.status.text }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
}
