import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { NoticeAdminListComponent } from './notice-admin-list.component';

import { getNoticeAdminList } from './notice-admin-list.action';

import { NOTICE_ADMIN_LIST_STORE_NAME } from './notice-admin-list.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';

export function NoticeAdminListContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[NOTICE_ADMIN_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(getNoticeAdminList({}));
  }, []);

  return (
    <NoticeAdminListComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
