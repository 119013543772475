import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation, useRouteError, useLocation } from "react-router-dom";

import { NAVIGATION_STORE_NAME } from "./navigation.constant";
import { setActivePath, setPageLoading } from "./navigation.action";
import { LoaderBarComponent } from "../loader-bar/loader-bar.component";

export const NavigationObserver = () => {
  const { pageLoading } = useSelector((state) => ({
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const dispatch = useDispatch();
  let location = useLocation();
  const navigation = useNavigation();
  let routeError = useRouteError();

  useEffect(() => {
    dispatch(setActivePath(location.pathname));

    if (routeError) {
      dispatch(setPageLoading(false));
    }
    dispatch(setPageLoading(false));

    return () => {
      window.scrollTo(0, 0);
    };
  }, [navigation, location]);

  return pageLoading ? <LoaderBarComponent /> : null;
};
