export const PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_STORE_NAME =
  'PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE';

export const PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_API = {
  UPDATE_DATA: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/customer/boost/time`,
    TYPE: 'POST',
  },
};

export const PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_ACTION_TYPE = {
  FORM_PENDING:
    'PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS:
    'PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_ACTION_TYPE.FORM_RESET',
};

export const PURCHASE_CUSTOMER_ITEM_BOOST_TIME_CREATE_DATA_NAME = {
  FROM_TIME: 'fromTime',
  TO_TIME: 'toTime',
};
