import { validate } from '../../main/validate';

import { USER_ADMIN_CREATE_DATA_NAME } from './user-admin-create.constant';

import { login, email, required } from '../../main/validate/validate.service';

const config = {
  [USER_ADMIN_CREATE_DATA_NAME.LOGIN]: [required, login],
  [USER_ADMIN_CREATE_DATA_NAME.EMAIL]: [required, email],
  [USER_ADMIN_CREATE_DATA_NAME.TIMEZONE]: [required],
  [USER_ADMIN_CREATE_DATA_NAME.ROLE]: [required],
  [USER_ADMIN_CREATE_DATA_NAME.ROLE]: [],
};

export const userAdminCreateFormValidation = (values) =>
  validate(values, config);
