import { PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME } from './purchase-customer-item-boost-account-data.constant';

export const convertPurchaseCustomerItemBoostAccountData = (data) => ({
  [PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.ID]:
    data[PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.ID],
  [PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.PURCHASE_ID]:
    data[PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.PURCHASE_ID],
  [PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.DATA]:
    data[PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.DATA],
});
