import React from "react";
import { useNavigate } from "react-router-dom";

import { PopupAdminCreateInfoComponent } from "./popup-admin-create-info.component";
import { POPUP_ADMIN_CREATE_ROUTE_PATH } from "../popup-admin-create/popup-admin-create.constant";

export function PopupAdminCreateInfoContainer() {
  const navigate = useNavigate();

  const onSubmitForm = () => {
    return navigate(POPUP_ADMIN_CREATE_ROUTE_PATH);
  };

  return <PopupAdminCreateInfoComponent onSubmitForm={onSubmitForm} />;
}
