import React from "react";
import { useNavigate } from "react-router-dom";

import { UserAdminFindItemComponent } from "./user-admin-find-item.component";
import { USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC } from "../user-admin-item/user-admin-item.constant";

export function UserAdminFindItemContainer() {
  const navigate = useNavigate();
  const onSubmitForm = (userId) => {
    return navigate(USER_ADMIN_ITEM_ROUTE_PATH_DYNAMIC({ userId }));
  };

  return <UserAdminFindItemComponent onSubmitForm={onSubmitForm} />;
}
