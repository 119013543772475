import { httpRequest } from '../../main/http';

import {
  ACCOUNT_SETTINGS_UPDATE_EMAIL_API,
  ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE,
} from './account-settings-update-email.constant';

import { authUpdateUserData } from '../../lib/common/auth';

export function uploadAccountSettingsUpdateEmailForm(data) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: ACCOUNT_SETTINGS_UPDATE_EMAIL_API.CHANGE_EMAIL.TYPE,
        url: ACCOUNT_SETTINGS_UPDATE_EMAIL_API.CHANGE_EMAIL.ENDPOINT,
        data,
      });

      await dispatch(authUpdateUserData());

      dispatch({
        type: ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetAccountSettingsUpdateEmailFormState() {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_SETTINGS_UPDATE_EMAIL_ACTION_TYPE.FORM_RESET,
    });
  };
}
