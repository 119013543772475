import Grid from '@mui/material/Grid';

import { TextComponent } from '../../../lib/common/text/text.component';
import { PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME } from '../promocode-admin-item-data.constant';

export function PromocodeAdminItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="PROMOCODE.ADMIN.ITEM_DATA.ID"
        />
        <TextComponent variant="dataViewContent">
          {data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.ID]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="PROMOCODE.ADMIN.ITEM_DATA.NAME"
        />
        <TextComponent variant="dataViewContent">
          {data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.NAME]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="PROMOCODE.ADMIN.ITEM_DATA.FACTOR"
        />
        <TextComponent variant="dataViewContent">
          {data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.FACTOR].text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="PROMOCODE.ADMIN.ITEM_DATA.CREATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]}
        </TextComponent>
      </Grid>
    </Grid>
  );
}
