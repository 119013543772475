import { format, addMinutes } from 'date-fns';

import moment from 'moment';
import { text } from '../text';

export const convertDatetimePm = (date) => {
  return moment(date).locale('en').format(text('CONVERT.DATE.DATE_TIME_PM'));
};

export const convertDatetime = (date) => {
  return moment(date).locale('en-gb').format(text('CONVERT.DATE.DATE_TIME'));
};

export const convertDateSchedule = (date) => {
  return moment(date).locale('en-gb').format(text('CONVERT.DATE.SCHEDULE'));
};

export const convertDateSchedulePm = (date) => {
  return moment(date).locale('en').format(text('CONVERT.DATE.SCHEDULE_PM'));
};

export const convertDate = (date) => {
  let serverDate = new Date(date);

  // const timezone = new Date().getTimezoneOffset() / -60;
  // const hours = serverDate.getHours();

  // serverDate = serverDate.setHours(hours + timezone);

  // const localDate = new Date(serverDate);

  // return localDate.toLocaleDateString();

  // return serverDate.toLocaleDateString();

  return moment(date).format(text('CONVERT.DATE.DATE'));
};

export const formatDateToUtc = (date) => {
  try {
    const newDate = format(
      addMinutes(date, date.getTimezoneOffset()),
      "yyyy-MM-dd'T'HH:mm:ss",
    );
    return newDate;
  } catch (error) {
    console.log(error);
  }
};

export const convertDateToCalendarTimePm = (date) => {
  return moment(date)
    .locale('en')
    .calendar({
      sameDay: text('CONVERT.DATE.CALENDAR_SAME_DAY_PM'),
      lastDay: text('CONVERT.DATE.CALENDAR_LAST_DAY_PM'),
      lastWeek: text('CONVERT.DATE.CALENDAR_LAST_WEEK_PM'),
      sameElse: text('CONVERT.DATE.CALENDAR_SAME_ELSE_PM'),
    });
};

export const convertDateToCalendarTime = (date) => {
  return moment(date)
    .locale('en-gb')
    .calendar({
      sameDay: text('CONVERT.DATE.CALENDAR_SAME_DAY'),
      lastDay: text('CONVERT.DATE.CALENDAR_LAST_DAY'),
      lastWeek: text('CONVERT.DATE.CALENDAR_LAST_WEEK'),
      sameElse: text('CONVERT.DATE.CALENDAR_SAME_ELSE'),
    });
};

export const convertTimePm = (date) => {
  return moment(date).locale('en').format(text('CONVERT.DATE.TIME_PM'));
};

export const convertTime = (date) => {
  return moment(date).locale('en-gb').format(text('CONVERT.DATE.TIME'));
};
