import { PROMOCODE_ADMIN_LIST_DATA_NAME } from './promocode-admin-list.constant';

import { convertPromocodeFactor } from '../../lib/common/promocode/promocode.convert';

export const convertPromocodeAdminList = (d) =>
  d.list.map((data) => {
    return {
      [PROMOCODE_ADMIN_LIST_DATA_NAME.ID]:
        data[PROMOCODE_ADMIN_LIST_DATA_NAME.ID],
      [PROMOCODE_ADMIN_LIST_DATA_NAME.NAME]:
        data[PROMOCODE_ADMIN_LIST_DATA_NAME.NAME],

      [PROMOCODE_ADMIN_LIST_DATA_NAME.FACTOR]: convertPromocodeFactor(
        data[PROMOCODE_ADMIN_LIST_DATA_NAME.FACTOR],
      ),
    };
  });
