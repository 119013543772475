import { USER_ADMIN_ITEM_DATA_ACTION_TYPE } from './user-admin-item-data.constant';
import {
  initRequestState,
  setRequestPending,
  setRequestSuccess,
  setRequestError,
  setRequestUpdatePending,
  setRequestUpdateSuccess,
} from '../../main/store/store.service';

const initialState = {
  request: initRequestState(),
  userId: null,
};

export function userAdminItemDataStore(state = initialState, action) {
  switch (action.type) {
    case USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.data),
        userId: action.userId,
      };

    case USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.errorMessage),
      };

    case USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING:
      return {
        ...state,
        request: setRequestUpdatePending(state.request),
      };

    case USER_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        request: setRequestUpdateSuccess(state.request, action.data),
      };

    default:
      return state;
  }
}
