import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { AuthLoginContainer } from "./auth-login.container";

export function AuthLoginPage() {
  return (
    <Container sx={{ px: 3 }} maxWidth="xs">
      <Grid sx={{ py: 6, minHeight: "100vh" }} container>
        <Grid item>
          <AuthLoginContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
