import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { UserAdminListPaginationComponent } from './user-admin-list-pagination.component';

import {
  getRequestData,
  isRequestPending,
} from '../../main/store/store.service';
import { USER_ADMIN_LIST_STORE_NAME } from '../user-admin-list/user-admin-list.constant';
import { getUserAdminList } from '../user-admin-list/user-admin-list.action';

export function UserAdminListPaginationContainer() {
  const dispatch = useDispatch();

  const { state, isLoading } = useSelector((state) => ({
    state: getRequestData(state[USER_ADMIN_LIST_STORE_NAME].request),
    isLoading: isRequestPending(state[USER_ADMIN_LIST_STORE_NAME].request),
  }));

  const onChangeFilter = (data) => {
    dispatch(getUserAdminList({ ...state, ...data }));
  };

  return (
    <UserAdminListPaginationComponent
      data={state}
      onChangeFilter={onChangeFilter}
      isLoading={isLoading}
    />
  );
}
