import { ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE } from './account-verification-email.constant';
import {
  initRequestState,
  setRequestPending,
  setRequestSuccess,
  setRequestError,
  resetRequestStatus,
} from '../../main/store/store.service';

const initialState = {
  form: initRequestState(),
  request: initRequestState(),
};

export function accountVerificationEmailStore(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.FORM_PENDING:
      return {
        ...state,
        form: setRequestPending(state.form),
      };

    case ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.FORM_SUCCESS:
      return {
        ...state,
        form: setRequestSuccess(state.form),
      };

    case ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.FORM_ERROR:
      return {
        ...state,
        form: setRequestError(state.form, action.errorMessage),
      };

    case ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.REQUEST_PENDING:
      return {
        ...state,
        request: setRequestPending(state.form),
      };

    case ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.REQUEST_SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.form),
      };

    case ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.REQUEST_ERROR:
      return {
        ...state,
        request: setRequestError(state.form, action.errorMessage),
      };

    case ACCOUNT_VERIFICATION_EMAIL_ACTION_TYPE.RESET:
      return {
        ...state,
        request: resetRequestStatus(state.request),
      };

    default:
      return state;
  }
}
