import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_STORE_NAME } from '../purchase-customer-item-boost-time-list/purchase-customer-item-boost-time-list.constant';
import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_DELETE_STORE_NAME } from './purchase-customer-item-boost-time-delete.constant';
import { PurchaseCustomerItemBoostTimeDeleteComponent } from './purchase-customer-item-boost-time-delete.component';
import {
  uploadPurchaseCustomerItemBoostTimeDeleteForm,
  resetPurchaseCustomerItemBoostTimeDeleteFormState,
} from './purchase-customer-item-boost-time-delete.action';

export function PurchaseCustomerItemBoostTimeDeleteContainer(props) {
  const { boostTimeId } = props;
  const dispatch = useDispatch();
  const { state, pageLoading, boostTimeStore } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_DELETE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    boostTimeStore: state[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseCustomerItemBoostTimeDeleteFormState());
  }, []);

  const purchaseCustomerItemBoostTimeDeleteFormSendData = () => {
    dispatch(
      uploadPurchaseCustomerItemBoostTimeDeleteForm(
        boostTimeStore.purchaseId,
        boostTimeId,
      ),
    );
  };

  return (
    <PurchaseCustomerItemBoostTimeDeleteComponent
      isPending={isRequestPending(state.form)}
      isPendingThis={state.boostTimeId === boostTimeId}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(boostTimeStore.request)}
      onSubmitForm={purchaseCustomerItemBoostTimeDeleteFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
