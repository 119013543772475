import { httpRequest } from '../../main/http';

import {
  PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_API,
  PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_ACTION_TYPE,
} from './purchase-customer-item-coaching-demo-create.constant';

import { reloadPurchaseCustomerItemCoachingDemoList } from '../purchase-customer-item-coaching-demo-list/purchase-customer-item-coaching-demo-list.action';

export function uploadPurchaseCustomerItemCoachingDemoCreateForm(data, cb) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_ACTION_TYPE.FORM_PENDING,
    });
    try {
      await httpRequest({
        method:
          PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_API.UPDATE_DATA.TYPE,
        url: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_API.UPDATE_DATA.ENDPOINT(
          data.purchaseId,
        ),
        data,
      });

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_ACTION_TYPE.FORM_SUCCESS,
      });

      await dispatch(reloadPurchaseCustomerItemCoachingDemoList());

      await cb();
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseCustomerItemCoachingDemoCreateFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_CREATE_ACTION_TYPE.FORM_RESET,
    });
  };
}
