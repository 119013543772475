export const GDS_ITEM_STATUS_TYPE = {
  ACTIVE: 1,
  DISABLED: 2,
};

export const GDS_ITEM_SORT_TYPE = {
  ORDER: 1,
  ID: 2,
};

export const GDS_ITEM_STATUS_VALUE = {
  1: 'ACTIVE',
  2: 'DISABLED',
};

export const GDS_ITEM_SORT_VALUE = {
  1: 'ORDER',
  2: 'ID',
};

export const GDS_ITEM_STATUS_OPTION = [
  { id: 1, value: 1, tid: 'GDS_ITEM.DATA.STATUS.ACTIVE' },
  { id: 2, value: 2, tid: 'GDS_ITEM.DATA.STATUS.DISABLED' },
];

export const GDS_ITEM_SORT_OPTION = [
  { id: 1, value: 1, tid: 'GDS_ITEM.DATA.SORT.ORDER' },
  { id: 2, value: 2, tid: 'GDS_ITEM.DATA.SORT.ID' },
];
