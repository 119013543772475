import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseAdminItemTabContainer } from "../purchase-admin-item-tab/purchase-admin-item-tab.container";
import { PurchaseAdminItemCoachingDemoListContainer } from "../purchase-admin-item-coaching-demo-list/purchase-admin-item-coaching-demo-list.container";
import { PurchaseAdminItemContainer } from "../purchase-admin-item/purchase-admin-item.container";
import { PurchaseAdminItemCoachingDemoCreateContainer } from "../purchase-admin-item-coaching-demo-create/purchase-admin-item-coaching-demo-create.container";

export function PurchaseAdminItemCoachingDemoPage() {
  return (
    <>
      <PurchaseAdminItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseAdminItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemCoachingDemoListContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemCoachingDemoCreateContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
