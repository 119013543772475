import { httpRequest } from '../../main/http';

import {
  NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_SHORT_API,
  NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE,
} from './notice-account-item-update-view.constant';

export function updateNoticeAccountItemView(
  noticeId,
  cb,
  noticeStateId = noticeId,
) {
  return async (dispatch) => {
    dispatch({
      type: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE.REQUEST_PENDING,
      noticeId: { [noticeStateId]: true },
    });

    try {
      const res = await httpRequest({
        method: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_SHORT_API.ITEM_UPDATE_VIEW.TYPE,
        url: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_SHORT_API.ITEM_UPDATE_VIEW.ENDPOINT(
          noticeId,
        ),
      });

      await cb();

      dispatch({
        type: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE.REQUEST_SUCCESS,
        noticeId: { [noticeStateId]: true },
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function updateNoticeAccountItemViewAll(cb) {
  return async (dispatch) => {
    dispatch({
      type: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE.REQUEST_PENDING,
      noticeId: true,
    });

    try {
      const res = await httpRequest({
        method:
          NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_SHORT_API.LIST_UPDATE_VIEW_ALL.TYPE,
        url: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_SHORT_API.LIST_UPDATE_VIEW_ALL
          .ENDPOINT,
      });

      await cb();

      dispatch({
        type: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE.REQUEST_SUCCESS,
        noticeId: true,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function updateNoticeAccountItemViewByIds(ids, cb, noticeId) {
  return async (dispatch) => {
    dispatch({
      type: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE.REQUEST_PENDING,
      noticeId,
    });

    try {
      const res = await httpRequest({
        method: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_SHORT_API.LIST_UPDATE_VIEW.TYPE,
        url: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_SHORT_API.LIST_UPDATE_VIEW
          .ENDPOINT,
        data: {
          ids,
        },
      });

      await cb();

      dispatch({
        type: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE.REQUEST_SUCCESS,
        noticeId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
