export const PROMOCODE_ADMIN_ITEM_DATA_STORE_NAME = 'PROMOCODE_ADMIN_ITEM_DATA';

export const PROMOCODE_ADMIN_ITEM_DATA_API = {
  PROMOCODE_ADMIN_ITEM_DATA: {
    ENDPOINT: (promocodeId) => `/promocode/${promocodeId}`,
    TYPE: 'GET',
  },
};

export const PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE = {
  REQUEST_PENDING: 'PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'PROMOCODE_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const PROMOCODE_ADMIN_ITEM_DATA_DATA_NAME = {
  ID: 'id',
  NAME: 'name',
  FACTOR: 'factor',
  CREATE_DATE: 'createDate',
};
