import Box from '@mui/material/Box';

import { FieldTextComponent } from '../../../lib/common/field-text/field-text.component';
import { FieldPasswordComponent } from '../../../lib/common/field-password/field-password.component';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ACCOUNT_UPDATE_AUTH_DATA_NAME } from '../account-update-auth.constant';

export const AccountUpdateAuthFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === '{}') {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              label={<TextComponent tid="ACCOUNT.UPDATE_AUTH.FORM.CODE" />}
              name={ACCOUNT_UPDATE_AUTH_DATA_NAME.CODE}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[ACCOUNT_UPDATE_AUTH_DATA_NAME.CODE]}
              error={isFieldError(ACCOUNT_UPDATE_AUTH_DATA_NAME.CODE)}
              fullWidth
              errorText={getFieldError(ACCOUNT_UPDATE_AUTH_DATA_NAME.CODE)}
            />
          </Grid>
          <Grid item>
            <FieldPasswordComponent
              label={<TextComponent tid="ACCOUNT.UPDATE_AUTH.FORM.PASSWORD" />}
              name={ACCOUNT_UPDATE_AUTH_DATA_NAME.PASSWORD}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[ACCOUNT_UPDATE_AUTH_DATA_NAME.PASSWORD]}
              error={isFieldError(ACCOUNT_UPDATE_AUTH_DATA_NAME.PASSWORD)}
              fullWidth
              errorText={getFieldError(ACCOUNT_UPDATE_AUTH_DATA_NAME.PASSWORD)}
            />
          </Grid>
          <Grid item>
            <FieldPasswordComponent
              label={
                <TextComponent tid="ACCOUNT.UPDATE_AUTH.FORM.REPEAT_PASSWORD" />
              }
              name={ACCOUNT_UPDATE_AUTH_DATA_NAME.REPEAT_PASSWORD}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[ACCOUNT_UPDATE_AUTH_DATA_NAME.REPEAT_PASSWORD]}
              error={isFieldError(
                ACCOUNT_UPDATE_AUTH_DATA_NAME.REPEAT_PASSWORD,
              )}
              fullWidth
              errorText={getFieldError(
                ACCOUNT_UPDATE_AUTH_DATA_NAME.REPEAT_PASSWORD,
              )}
            />
          </Grid>
          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="ACCOUNT.UPDATE_AUTH.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
