import { PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME } from './purchase-admin-item-boost-account-data.constant';

export const convertPurchaseAdminItemBoostAccountData = (data) => ({
  [PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.ID]:
    data[PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.ID],
  [PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.PURCHASE_ID]:
    data[PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.PURCHASE_ID],
  [PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.DATA]:
    data[PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.DATA],
});
