import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { IndexAdminComponent } from "./index-admin.component";

export function IndexAdminPage() {
  return (
    <Container maxWidth="xs">
      <Grid container>
        <Grid item>
          <IndexAdminComponent />
        </Grid>
      </Grid>
    </Container>
  );
}
