import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { NewsAdminItemDeleteComponent } from "./news-admin-item-delete.component";
import {
  uploadNewsAdminItemDeleteForm,
  resetNewsAdminItemDeleteFormState,
} from "./news-admin-item-delete.action";
import { NEWS_ADMIN_ITEM_DELETE_STORE_NAME } from "./news-admin-item-delete.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import { NEWS_ADMIN_ITEM_DATA_STORE_NAME } from "../news-admin-item-data/news-admin-item-data.constant";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function NewsAdminItemDeleteContainer() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { state, pageLoading, itemStore } = useSelector((state) => ({
    state: state[NEWS_ADMIN_ITEM_DELETE_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    itemStore: state[NEWS_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetNewsAdminItemDeleteFormState());
  }, []);

  const newsAdminItemDeleteFormSendData = () => {
    dispatch(uploadNewsAdminItemDeleteForm(itemStore.newsId, navigate));
  };

  return (
    <NewsAdminItemDeleteComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(itemStore.request)}
      onSubmitForm={newsAdminItemDeleteFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
