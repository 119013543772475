import React from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Pagination from '@mui/material/Pagination';

import Skeleton from '@mui/material/Skeleton';

import { PURCHASE_ADMIN_LIST_DATA_NAME } from '../purchase-admin-list/purchase-admin-list.constant';

export function NoticeAccountListPaginationComponent(props) {
  const { onChangeFilter, data, isLoading } = props;

  const handleChangePagination = (e, value) => {
    onChangeFilter({
      [PURCHASE_ADMIN_LIST_DATA_NAME.SKIP]: data.take * value - data.take,
    });
  };

  const paginationCount = Math.ceil(data.amount / data.take);
  const paginationPage = Math.ceil(data.skip / data.take + 1);

  return (
    <Box>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={40}
          sx={{ borderRadius: 1 }}
        />
      ) : (
        <Pagination
          count={paginationCount}
          page={paginationPage}
          onChange={handleChangePagination}
          size="large"
          variant="outlined"
          shape="rounded"
        />
      )}
    </Box>
  );
}
