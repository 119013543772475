import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PopupAdminListContainer } from "./popup-admin-list.container";
import { PopupAdminCreateInfoContainer } from "../popup-admin-create-info/popup-admin-create-info.container";

export function PopupAdminListPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <PopupAdminCreateInfoContainer />
        </Grid>
        <Grid item>
          <PopupAdminListContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
