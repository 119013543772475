import { USER_ADMIN_ITEM_DATA_DATA_NAME } from "./user-admin-item-data.constant";

import {
  convertBoosterRole,
  convertTimezone,
  convertUserRole,
} from "../../lib/common/user/user.convert";

export const convertUserAdminItemData = (data) => {
  if (!data) {
    return;
  }

  return {
    [USER_ADMIN_ITEM_DATA_DATA_NAME.LOGIN]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.LOGIN],
    [USER_ADMIN_ITEM_DATA_DATA_NAME.BONUS]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.BONUS],
    [USER_ADMIN_ITEM_DATA_DATA_NAME.ONLINE]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.ONLINE],
    [USER_ADMIN_ITEM_DATA_DATA_NAME.EMAIL]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.EMAIL],
    [USER_ADMIN_ITEM_DATA_DATA_NAME.BOOSTER_ROLE]: convertBoosterRole(
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.BOOSTER_ROLE]
    ),
    [USER_ADMIN_ITEM_DATA_DATA_NAME.ID]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.ID],
    [USER_ADMIN_ITEM_DATA_DATA_NAME.TIMEZONE]: convertTimezone(
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.TIMEZONE]
    ),
    [USER_ADMIN_ITEM_DATA_DATA_NAME.ROLE]: convertUserRole(
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.ROLE]
    ),
    [USER_ADMIN_ITEM_DATA_DATA_NAME.TELEGRAM]:
      data[USER_ADMIN_ITEM_DATA_DATA_NAME.TELEGRAM] || null,
  };
};
