import { PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_DATA_NAME } from './purchase-admin-item-update-coaching-data.constant';
import { getBoostRankImageUrl } from '../../lib/common/boost/boost.core';
import { i18n } from '../../main/lang';

export const convertPurchaseAdminItemUpdateCoachingDataFormData = (data) => {
  return {
    [PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION]:
      data[PURCHASE_ADMIN_ITEM_UPDATE_COACHING_DATA_DATA_NAME.ACTUAL_SESSION],
  };
};
