import { httpRequest } from "../../main/http";

import {
  ACCOUNT_TELEGRAM_CONNECT_API,
  ACCOUNT_TELEGRAM_CONNECT_ACTION_TYPE,
} from "./account-telegram-connect.constant";

export function uploadAccountTelegramConnectForm(data) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_TELEGRAM_CONNECT_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: ACCOUNT_TELEGRAM_CONNECT_API.GET_EMAIL.TYPE,
        url: ACCOUNT_TELEGRAM_CONNECT_API.GET_EMAIL.ENDPOINT,
        data,
      });

      dispatch({
        type: ACCOUNT_TELEGRAM_CONNECT_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: ACCOUNT_TELEGRAM_CONNECT_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
