import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Alert from '@mui/material/Alert';

import { Formik } from 'formik';

import { LoaderBarComponent } from '../../lib/common/loader-bar';
import { TextComponent } from '../../lib/common/text';

import { PurchaseAdminItemUpdateGdsItemFormComponent } from './frame/purchase-admin-item-update-gds-item-form.component';

export function PurchaseAdminItemUpdateGdsItemComponent(props) {
  const {
    initialValue,
    pageLoading,
    isPending,
    validation,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
  } = props;
  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="title"
              component="div"
              tid="PURCHASE.ADMIN.UPDATE.GDS_ITEM.TITLE"
            />
          </Box>
          <Divider />
          <Formik
            initialValues={initialValue}
            validate={validation}
            onSubmit={onSubmitForm}
          >
            {(props) => (
              <PurchaseAdminItemUpdateGdsItemFormComponent
                {...props}
                isPending={isPending}
                isError={isError}
                errorMessage={errorMessage}
                pageLoading={pageLoading}
              />
            )}
          </Formik>
          {isError && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isSuccess && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="success">
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.GDS_ITEM.SUCCESS_MESSAGE" />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
