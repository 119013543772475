import Box from "@mui/material/Box";
import List from "@mui/material/List";

import { TextComponent } from "../../../lib/common/text/text.component";
import { NoticeItemComponent } from "../../../lib/common/notice/notice-item.component";

export function NoticeAccountListShortViewComponent(props) {
  const { data, viewUpdateId, isViewUpdate, handleUpdateView } = props;

  if (data.list.length === 0) {
    return (
      <Box sx={{ px: 6 }}>
        <TextComponent
          variant="body1"
          tid="NOTICE.ACCOUNT.LIST_SHORT.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  return (
    <Box>
      <List>
        {data.list.map((item, index) => (
          <NoticeItemComponent
            onDelete={handleUpdateView}
            loading={
              (viewUpdateId === true
                ? true
                : viewUpdateId && viewUpdateId[item.id]) && isViewUpdate
            }
            disabled={
              (viewUpdateId === true
                ? false
                : viewUpdateId && viewUpdateId[item.id]) && isViewUpdate
            }
            index={index}
            {...item}
          />
        ))}
      </List>
    </Box>
  );
}
