import { PopupAdminCreateContainer } from "./popup-admin-create.container";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

export function PopupAdminCreatePage() {
  return (
    <Container maxWidth="sm">
      <Grid container>
        <Grid item>
          <PopupAdminCreateContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
