import { POPUP_ADMIN_ITEM_DATA_DATA_NAME } from './popup-admin-item-data.constant';

import {
  convertPopupLang,
  convertPopupType,
} from '../../lib/common/popup/popup.convert';

import { convertDatetime } from '../../lib/common/convert/convert.core';
import { convertFile } from '../../lib/common/file/file.convert';

export const convertPopupAdminItemData = (data) => ({
  [POPUP_ADMIN_ITEM_DATA_DATA_NAME.ID]:
    data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.ID],
  [POPUP_ADMIN_ITEM_DATA_DATA_NAME.TEXT]:
    data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.TEXT],
  [POPUP_ADMIN_ITEM_DATA_DATA_NAME.LANG]: convertPopupLang(
    data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.LANG],
  ),
  [POPUP_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]: convertDatetime(
    data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE],
  ),
  [POPUP_ADMIN_ITEM_DATA_DATA_NAME.TYPE]: convertPopupType(
    data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.TYPE],
  ),
  [POPUP_ADMIN_ITEM_DATA_DATA_NAME.FILE]: convertFile(
    data[POPUP_ADMIN_ITEM_DATA_DATA_NAME.FILE],
  ),
});
