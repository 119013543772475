import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseCustomerItemContainer } from "../purchase-customer-item/purchase-customer-item.container";

import { PurchaseCustomerItemChatListContainer } from "../purchase-customer-item-chat-list/purchase-customer-item-chat-list.container";
import { PurchaseCustomerItemChatCreateContainer } from "../purchase-customer-item-chat-create/purchase-customer-item-chat-create.container";

import { PurchaseCustomerItemTabContainer } from "../purchase-customer-item-tab/purchase-customer-item-tab.container";
import { PurchaseCustomerItemTimezoneContainer } from "../purchase-customer-item-timezone/purchase-customer-item-timezone.container";

export function PurchaseCustomerItemChatPage() {
  return (
    <>
      <PurchaseCustomerItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseCustomerItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemChatListContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemChatCreateContainer />
          </Grid>
          <Grid item>
            <PurchaseCustomerItemTimezoneContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
