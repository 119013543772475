import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { TextComponent } from '../../../lib/common/text';

export const PurchaseAdminItemBoostAccountDeleteDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    setFieldValue,
    onSubmitForm,
    disabled,
  } = props;

  const isSubmitDisabled = () => {
    if (disabled) {
      return true;
    }
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }
  };

  return (
    <Box sx={{ pt: 4 }}>
      <Grid spacing={3} container>
        <Grid item>
          <Button
            onClick={onSubmitForm}
            disabled={isSubmitDisabled()}
            fullWidth
          >
            <TextComponent tid="PURCHASE.ADMIN.ITEM_BOOST_ACCOUNT.DELETE_DATA.FORM.SUBMIT" />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
