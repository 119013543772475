import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { NavigationContainer } from "../navigation/navigation.container";
import { PurchaseAdminItemUpdateBoosterContainer } from "../purchase-admin-item-update-booster/purchase-admin-item-update-booster.container";
import { PurchaseAdminItemUpdateCustomerContainer } from "../purchase-admin-item-update-customer/purchase-admin-item-update-customer.container";
import { PurchaseAdminItemUpdateDataContainer } from "../purchase-admin-item-update-data/purchase-admin-item-update-data.container";
import { PurchaseAdminItemContainer } from "../purchase-admin-item/purchase-admin-item.container";
import { PurchaseAdminItemUpdateBoostDataContainer } from "../purchase-admin-item-update-boost-data/purchase-admin-item-update-boost-data.container";
import { PurchaseAdminItemPaymentConfirmContainer } from "../purchase-admin-item-payment-confirm/purchase-admin-item-payment-confirm.container";
import { PurchaseAdminItemPaymentCreateContainer } from "../purchase-admin-item-payment-create/purchase-admin-item-payment-create.container";
import { PurchaseAdminItemTabContainer } from "../purchase-admin-item-tab/purchase-admin-item-tab.container";
import { PurchaseAdminItemUpdateGdsItemContainer } from "../purchase-admin-item-update-gds-item/purchase-admin-item-update-gds-item.container";
import { PurchaseAdminItemUpdateCoachingDataContainer } from "../purchase-admin-item-update-coaching-data/purchase-admin-item-update-coaching-data.container";

export function PurchaseAdminItemUpdatePage() {
  return (
    <>
      <PurchaseAdminItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseAdminItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemUpdateBoosterContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemUpdateCustomerContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemUpdateGdsItemContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemPaymentCreateContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemPaymentConfirmContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemUpdateDataContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemUpdateBoostDataContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemUpdateCoachingDataContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
