import React from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import { TextComponent } from "../../../lib/common/text";
import { AUTH_SIGNUP_ROUTE_PATH } from "../../auth-signup/auth-signup.constant";
import { ACCOUNT_RECOVERY_ROUTE_PATH } from "../../account-recovery/account-recovery.constant";

export const AuthLoginFooterComponent = (props) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box>
        <Box>
          <TextComponent
            variant="body1"
            component="span"
            tid="AUTH.LOGIN.FOOTER.SIGNUP_LINK_TEXT"
          />{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(AUTH_SIGNUP_ROUTE_PATH);
            }}
          >
            <TextComponent tid="AUTH.LOGIN.FOOTER.SIGNUP_LINK" />
          </Link>
        </Box>
        <Box>
          <TextComponent
            variant="body1"
            component="span"
            tid="AUTH.LOGIN.FOOTER.RECOVERY_LINK_TEXT"
          />{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(ACCOUNT_RECOVERY_ROUTE_PATH);
            }}
          >
            <TextComponent tid="AUTH.LOGIN.FOOTER.RECOVERY_LINK" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
