import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import { PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_STORE_NAME } from '../purchase-customer-item-boost-account-data/purchase-customer-item-boost-account-data.constant';

import { PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA_STORE_NAME } from './purchase-customer-item-boost-account-delete-data.constant';
import { PurchaseCustomerItemBoostAccountDeleteDataComponent } from './purchase-customer-item-boost-account-delete-data.component';
import {
  uploadPurchaseCustomerItemBoostAccountDeleteDataForm,
  resetPurchaseCustomerItemBoostAccountDeleteDataFormState,
} from './purchase-customer-item-boost-account-delete-data.action';

export function PurchaseCustomerItemBoostAccountDeleteDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, boostAccountItemStore } = useSelector(
    (state) => ({
      state: state[PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DELETE_DATA_STORE_NAME],
      pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
      boostAccountItemStore:
        state[PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_DATA_STORE_NAME],
    }),
  );
  const boostAccount = getRequestData(boostAccountItemStore.request);

  React.useEffect(() => {
    dispatch(resetPurchaseCustomerItemBoostAccountDeleteDataFormState());
  }, []);

  const purchaseCustomerItemBoostAccountDeleteDataFormSendData = () => {
    if (boostAccount.id) {
      const data = {
        purchaseId: boostAccount.purchaseId,
        boostAccountId: boostAccount.id,
      };
      dispatch(uploadPurchaseCustomerItemBoostAccountDeleteDataForm(data));
    }
  };

  return (
    <PurchaseCustomerItemBoostAccountDeleteDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(boostAccountItemStore.request)}
      onSubmitForm={purchaseCustomerItemBoostAccountDeleteDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
      disabled={!boostAccount.id}
    />
  );
}
