import { text } from '../text/text.core';

import { GDS_ITEM_STATUS_VALUE } from './gds-item.type';

export const convertGdsItemStatus = (gdsItemStatusId) => {
  if (!gdsItemStatusId) {
    return null;
  }

  return {
    id: gdsItemStatusId,
    value: GDS_ITEM_STATUS_VALUE[gdsItemStatusId],
    text: text(
      `GDS_ITEM.DATA.STATUS.${GDS_ITEM_STATUS_VALUE[gdsItemStatusId]}`,
    ),
  };
};
