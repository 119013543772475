import { httpRequest } from '../../main/http';

import {
  USER_ADMIN_LIST_API,
  USER_ADMIN_LIST_ACTION_TYPE,
} from './user-admin-list.constant';

import { convertUserAdminList } from './user-admin-list.convert';
import { USER_ADMIN_LIST_DATA_NAME } from '../user-admin-list/user-admin-list.constant';

export function getUserAdminList(data = {}) {
  return async (dispatch) => {
    dispatch({
      type: USER_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: USER_ADMIN_LIST_API.USER_ADMIN_LIST.TYPE,
        url: USER_ADMIN_LIST_API.USER_ADMIN_LIST.ENDPOINT,
        params: {
          [USER_ADMIN_LIST_DATA_NAME.SKIP]:
            data[USER_ADMIN_LIST_DATA_NAME.SKIP],
          [USER_ADMIN_LIST_DATA_NAME.TAKE]:
            data[USER_ADMIN_LIST_DATA_NAME.TAKE],
        },
      });

      dispatch({
        type: USER_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertUserAdminList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: USER_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
