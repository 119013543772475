import { text } from '../text/text.core';

import { PROMOCODE_DATA_TYPE } from './promocode.type';

export const convertPromocode = (data, price = 0) => {
  if (!data || !data[PROMOCODE_DATA_TYPE.ID]) {
    return null;
  }

  return {
    [PROMOCODE_DATA_TYPE.ID]: data[PROMOCODE_DATA_TYPE.ID],
    [PROMOCODE_DATA_TYPE.FACTOR]: convertPromocodeFactor(
      data[PROMOCODE_DATA_TYPE.FACTOR],
    ),
    [PROMOCODE_DATA_TYPE.NAME]: data[PROMOCODE_DATA_TYPE.NAME],
    [PROMOCODE_DATA_TYPE.PRICE_WITH_PROMOCODE]: Number(
      (Number(data[PROMOCODE_DATA_TYPE.FACTOR]) * Number(price)).toFixed(2),
    ),
  };
};

export const convertPromocodeFactor = (factor) => {
  if (!factor) {
    return null;
  }

  return {
    text: text('PROMOCODE.DATA.FACTOR', { factor: (factor * 100 - 100) * -1 }),
    value: factor,
  };
};
