import { validate } from '../../main/validate';

import { POPUP_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './popup-admin-item-update-data.constant';

import {
  requiredArray,
  arrayLengthMax,
  maxLength,
} from '../../main/validate/validate.service';

const config = {
  // [POPUP_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.FILE]: [
  //   requiredArray,
  //   arrayLengthMax(1),
  // ],
  // [POPUP_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TEXT]: [maxLength(300)],
};

export const popupAdminItemUpdateDataFormValidation = (values) =>
  validate(values, config);
