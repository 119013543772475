export const GDS_ITEM_ADMIN_CREATE_ROUTE_PATH = "/admin/gds/:gdsId/item/create";

export const GDS_ITEM_ADMIN_CREATE_ROUTE_PATH_DYNAMIC = (
  { gdsId } = {
    gdsId: "[gdsId]",
  }
) => `/admin/gds/${gdsId}/item/create`;

export const GDS_ITEM_ADMIN_CREATE_STORE_NAME = "GDS_ITEM_ADMIN_CREATE";

export const GDS_ITEM_ADMIN_CREATE_API = {
  GDS_ITEM_ADMIN_CREATE: {
    ENDPOINT: (gdsId) => `/gds/${gdsId}/item`,
    TYPE: "POST",
  },
};

export const GDS_ITEM_ADMIN_CREATE_ACTION_TYPE = {
  FORM_PENDING: "GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "GDS_ITEM_ADMIN_CREATE_ACTION_TYPE.FORM_RESET",
};

export const GDS_ITEM_ADMIN_CREATE_DATA_NAME = {
  PRICE_EN: "priceEn",
  STOCK_EN: "stockEn",
  PRICE_RU: "priceRu",
  STOCK_RU: "stockRu",
  ORDER: "order",
  TITLE_EN: "titleEn",
  TITLE_RU: "titleRu",
  DESCRIPTION_EN: "descriptionEn",
  DESCRIPTION_RU: "descriptionRu",
  DATA: "data",
};

export const GDS_ITEM_ADMIN_CREATE_SHORTCUT_LIST = [
  {
    id: 1,
    title: "GDS.SHORTCUT.FRED.TITLE",
    data: "GDS.SHORTCUT.FRED.DATA",
  },
  {
    id: 2,
    title: "GDS.SHORTCUT.REG.TITLE",
    data: "GDS.SHORTCUT.REG.DATA",
  },
  {
    id: 3,
    title: "GDS.SHORTCUT.REGRU.TITLE",
    data: "GDS.SHORTCUT.REGRU.DATA",
  },
];
