import React from "react";
import styled from "styled-components";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import useMediaQuery from "@mui/material/useMediaQuery";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "../../purchase-customer-item/purchase-customer-item.constant";
import { getBoostRankImageUrl } from "../../../lib/common/boost/boost.core";

export function PurchaseCustomerItemDataBoostViewComponent(props) {
  const { data } = props;
  const isDesiredImg =
    !!data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].desired.value;
  const isCurrentImg =
    !!data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].current.value;

  const isActualImg =
    !!data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].actual.value;

  const isWinsBoost = !isCurrentImg && !isActualImg;

  const matches = useMediaQuery("(max-width:1000px)");

  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_BOOST.TYPE"
            />
            <TextComponent
              tid="PURCHASE.CUSTOMER.ITEM_BOOST.TYPE_DATA"
              tvalue={{
                type: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].type.text,
              }}
              variant="dataViewContent"
            />
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_BOOST.ADDITIONALS"
            />
            <TextComponent variant="dataViewContent">
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST][
                PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_ADDITIONALS
              ].length ? (
                data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST][
                  PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST_ADDITIONALS
                ]
                  .map((item) => item.text)
                  .join(", ")
              ) : (
                <>
                  <TextComponent tid="PURCHASE.CUSTOMER.ITEM_BOOST.ADDITIONALS_NULL" />
                </>
              )}
            </TextComponent>
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_BOOST.BOOST_DATA"
            />

            {Object.keys(
              data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST]?.data || {}
            ).length ? (
              <Grid>
                {Object.entries(
                  data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].data
                ).map((item) => (
                  <Grid key={item.toString()} item>
                    {Array.isArray(item[1]) ? (
                      <>
                        <TextComponent
                          variant="dataViewContent"
                          tid={`PURCHASE.CUSTOMER.ITEM_BOOST.BOOST_DATA_DATA.${item[0].toUpperCase()}`}
                          sx={{ lineHeight: "1.3em" }}
                        />
                        <ListElem>
                          {item[1].map((elem) => (
                            <TextComponent
                              variant="dataViewContent"
                              tid={elem}
                              sx={{ lineHeight: "1.3em" }}
                            />
                          ))}
                        </ListElem>
                      </>
                    ) : (
                      <TextComponent
                        variant="dataViewContent"
                        tid={`PURCHASE.CUSTOMER.ITEM_BOOST.BOOST_DATA_DATA.${item[0].toUpperCase()}`}
                        tvalue={{ value: item[1] }}
                        sx={{ lineHeight: "1.3em" }}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <TextComponent
                variant="dataViewContent"
                tid="PURCHASE.CUSTOMER.ITEM_BOOST.BOOST_DATA_NULL"
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <Grid
              container
              spacing={3}
              sx={{ flexDirection: matches ? "column" : "row" }}
            >
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].current.value && (
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.ITEM_BOOST.CURRENT"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.ITEM_BOOST.CURRENT_DATA"
                    tvalue={{
                      current:
                        data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].current
                          .text,
                    }}
                    gutterBottom
                    variant="dataViewContent"
                  />
                  {isCurrentImg && (
                    <img
                      width="100px"
                      src={getBoostRankImageUrl(
                        data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].current
                          .value
                      )}
                    />
                  )}
                </Grid>
              )}
              {!isWinsBoost && (
                <Grid hidden={matches} item xs>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DoubleArrowIcon sx={{ color: "#8F97DD" }} />
                  </Box>
                </Grid>
              )}

              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].desired.value && (
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.ITEM_BOOST.ACTUAL"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.ITEM_BOOST.ACTUAL_DATA"
                    tvalue={{
                      actual:
                        data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].actual
                          .text,
                    }}
                    variant="dataViewContent"
                    gutterBottom
                  />
                  {isActualImg && (
                    <img
                      width="100px"
                      src={getBoostRankImageUrl(
                        data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].actual
                          .value
                      )}
                    />
                  )}
                </Grid>
              )}

              <Grid item hidden={matches} xs>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                    alignItems: "center",
                  }}
                >
                  {!isWinsBoost && (
                    <DoubleArrowIcon sx={{ color: "#8F97DD" }} />
                  )}
                </Box>
              </Grid>
              {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].desired.value && (
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.ITEM_BOOST.DESIRED"
                  />
                  <TextComponent
                    tid="PURCHASE.CUSTOMER.ITEM_BOOST.DESIRED_DATA"
                    tvalue={{
                      desired:
                        data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].desired
                          .text,
                    }}
                    variant="dataViewContent"
                    gutterBottom
                  />
                  {!isWinsBoost && (
                    <img
                      width="100px"
                      src={getBoostRankImageUrl(
                        data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.BOOST].desired
                          .value
                      )}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const ListElem = styled.div`
  margin-left: 12px;
`;
