import { validate } from '../../main/validate';

import { PROMOCODE_ADMIN_CREATE_DATA_NAME } from './promocode-admin-create.constant';

import {
  required,
  numberPositive,
  numberPositiveMax,
  maxLength,
} from '../../main/validate/validate.service';

const config = {
  [PROMOCODE_ADMIN_CREATE_DATA_NAME.FACTOR]: [
    required,
    numberPositive,
    numberPositiveMax(1),
  ],
  [PROMOCODE_ADMIN_CREATE_DATA_NAME.NAME]: [required, maxLength(20)],
};

export const promocodeAdminCreateFormValidation = (values) =>
  validate(values, config);
