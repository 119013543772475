import Typography from '@mui/material/Typography';
import { text } from './text.core';

export function TextComponent({
  variant,
  component = 'div',
  tid,
  tvalue,
  children,
  gutterBottom,
  ...props
}) {
  if (children) {
    return (
      <Typography
        gutterBottom={gutterBottom}
        variant={variant}
        component={component}
        {...props}
      >
        {children}
      </Typography>
    );
  }

  if (!variant) {
    return text(tid, tvalue);
  }

  return (
    <Typography
      gutterBottom={gutterBottom}
      component={component}
      variant={variant}
      {...props}
    >
      {text(tid, tvalue)}
    </Typography>
  );
}
