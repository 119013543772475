import Box from '@mui/material/Box';

import { FieldTextComponent } from '../../../lib/common/field-text/field-text.component';
import { FieldPasswordComponent } from '../../../lib/common/field-password/field-password.component';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_DATA_NAME } from '../purchase-admin-item-update-gds-item.constant';

export const PurchaseAdminItemUpdateGdsItemFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === '{}') {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE.GDS_ITEM.FORM.ID" />
              }
              name={PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_DATA_NAME.ID}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_DATA_NAME.ID]}
              // error={isFieldError(
              //   PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_DATA_NAME.ID,
              // )}
              fullWidth
              errorText={getFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_GDS_ITEM_DATA_NAME.ID,
              )}
            />
          </Grid>
          <Grid item>
            <Button type="submit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="PURCHASE.ADMIN.UPDATE.GDS_ITEM.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
