import { httpRequest } from '../../main/http';

import {
  ACCOUNT_SETTINGS_UPDATE_TIMEZONE_API,
  ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE,
} from './account-settings-update-timezone.constant';

import { authUpdateUserData } from '../../lib/common/auth';

export function uploadAccountSettingsUpdateTimezoneForm(data) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: ACCOUNT_SETTINGS_UPDATE_TIMEZONE_API.CHANGE_TIMEZONE.TYPE,
        url: ACCOUNT_SETTINGS_UPDATE_TIMEZONE_API.CHANGE_TIMEZONE.ENDPOINT,
        data,
      });

      await dispatch(authUpdateUserData());

      dispatch({
        type: ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetAccountSettingsUpdateTimezoneFormState() {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE.FORM_RESET,
    });
  };
}
