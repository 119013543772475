import React from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { TextComponent } from '../../lib/common/text';
import Grid from '@mui/material/Grid';

import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';

import {
  PURCHASE_STATUS_OPTION,
  PURCHASE_TYPE_OPTION,
} from '../../lib/common/purchase/purchase.type';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

import { FieldSelectComponent } from '../../lib/common/field-select/field-select.component';

import { PURCHASE_ADMIN_LIST_DATA_NAME } from '../purchase-admin-list/purchase-admin-list.constant';
import {
  convertPurchaseStatus,
  convertPurchaseType,
} from '../../lib/common/purchase/purchase.convert';

export function PurchaseAdminListPaginationComponent(props) {
  const { onChangeFilter, data } = props;

  const [openFilter, setOpenFilter] = React.useState();

  const handleOpenFilter = () => {
    if (openFilter === true) {
      onChangeFilter({
        [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]: false,
        [PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]: null,
        [PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]: null,
      });
    }

    setOpenFilter(!openFilter);
  };

  const handleChangeFilterSelect = (key) => (e) => {
    onChangeFilter({ [key]: e.target.value.id });
  };

  const handleChangePagination = (e, value) => {
    onChangeFilter({
      [PURCHASE_ADMIN_LIST_DATA_NAME.SKIP]: data.take * value - data.take,
    });
  };

  const handleChangeBoughtFilter = (e, value) => {
    onChangeFilter({
      [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]: value,
    });
  };

  const paginationCount = Math.ceil(data.amount / data.take);
  const paginationPage = Math.ceil(data.skip / data.take + 1);

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Grid container spacing={4}>
          <Grid item>
            <Paper>
              <FormGroup>
                <FormControlLabel
                  sx={{ py: 1, px: 2, m: 0 }}
                  control={<Switch />}
                  onChange={handleOpenFilter}
                  label={
                    <TextComponent tid="PURCHASE.ADMIN.PAGINATION.FILTER" />
                  }
                />
              </FormGroup>
            </Paper>
          </Grid>
          {openFilter && (
            <React.Fragment>
              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeFilterSelect(
                    PURCHASE_ADMIN_LIST_DATA_NAME.STATUS,
                  )}
                  value={convertPurchaseStatus(data.status)}
                  option={PURCHASE_STATUS_OPTION}
                  label={
                    <TextComponent tid="PURCHASE.ADMIN.PAGINATION.STATUS" />
                  }
                />
              </Grid>
              <Grid item>
                <FieldSelectComponent
                  onChange={handleChangeFilterSelect(
                    PURCHASE_ADMIN_LIST_DATA_NAME.TYPE,
                  )}
                  value={convertPurchaseType(data.type)}
                  option={PURCHASE_TYPE_OPTION}
                  label={<TextComponent tid="PURCHASE.ADMIN.PAGINATION.TYPE" />}
                />
              </Grid>
              <Grid item>
                <Paper>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ py: 1, px: 2, m: 0 }}
                      control={<Switch />}
                      checked={data.paid}
                      onChange={handleChangeBoughtFilter}
                      label={
                        <TextComponent tid="PURCHASE.ADMIN.PAGINATION.PAID" />
                      }
                    />
                  </FormGroup>
                </Paper>
              </Grid>
            </React.Fragment>
          )}
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Pagination
              count={paginationCount}
              page={paginationPage}
              onChange={handleChangePagination}
              size="large"
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
