import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getNoticeAccountListShort,
  updateNoticeAccountListShort,
} from "./notice-account-list-short.action";
import {
  NOTICE_ACCOUNT_LIST_SHORT_CRON_UPDATE,
  NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME,
} from "./notice-account-list-short.constant";
import {
  getRequestData,
  isRequestError,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  initVisualNoticePlayer,
  clearVisualNotice,
} from "../../lib/common/notice/notice.core";
import {
  PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../purchase-admin-item-chat/purchase-admin-item-chat.constant";
import {
  PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../purchase-customer-item-chat/purchase-customer-item-chat.constant";
import {
  PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-chat/purchase-booster-item-chat.constant";
import { NOTICE_TYPE_TYPE } from "../../lib/common/notice/notice.type";
import { updateNoticeAccountItemViewByIds } from "../notice-account-item-update-view/notice-account-item-update-view.action";
import { updateNoticeAccountList } from "../notice-account-list/notice-account-list.action";
import { NOTICE_ACCOUNT_LIST_STORE_NAME } from "../notice-account-list/notice-account-list.constant";
import {
  PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-boost-time/purchase-booster-item-boost-time.constant";
import {
  PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-boost-account/purchase-booster-item-boost-account.constant";
import { PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC } from "../purchase-admin-item-boost-account/purchase-admin-item-boost-account.constant";
import { PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item-boost-account/purchase-customer-item-boost-account.constant";
import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC } from "../purchase-customer-item-boost-time/purchase-customer-item-boost-time.constant";
import { PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC } from "../purchase-admin-item-boost-time/purchase-admin-item-boost-time.constant";

function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function NoticeAccountListShortHandler() {
  const dispatch = useDispatch();
  let { purchaseId } = useParams();

  const [playVisualNotice, setHandlerPlayVisualNotice] = React.useState(null);

  const { notice, activePath, fullList } = useSelector((state) => ({
    notice: state[NOTICE_ACCOUNT_LIST_SHORT_STORE_NAME].request,
    activePath: state[NAVIGATION_STORE_NAME].activePath,
    fullList: state[NOTICE_ACCOUNT_LIST_STORE_NAME],
  }));

  const isNoticeLoaded = isRequestSuccess(notice);
  const isNoticeError = isRequestError(notice);
  const noticeAmount = isNoticeLoaded ? getRequestData(notice).amount : null;
  const noticeData = getRequestData(notice);

  const isFullListLoader = isRequestSuccess(fullList.request);
  const fullListData = getRequestData(fullList.request);

  const prevAmount = usePrevious({ noticeAmount });

  const cronUpdateData = () => {
    dispatch(updateNoticeAccountListShort());
  };

  const isViewChatMessageNotice =
    activePath ===
      PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH_DYNAMIC({ purchaseId }) ||
    activePath ===
      PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({ purchaseId }) ||
    activePath ===
      PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({ purchaseId }) ||
    activePath ===
      PURCHASE_ADMIN_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({ purchaseId }) ||
    activePath ===
      PURCHASE_CUSTOMER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({ purchaseId }) ||
    activePath ===
      PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({ purchaseId }) ||
    activePath ===
      PURCHASE_ADMIN_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({ purchaseId }) ||
    activePath ===
      PURCHASE_CUSTOMER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({
        purchaseId,
      }) ||
    activePath ===
      PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({ purchaseId });

  const setViewChatMessageNotice = () => {
    if (isViewChatMessageNotice) {
      if (isNoticeLoaded) {
        if (noticeData.list) {
          if (noticeData.list.length) {
            const ids = [];
            const idsObj = {};

            for (const noticeItem of noticeData.list) {
              if (
                noticeItem.type.id ===
                  NOTICE_TYPE_TYPE.CHAT_MESSAGE_CREATE_BY_ADMIN ||
                noticeItem.type.id ===
                  NOTICE_TYPE_TYPE.CHAT_MESSAGE_CREATE_BY_BOOSTER ||
                noticeItem.type.id ===
                  NOTICE_TYPE_TYPE.CHAT_MESSAGE_CREATE_BY_CUSTOMER ||
                activePath === PURCHASE_ADMIN_ITEM_CHAT_ROUTE_PATH ||
                activePath === PURCHASE_CUSTOMER_ITEM_CHAT_ROUTE_PATH ||
                activePath === PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH
              ) {
                if (Number(noticeItem.data.purchaseId) === Number(purchaseId)) {
                  ids.push(noticeItem.id);
                  idsObj[noticeItem.id] = true;
                }
              }

              if (
                noticeItem.type.id ===
                  NOTICE_TYPE_TYPE.BOOST_TIME_ADD_BY_CUSTOMER ||
                activePath === PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH
              ) {
                if (Number(noticeItem.data.purchaseId) === Number(purchaseId)) {
                  ids.push(noticeItem.id);
                  idsObj[noticeItem.id] = true;
                }
              }

              if (
                noticeItem.type.id ===
                  NOTICE_TYPE_TYPE.BOOST_ACCOUNT_ADD_BY_CUSTOMER ||
                activePath === PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH
              ) {
                if (Number(noticeItem.data.purchaseId) === Number(purchaseId)) {
                  ids.push(noticeItem.id);
                  idsObj[noticeItem.id] = true;
                }
              }
            }

            if (ids.length === 0) {
              return null;
            }

            dispatch(
              updateNoticeAccountItemViewByIds(
                ids,
                () => {
                  if (isFullListLoader) {
                    return dispatch(updateNoticeAccountList(fullListData));
                  }

                  return dispatch(updateNoticeAccountListShort());
                },
                idsObj
              )
            );
          }
        }
      }
    }
  };

  React.useEffect(() => {
    if (isNoticeLoaded && !isNoticeError) {
      dispatch(updateNoticeAccountListShort());
    } else {
      dispatch(getNoticeAccountListShort());
    }

    const setIntervalId = setInterval(
      cronUpdateData,
      NOTICE_ACCOUNT_LIST_SHORT_CRON_UPDATE
    );

    return () => {
      clearInterval(setIntervalId);
    };
  }, [noticeAmount]);

  React.useEffect(() => {
    if (playVisualNotice) {
      return null;
    }

    if (isNoticeLoaded) {
      setHandlerPlayVisualNotice(initVisualNoticePlayer);
    }

    if (isNoticeLoaded) {
      if (noticeData.list) {
        setTimeout(() => {

          setViewChatMessageNotice();
        }, 0);
      }
    }
  }, [isNoticeLoaded, isViewChatMessageNotice, noticeAmount]);

  React.useEffect(() => {

    if (!prevAmount) {
      return null;
    }

    if (prevAmount.noticeAmount === null) {
      return null;
    }

    if (Number.isNaN(noticeAmount)) {
      return null;
    }

    if (noticeAmount < prevAmount.noticeAmount) {
      clearVisualNotice();
      return null;
    }

    if (noticeAmount > prevAmount.noticeAmount) {
      playVisualNotice(
        "NOTICE.PLAY_VISUAL.NEW",
        { amount: noticeAmount },
        noticeData.list[0].visual
      );

      setViewChatMessageNotice();
    }
  }, [noticeAmount]);

  return null;
}
