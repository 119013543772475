import { text } from '../text/text.core';

import {
  PURCHASE_TYPE_VALUE,
  PURCHASE_STATUS_VALUE,
  PURCHASE_LANG_VALUE,
} from './purchase.type';

export const convertPurchaseType = (purchaseTypeId) => {
  if (!purchaseTypeId) {
    return null;
  }

  return {
    id: purchaseTypeId,
    value: PURCHASE_TYPE_VALUE[purchaseTypeId],
    text: text(`PURCHASE.DATA.TYPE.${PURCHASE_TYPE_VALUE[purchaseTypeId]}`),
  };
};

export const convertPurchaseStatus = (purchaseStatusId) => {
  if (!purchaseStatusId) {
    return null;
  }

  return {
    id: purchaseStatusId,
    value: PURCHASE_STATUS_VALUE[purchaseStatusId],
    text: text(
      `PURCHASE.DATA.STATUS.${PURCHASE_STATUS_VALUE[purchaseStatusId]}`,
    ),
  };
};

export const convertPurchaseLang = (purchaseLangId) => {
  if (!purchaseLangId) {
    return null;
  }

  return {
    id: purchaseLangId,
    value: PURCHASE_LANG_VALUE[purchaseLangId],
    text: text(`PURCHASE.DATA.LANG.${PURCHASE_LANG_VALUE[purchaseLangId]}`),
  };
};
