import React from 'react';

import Grid from '@mui/material/Grid';

import Chip from '@mui/material/Chip';

import { text } from '../text';

export const ChatMessageShortcutComponent = ({ list, name, onChange }) => {
  const handleChange = (item) => () => {
    onChange({ target: { value: text(item.data), name } });
  };

  return (
    <Grid
      container
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
      spacing={2}
    >
      {list.map((item) => (
        <React.Fragment key={item.id}>
          <Grid item>
            <Chip
              label={text(item.title)}
              variant="outlined"
              onClick={handleChange(item)}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};
