import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoaderBarComponent } from '../../lib/common/loader-bar';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ModalConfirmComponent } from '../../lib/common/modal-confirm/modal-confirm.component';

export function PurchaseAdminItemBoostTimeDeleteComponent(props) {
  const { isPending, isPendingThis, onSubmitForm, isError } = props;

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (isPendingThis) {
      return true;
    }
  };

  return (
    <React.Fragment>
      <ModalConfirmComponent
        action={onSubmitForm}
        disabled={isSubmitDisabled()}
      >
        <IconButton
          disabled={isSubmitDisabled()}
          edge="end"
          aria-label="delete"
        >
          {isPendingThis ? <CircularProgress size={24} /> : <DeleteIcon />}
        </IconButton>
      </ModalConfirmComponent>
    </React.Fragment>
  );
}
