import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseBoosterItemBoostAccountDataContainer } from "../purchase-booster-item-boost-account-data/purchase-booster-item-boost-account-data.container";

import { PurchaseBoosterItemTabContainer } from "../purchase-booster-item-tab/purchase-booster-item-tab.container";
import { PurchaseBoosterItemContainer } from "../purchase-booster-item/purchase-booster-item.container";

export function PurchaseBoosterItemBoostAccountPage() {
  return (
    <Container maxWidth="sm">
      <PurchaseBoosterItemContainer />
      <Grid spacing={3} container>
        <Grid item>
          <PurchaseBoosterItemTabContainer />
        </Grid>
        <Grid item>
          <PurchaseBoosterItemBoostAccountDataContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
