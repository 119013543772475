import { validate } from '../../main/validate';

import { PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './purchase-admin-item-update-data.constant';

import { maxLength } from '../../main/validate/validate.service';

const config = {
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.COMMENTS]: [maxLength(1000)],
  [PURCHASE_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]: [],
};

export const purchaseAdminItemUpdateDataFormValidation = (values) =>
  validate(values, config);
