import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { TextComponent } from '../../../lib/common/text/text.component';
import { PurchaseCustomerItemBoostTimeDeleteContainer } from '../../purchase-customer-item-boost-time-delete/purchase-customer-item-boost-time-delete.container';
import { PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME } from '../purchase-customer-item-boost-time-list.constant';

export function PurchaseCustomerItemBoostTimeListViewComponent(props) {
  const { data } = props;

  if (!data.list?.length) {
    return (
      <Box sx={{ pt: 4 }}>
        <TextComponent
          variant="body1"
          tid="PURCHASE.CUSTOMER.ITEM_BOOST_TIME.LIST.VIEW.LIST_IS_EMPTY"
        />
      </Box>
    );
  }

  return (
    <List>
      {data.list.map((item) => (
        <ListItem
          sx={{ mt: 4, py: 0, px: 0 }}
          variant="list"
          secondaryAction={
            <PurchaseCustomerItemBoostTimeDeleteContainer
              boostTimeId={
                item[PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME.ID]
              }
            />
          }
        >
          <ListItemText
            secondary={
              <TextComponent
                sx={{ pr: 12 }}
                tid="PURCHASE.CUSTOMER.ITEM_BOOST_TIME.LIST.VIEW.FROM_TO_TIME"
                tvalue={{
                  fromTime:
                    item[
                      PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME.FROM_TIME
                    ],
                  toTime:
                    item[
                      PURCHASE_CUSTOMER_ITEM_BOOST_TIME_LIST_DATA_NAME.TO_TIME
                    ],
                }}
                variant="body1"
              />
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
