import { NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from './news-admin-item-update-data.constant';
import { convertFileFieldValue } from '../../lib/common/field-file/field-file.convert';
import { convertSelectFieldValue } from '../../lib/common/field-select/field-select.convert';

export const convertNewsAdminItemUpdateDataFormData = (data) => ({
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]: Number(
    data[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER],
  ),
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY]: String(
    data[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY],
  ),
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY]: convertSelectFieldValue(
    data[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY],
  ),
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]: convertSelectFieldValue(
    data[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS],
  ),
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION]: String(
    data[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION],
  ),
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.LANG]: convertSelectFieldValue(
    data[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.LANG],
  ),
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PREVIEW]: convertFileFieldValue(
    data[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PREVIEW],
  )[0],

  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE]: String(
    data[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE],
  ),
  [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL]: String(
    data[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL],
  ),
});
