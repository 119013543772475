import { httpRequest } from '../../main/http';

import {
  PURCHASE_ADMIN_ITEM_API,
  PURCHASE_ADMIN_ITEM_ACTION_TYPE,
  PURCHASE_ADMIN_ITEM_STORE_NAME,
} from './purchase-admin-item.constant';

import { convertPurchaseAdminItem } from './purchase-admin-item.convert';

export function getPurchaseAdminItem(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_ADMIN_ITEM_API.PURCHASE_ADMIN_ITEM.TYPE,
        url: PURCHASE_ADMIN_ITEM_API.PURCHASE_ADMIN_ITEM.ENDPOINT(
          data.purchaseId,
        ),
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseAdminItem(res.data),
        purchaseId: data.purchaseId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadPurchaseAdminItem() {
  return async (dispatch, getState) => {
    const { purchaseId } = getState()[PURCHASE_ADMIN_ITEM_STORE_NAME];

    dispatch({
      type: PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_ADMIN_ITEM_API.PURCHASE_ADMIN_ITEM.TYPE,
        url: PURCHASE_ADMIN_ITEM_API.PURCHASE_ADMIN_ITEM.ENDPOINT(purchaseId),
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseAdminItem(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
