import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AuthLoginComponent } from "./auth-login.component";
import { authLoginFormValidation } from "./auth-login.validation";
import { convertAuthLoginFormData } from "./auth-login.convert";
import { resetAuthLoginForm, uploadAuthLoginForm } from "./auth-login.action";
import {
  AUTH_LOGIN_STORE_NAME,
  AUTH_LOGIN_DATA_NAME,
} from "./auth-login.constant";
import { scrollToTop } from "../../main/navigation";

import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { INDEX_ROUTE_PATH } from "../index/index.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

export function AuthLoginContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[AUTH_LOGIN_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));
  const navigate = useNavigate();

  const loginFormSendData = async (values, { resetForm }) => {
    const data = convertAuthLoginFormData(values);
    await dispatch(uploadAuthLoginForm(data, resetForm));
  };

  const getInitialValue = () => {
    return {
      [AUTH_LOGIN_DATA_NAME.LOGIN]: "",
      [AUTH_LOGIN_DATA_NAME.PASSWORD]: "",
    };
  };

  React.useEffect(() => {
    dispatch(resetAuthLoginForm());
  }, []);

  return (
    <AuthLoginComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={authLoginFormValidation}
      onSubmitForm={loginFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
