import Grid from '@mui/material/Grid';
import { TextComponent } from '../../../lib/common/text/text.component';
import { PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME } from '../purchase-booster-item-boost-account-data.constant';

export function PurchaseBoosterItemBoostAccountDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="PURCHASE.BOOSTER.ITEM_BOOST_ACCOUNT.DATA.VIEW.DATA_NAME"
        />
        <TextComponent sx={{ whiteSpace: 'break-spaces' }} variant="body1">
          {data[PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_DATA_DATA_NAME.DATA] || (
            <TextComponent tid="PURCHASE.BOOSTER.ITEM_BOOST_ACCOUNT.DATA.VIEW.DATA_NONE" />
          )}
        </TextComponent>
      </Grid>
    </Grid>
  );
}
