import { validate } from '../../main/validate';

import { ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME } from './account-settings-update-timezone.constant';

import { required } from '../../main/validate/validate.service';

const config = {
  [ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME.TIMEZONE]: [required],
};

export const accountSettingsUpdateTimezoneFormValidation = (values) =>
  validate(values, config);
