import { httpRequest } from '../../main/http';

import {
  PURCHASE_BOOSTER_ITEM_CHAT_CREATE_API,
  PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE,
} from './purchase-booster-item-chat-create.constant';

import { reloadPurchaseBoosterItemChatList } from '../purchase-booster-item-chat-list/purchase-booster-item-chat-list.action';

export function uploadPurchaseBoosterItemChatCreateForm(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_PENDING,
    });

    try {
      await httpRequest({
        method: PURCHASE_BOOSTER_ITEM_CHAT_CREATE_API.CHANGE_PASSWORD.TYPE,
        url: PURCHASE_BOOSTER_ITEM_CHAT_CREATE_API.CHANGE_PASSWORD.ENDPOINT(
          data.purchaseId,
          data.id,
        ),
        data,
      });

      await dispatch(reloadPurchaseBoosterItemChatList(data));

      dispatch({
        type: PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseBoosterItemChatCreateFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_BOOSTER_ITEM_CHAT_CREATE_ACTION_TYPE.FORM_RESET,
    });
  };
}
