import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PurchaseAdminItemPaymentConfirmComponent } from "./purchase-admin-item-payment-confirm.component";
import { purchaseAdminItemPaymentConfirmFormValidation } from "./purchase-admin-item-payment-confirm.validation";
import { convertPurchaseAdminItemPaymentConfirmFormData } from "./purchase-admin-item-payment-confirm.convert";
import {
  uploadPurchaseAdminItemPaymentConfirmForm,
  resetPurchaseAdminItemPaymentConfirmFormState,
} from "./purchase-admin-item-payment-confirm.action";
import {
  PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_STORE_NAME,
  PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_DATA_NAME,
} from "./purchase-admin-item-payment-confirm.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";

export function PurchaseAdminItemPaymentConfirmContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(resetPurchaseAdminItemPaymentConfirmFormState());
  }, []);

  const purchaseAdminItemPaymentConfirmFormSendData = (values) => {
    const data = convertPurchaseAdminItemPaymentConfirmFormData(values);

    data.purchaseId = purchaseId;

    dispatch(uploadPurchaseAdminItemPaymentConfirmForm(data));
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_ADMIN_ITEM_PAYMENT_CONFIRM_DATA_NAME.ID]: "",
    };
  };

  return (
    <PurchaseAdminItemPaymentConfirmComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseAdminItemPaymentConfirmFormValidation}
      onSubmitForm={purchaseAdminItemPaymentConfirmFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
