import React from 'react';

import { PurchaseAdminItemTimezoneComponent } from './purchase-admin-item-timezone.component';

import { useSelector } from 'react-redux';

import {
  PURCHASE_ADMIN_ITEM_DATA_NAME,
  PURCHASE_ADMIN_ITEM_STORE_NAME,
} from '../purchase-admin-item/purchase-admin-item.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';
import {
  calcCurrentTimeByTimezone,
  calcTimezone,
} from '../../lib/common/time/time.core';
import { convertTime } from '../../lib/common/convert/convert.core';

export function PurchaseAdminItemTimezoneContainer() {
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const [timezone, setTimezoneData] = React.useState({});

  const calcTime = () => {
    if (!isRequestSuccess(state.request)) return null;

    let customerTime = null;
    let boosterTime = null;

    const currentTimezone = calcTimezone();

    const data = getRequestData(state.request);

    if (data[PURCHASE_ADMIN_ITEM_DATA_NAME.IS_CUSTOMER]) {
      customerTime = data[PURCHASE_ADMIN_ITEM_DATA_NAME.CUSTOMER].timezone;
      customerTime.currentTime = calcCurrentTimeByTimezone(
        Number(customerTime.value),
        Number(currentTimezone),
      );

      customerTime.currentTime = convertTime(customerTime.currentTime);
    }

    if (data[PURCHASE_ADMIN_ITEM_DATA_NAME.IS_BOOSTER]) {
      boosterTime = data[PURCHASE_ADMIN_ITEM_DATA_NAME.BOOSTER].timezone;
      boosterTime.currentTime = calcCurrentTimeByTimezone(
        Number(boosterTime.value),
        Number(currentTimezone),
      );

      boosterTime.currentTime = convertTime(boosterTime.currentTime);
    }

    setTimezoneData({
      customerTime,
      boosterTime,
      currentTimezone,
    });
  };

  React.useEffect(calcTime, [isRequestSuccess(state.request)]);

  React.useEffect(() => {
    const id = setInterval(calcTime, 2000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <PurchaseAdminItemTimezoneComponent
      timezone={timezone}
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
