import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { PurchaseCustomerListComponent } from "./purchase-customer-list.component";

import {
  getPurchaseCustomerList,
  updatePurchaseCustomerList,
} from "./purchase-customer-list.action";

import {
  PURCHASE_CUSTOMER_LIST_PAGINATION_CRON_UPDATE,
  PURCHASE_CUSTOMER_LIST_STORE_NAME,
} from "./purchase-customer-list.constant";

import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from "../../main/store/store.service";

export function PurchaseCustomerListContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const [intervalId, setIntervalId] = React.useState(null);

  React.useEffect(() => {
    clearInterval(intervalId);

    dispatch(getPurchaseCustomerList());

    const intId = setInterval(() => {
      dispatch(updatePurchaseCustomerList());
    }, PURCHASE_CUSTOMER_LIST_PAGINATION_CRON_UPDATE);

    setIntervalId(intId);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return (
    <PurchaseCustomerListComponent
      isPending={isRequestPending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      isUpdating={isRequestUpdatePending(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
