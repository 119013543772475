import { PURCHASE_BOOSTER_LIST_DATA_NAME } from "./purchase-booster-list.constant";

import {
  convertPurchaseType,
  convertPurchaseStatus,
} from "../../lib/common/purchase/purchase.convert";
import {
  convertBoostAdditionalList,
  convertBoostRank,
  convertBoostType,
} from "../../lib/common/boost/boost.convert";
import { convertPromocode } from "../../lib/common/promocode/promocode.convert";
import { PURCHASE_TYPE_TYPE } from "../../lib/common/purchase/purchase.type";
import { convertDate } from "../../lib/common/convert/convert.core";
import { PURCHASE_BOOSTER_ITEM_DATA_NAME } from "../purchase-booster-item/purchase-booster-item.constant";
import { PURCHASE_ADMIN_LIST_DATA_NAME } from "../purchase-admin-list/purchase-admin-list.constant";
import {
  convertCoachingAdditionalList,
  convertCoachingType,
} from "../../lib/common/coaching/coaching.convert";

export const convertPurchaseBoosterList = (d) => ({
  [PURCHASE_BOOSTER_LIST_DATA_NAME.LIST]: d[
    PURCHASE_BOOSTER_LIST_DATA_NAME.LIST
  ].map((data) => {
    const purchaseType = convertPurchaseType(
      data[PURCHASE_BOOSTER_LIST_DATA_NAME.TYPE]
    );

    const boostData = data[PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST];

    const boost =
      purchaseType.id === PURCHASE_TYPE_TYPE.BOOST
        ? {
            [PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_CURRENT]: convertBoostRank(
              boostData[PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_CURRENT]
            ),
            [PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_DESIRED]: convertBoostRank(
              boostData[PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_DESIRED]
            ),
            [PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_ACTUAL]: convertBoostRank(
              boostData[PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_ACTUAL]
            ),
            [PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_ID]:
              boostData[PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_ID],
            [PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_TYPE]: convertBoostType(
              boostData[PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_TYPE]
            ),
            [PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_ADDITIONALS]:
              convertBoostAdditionalList(
                boostData[PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_ADDITIONALS]
              ),
            [PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_DATA]:
              boostData[PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST_DATA],
          }
        : null;

    const coachingData = data[PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING];

    const coaching =
      purchaseType.id === PURCHASE_TYPE_TYPE.COACHING
        ? {
            [PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_ADDITIONALS]:
              convertCoachingAdditionalList(
                coachingData[
                  PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_ADDITIONALS
                ]
              ),
            [PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_ID]:
              coachingData[PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_ID],
            [PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_DATA]:
              coachingData[PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_DATA],
            [PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_TYPE]:
              convertCoachingType(
                coachingData[PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_TYPE]
              ),
            [PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_SESSION_AMOUNT]:
              coachingData[
                PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_SESSION_AMOUNT
              ],
            [PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_ACTUAL_SESSION]:
              coachingData[
                PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING_ACTUAL_SESSION
              ],
          }
        : null;

    return {
      [PURCHASE_BOOSTER_LIST_DATA_NAME.ID]:
        data[PURCHASE_BOOSTER_LIST_DATA_NAME.ID],

      [PURCHASE_BOOSTER_LIST_DATA_NAME.CREATE_DATE]: convertDate(
        data[PURCHASE_BOOSTER_LIST_DATA_NAME.CREATE_DATE]
      ),

      [PURCHASE_BOOSTER_LIST_DATA_NAME.NOTES]:
        data[PURCHASE_BOOSTER_LIST_DATA_NAME.NOTES],
      [PURCHASE_BOOSTER_LIST_DATA_NAME.BOOST]: boost,

      [PURCHASE_BOOSTER_LIST_DATA_NAME.COACHING]: coaching,

      [PURCHASE_BOOSTER_LIST_DATA_NAME.TYPE]: purchaseType,
    };
  }),
});
