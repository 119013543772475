import { GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME } from './gds-item-admin-item-data.constant';

import { convertGdsItemStatus } from '../../lib/common/gds-item/gds-item.convert';

import { convertDatetime } from '../../lib/common/convert/convert.core';
import {
  convertFile,
  convertFileList,
} from '../../lib/common/file/file.convert';

export const convertGdsItemAdminItemData = (data) => ({
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]: convertDatetime(
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE],
  ),
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DATA]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DATA],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.BOUGHT]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.BOUGHT],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_EN]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_EN],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_RU]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION_RU],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ID]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ID],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ORDER]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.ORDER],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.PRICE_EN]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.PRICE_EN],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.PRICE_RU]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.PRICE_RU],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STATUS]: convertGdsItemStatus(
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STATUS],
  ),
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STOCK_EN]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STOCK_EN],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STOCK_RU]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.STOCK_RU],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.TITLE_EN]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.TITLE_EN],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.TITLE_RU]:
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.TITLE_RU],
  [GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]: convertDatetime(
    data[GDS_ITEM_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE],
  ),
});
