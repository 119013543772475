import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getPurchaseCustomerItem,
  reloadPurchaseCustomerItem,
} from "./purchase-customer-item.action";
import { PURCHASE_CUSTOMER_ITEM_STORE_NAME } from "./purchase-customer-item.constant";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";

export function PurchaseCustomerItemContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state } = useSelector((state) => ({
    state: state[PURCHASE_CUSTOMER_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    const data = { purchaseId };

    if (state?.purchaseId === purchaseId) {
      dispatch(reloadPurchaseCustomerItem(data));
      return null;
    }

    dispatch(getPurchaseCustomerItem(data));
  }, []);

  return null;
}
