import React from "react";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "../../purchase-customer-item/purchase-customer-item.constant";

export function PurchaseCustomerItemDataPaymentViewComponent(props) {
  const { data } = props;

  const handlePay = () => {
    window.open(`${String(data.metadata.link.payment)}/${data.id}`);
  };

  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PRICE"
            />
            <TextComponent
              tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PRICE_DATA"
              tvalue={{
                price: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PRICE],
                valute: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.VALUTE].text,
              }}
              variant="dataViewContent"
            />
          </Grid>
          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PROMOCODE"
            />
            {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.IS_PROMOCODE] ? (
              <TextComponent
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PROMOCODE_DATA"
                tvalue={{
                  name: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PROMOCODE].name,
                  factor:
                    data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PROMOCODE].factor
                      .text,
                }}
                variant="dataViewContent"
              />
            ) : (
              <TextComponent
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PROMOCODE_NONE"
                variant="dataViewContent"
              />
            )}
          </Grid>
          {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.METADATA].static?.bonus && (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.BONUS"
              />
              <TextComponent
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.BONUS_DATA"
                tvalue={{
                  amount:
                    data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.METADATA].static
                      ?.bonus,
                  valute: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.VALUTE].text,
                }}
                variant="dataViewContent"
              />
            </Grid>
          )}

          {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.METADATA].static?.reward && (
            <Grid item>
              <TextComponent
                gutterBottom
                variant="dataViewTitle"
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.REWARD"
              />
              <TextComponent
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.REWARD_DATA"
                tvalue={{
                  amount:
                    data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.METADATA].static
                      ?.reward,
                  valute: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.VALUTE].text,
                }}
                variant="dataViewContent"
              />
            </Grid>
          )}

          <Grid item>
            <TextComponent
              gutterBottom
              variant="dataViewTitle"
              tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PAID"
            />
            {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAID] ? (
              <TextComponent
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PAID_DATA"
                tvalue={{
                  confirm:
                    data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT].confirm,
                  system:
                    data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT].system.text,
                }}
                variant="dataViewContent"
              />
            ) : (
              <TextComponent
                tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PAID_NONE"
                variant="dataViewContent"
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAID] !== true && (
        <React.Fragment>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <TextComponent
                  tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.NEED_PAYMENT_INFO"
                  variant="body1"
                />
              </Grid>
              <Grid item>
                <Button onClick={handlePay} fullWidth>
                  <TextComponent tid="PURCHASE.CUSTOMER.ITEM_PAYMENT.PAY_BUTTON" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
}
