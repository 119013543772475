import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseAdminItemBoostTimeListContainer } from "../purchase-admin-item-boost-time-list/purchase-admin-item-boost-time-list.container";
import { PurchaseAdminItemBoostTimeCreateContainer } from "../purchase-admin-item-boost-time-create/purchase-admin-item-boost-time-create.container";
import { PurchaseAdminItemTabContainer } from "../purchase-admin-item-tab/purchase-admin-item-tab.container";
import { PurchaseAdminItemContainer } from "../purchase-admin-item/purchase-admin-item.container";

export function PurchaseAdminItemBoostTimePage() {
  return (
    <>
      <PurchaseAdminItemContainer />
      <Container maxWidth="sm">
        <Grid spacing={3} container>
          <Grid item>
            <PurchaseAdminItemTabContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemBoostTimeListContainer />
          </Grid>
          <Grid item>
            <PurchaseAdminItemBoostTimeCreateContainer />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
