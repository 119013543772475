import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { TabComponent } from "./tab.component";

export function TabContainer({ tab }) {
  let location = useLocation();
  const navigate = useNavigate();

  const defaultItem = tab.find((item) => item.path === location.pathname);

  const [value, setValue] = React.useState(defaultItem ? defaultItem.id : null);

  const handleChange = (event, newValue, action) => {
    setValue(newValue);
    const selectTabPath = tab.find((item) => item.id === newValue).path;
    navigate(selectTabPath);
  };

  return <TabComponent tab={tab} value={value} handleChange={handleChange} />;
}
