import { PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME } from './purchase-booster-item-update-boost-data.constant';
import { getBoostRankImageUrl } from '../../lib/common/boost/boost.core';
import { i18n } from '../../main/lang';

const STATIC_URL = process.env.STATIC;

export const convertPurchaseBoosterItemUpdateBoostDataFormData = (data) => {
  return {
    [PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]:
      data[PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]
        .value,
    [PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL_STATIC_VALUE]:
      i18n.t(
        data[PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]
          .tid,
      ),
    [PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL_STATIC_IMAGE]:
      getBoostRankImageUrl(
        data[PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]
          .value,
        STATIC_URL,
      ),
  };
};
