import { httpRequest } from '../../main/http';

import {
  NEWS_ADMIN_ITEM_DATA_API,
  NEWS_ADMIN_ITEM_DATA_ACTION_TYPE,
  NEWS_ADMIN_ITEM_DATA_STORE_NAME,
} from './news-admin-item-data.constant';

import { convertNewsAdminItemData } from './news-admin-item-data.convert';

export function getNewsAdminItemData(data) {
  return async (dispatch) => {
    dispatch({
      type: NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: NEWS_ADMIN_ITEM_DATA_API.NEWS_ADMIN_ITEM_DATA.TYPE,
        url: NEWS_ADMIN_ITEM_DATA_API.NEWS_ADMIN_ITEM_DATA.ENDPOINT(
          data.newsId,
        ),
      });

      dispatch({
        type: NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertNewsAdminItemData(res.data),
        newsId: data.newsId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadNewsAdminItemData() {
  return async (dispatch, getState) => {
    const { newsId } = getState()[NEWS_ADMIN_ITEM_DATA_STORE_NAME];

    dispatch({
      type: NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: NEWS_ADMIN_ITEM_DATA_API.NEWS_ADMIN_ITEM_DATA.TYPE,
        url: NEWS_ADMIN_ITEM_DATA_API.NEWS_ADMIN_ITEM_DATA.ENDPOINT(newsId),
      });

      dispatch({
        type: NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertNewsAdminItemData(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
