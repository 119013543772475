import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { TextComponent } from '../text';
import styled from 'styled-components';

export function Element({ severity, tid }) {
  const [open, setOpen] = React.useState(true);
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  return (
    <SnackbarStyled
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      action={[
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          <TextComponent tid={tid} />
        </Alert>,
      ]}
    />
  );
}

const SnackbarStyled = styled(Snackbar)`
  & > div {
    box-shadow: unset !important;
    background-image: unset !important;
    border: unset !important;
    padding: unset !important;
    position: relative;

    .MuiSnackbarContent-action {
      padding-left: unset !important;
      margin-right: unset !important;
    }
  }
`;
