import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export const SkeletonDataViewComponent = () => {
  return (
    <Grid spacing={2} container>
      <Grid item>
        <Skeleton height="18px" animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton height="18px" animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton height="18px" animation="wave" />
      </Grid>
      <Grid item>
        <Skeleton height="18px" animation="wave" />
      </Grid>
    </Grid>
  );
};
