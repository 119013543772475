import { httpRequest } from '../../main/http';
import {
  PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_API,
  PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE,
} from './purchase-customer-item-coaching-demo-delete.constant';
import { reloadPurchaseCustomerItemCoachingDemoList } from '../purchase-customer-item-coaching-demo-list/purchase-customer-item-coaching-demo-list.action';

export function uploadPurchaseCustomerItemCoachingDemoDeleteForm(
  purchaseId,
  coachingDemoId,
) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE.FORM_PENDING,
      coachingDemoId,
    });

    try {
      await httpRequest({
        method:
          PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_API.DELETE_DATA.TYPE,
        url: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_API.DELETE_DATA.ENDPOINT(
          purchaseId,
          coachingDemoId,
        ),
      });

      dispatch({
        type: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE.FORM_SUCCESS,
        coachingDemoId,
      });

      await dispatch(reloadPurchaseCustomerItemCoachingDemoList());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseCustomerItemCoachingDemoDeleteFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_CUSTOMER_ITEM_COACHING_DEMO_DELETE_ACTION_TYPE.FORM_RESET,
    });
  };
}
