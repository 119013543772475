export const POPUP_ADMIN_LIST_ROUTE_PATH = '/admin/popup';

export const POPUP_ADMIN_LIST_STORE_NAME = 'POPUP_ADMIN_LIST';

export const POPUP_ADMIN_LIST_API = {
  POPUP_ADMIN_LIST: {
    ENDPOINT: `/popup/short`,
    TYPE: 'GET',
  },
};

export const POPUP_ADMIN_LIST_ACTION_TYPE = {
  REQUEST_PENDING: 'POPUP_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'POPUP_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'POPUP_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR',
};

export const POPUP_ADMIN_LIST_DATA_NAME = {
  ID: 'id',
  TYPE: 'type',
  LANG: 'lang',
};
