export const NEWS_STATUS_TYPE = {
  ACTIVE: 1,
  DISABLED: 2,
};

export const NEWS_CATEGORY_TYPE = {
  NEWS: 1,
  ESPORTS: 2,
  REVIEWS: 3,
  GUIDES: 4,
};

export const NEWS_LANG_TYPE = {
  RU: 2,
  EN: 1,
};

export const NEWS_SORT_TYPE = {
  ORDER: 1,
  ID: 2,
};

export const NEWS_STATUS_VALUE = {
  1: 'ACTIVE',
  2: 'DISABLED',
};

export const NEWS_CATEGORY_VALUE = {
  1: 'NEWS',
  2: 'ESPORTS',
  3: 'REVIEWS',
  4: 'GUIDES',
};

export const NEWS_LANG_VALUE = {
  2: 'RU',
  1: 'EN',
};

export const NEWS_SORT_VALUE = {
  1: 'ORDER',
  2: 'ID',
};

export const NEWS_STATUS_OPTION = [
  { id: 1, value: 1, tid: 'NEWS.DATA.STATUS.ACTIVE' },
  { id: 2, value: 2, tid: 'NEWS.DATA.STATUS.DISABLED' },
];

export const NEWS_CATEGORY_OPTION = [
  { id: 1, value: 1, tid: 'NEWS.DATA.CATEGORY.NEWS' },
  { id: 2, value: 2, tid: 'NEWS.DATA.CATEGORY.ESPORTS' },
  { id: 3, value: 3, tid: 'NEWS.DATA.CATEGORY.REVIEWS' },
  { id: 4, value: 4, tid: 'NEWS.DATA.CATEGORY.GUIDES' },
];

export const NEWS_LANG_OPTION = [
  { id: 2, value: 2, tid: 'NEWS.DATA.LANG.RU' },
  { id: 1, value: 1, tid: 'NEWS.DATA.LANG.EN' },
];

export const NEWS_SORT_OPTION = [
  { id: 1, value: 1, tid: 'NEWS.DATA.SORT.ORDER' },
  { id: 2, value: 2, tid: 'NEWS.DATA.SORT.ID' },
];
