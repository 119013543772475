export const components = {
  MuiPaper: {
    defaultProps: {
      sx: {},
      variant: "elevation",
      elevation: 0,
      width: "100%",
    },
    styleOverrides: {
      root: { border: "1px solid rgba(255, 255, 255, 0.12)" },
    },
  },
  MuiDrawer: {
    defaultProps: {
      PaperProps: {
        variant: "elevation",
        elevation: 0,
      },
    },
    styleOverrides: {
      paper: {
        border: "none",
      },
      root: {
        border: "none",
      },
    },
  },
  MuiGrid: {
    defaultProps: {
      direction: "column",
      justifyContent: "center",
      alignItems: "stretch",
    },
    styleOverrides: {
      root: {},
      item: {
        maxWidth: "100% !important",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "outlined",
      fullWidth: true,
      size: "medium",
      inputProps: {
        style: {
          minHeight: "46px",
          boxSizing: "border-box",
          "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "#2952ff",
          },
        },
      },
    },
    styleOverrides: {
      input: {
        "&::placeholder": {
          textOverflow: "ellipsis !important",
          color: "#2952ff",
        },
      },
      input: {
        "&::placeholder": {
          color: "#fff",
          opacity: 0.5,
        },
      },
    },
  },

  MuiOutlinedInput: {
    defaultProps: {
      variant: "outlined",
      fullWidth: true,
      size: "medium",
      inputProps: {
        style: {
          // minHeight: '46px',
          boxSizing: "border-box",
        },
      },
    },
    styleOverrides: {
      root: {
        backgroundColor: "transparent",
        minHeight: "46px",
      },
      notchedOutline: {
        borderColor: "rgba(255, 255, 255, 0.23)",
      },
      input: {
        fontSize: "14px",
      },
    },
  },

  MuiInput: {
    styleOverrides: {
      "&::placeholder": {
        color: "#fff",
        opacity: 0.5,
      },
      input: {
        "&::placeholder": {
          color: "#fff",
          opacity: 0.5,
        },
      },
      placeholder: {
        color: "#fff",
        opacity: 0.5,
      },
    },
  },

  MuiInputBase: {
    defaultProps: {
      variant: "outlined",
      fullWidth: true,
      size: "medium",
      inputProps: {
        "&::placeholder": {
          color: "#fff",
          opacity: 0.5,
        },
        style: {
          minHeight: "46px",
          boxSizing: "border-box",
          "&::placeholder": {
            color: "#fff",
            opacity: 0.5,
          },
        },
      },
    },
    styleOverrides: {
      input: {
        "&::placeholder": {
          color: "#fff",
          opacity: 0.5,
        },
      },
      placeholder: {
        color: "#fff",
        opacity: 0.5,
      },
      inputMultiline: {
        minHeight: "16px !important",
        padding: "0 !important",
        paddingRight: "10px !important",
        paddingLeft: "10px !important",
        paddingTop: "8px !important",
      },
    },
  },

  MuiSelect: {
    defaultProps: {
      variant: "outlined",
      fullWidth: true,
      size: "medium",
      sx: {
        height: "46px",
        boxSizing: "border-box",
      },
    },
    styleOverrides: {
      root: {
        height: "46px",
        boxSizing: "border-box",
        backgroundColor: "transparent",
      },
      notchedOutline: {
        borderColor: "rgba(255, 255, 255, 0.23)",
      },
      input: {
        fontSize: "14px",
        height: "46px",
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: "14px",
        sizeSmall: true,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: "contained",
      size: "large",
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        boxShadow: "none",
        fontSize: "16px",
        height: "46px",
      },
      greySizeSmall: {
        fontSize: "14px",
        height: "36px",
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: "rgba(255, 255, 255, 0.12)",
        borderRadius: "10px",
        borderWidth: "1px;",
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      gutterBottom: false,
    },
    styleOverrides: {
      root: {},
      gutterBottom: {
        marginBottom: "8px",
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiListItem: {
    defaultProps: {},
    variants: [
      {
        props: {
          variant: "data",
        },
        style: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: 0,
        },
      },
      {
        props: {
          variant: "list",
        },
        style: {
          paddingTop: "12px",
          paddingBottom: "12px",
          paddingRight: "24px",
          paddingLeft: "24px",
        },
      },
    ],
    styleOverrides: {},
  },
  MuiListItemText: {
    defaultProps: {
      gutterBottom: false,
    },
    styleOverrides: {
      root: {
        margin: 0,
      },
    },
  },
  MuiAlert: {
    defaultProps: {
      variant: "outlined",
    },
    styleOverrides: {
      root: {},
    },
  },
  MuiTab: {
    defaultProps: {},
    styleOverrides: {
      textColorPrimary: {
        color: "#fff",
        fontWeight: "500",
        fontSize: "16px",
      },
      root: {
        "&.Mui-selected": {
          color: "",
        },
      },
    },
  },
  MuiTabs: {
    defaultProps: {},
    styleOverrides: {
      scrollButtons: {
        disabled: {
          opacity: 0.3,
        },
      },
    },
  },
  MuiListItemButton: {
    defaultProps: {},
    styleOverrides: {
      root: {},
    },
  },
  MuiAppBar: {
    defaultProps: {},
    styleOverrides: {
      root: {
        background: "rgb(43, 46, 77) !important",
        border: "none",
        boxShadow: "none",
        padding: "0 16px",
        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
      },
    },
  },
  MuiDrawer: {
    defaultProps: {},
    styleOverrides: {
      root: {
        border: "none",
        borderRight: "1px solid rgba(255, 255, 255, 0.12)",
      },
    },
  },
  MuiToolbar: {
    defaultProps: {},
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiContainer: {
    defaultProps: {},
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiBottomNavigation: {
    defaultProps: {},
    styleOverrides: {
      root: {
        borderTop: "1px solid rgba(255, 255, 255, 0.12)",
      },
    },
  },

  MuiTableCell: {
    defaultProps: {},
    styleOverrides: {
      root: {
        border: "none",
      },
      body: {
        padding: "12px 24px",
        borderTop: "1px solid rgba(255, 255, 255, 0.12)",
      },
      head: {
        padding: "12px 24px",
        paddingTop: "24px",
      },
      footer: {
        padding: "12px 24px",
        paddingBottom: "24px",
      },
    },
  },

  MuiChip: {
    defaultProps: {},
    styleOverrides: {
      root: {
        borderColor: "rgba(255, 255, 255, 0.23)",
      },
    },
  },

  MuiTableRow: {
    styleOverrides: {
      hover: {
        cursor: "pointer",
      },
    },
  },
};
