import { PURCHASE_ADMIN_LIST_ROUTE_PATH } from '../purchase-admin-list/purchase-admin-list.constant';
import { ACCOUNT_SETTINGS_ROUTE_PATH } from '../account-settings/account-settings.constant';
import { NOTICE_ADMIN_LIST_ROUTE_PATH } from '../notice-admin-list/notice-admin-list.constant';
import { USER_ADMIN_CREATE_ROUTE_PATH } from '../user-admin-create/user-admin-create.constant';
import { USER_ADMIN_LIST_ROUTE_PATH } from '../user-admin-list/user-admin-list.constant';
import { GDS_ADMIN_CREATE_ROUTE_PATH } from '../gds-admin-create/gds-admin-create.constant';
import { GDS_ADMIN_LIST_ROUTE_PATH } from '../gds-admin-list/gds-admin-list.constant';
import { NEWS_ADMIN_CREATE_ROUTE_PATH } from '../news-admin-create/news-admin-create.constant';
import { NEWS_ADMIN_LIST_ROUTE_PATH } from '../news-admin-list/news-admin-list.constant';
import { PROMOCODE_ADMIN_CREATE_ROUTE_PATH } from '../promocode-admin-create/promocode-admin-create.constant';
import { PROMOCODE_ADMIN_LIST_ROUTE_PATH } from '../promocode-admin-list/promocode-admin-list.constant';
import { POPUP_ADMIN_CREATE_ROUTE_PATH } from '../popup-admin-create/popup-admin-create.constant';
import { POPUP_ADMIN_LIST_ROUTE_PATH } from '../popup-admin-list/popup-admin-list.constant';
import { NOTICE_ACCOUNT_LIST_ROUTE_PATH } from '../notice-account-list/notice-account-list.constant';
import { FILE_ADMIN_ROUTE_PATH } from '../file-admin/file-admin.constant';

export const INDEX_ADMIN_ROUTE_PATH = '/admin';

export const INDEX_ADMIN_LINK_OPTION = [
  {
    id: 1,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.PURCHASE_LIST',
    path: PURCHASE_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 2,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.NOTICE_ADMIN',
    path: NOTICE_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 3,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.USER_CREATE',
    path: USER_ADMIN_CREATE_ROUTE_PATH,
  },
  {
    id: 4,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.USER_LIST',
    path: USER_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 5,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.GDS_CREATE',
    path: GDS_ADMIN_CREATE_ROUTE_PATH,
  },
  {
    id: 6,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.GDS_LIST',
    path: GDS_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 7,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.NEWS_CREATE',
    path: NEWS_ADMIN_CREATE_ROUTE_PATH,
  },
  {
    id: 8,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.NEWS_LIST',
    path: NEWS_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 9,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.PROMOCODE_CREATE',
    path: PROMOCODE_ADMIN_CREATE_ROUTE_PATH,
  },
  {
    id: 10,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.PROMOCODE_LIST',
    path: PROMOCODE_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 11,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.POPUP_CREATE',
    path: POPUP_ADMIN_CREATE_ROUTE_PATH,
  },
  {
    id: 12,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.POPUP_LIST',
    path: POPUP_ADMIN_LIST_ROUTE_PATH,
  },
  {
    id: 14,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.SETTINGS',
    path: ACCOUNT_SETTINGS_ROUTE_PATH,
  },
  {
    id: 13,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.NOTICE_ACCOUNT',
    path: NOTICE_ACCOUNT_LIST_ROUTE_PATH,
  },
  {
    id: 14,
    tid: 'NAVIGATION.INDEX.ADMIN.ROUTE.FILE',
    path: FILE_ADMIN_ROUTE_PATH,
  },
];
