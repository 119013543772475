import { formatDateToUtc } from '../../lib/common/convert/convert.core';
import { PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_DATA_NAME } from './purchase-admin-item-boost-time-create.constant';

export const convertPurchaseAdminItemBoostTimeCreateFormData = (values) => {
  return {
    [PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME]:
      formatDateToUtc(
        values[PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_DATA_NAME.FROM_TIME],
      ),
    [PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME]: formatDateToUtc(
      values[PURCHASE_ADMIN_ITEM_BOOST_TIME_CREATE_DATA_NAME.TO_TIME],
    ),
  };
};
