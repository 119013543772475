import { httpRequest } from '../../main/http';

import {
  PURCHASE_BOOSTER_LIST_API,
  PURCHASE_BOOSTER_LIST_ACTION_TYPE,
} from './purchase-booster-list.constant';

import { convertPurchaseBoosterList } from './purchase-booster-list.convert';

export function getPurchaseBoosterList(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_BOOSTER_LIST_API.PURCHASE_BOOSTER_LIST.TYPE,
        url: PURCHASE_BOOSTER_LIST_API.PURCHASE_BOOSTER_LIST.ENDPOINT,
      });

      dispatch({
        type: PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseBoosterList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function updatePurchaseBoosterList(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PURCHASE_BOOSTER_LIST_API.PURCHASE_BOOSTER_LIST.TYPE,
        url: PURCHASE_BOOSTER_LIST_API.PURCHASE_BOOSTER_LIST.ENDPOINT,
      });

      dispatch({
        type: PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseBoosterList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_BOOSTER_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
