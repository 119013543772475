export const convertSelectFieldValue = (selectFieldValue) => {


  if (!selectFieldValue) return null;

  if (selectFieldValue.id) return selectFieldValue.id;

  if (selectFieldValue.id === 0) return selectFieldValue.id;

  return selectFieldValue.map((item) => item.id);
};
