import React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { TextComponent } from "../../../lib/common/text/text.component";
import { PURCHASE_CUSTOMER_ITEM_DATA_NAME } from "../../purchase-customer-item/purchase-customer-item.constant";
import { PURCHASE_LANG_TYPE } from "../../../lib/common/purchase/purchase.type";
import { FieldCopyComponent } from "../../../lib/common/field-copy/field-copy.component";

export function PurchaseCustomerItemDataGdsViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={4} sx={{ pt: 4 }}>
      <Grid item>
        <Grid container spacing={3}>
          {data.gdsItem ? (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.CUSTOMER.ITEM_GDS.TITLE_LABEL"
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.ITEM_GDS.TITLE_DATA"
                  tvalue={{
                    title:
                      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.LANG].id ===
                      PURCHASE_LANG_TYPE.EN
                        ? data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM]
                            .titleEn
                        : data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM]
                            .titleRu,
                  }}
                  variant="dataViewContent"
                />
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.CUSTOMER.ITEM_GDS.DESCRIPTION"
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.ITEM_GDS.DESCRIPTION_DATA"
                  sx={{ lineHeight: "1.4em" }}
                  tvalue={{
                    title:
                      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.LANG].id ===
                      PURCHASE_LANG_TYPE.EN
                        ? data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM]
                            .descriptionEn
                        : data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM]
                            .descriptionRu,
                  }}
                  variant="dataViewContent"
                />
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.CUSTOMER.ITEM_GDS.ID"
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.ITEM_GDS.ID_DATA"
                  tvalue={{
                    gdsId: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS].id,
                    gdsItemId:
                      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM].id,
                  }}
                  variant="dataViewContent"
                />
              </Grid>
            </React.Fragment>
          ) : data.gds ? (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.CUSTOMER.GDS.TITLE_LABEL"
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.GDS.TITLE_DATA"
                  tvalue={{
                    title:
                      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.LANG].id ===
                      PURCHASE_LANG_TYPE.EN
                        ? data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS].titleEn
                        : data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS].titleRu,
                  }}
                  variant="dataViewContent"
                />
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.CUSTOMER.GDS.DESCRIPTION"
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.GDS.DESCRIPTION_DATA"
                  sx={{ lineHeight: "1.4em" }}
                  tvalue={{
                    title:
                      data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.LANG].id ===
                      PURCHASE_LANG_TYPE.EN
                        ? data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS]
                            .descriptionEn
                        : data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS]
                            .descriptionRu,
                  }}
                  variant="dataViewContent"
                />
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.CUSTOMER.GDS.ID"
                />
                <TextComponent
                  tid="PURCHASE.CUSTOMER.GDS.ID_DATA"
                  tvalue={{
                    gdsId: data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS].id,
                  }}
                  variant="dataViewContent"
                />
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item>
                <TextComponent
                  tid="PURCHASE.CUSTOMER.GDS.GDS_NOT_SET"
                  variant="dataViewContent"
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>

      {data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM] ? (
        data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAID] === true ? (
          data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.PAYMENT].confirm ? (
            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.ITEM_GDS.DATA"
                  />
                  <TextComponent
                    gutterBottom
                    variant="dataViewContent"
                    tid="PURCHASE.CUSTOMER.ITEM_GDS.DATA_READY"
                  />
                  <FieldCopyComponent
                    alertTid="PURCHASE.CUSTOMER.ITEM_GDS.DATA_ALERT_COPIED"
                    value={data[PURCHASE_CUSTOMER_ITEM_DATA_NAME.GDS_ITEM].data}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item>
                  <TextComponent
                    gutterBottom
                    variant="dataViewTitle"
                    tid="PURCHASE.CUSTOMER.ITEM_GDS.DATA"
                  />
                  <TextComponent
                    variant="dataViewContent"
                    tid="PURCHASE.CUSTOMER.ITEM_GDS.DATA_NOT_CONFIRM"
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        ) : (
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item>
                <TextComponent
                  gutterBottom
                  variant="dataViewTitle"
                  tid="PURCHASE.CUSTOMER.ITEM_GDS.DATA"
                />
                <TextComponent
                  variant="dataViewContent"
                  tid="PURCHASE.CUSTOMER.ITEM_GDS.DATA_NOT_PAID"
                />
              </Grid>
            </Grid>
          </Grid>
        )
      ) : null}
    </Grid>
  );
}
