export const PURCHASE_TYPE_TYPE = {
  BOOST: 1,
  GDS: 2,
  HIDDEN: 3,
  COACHING: 4,
};

export const PURCHASE_STATUS_TYPE = {
  NEW: 1,
  IN_PROGRESS: 2,
  DONE: 3,
  IN_BOOSTER_SEARCH: 4,
  IN_GDS_SEARCH: 5,
};

export const PURCHASE_LANG_TYPE = {
  EN: 1,
  RU: 2,
};

export const PURCHASE_TYPE_VALUE = {
  1: 'BOOST',
  2: 'GDS',
  3: 'HIDDEN',
  4: 'COACHING',
};

export const PURCHASE_STATUS_VALUE = {
  1: 'NEW',
  2: 'IN_PROGRESS',
  3: 'DONE',
  4: 'IN_BOOSTER_SEARCH',
  5: 'IN_GDS_SEARCH',
};

export const PURCHASE_LANG_VALUE = {
  1: 'EN',
  2: 'RU',
};

export const PURCHASE_TYPE_OPTION = [
  { id: 1, value: 1, tid: 'PURCHASE.DATA.TYPE.BOOST' },
  { id: 2, value: 2, tid: 'PURCHASE.DATA.TYPE.GDS' },
  { id: 3, value: 3, tid: 'PURCHASE.DATA.TYPE.HIDDEN' },
  { id: 4, value: 4, tid: 'PURCHASE.DATA.TYPE.COACHING' },
];

export const PURCHASE_STATUS_OPTION = [
  { id: 1, value: 1, tid: 'PURCHASE.DATA.STATUS.NEW' },
  { id: 2, value: 2, tid: 'PURCHASE.DATA.STATUS.IN_PROGRESS' },
  { id: 3, value: 3, tid: 'PURCHASE.DATA.STATUS.DONE' },
  { id: 4, value: 4, tid: 'PURCHASE.DATA.STATUS.IN_BOOSTER_SEARCH' },
  { id: 5, value: 5, tid: 'PURCHASE.DATA.STATUS.IN_GDS_SEARCH' },
];

export const PURCHASE_LANG_OPTION = [
  { id: 1, value: 1, tid: 'PURCHASE.DATA.LANG.EN' },
  { id: 2, value: 2, tid: 'PURCHASE.DATA.LANG.RU' },
];

export const PURCHASE_DATA_NAME = {
  ID: 'id',
  TYPE: 'type',
  CREATE_DATE: 'createDate',

  PAYMENT: 'payment',

  GDS_ITEM: 'gdsItem',

  PROMOCODE: 'promocode',

  BOOST: 'boost',
};
