export const PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE_STORE_NAME =
  'PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE';

export const PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE_API = {
  DELETE_DATA: {
    ENDPOINT: (purchaseId, boostTimeId) =>
      `/purchase/${purchaseId}/admin/boost/time/${boostTimeId}`,
    TYPE: 'DELETE',
  },
};

export const PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE_ACTION_TYPE = {
  FORM_PENDING:
    'PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS:
    'PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'PURCHASE_ADMIN_ITEM_BOOST_TIME_DELETE_ACTION_TYPE.FORM_RESET',
};
