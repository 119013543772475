import { httpRequest } from "../../main/http";

import {
  ACCOUNT_UPDATE_AUTH_API,
  ACCOUNT_UPDATE_AUTH_ACTION_TYPE,
} from "./account-update-auth.constant";

import { authSetData } from "../../lib/common/auth/auth.action";

import { INDEX_ROUTE_PATH } from "../index/index.constant";

export function uploadAccountUpdateAuthForm(data, redirect) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_UPDATE_AUTH_ACTION_TYPE.FORM_PENDING,
    });

    try {
      const res = await httpRequest({
        method: ACCOUNT_UPDATE_AUTH_API.CHANGE_PASSWORD.TYPE,
        url: ACCOUNT_UPDATE_AUTH_API.CHANGE_PASSWORD.ENDPOINT,
        data,
      });
      dispatch(authSetData(res.data.accessToken));

      await redirect(INDEX_ROUTE_PATH);

      dispatch({
        type: ACCOUNT_UPDATE_AUTH_ACTION_TYPE.FORM_SUCCESS,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: ACCOUNT_UPDATE_AUTH_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetAccountUpdateAuthForm(data) {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNT_UPDATE_AUTH_ACTION_TYPE.FORM_RESET,
    });
  };
}
