import React from 'react';

import { PurchaseAdminListComponent } from './purchase-admin-list.component';

import { useDispatch, useSelector } from 'react-redux';

import { getPurchaseAdminList } from './purchase-admin-list.action';

import { PURCHASE_ADMIN_LIST_STORE_NAME } from './purchase-admin-list.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
  isRequestUpdatePending,
} from '../../main/store/store.service';

export function PurchaseAdminListContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading } = useSelector((state) => ({
    state: state[PURCHASE_ADMIN_LIST_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  React.useEffect(() => {
    dispatch(getPurchaseAdminList());
  }, []);

  return (
    <PurchaseAdminListComponent
      isPending={isRequestPending(state.request)}
      isUpdating={isRequestUpdatePending(state.request)}
      isError={isRequestError(state.request)}
      isSuccess={isRequestSuccess(state.request)}
      pageLoading={pageLoading}
      data={getRequestData(state.request)}
      errorMessage={getRequestErrorMessage(state.request)}
    />
  );
}
