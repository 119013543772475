import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import { TextComponent } from '../../../lib/common/text/text.component';
import { FileListComponent } from '../../../lib/common/file-list/file-list.component';
import { NEWS_ADMIN_ITEM_DATA_DATA_NAME } from '../news-admin-item-data.constant';

export function NewsAdminItemDataViewComponent(props) {
  const { data } = props;

  return (
    <Grid container spacing={3} sx={{ pt: 4 }}>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.ID"
        />
        <TextComponent variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.ID]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.ORDER"
        />
        <TextComponent variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.ORDER]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.TITLE"
        />
        <TextComponent sx={{ lineHeight: '1.3em' }} variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.TITLE]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.DESCRIPTION"
        />
        <TextComponent sx={{ lineHeight: '1.3em' }} variant="dataViewContent">
          {data.description}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.BODY"
        />
        <TextComponent sx={{ lineHeight: '1.3em' }} variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.BODY]}
        </TextComponent>
      </Grid>

      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.URL"
        />
        <TextComponent variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.URL]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.CATEGORY"
        />
        <TextComponent variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY].text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.LANG"
        />
        <TextComponent variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.LANG].text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.STATUS"
        />
        <TextComponent variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.STATUS].text}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.CREATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.CREATE_DATE]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.UPDATE_DATE"
        />
        <TextComponent variant="dataViewContent">
          {data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.UPDATE_DATE]}
        </TextComponent>
      </Grid>
      <Grid item>
        <TextComponent
          gutterBottom
          variant="dataViewTitle"
          tid="NEWS.ADMIN.ITEM_DATA.PREVIEW"
        />
        <FileListComponent
          item={data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.PREVIEW]}
        />
      </Grid>
    </Grid>
  );
}
