import React from "react";
import { Outlet } from "react-router-dom";

import { NavigationObserver } from "./lib/common/navigation";
import { NavigationContainer } from "./core/navigation/navigation.container";

export const Layout = () => {
  return (
    <NavigationContainer>
      <NavigationObserver />
      <Outlet />
    </NavigationContainer>
  );
};
