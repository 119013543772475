import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { ModalConfirmComponent } from '../../lib/common/modal-confirm/modal-confirm.component';

export function PurchaseAdminItemCoachingReportDeleteComponent(props) {
  const {
    pageLoading,
    isPending,
    onSubmitForm,
    isError,
    errorMessage,
    isDependentPending,
    stateCoachingReportId,
    coachingReportId,
  } = props;

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }
  };

  const isLoading = isPending && stateCoachingReportId === coachingReportId;

  return (
    <React.Fragment>
      <ModalConfirmComponent
        action={onSubmitForm}
        disabled={isSubmitDisabled()}
      >
        <IconButton
          edge="end"
          aria-label="delete"
          disabled={isSubmitDisabled()}
        >
          {isLoading ? <CircularProgress size={25} /> : <DeleteIcon />}
        </IconButton>
      </ModalConfirmComponent>
    </React.Fragment>
  );
}
