export const PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_STORE_NAME =
  'PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST';

export const PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_API = {
  PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST: {
    ENDPOINT: (purchaseId) => `/purchase/${purchaseId}/admin/boost/time`,
    TYPE: 'GET',
  },
};

export const PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_ACTION_TYPE = {
  REQUEST_PENDING:
    'PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS:
    'PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR:
    'PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const PURCHASE_ADMIN_ITEM_BOOST_TIME_LIST_DATA_NAME = {
  ID: 'id',
  FROM_TIME: 'fromTime',
  TO_TIME: 'toTime',
};
