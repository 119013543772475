export const ACCOUNT_SETTINGS_UPDATE_TIMEZONE_STORE_NAME =
  'ACCOUNT_SETTINGS_UPDATE_TIMEZONE';

export const ACCOUNT_SETTINGS_UPDATE_TIMEZONE_API = {
  CHANGE_TIMEZONE: {
    ENDPOINT: '/user-settings/timezone',
    TYPE: 'PATCH',
  },
};

export const ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE = {
  FORM_PENDING: 'ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE.FORM_PENDING',
  FORM_SUCCESS: 'ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE.FORM_SUCCESS',
  FORM_ERROR: 'ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE.FORM_ERROR',
  FORM_RESET: 'ACCOUNT_SETTINGS_UPDATE_TIMEZONE_ACTION_TYPE.FORM_RESET',
};

export const ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME = {
  TIMEZONE: 'timezone',
};
