import { Method } from 'axios';

export const AUTH_STORE_NAME = 'AUTH';

const GET: Method = 'GET';

export const AUTH_API = {
  UPDATE_TOKEN: {
    ENDPOINT: '/auth/token',
    TYPE: GET,
  },
};

export const REMEMBER_LINK_TO_REDIRECT_COOKIE_NAME = 'LINK_TO_REDIRECT';

export const REMEMBER_LINK_TO_REDIRECT_LOCALSTORAGE_NAME = 'LINK_TO_REDIRECT';
