import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { PurchaseAdminItemTabContainer } from "../purchase-admin-item-tab/purchase-admin-item-tab.container";
import { PurchaseAdminItemCoachingReportListContainer } from "../purchase-admin-item-coaching-report-list/purchase-admin-item-coaching-report-list.container";
import { PurchaseAdminItemContainer } from "../purchase-admin-item/purchase-admin-item.container";
import { PurchaseAdminItemCoachingReportCreateContainer } from "../purchase-admin-item-coaching-report-create/purchase-admin-item-coaching-report-create.container";

export function PurchaseAdminItemCoachingReportPage() {
  return (
    <Container maxWidth="sm">
      <PurchaseAdminItemContainer />
      <Grid spacing={3} container>
        <Grid item>
          <PurchaseAdminItemTabContainer />
        </Grid>
        <Grid item>
          <PurchaseAdminItemCoachingReportListContainer />
        </Grid>
        <Grid item>
          <PurchaseAdminItemCoachingReportCreateContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
