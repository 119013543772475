import { httpRequest } from '../../main/http';

import {
  PROMOCODE_ADMIN_LIST_API,
  PROMOCODE_ADMIN_LIST_ACTION_TYPE,
} from './promocode-admin-list.constant';

import { convertPromocodeAdminList } from './promocode-admin-list.convert';

export function getPromocodeAdminList() {
  return async (dispatch) => {
    dispatch({
      type: PROMOCODE_ADMIN_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method: PROMOCODE_ADMIN_LIST_API.PROMOCODE_ADMIN_LIST.TYPE,
        url: PROMOCODE_ADMIN_LIST_API.PROMOCODE_ADMIN_LIST.ENDPOINT,
      });

      dispatch({
        type: PROMOCODE_ADMIN_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPromocodeAdminList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PROMOCODE_ADMIN_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
