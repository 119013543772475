import { httpRequest } from '../../main/http';

import {
  PURCHASE_ADMIN_ITEM_CHAT_LIST_API,
  PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE,
} from './purchase-admin-item-chat-list.constant';

import { convertPurchaseAdminItemChatList } from './purchase-admin-item-chat-list.convert';

export function getPurchaseAdminItemChatList(data) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_PENDING,
    });

    try {
      const res = await httpRequest({
        method:
          PURCHASE_ADMIN_ITEM_CHAT_LIST_API.PURCHASE_ADMIN_ITEM_CHAT_LIST.TYPE,
        url: PURCHASE_ADMIN_ITEM_CHAT_LIST_API.PURCHASE_ADMIN_ITEM_CHAT_LIST.ENDPOINT(
          data.purchaseId,
        ),
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_SUCCESS,
        data: convertPurchaseAdminItemChatList(res.data),
        purchaseId: data.purchaseId,
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function reloadPurchaseAdminItemChatList({ purchaseId }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING,
      });

      const res = await httpRequest({
        method:
          PURCHASE_ADMIN_ITEM_CHAT_LIST_API.PURCHASE_ADMIN_ITEM_CHAT_LIST.TYPE,
        url: PURCHASE_ADMIN_ITEM_CHAT_LIST_API.PURCHASE_ADMIN_ITEM_CHAT_LIST.ENDPOINT(
          purchaseId,
        ),
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS,
        data: convertPurchaseAdminItemChatList(res.data),
      });
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}
