import { PURCHASE_ADMIN_LIST_DATA_NAME } from './purchase-admin-list.constant';

import {
  convertPurchaseType,
  convertPurchaseStatus,
} from '../../lib/common/purchase/purchase.convert';
import {
  convertBoostRank,
  convertBoostType,
  convertBoostAdditionalList,
} from '../../lib/common/boost/boost.convert';
import { convertPromocode } from '../../lib/common/promocode/promocode.convert';
import { PURCHASE_TYPE_TYPE } from '../../lib/common/purchase/purchase.type';
import { convertDatetime } from '../../lib/common/convert/convert.core';
import {
  convertPaymentSystemType,
  convertPaymentValuteType,
} from '../../lib/common/payment/payment.convert';
import {
  convertCoachingAdditionalList,
  convertCoachingType,
} from '../../lib/common/coaching/coaching.convert';

export const convertPurchaseAdminList = (d) => ({
  [PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]:
    d[PURCHASE_ADMIN_LIST_DATA_NAME.STATUS],
  [PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]: d[PURCHASE_ADMIN_LIST_DATA_NAME.TYPE],
  [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]: d[PURCHASE_ADMIN_LIST_DATA_NAME.PAID],
  [PURCHASE_ADMIN_LIST_DATA_NAME.TAKE]: d[PURCHASE_ADMIN_LIST_DATA_NAME.TAKE],
  [PURCHASE_ADMIN_LIST_DATA_NAME.LANG]: d[PURCHASE_ADMIN_LIST_DATA_NAME.LANG],

  [PURCHASE_ADMIN_LIST_DATA_NAME.AMOUNT]:
    d[PURCHASE_ADMIN_LIST_DATA_NAME.AMOUNT],
  [PURCHASE_ADMIN_LIST_DATA_NAME.SKIP]: d[PURCHASE_ADMIN_LIST_DATA_NAME.SKIP],

  [PURCHASE_ADMIN_LIST_DATA_NAME.LIST]: d[
    PURCHASE_ADMIN_LIST_DATA_NAME.LIST
  ].map((data) => {
    const purchaseType = convertPurchaseType(
      data[PURCHASE_ADMIN_LIST_DATA_NAME.TYPE],
    );

    const gdsItemData = data[PURCHASE_ADMIN_LIST_DATA_NAME.GDS_ITEM];

    const gdsItem =
      purchaseType.id === PURCHASE_TYPE_TYPE.GDS && gdsItemData
        ? {
            [PURCHASE_ADMIN_LIST_DATA_NAME.GDS_ITEM_TITLE]:
              gdsItemData[PURCHASE_ADMIN_LIST_DATA_NAME.GDS_ITEM_TITLE],
            [PURCHASE_ADMIN_LIST_DATA_NAME.GDS_ITEM_ID]:
              gdsItemData[PURCHASE_ADMIN_LIST_DATA_NAME.GDS_ITEM_ID],
          }
        : null;

    const gdsData =
      purchaseType.id === PURCHASE_TYPE_TYPE.GDS
        ? data[PURCHASE_ADMIN_LIST_DATA_NAME.GDS]
        : null;

    const gds =
      purchaseType.id === PURCHASE_TYPE_TYPE.GDS && gdsData
        ? {
            [PURCHASE_ADMIN_LIST_DATA_NAME.GDS_ID]:
              gdsData[PURCHASE_ADMIN_LIST_DATA_NAME.GDS_ID],
            [PURCHASE_ADMIN_LIST_DATA_NAME.GDS_TITLE]:
              gdsData[PURCHASE_ADMIN_LIST_DATA_NAME.GDS_TITLE],
          }
        : null;

    const boostData = data[PURCHASE_ADMIN_LIST_DATA_NAME.BOOST];

    const boost =
      purchaseType.id === PURCHASE_TYPE_TYPE.BOOST
        ? {
            [PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_CURRENT]: convertBoostRank(
              boostData[PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_CURRENT],
            ),
            [PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_DESIRED]: convertBoostRank(
              boostData[PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_DESIRED],
            ),
            [PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_ACTUAL]: convertBoostRank(
              boostData[PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_ACTUAL],
            ),
            [PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_ADDITIONALS]:
              convertBoostAdditionalList(
                boostData[PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_ADDITIONALS],
              ),
            [PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_ID]:
              boostData[PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_ID],
            [PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_TYPE]: convertBoostType(
              boostData[PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_TYPE],
            ),
            [PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_DATA]:
              boostData[PURCHASE_ADMIN_LIST_DATA_NAME.BOOST_DATA],
          }
        : null;

    const coachingData = data[PURCHASE_ADMIN_LIST_DATA_NAME.COACHING];

    const coaching =
      purchaseType.id === PURCHASE_TYPE_TYPE.COACHING
        ? {
            [PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_ADDITIONALS]:
              convertCoachingAdditionalList(
                coachingData[
                  PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_ADDITIONALS
                ],
              ),
            [PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_ID]:
              coachingData[PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_ID],
            [PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_DATA]:
              coachingData[PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_DATA],
            [PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_TYPE]: convertCoachingType(
              coachingData[PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_TYPE],
            ),
            [PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_SESSION_AMOUNT]:
              coachingData[
                PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_SESSION_AMOUNT
              ],
            [PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_ACTUAL_SESSION]:
              coachingData[
                PURCHASE_ADMIN_LIST_DATA_NAME.COACHING_ACTUAL_SESSION
              ],
          }
        : null;

    const isPromocode = !!data[PURCHASE_ADMIN_LIST_DATA_NAME.PROMOCODE];

    let promocode = null;

    if (isPromocode) {
      promocode = convertPromocode(
        data[PURCHASE_ADMIN_LIST_DATA_NAME.PROMOCODE],
        data[PURCHASE_ADMIN_LIST_DATA_NAME.PRICE],
      );
    }

    const paid = !!data[PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT];

    const paymentData = data[PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT];

    const payment = paid
      ? {
          [PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT_ID]:
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT_ID],
          [PURCHASE_ADMIN_LIST_DATA_NAME.STRIPE_PAID]: Boolean(
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.STRIPE_PAID],
          ),
          [PURCHASE_ADMIN_LIST_DATA_NAME.PAYOP_PAID]: Boolean(
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.PAYOP_PAID],
          ),
          [PURCHASE_ADMIN_LIST_DATA_NAME.STRIPE_CHECKED]: Boolean(
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.STRIPE_CHECKED],
          ),
          [PURCHASE_ADMIN_LIST_DATA_NAME.ELOBOSS_GG]: Boolean(
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.ELOBOSS_GG],
          ),
          [PURCHASE_ADMIN_LIST_DATA_NAME.COINBASE_PAID]: Boolean(
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.COINBASE_PAID],
          ),
          [PURCHASE_ADMIN_LIST_DATA_NAME.UNITPAY_PAID]: Boolean(
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.UNITPAY_PAID],
          ),
          [PURCHASE_ADMIN_LIST_DATA_NAME.MANUAL_PAID]: Boolean(
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.MANUAL_PAID],
          ),
          [PURCHASE_ADMIN_LIST_DATA_NAME.CONFIRM]:
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.CONFIRM],
          [PURCHASE_ADMIN_LIST_DATA_NAME.SYSTEM]: convertPaymentSystemType(
            paymentData[PURCHASE_ADMIN_LIST_DATA_NAME.SYSTEM],
          ),
        }
      : null;

    const isCustomer = !!data[PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER];
    const customerData = data[PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER];

    const customer = isCustomer
      ? {
          [PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER_ID]:
            customerData[PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER_ID],
          [PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER_EMAIL]:
            customerData[PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER_EMAIL],
        }
      : null;

    const isBooster = !!data[PURCHASE_ADMIN_LIST_DATA_NAME.BOOSTER];
    const boosterData = data[PURCHASE_ADMIN_LIST_DATA_NAME.BOOSTER];

    const booster = isBooster
      ? {
          [PURCHASE_ADMIN_LIST_DATA_NAME.BOOSTER_ID]:
            boosterData[PURCHASE_ADMIN_LIST_DATA_NAME.BOOSTER_ID],
          [PURCHASE_ADMIN_LIST_DATA_NAME.BOOSTER_EMAIL]:
            boosterData[PURCHASE_ADMIN_LIST_DATA_NAME.BOOSTER_EMAIL],
        }
      : null;

    return {
      [PURCHASE_ADMIN_LIST_DATA_NAME.ID]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.ID],
      [PURCHASE_ADMIN_LIST_DATA_NAME.PRICE]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.PRICE],
      [PURCHASE_ADMIN_LIST_DATA_NAME.VALUTE]: convertPaymentValuteType(
        data[PURCHASE_ADMIN_LIST_DATA_NAME.VALUTE],
      ),

      [PURCHASE_ADMIN_LIST_DATA_NAME.STATUS]: convertPurchaseStatus(
        data[PURCHASE_ADMIN_LIST_DATA_NAME.STATUS],
      ),

      [PURCHASE_ADMIN_LIST_DATA_NAME.TYPE]: purchaseType,

      [PURCHASE_ADMIN_LIST_DATA_NAME.CREATE_DATE]: convertDatetime(
        data[PURCHASE_ADMIN_LIST_DATA_NAME.CREATE_DATE],
      ),

      [PURCHASE_ADMIN_LIST_DATA_NAME.PAID]: paid,
      [PURCHASE_ADMIN_LIST_DATA_NAME.PAYMENT]: payment,

      [PURCHASE_ADMIN_LIST_DATA_NAME.GDS_ITEM]: gdsItem,
      [PURCHASE_ADMIN_LIST_DATA_NAME.GDS]: gds,
      [PURCHASE_ADMIN_LIST_DATA_NAME.BOOST]: boost,

      [PURCHASE_ADMIN_LIST_DATA_NAME.IS_PROMOCODE]: isPromocode,
      [PURCHASE_ADMIN_LIST_DATA_NAME.PROMOCODE]: promocode,

      [PURCHASE_ADMIN_LIST_DATA_NAME.IS_CUSTOMER]: isCustomer,
      [PURCHASE_ADMIN_LIST_DATA_NAME.CUSTOMER]: customer,

      [PURCHASE_ADMIN_LIST_DATA_NAME.IS_BOOSTER]: isBooster,
      [PURCHASE_ADMIN_LIST_DATA_NAME.BOOSTER]: booster,

      [PURCHASE_ADMIN_LIST_DATA_NAME.COACHING]: coaching,

      [PURCHASE_ADMIN_LIST_DATA_NAME.METADATA]:
        data[PURCHASE_ADMIN_LIST_DATA_NAME.METADATA],
    };
  }),
});
