export const NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_STORE_NAME =
  'NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_SHORT';

export const NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_SHORT_API = {
  ITEM_UPDATE_VIEW: {
    ENDPOINT: (noticeId) => `/notice/${noticeId}/view`,
    TYPE: 'PATCH',
  },
  LIST_UPDATE_VIEW: {
    ENDPOINT: `/notice/list/view`,
    TYPE: 'PATCH',
  },
  LIST_UPDATE_VIEW_ALL: {
    ENDPOINT: `/notice/all/view`,
    TYPE: 'PATCH',
  },
};

export const NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_ACTION_TYPE = {
  REQUEST_PENDING: 'NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'NOTICE_ACCOUNT_ITEM_UPDATE_VIEW_TYPE.REQUEST_ERROR',
};
