import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { text } from '../text';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { FieldFileContainer } from '../field-file/field-file.container';
export const ChatMessageFieldComponent = ({
  errorText,
  error,
  label,
  placeholder,
  onFileAdd,
  onSend,
  type,
  disabled,
  fileInput,
  ...props
}) => {
  const handleChange = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <TextField
          {...props}
          onKeyPress={handleChange}
          placeholder={placeholder ? text(placeholder) : ''}
          error={error}
          multiline
          InputProps={{
            sx: {
              py: '7px !important',
              pl: '10px !important',
              pr: '80px !important',
              minHeight: 'initial !important',
              height: 'initial !important',
            },
            endAdornment: (
              <Grid
                sx={{
                  width: '75px',
                  flexDirection: 'row',
                  position: 'absolute',
                  right: '12px',
                  bottom: '6.5px',
                }}
                container
              >
                <Grid item>
                  <FieldFileContainer
                    icon
                    iconProps={{ edge: 'start' }}
                    {...fileInput}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    type={type}
                    disabled={disabled}
                    onClick={onSend ? onSend : null}
                    edge="end"
                    color="primary"
                  >
                    <SendIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
