import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { TextComponent } from "../../lib/common/text/text.component";
import { NavigationNoticeComponent } from "./navigation-notice.component";

const drawerWidth = 240;

export function NavigationDesctopComponent(props) {
  const navigate = useNavigate();
  const { routeList, activePath, noticeAmount, role } = props;

  return (
    <Box>
      <Box>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              padding: 0,
            },
          }}
        >
          <Box sx={{ overflow: "auto", mt: "68px", pt: 3, px: 4 }}>
            <List disablePadding>
              {routeList.map((route, i) => {
                return (
                  <React.Fragment key={i}>
                    <ListItem
                      disablePadding
                      sx={{
                        px: 6,
                        py: 3,
                        mt: i === 0 ? 0 : 1,
                        borderRadius: 1,
                      }}
                      selected={activePath === route.path}
                      button
                      onClick={() => {
                        navigate(route.route);
                      }}
                    >
                      <ListItemText
                        disablePadding
                        primary={
                          <TextComponent
                            variant="navigationItem"
                            tid={route.tid}
                          />
                        }
                      />
                    </ListItem>
                  </React.Fragment>
                );
              })}
            </List>
          </Box>
        </Drawer>

        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1201,
          }}
        >
          <AppBar position="static">
            <Toolbar>
              <img
                style={{ display: "block" }}
                height="28px"
                src="/static/img/logo.svg"
              />
              <Box sx={{ ml: "auto" }}>
                <NavigationNoticeComponent
                  role={role}
                  noticeAmount={noticeAmount}
                />
              </Box>
            </Toolbar>
          </AppBar>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: 4,
            py: 24,
            pb: 8,
            pl: 64,
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
