import {
  PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-boost-account/purchase-booster-item-boost-account.constant";
import {
  PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-boost-time/purchase-booster-item-boost-time.constant";

import {
  PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-chat/purchase-booster-item-chat.constant";

import {
  PURCHASE_BOOSTER_ITEM_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item/purchase-booster-item.constant";
import {
  PURCHASE_BOOSTER_ITEM_COACHING_REPORT_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-coaching-report/purchase-booster-item-coaching-report.constant";
import {
  PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH,
  PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC,
} from "../purchase-booster-item-coaching-demo/purchase-booster-item-coaching-demo.constant";
import { PURCHASE_BOOSTER_ITEM_UPDATE_ROUTE_PATH_DYNAMIC } from "../purchase-booster-item-update/purchase-admin-item-update.constant";

export const getPurchaseBoosterItemBoost = (purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM",
    path: PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_UPDATE",
    path: PURCHASE_BOOSTER_ITEM_UPDATE_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 2,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_BOOST_ACCOUNT",
    path: PURCHASE_BOOSTER_ITEM_BOOST_ACCOUNT_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 3,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_BOOST_TIME",
    path: PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 4,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_CHAT",
    path: PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
];

export const getPurchaseBoosterItemCoaching = (purchaseId) => [
  {
    id: 0,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM",
    path: PURCHASE_BOOSTER_ITEM_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 1,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_UPDATE",
    path: PURCHASE_BOOSTER_ITEM_UPDATE_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 2,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_COACHING_DEMO",
    path: PURCHASE_BOOSTER_ITEM_COACHING_DEMO_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 3,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_COACHING_REPORT",
    path: PURCHASE_BOOSTER_ITEM_COACHING_REPORT_ROUTE_PATH_DYNAMIC({
      purchaseId,
    }),
  },
  {
    id: 4,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_BOOST_TIME",
    path: PURCHASE_BOOSTER_ITEM_BOOST_TIME_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
  {
    id: 5,
    tid: "NAVIGATION.TAB.PURCHASE.BOOSTER.ITEM_CHAT",
    path: PURCHASE_BOOSTER_ITEM_CHAT_ROUTE_PATH_DYNAMIC({ purchaseId }),
  },
];
