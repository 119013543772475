import { httpRequest } from '../../main/http';
import {
  PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_API,
  PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE,
} from './purchase-admin-item-coaching-report-delete.constant';

import { reloadPurchaseAdminItemCoachingReportList } from '../purchase-admin-item-coaching-report-list/purchase-admin-item-coaching-report-list.action';

export function uploadPurchaseAdminItemCoachingReportDeleteForm(
  purchaseId,
  coachingReportId,
) {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE.FORM_PENDING,
      coachingReportId,
    });

    try {
      await httpRequest({
        method: PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_API.DELETE_DATA.TYPE,
        url: PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_API.DELETE_DATA.ENDPOINT(
          purchaseId,
          coachingReportId,
        ),
      });

      dispatch({
        type: PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE.FORM_SUCCESS,
        coachingReportId,
      });

      await dispatch(reloadPurchaseAdminItemCoachingReportList());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      if (error.response) {
        dispatch({
          type: PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE.FORM_ERROR,
          errorMessage: error.response.data.message,
        });
      }
    }
  };
}

export function resetPurchaseAdminItemCoachingReportDeleteFormState() {
  return async (dispatch) => {
    dispatch({
      type: PURCHASE_ADMIN_ITEM_COACHING_REPORT_DELETE_ACTION_TYPE.FORM_RESET,
    });
  };
}
