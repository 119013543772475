import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { NewsAdminListContainer } from "./news-admin-list.container";
import { NewsAdminCreateInfoContainer } from "../news-admin-create-info/news-admin-create-info.container";
import { NewsAdminListPaginationContainer } from "../news-admin-list-pagination/news-admin-list-pagination.container";

export function NewsAdminListPage() {
  return (
    <Container maxWidth="sm">
      <Grid spacing={6} container>
        <Grid item>
          <NewsAdminCreateInfoContainer />
        </Grid>
        <Grid item>
          <NewsAdminListPaginationContainer />
        </Grid>
        <Grid item sx={{ width: "100%", alignSelf: "baseline" }}>
          <NewsAdminListContainer />
        </Grid>
        <Grid item>
          <NewsAdminListPaginationContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
