import { PURCHASE_BOOSTER_ITEM_BOOST_TIME_LIST_DATA_NAME } from './purchase-booster-item-boost-time-list.constant';

import { convertDateSchedule } from '../../lib/common/convert/convert.core';

export const convertPurchaseBoosterItemBoostTimeList = (d) => {
  return {
    list: d.list.map((data) => {
      return {
        [PURCHASE_BOOSTER_ITEM_BOOST_TIME_LIST_DATA_NAME.ID]:
          data[PURCHASE_BOOSTER_ITEM_BOOST_TIME_LIST_DATA_NAME.ID],
        [PURCHASE_BOOSTER_ITEM_BOOST_TIME_LIST_DATA_NAME.FROM_TIME]:
          convertDateSchedule(
            data[PURCHASE_BOOSTER_ITEM_BOOST_TIME_LIST_DATA_NAME.FROM_TIME],
          ),
        [PURCHASE_BOOSTER_ITEM_BOOST_TIME_LIST_DATA_NAME.TO_TIME]:
          convertDateSchedule(
            data[PURCHASE_BOOSTER_ITEM_BOOST_TIME_LIST_DATA_NAME.TO_TIME],
          ),
      };
    }),
  };
};
