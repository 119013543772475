import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export const SkeletonFormComponent = () => {
  return (
    <Grid container spacing={3}>
      <Grid item>
        <Skeleton
          sx={{ borderRadius: 1 }}
          variant="rectangular"
          width="100%"
          height="46px"
        />
      </Grid>

      <Grid item>
        <Skeleton
          sx={{ borderRadius: 1 }}
          variant="rectangular"
          width="100%"
          height="46px"
        />
      </Grid>
      <Grid item>
        <Skeleton
          sx={{ borderRadius: 1 }}
          variant="rectangular"
          width="100%"
          height="46px"
        />
      </Grid>
      <Grid item>
        <Skeleton
          sx={{ borderRadius: 1 }}
          variant="rectangular"
          width="100%"
          height="46px"
        />
      </Grid>
    </Grid>
  );
};
