import Box from '@mui/material/Box';

import { FieldSelectComponent } from '../../../lib/common/field-select/field-select.component';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME } from '../account-settings-update-timezone.constant';
import { USER_TIMEZONE_OPTION } from '../../../lib/common/user/user.type';

export const AccountSettingsUpdateTimezoneFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    userEmail,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === '{}') {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldSelectComponent
              option={USER_TIMEZONE_OPTION}
              label={
                <TextComponent tid="ACCOUNT.UPDATE_TIMEZONE.FORM.TIMEZONE" />
              }
              name={ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME.TIMEZONE}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={
                values[ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME.TIMEZONE]
              }
              error={isFieldError(
                ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME.TIMEZONE,
              )}
              fullWidth
              errorText={getFieldError(
                ACCOUNT_SETTINGS_UPDATE_TIMEZONE_DATA_NAME.TIMEZONE,
              )}
            />
          </Grid>

          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="ACCOUNT.UPDATE_TIMEZONE.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
