import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { TextComponent } from '../../../lib/common/text';
import { FieldTextComponent } from '../../../lib/common/field-text/field-text.component';
import { FieldSelectComponent } from '../../../lib/common/field-select/field-select.component';
import { PURCHASE_STATUS_OPTION } from '../../../lib/common/purchase/purchase.type';
import { PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME } from '../purchase-admin-item-update-boost-data.constant';
import { FieldBoostRankComponent } from '../../../lib/common/boost/field-boost-rank.component';
import { ModalConfirmComponent } from '../../../lib/common/modal-confirm/modal-confirm.component';

export const PurchaseAdminItemUpdateBoostDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,
    submitForm,
    pageLoading,
    isSuccess,
    isError,
    isPending,
    isBoost,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (!isBoost) {
      return true;
    }

    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === '{}') {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldBoostRankComponent
              label={
                <TextComponent tid="PURCHASE.ADMIN.UPDATE_BOOST_DATA.FORM.BOOST_ACTUAL" />
              }
              name={
                PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL
              }
              disabled={!isBoost}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={
                values[
                  PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL
                ]
              }
              error={isFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL,
              )}
              fullWidth
              errorText={getFieldError(
                PURCHASE_ADMIN_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL,
              )}
            />
          </Grid>
          <Grid item>
            <ModalConfirmComponent
              disabled={isSubmitDisabled()}
              action={submitForm}
            >
              <Button disabled={isSubmitDisabled()} fullWidth>
                <TextComponent tid="PURCHASE.ADMIN.UPDATE_BOOST_DATA.FORM.SUBMIT" />
              </Button>
            </ModalConfirmComponent>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
