import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../main/store/store.service";
import { NAVIGATION_STORE_NAME } from "../../lib/common/navigation";
import {
  uploadPurchaseBoosterItemUpdateBoostDataForm,
  resetPurchaseBoosterItemUpdateBoostDataFormState,
} from "./purchase-booster-item-update-boost-data.action";
import {
  PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_STORE_NAME,
  PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME,
} from "./purchase-booster-item-update-boost-data.constant";
import { convertPurchaseBoosterItemUpdateBoostDataFormData } from "./purchase-booster-item-update-boost-data.convert";
import { purchaseBoosterItemUpdateBoostDataFormValidation } from "./purchase-booster-item-update-boost-data.validation";
import { PurchaseBoosterItemUpdateBoostDataComponent } from "./purchase-booster-item-update-boost-data.component";
import {
  PURCHASE_BOOSTER_ITEM_DATA_NAME,
  PURCHASE_BOOSTER_ITEM_STORE_NAME,
} from "../purchase-booster-item/purchase-booster-item.constant";

export function PurchaseBoosterItemUpdateBoostDataContainer() {
  let { purchaseId } = useParams();
  const dispatch = useDispatch();
  const { state, pageLoading, boostItemState } = useSelector((state) => ({
    state: state[PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_STORE_NAME],
    boostItemState: state[PURCHASE_BOOSTER_ITEM_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
  }));

  const boostData = getRequestData(boostItemState.request)?.[
    PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST
  ];

  const boostDataSuccess =
    isRequestSuccess(boostItemState.request) && !!boostData;

  React.useEffect(() => {
    dispatch(resetPurchaseBoosterItemUpdateBoostDataFormState());
  }, []);

  const purchaseBoosterItemUpdateBoostDataFormSendData = (values) => {
    const data = convertPurchaseBoosterItemUpdateBoostDataFormData(values);

    data.purchaseId = purchaseId;
    data.boostId = boostData[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ID];
    dispatch(uploadPurchaseBoosterItemUpdateBoostDataForm(data));
  };

  const getInitialValue = () => {
    return {
      [PURCHASE_BOOSTER_ITEM_UPDATE_BOOST_DATA_DATA_NAME.BOOST_ACTUAL]:
        boostData?.[PURCHASE_BOOSTER_ITEM_DATA_NAME.BOOST_ACTUAL],
    };
  };

  return (
    <PurchaseBoosterItemUpdateBoostDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      initialValue={getInitialValue()}
      validation={purchaseBoosterItemUpdateBoostDataFormValidation}
      onSubmitForm={purchaseBoosterItemUpdateBoostDataFormSendData}
      pageLoading={pageLoading}
      isBoost={boostDataSuccess}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
