import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";

export const FileListComponent = ({ list, item, handleDelete = null }) => {
  const handleRedirect = (url) => () => window.open(url, "_blank");
  0;
  const handleDeleteF = (id) => () => handleDelete(id);

  if (item) {
    list = [item];
  }

  if (!list) return null;

  if (!list[0]) return null;

  const navigate = useNavigate();

  return (
    <Grid container spacing={item ? 0 : 2}>
      {list.map((file, index) => (
        <Grid item key={file.id || index}>
          <Chip
            sx={{ maxWidth: "70%" }}
            id={file.id || index}
            clickable
            onClick={handleRedirect(file.url)}
            label={file.name}
            onDelete={handleDelete ? handleDeleteF(index, file.id) : null}
          />
        </Grid>
      ))}
    </Grid>
  );
};
